<template lang="pug">
div(
  v-if="$can('view-calculation-external-information') && canUseNexusMiddleware"
)
  section.section
    p
    table.table.is-bordered.is-fixed(v-if="tableData")
      thead(v-if="tableData || tableData.length")
        tr
          th ID
          th Calculation Type
          th Calculation Batch Number
          th Calculation External Id
          th.cell-wide Invoice External Id
          th Invoice External Batch Number
      tbody.tableTr
        tr(v-if="loadingExternalHistoryLog")
          td.center-text(colspan="100")
            img(src="../../assets/images/comps/loader.svg")
        tr(v-if="!tableData || tableData.length == 0")
          td(colspan="100")
            | No data in table
        tr(v-for="item in tableData", v-if="!loadingExternalHistoryLog")
          td {{ item.id }}
          td.cell-wide
            span {{ item.calculationType }}
          td {{ item.calculationBatchNumber }}
          td {{ item.calculationExternalId }}
          td.cell-wide
            span {{ item.invoiceExternalId }}
          td.cell-wide
            span {{ item.invoiceExternalBatchNumber }}

    pagination(
      v-if="pagination.total_pages",
      :total-pages="pagination.total_pages",
      :current-page="pagination.current_page",
      @page-changed="pageHasChanged"
    )
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ref, onMounted } from "vue";
import Pagination from "../../components/Pagination.vue";

export default {
  name: "ExternalHistoryLog",
  components: {
    Pagination,
  },
  data() {
    return {
      tableData: [],
      pagination: {},
      loadingExternalHistoryLog: true,
    };
  },
  mounted() {
    this.getPaymentLogs();
  },
  methods: {
    ...mapActions({
      getExternalHistoryLog: "getExternalHistoryLog",
    }),
    async getPaymentLogs(clickedPage) {
      const params = {
        page: clickedPage,
        shift_id: this.$route.params.shiftId,
      };
      try {
        const res = await this.getExternalHistoryLog(params);
        this.tableData = res.data.data;
        this.pagination = res.data.meta.pagination;
        this.loadingExternalHistoryLog = false;
      } catch (err) {
        console.log(err);
      }
    },
    async pageHasChanged(clickedPage) {
      await this.getPaymentLogs(clickedPage);
    },
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped></style>
