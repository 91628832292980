import { render, staticRenderFns } from "./UploadForm.vue?vue&type=template&id=3fe34286&scoped=true"
import script from "./UploadForm.vue?vue&type=script&lang=js"
export * from "./UploadForm.vue?vue&type=script&lang=js"
import style0 from "./UploadForm.vue?vue&type=style&index=0&id=3fe34286&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_prettier@3.2.5_pug@3.0.2_underscore@1.13.6_vue-template-_6c5xy3ku26i64jowneuu67eca4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fe34286",
  null
  
)

export default component.exports