<template lang="pug">
table.table
  thead
    tr
      //- th Select All
      th NAME
      th STATUS
      th REGION
      //- th USERS
      th CATEGORIES/GRADES
      //- th REGULAR WORKERS
      th
        span(
          v-if="gPermissions.canViewRulesCalcEng || gPermissions.canEditRulesCalcEng"
        ) RULES
      th 
      th
      //- th
      th
        span(v-if="gPermissions.canViewDefaultShiftTimes") DEFAULT SHIFT TIMES
      th {{ this.canUseNexusMiddleware ? "CLIENT REFERENCE ID" : "EXT. SYS. ID" }}
      //- th CLIENT USER MANAGEMENT
      th
        span(v-if="gPermissions.canEditBreakTimes") BREAK TIMES
      //- FEE TYPE
      th
        span(v-if="gPermissions.canManageRates") RATES
      th
        span(
          v-if="(gPermissions.canEditShiftConfirmInterval && canUseShiftConfirm) || shiftConfirmEnablement"
        ) CONFIRM INTERVAL
      th(style="text-align: center")
        //- i.fa.fa-refresh(title="Auto Repush interval")
        span(
          title="Auto-repush interval per client",
          v-if="gPermissions.canSetAutorepush"
        ) AUTO REPUSH
      th
        span(v-if="gPermissions.canClientProfileView") PROFILE
  template(v-for="client in clients")
    clients-table-row(
      :active_row="active_row_id == client.id",
      :client="client",
      @activate-row="activateRow(client.id)",
      @changed-status="onChangedStatus"
    )
    tbody(v-if="isLoading(client)")
      tr
        td.loader-wrapper(colspan="100")
          img(src="../../assets/images/comps/loader.svg")
    template(v-if="renderSubTable && active_row_id == client.id")
      client-sub-header(
        :hasCommunityAllowance="hasCommunityAllowance",
        :has-on-call-shifts="canUseOnCallShifts",
        :has-sleepover-shifts="canUseSleepoverShifts"
      )
      transition(name="custom-animation")
        client-sub-table(
          :client="client",
          :has-on-call-shifts="canUseOnCallShifts",
          :has-sleepover-shifts="canUseSleepoverShifts",
          :isFetchedTempsOnce="isFetchedTempsOnce",
          @is-fetched-temps-once="onFetchedTempsOnce",
          @hasCommunityAllowance="hasCommunityAllowance = $event",
          @on-show-modal="$emit('on-show-modal', $event)"
        )
</template>

<script>
import { mapActions } from "vuex";
import ClientsTableRow from "./ClientsTableRow.vue";
import ClientSubTable from "./ClientSubTable.vue";
import ClientSubHeader from "./ClientSubHeader.vue";
import { parseErrors } from "../../lib/helpers/function";

export default {
  components: {
    ClientsTableRow,
    ClientSubTable,
    ClientSubHeader,
  },
  props: ["clients"],
  data() {
    return {
      active_row_id: 0,
      clientActive: null,
      renderSubTable: false,
      hasCommunityAllowance: false,
      isFetchedTempsOnce: false,
    };
  },
  methods: {
    ...mapActions({
      fetchClientDetails: "locationClientManagement/fetchClientDetails",
    }),
    onChangedStatus() {
      this.$emit("changed-status");
    },
    activateRow(id) {
      if (this.gPermissions.canClientProfileView) {
        this.active_row_id = this.active_row_id === id ? false : id;
        if (!this.active_row_id) return;

        this.openSubTable(id);
      }
    },
    async openSubTable(id) {
      this.renderSubTable = false;
      const include = [
        // "locations.users",
        // "locations.temps",
        "locations.categories.subcategories",
        "locations.locationOnCallInfo",
        "locations.locationSleepoverInfo",
        // "region",
        "sectors",
        // "calcEngine",
        "withArchived",
      ].join(",");
      try {
        await this.fetchClientDetails({ id, include });
        this.renderSubTable = true;
      } catch (err) {
        console.warn(err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    onFetchedTempsOnce() {
      this.isFetchedTempsOnce = true;
    },
    isLoading(client) {
      return !this.renderSubTable && this.active_row_id === client.id;
    },
  },
};
</script>

<style lang="scss">
.slide-down-enter-active {
  transition: all 0.6s;
}

.slide-down-leave-active {
  transition: all 0.3s;
}

.slide-down-enter {
  transform: translateY(-50px);
}

.slide-down-leave-to {
  transform: translateY(-50px);
  z-index: 1000;
}

.slide-down-leave {
  height: 0;
}
</style>
<style lang="scss" scoped>
.loader-wrapper {
  text-align: center;
  background: #f2f7fa;
}
</style>
