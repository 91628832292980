/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from "vue";
import App from "@/components/App.vue";
import router from "@/router.js";
import Acl from "vue-acl";
import Jwt from "@/lib/helpers/Jwt.js";
import jwtDecode from "jwt-decode";
import axios from "axios";
import VueAxios from "vue-axios";
import InlineSvg from "vue-inline-svg";
import Toasted from "vue-toasted";
import Dropdown from "hsy-vue-dropdown";
import SharedDirectives from "@/directives";
import SharedComponents from "@/components/shared";
import globalFilters from "@/filters";
import store from "@/vuex/store.js";
import "@/assets/styles/vars.css";
import "@/assets/styles/globalStyles.scss";
import VTooltip from "v-tooltip";
Vue.use(VTooltip);
import InputFacade from "vue-input-facade";
Vue.use(InputFacade);
import auth from "@/lib/auth.js";
axios.defaults.baseURL = window.ApiURL;
axios.interceptors.request.use((config) => {
    config.headers["Authorization"] = Jwt.getAuthHeader();
    config.headers["Accept"] = "application/json";
    return config;
});
Vue.use(VueAxios, axios);
Vue.use(Toasted);
Vue.use(Dropdown);
Vue.use(SharedDirectives);
Vue.use(SharedComponents);
Vue.use(globalFilters);
Vue.use(VTooltip);
Vue.component("InlineSvg", InlineSvg);
// only-numbers-commas
Vue.directive("only-numbers-commas", {
    bind(el, binding, vnode) {
        // Define the input handler function
        el.inputHandler = function (event) {
            const cleanedValue = el.value.replace(/[^0-9,]/g, "");
            if (el.value !== cleanedValue) {
                el.value = cleanedValue;
                if (vnode.componentInstance) {
                    vnode.componentInstance.$emit("input", cleanedValue);
                }
                else {
                    vnode.elm.dispatchEvent(new Event("input"));
                }
            }
        };
        // Add the event listener
        el.addEventListener("input", el.inputHandler);
    },
    unbind(el) {
        // Remove the event listener
        el.removeEventListener("input", el.inputHandler);
    },
});
// remove-spaces-from-input
Vue.directive("remove-spaces-from-input", {
    bind(el, binding, vnode) {
        // Define the input handler function
        el.inputHandler = function (event) {
            const cleanedValue = el.value.replace(/\s+/g, "");
            if (el.value !== cleanedValue) {
                el.value = cleanedValue;
                if (vnode.componentInstance) {
                    vnode.componentInstance.$emit("input", cleanedValue);
                }
                else {
                    vnode.elm.dispatchEvent(new Event("input"));
                }
            }
        };
        // Add the event listener
        el.addEventListener("input", el.inputHandler);
    },
    unbind(el) {
        // Remove the event listener
        el.removeEventListener("input", el.inputHandler);
    },
});
// click-outside
Vue.directive("click-outside", {
    bind: function (element, binding, vnode) {
        element.clickOutsideEvent = function (event) {
            //  check that click was outside the el and his children
            if (!(element === event.target || element.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
                // binding.value(); run the arg
            }
        };
        document.body.addEventListener("click", element.clickOutsideEvent);
    },
    unbind: function (element) {
        document.body.removeEventListener("click", element.clickOutsideEvent);
    },
});
// end click-outside
import globalMixin from "@/shared/globalMixin.js";
Vue.mixin(globalMixin);
function callNextGuard(to, from, next, context) {
    // console.warn('==== Calling next', to, from, context);
    next(context);
}
router.beforeEach(async function (to, from, next) {
    // prevent access to login & register once signed in...
    if (to.matched.some((record) => record.meta.requiresGuest) &&
        Jwt.loggedIn()) {
        const token = Jwt.getToken();
        const jwtd = jwtDecode(token);
        if (jwtd.roles.indexOf("temp") === -1 && to.name !== "password.recovery") {
            callNextGuard(to, from, next, { path: "/dashboard" });
        }
        else {
            callNextGuard(to, from, next, { path: "/temp-calendar" });
        }
    }
    else if (from.matched.some((record) => record.meta.requiresAuth) &&
        !Jwt.loggedIn()) {
        router.go("/");
    }
    else if (to.matched.some((record) => record.meta.requiresAuth)) {
        // routes with meta.requiresAuth attr require user to be authorized
        // if not, redirect to /auth/signin...
        if (!Jwt.loggedIn()) {
            callNextGuard(to, from, next, {
                path: "/auth/signin",
                query: { redirect: to.fullPath },
            });
        }
        else {
            callNextGuard(to, from, next);
        }
    }
    else if (to.name === "password.recovery") {
        auth.blacklistToken().then(() => {
            callNextGuard(to, from, next);
        });
    }
    callNextGuard(to, from, next); // make sure to always call next()!
});
const vueInstance = new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
Vue.use(Acl, { router: router, init: "" });
Vue.use(Acl, { router: router, init: "any" });
export default vueInstance;
