<template lang="pug">
div
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    :size="modalSize"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @evtCancelShift="shiftCancellation",
      @dispose-modal="modalVisible = false",
      @cancel-modal="modalVisible = false",
      @confirm-modal="closeAndRefreshData"
    )
  section.section
    h1.title.is-3
      <router-link :to="{ name: 'shiftSchedule', query:{status:this.$route.query.status?.status} }">
        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="23" viewBox="0 0 13 23">
          <path fill="#7591AE" stroke="#7991AE" d="M1.44295652,10.3728261 L10.8125217,1.00326087 C11.0429565,0.772826087 11.0429565,0.40326087 10.8125217,0.172826087 C10.582087,-0.0576086957 10.2125217,-0.0576086957 9.98208696,0.172826087 L0.195130435,9.95978261 C-0.0353043478,10.1902174 -0.0353043478,10.5597826 0.195130435,10.7902174 L9.98208696,20.5728261 C10.0951304,20.6858696 10.2473043,20.7467391 10.3951304,20.7467391 C10.5429565,20.7467391 10.6951304,20.6902174 10.8081739,20.5728261 C11.0386087,20.3423913 11.0386087,19.9728261 10.8081739,19.7423913 L1.44295652,10.3728261 Z" transform="translate(1 1)"/>
        </svg>
      </router-link>
      span.sep
      i.fa.fa-clock-o
      | Shift details
    .loading-wrapper(v-if="isLoadingShiftData", style="text-align: center")
      img(src="@/assets/images/comps/loader.svg")
    template(v-else)
      table.table.is-bordered(v-if="shift")
        tbody
          tr(colspan=2)
            th.one-td <span>SHIFT ID: {{ shift.id }}</span> <span>DATE: {{ shiftStartTime }}</span> <span>STATUS: {{ shift.status }}</span>

      table.table.is-bordered(v-if="shift")
        tbody
          tr
            th NAME
            td {{ hasProperty(shift, "temp") ? shift.temp.name : "" }}
          tr
            th GENDER
            td {{ hasProperty(shift, "shiftRequest") ? shift.shiftRequest.gender : "" }}
          tr
            th LOCATION
            td {{ hasProperty(shift, "location") ? shift.location.client.name : "" }}
          tr
            th WARD
            td {{ hasProperty(shift, "location") ? shift.location.name : "" }}
          tr
            th NOTE
            td {{ hasProperty(shift, "shiftRequest") ? shift.shiftRequest.notes : "" }}
          tr
            th {{ getEirCodeLabelKey }}
            td {{ hasProperty(shift, "temp") ? shift.temp.eircode : `No ${getEirCodeLabelKey}` }}
          tr
            th SPECIAL SHIFT
            td {{ shift.shiftRequest.specialShiftName ? shift.shiftRequest.specialShiftName : "" }}
          tr
            th TIME
            td {{ getTimeRange }}
          tr(v-if="shift.type === 'ONCL'")
            th STANDBY / ON CALL
            td {{ getShiftPartsRange("ONCL") }}
          tr(v-if="shift.type === 'SLPO'")
            th SLEEPOVER
            td {{ getShiftPartsRange("SLPO") }}
          tr(v-if="shift.type === 'SLPO' && isAwake")
            th AWAKE
            td {{ getShiftPartsRange("AWKE") }}
          tr
            th TOTAL TIME
            td {{ totalTime }}
          tr(v-if="shift.signedOffBy")
            th SIGNED OFF BY
            td {{ shift.signedOffBy.name }}
          tr(v-if="shift.createdAt && shift.createdAt.date")
            th FIRST CREATED AT
            td {{ formateCreatedAtDate(shift.createdAt.date) }}
          tr(v-if="shift.userCreated?.name")
            th FIRST CREATED BY
            td {{ shift.userCreated.name }}
          tr(v-if="canManageTags && shift.tags")
            th ROUND TAG
            td
              .flex-wrap
                multi-select(
                  :disabled="!canManageTags",
                  :options="activityTagsList",
                  :value="shift.tags?.[0]",
                  select-label="",
                  selected-label="",
                  deselect-label="",
                  track-by="id",
                  label="name",
                  placeholder="Select Tag",
                  style="width: 250px",
                  @select="updateTags"
                )
                  span(slot="noResult") Nothing found.
                button.button.is-generic-app-blue.is-caps-lock.reset-filters(
                  @click="onClickQuoteBuilder",
                  v-if="isRound3TagQuoteable"
                )
                  span Round 3 Quote Builder

          template(v-if="canUseShiftConfirm || shiftConfirmEnablement")
            tr(
              v-if="($can('confirm-temp-for-shift') && canConfirmTemp) || shiftConfirmEnablement"
            )
              th SHIFT STATUS
              td
                .flex-wrap
                  //- span(v-if="canConfirmTemp") Status: ({{ canConfirmTemp }})
                  button.button.is-generic-app-blue.is-caps-lock.reset-filters(
                    @click="onShiftConfirm"
                  )
                    span Confirm
                  button.button.is-generic-app-blue.is-caps-lock.reset-filters(
                    @click="onShiftCancel"
                  )
                    span Cancel
            tr(
              v-if="$can('view-shift-confirmation-engine-note') || shiftConfirmEnablement"
            )
              th SHIFT CONFIRM NOTE
              td {{ shift.shiftNote }}
            tr(
              v-if="canUseNexusMiddleware && $can('can-use-shift-price-overwrite')"
            )
              th SHIFT OVERWRITE NOTE
              td {{ shift?.shiftPriceOverwriteNote ?? "" }}

      table.table.is-bordered.is-fixed(
        v-if="getShiftHistory || (responses && responses.length)"
      )
        tbody
          tr(v-if="getShiftHistory && getShiftHistory.length")
            th TIME
            th NAME
            th WARD
            th.cell-wide SHIFT CHANGES
            th ACTION
          tr(v-for="log in getShiftHistory")
            td {{ formatDateTime(log.createdAt) }}
            td(v-if="log.user !== null && log.user !== undefined") {{ log.user.name }}
            td(v-else)
            td {{ getShiftLocation(log) }}
            td.cell-wide
              span(:style="pre") {{ getLogHistory(log) }}
            td
              span.generic-app-tag {{ log.type }}
          tr(v-for="response in responses")
            td
            td {{ response.name }}
            td
            td
            td
              span.generic-app-tag AWAITING
      span.label(
        v-if="canUseShiftConfirm && $can('view-shift-confirmation-engine-note') && confirmationLogs && confirmationLogs.length"
      )
        | Shift confirmation
      table.table.is-bordered.is-fixed(
        v-if="canUseShiftConfirm && $can('view-shift-confirmation-engine-note') && confirmationLogs && confirmationLogs.length"
      )
        tbody
          tr(v-if="confirmationLogs && confirmationLogs.length")
            th TIME
            th NAME
            th WARD
            th.cell-wide CONTACT
            th ACTION
          tr(v-for="log in confirmationLogs")
            td {{ formatDateTime(log.time) }}
            td {{ log.name }}
            td {{ log.ward }}
            td(:title="log.contact_email") {{ log.contact }}
            td
              span.generic-app-tag {{ log.action }}
  simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";
import moment from "moment";
import {
  momentRangeFromApi,
  momentDateFromApi,
  momentTimeFromApi,
  momentDiff,
  parseDateObjWithTimeZone,
  parseErrors,
} from "../../lib/helpers/function.js";
import ShiftDetailsRoundModal from "./ShiftDetailsRoundModal.vue";
import CancellationReason from "../in-modal/CancellationReason.vue";
import ShiftConfirmDialog from "../ShiftScheduleViews/ShiftConfirmDialog.vue";
// import { LOG_TYPES } from '../config/shifts.js'
// import CommentBox from "../CommentBox.vue";

export default {
  name: "shiftDetailsRegular",
  components: {
    // CommentBox,
    ShiftDetailsRoundModal,
    CancellationReason,
    ShiftConfirmDialog,
  },
  data() {
    return {
      modalData: null,
      modalVisible: false,
      inmodal: "",
      shift: null,
      notes: "",
      logMsgs: [],
      responses: [],
      pre: {
        whiteSpace: "pre-wrap",
        fontStyle: "italic",
      },
      showUser: false,
      includes: [
        "shiftLogs.user",
        "shiftLogs.shift.location",
        // 'shiftLogs.previousShiftState',
        // 'shiftLogs.currentShiftState',
        "location.client.locations",
        "previousTemp",
        "temp",
        "shiftRequest",
        "shiftRequest.subcategories",
        "signedOffBy",
        "responses",
        "shiftParts",
        "userCreated",
      ],
      getShiftHistory: [],
      activityTagsList: [],
      confirmationLogs: [],
      isSaveLoading: false,
      isLoadingShiftData: false,
    };
  },
  computed: {
    ...mapGetters(["getShift"]),
    tabs() {
      return [
        {
          id: 1,
          name: "Regular shift changes",
          label: "Regular shift changes",
          routeName: "Regular",
        },
        {
          id: 2,
          name: "Corrections",
          label: "Corrections",
          routeName: "Corrections",
          // isInactive: !this.categories?.length || !this.rateSectorSelected,
        },
      ];
    },
    filteredTabs() {
      return this.tabs.filter((tab) => {
        console.log(tab);
        const hasPerm =
          "permission" in tab
            ? typeof tab.permission === "string"
              ? this.$can(tab.permission)
              : tab.permission
            : true;
        const hasSysPerm = tab.systemPermissions?.length
          ? tab.systemPermissions.every(
              (tabPerm) => this.initialStatus[tabPerm]
            )
          : true;
        const hasCondition = tab.conditions?.length
          ? tab.conditions.every(Boolean)
          : true;
        return hasPerm && hasSysPerm && hasCondition;
      });
    },
    getEirCodeLabelKey() {
      return (
        this.$store.getters.getColumnNameLocl("usr_eircode") || ""
      ).toUpperCase();
    },
    shiftStartTime() {
      return parseDateObjWithTimeZone(this.shift.startTime);
    },
    shiftEndTime() {
      return parseDateObjWithTimeZone(this.shift.endTime);
    },
    getTimeRange() {
      return momentRangeFromApi(this.shiftStartTime, this.shiftEndTime, "TBC");
    },
    totalTime() {
      return momentDiff(this.shiftStartTime, this.shiftEndTime);
    },
    isAwake() {
      return !!this.shift.shiftParts?.find(
        (partObj) => partObj.type === "AWKE"
      );
    },
    canManageTags() {
      return (
        this.initialStatus.can_use_shift_activity_tags &&
        this.$can("edit-shift-activity-tag")
      );
    },
    canViewTags() {
      return (
        this.initialStatus.can_use_shift_activity_tags &&
        (this.$can("view-shift-activity-tag") ||
          this.$can("edit-shift-activity-tag"))
      );
    },
    isRound3TagQuoteable() {
      if (this.shift.tags?.length) {
        const firstTag = this.shift.tags[0];
        return (
          firstTag.id ===
            this.activityTagsList.find((obj) => obj.name === "Round 3")?.id &&
          this.shift.canBeQuoted
        );
      }
      return false;
    },
    canConfirmTemp() {
      return this.shift.canConfirmTemp;
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    ...mapActions([
      "fetchShiftHistory",
      "getShiftsActivityTags",
      "patchShift",
      "cancelShift",
      "getShiftConfirmationLogs",
    ]),
    async loadShiftData() {
      this.isLoadingShiftData = true;
      const includes = this.includes;

      if (this.initialStatus.can_use_shift_activity_tags) {
        includes.push("tags");
        includes.push("canBeQuoted");
      }

      if (this.canUseShiftConfirm || this.shiftConfirmEnablement) {
        includes.push("tempConfirmation");
      }
      if (
        this.canUseNexusMiddleware &&
        this.$can("can-use-shift-price-overwrite")
      ) {
        includes.push("shiftPriceOverwriteNote");
      }

      const includesStr = includes.join(",");

      // this.shift = this.getShift(this.$route.params.shiftId) || null
      try {
        const data = await this.fetchShiftHistory({
          id: this.$route.params.shiftId,
          include: includesStr,
        });

        this.shift = data;
        this.getShiftHistory = data.shiftLogs;
        this.responses = data.responses;

        /**
         * getShiftHistory now available,
         * collect entries into local logMsgs component property
         */
        for (const log of this.getShiftHistory) {
          const msgs = this.getLogHistory(log);
          console.log("shift msg", msgs);
          this.logMsgs.push(msgs);
        }
      } catch (err) {
        console.warn(err.message);
      } finally {
        this.isLoadingShiftData = false;
      }
    },
    async initData() {
      await this.loadShiftData();

      if (this.canViewTags) {
        this.fetchShiftsActivityTags();
      }

      if (this.canUseShiftConfirm) {
        this.fetchConfirmationLogs();
      }
    },
    async fetchShiftsActivityTags() {
      try {
        const res = await this.getShiftsActivityTags();
        this.activityTagsList = res.data.data;
      } catch (e) {
        console.warn("error", e);
      }
    },
    async fetchConfirmationLogs() {
      try {
        const res = await this.getShiftConfirmationLogs({
          id: this.$route.params.shiftId,
        });
        this.confirmationLogs = res.data.data;
      } catch (e) {
        console.warn("error", e);
      }
    },
    confirmAlert(message) {
      return new Promise((resolve) => {
        const alert = {
          title: `${message}`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve("");
          },
        };
        this.$refs.simplert.openSimplert(alert);
        // simplertRef.value.openSimplert(alert);
      });
    },
    async updateTags(e, itemId) {
      if (this.shift.tags?.map((tag) => tag.id).includes(e.id)) {
        console.log("Same tag, skipping", e.id);
        return;
      }

      const isRound3 =
        this.activityTagsList.find((obj) => obj.name === "Round 3")?.id ===
        e.id;

      if (isRound3) {
        try {
          await this.confirmAlert(
            "Please note that once a shift has been set to Round 3, it cannot be reversed to another tag"
          );
        } catch (err) {
          console.warn(err.message);
          return;
        }
      }

      const shiftTag = e;

      this.patchShift({
        id: this.shift.id,
        data: {
          shift_activity_tag_id: shiftTag.id,
        },
      })
        .then(() => {
          this.$toasted.info("Activity tag changed.").goAway(1500);
          this.shift.tags = [shiftTag];
        })
        .catch((error) => {
          const errs = parseErrors(error, "Error changing activity tag");
          this.$toasted.error(errs).goAway(2500);
        });
    },
    closeAndRefreshData() {
      this.loadShiftData();
      this.modalVisible = false;
    },
    getShiftLocation(log) {
      const shiftLocation =
        (this.shift.location && this.shift.location.name) || "";
      const locFromLog =
        log.shift && log.shift.location && log.shift.location.name;
      return locFromLog || shiftLocation;
    },
    onClickQuoteBuilder() {
      this.showModal(
        "shift-details-round-modal",
        {
          shift: this.shift,
        },
        "Round 3 Quote Builder",
        850
      );
    },
    showModal(
      modal,
      modalData = {},
      modalTitle = "Modal title",
      modalSize = 700
    ) {
      // console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalSize = modalSize;
      this.modalVisible = true;
    },
    formateCreatedAtDate(date) {
      const formatted = moment(date, "YYYY-MM-DD HH:mm:ss").format(
        "DD/MM/YYYY HH:mm:ss"
      );
      return formatted;
    },
    getLogHistory(log) {
      let msgs = "";
      if (Array.isArray(log.shiftLogDetails)) {
        for (const logDet of log.shiftLogDetails) {
          msgs += this.genMessages(logDet) + "\n";
        }
      }
      return msgs;
    },
    genMessages(logDet) {
      const { name, oldval, newval } = logDet;
      const getOldVal = (oldVal) => {
        if (oldVal) {
          return `from ${oldval} `;
        }
        return "";
      };
      return `${name} changed ${getOldVal(oldval)}to ${newval}`;
    },
    // writeLogMsg (log) {
    //   return constructMessages(
    //     getObjectDiff(log.currentShiftState, log.previousShiftState), log
    //   ).join('\n')
    // },
    /***
     * CHecks if object has a property prop
     * TODO, register this one as a global helper
     * so we can use it anywhere!
     * prop can be a dot delimited string of nested properties...
     */
    hasProperty(obj, prop) {
      return _.has(obj, prop);
    },
    formatTime(time) {
      return momentTimeFromApi(time);
    },
    formatDate(time) {
      return momentDateFromApi(time);
    },
    formatDateTime(time) {
      return `${this.formatDate(time)} ${this.formatTime(time)}`;
    },
    // message: function (logType) {
    //   return _.findKey(LOG_TYPES, { type: logType })
    // }
    getShiftPartsRange(code) {
      if (this.shift.shiftParts?.length > 0) {
        const datePartObj = this.shift.shiftParts?.find(
          (partObj) => partObj.type === code
        );
        const startParsed = parseDateObjWithTimeZone(datePartObj.from);
        if (startParsed) {
          const endParsed = parseDateObjWithTimeZone(datePartObj.to);
          return momentRangeFromApi(startParsed, endParsed, "TBC");
        }
      }
      return "--";
    },
    onShiftConfirm() {
      this.showModal(
        "ShiftConfirmDialog",
        { shiftIds: [{ id: this.shift.id }] },
        "Shift confirm dialog"
      );
    },
    onShiftCancel() {
      this.showModal(
        "CancellationReason",
        { shiftIds: [{ id: this.shift.id }] },
        "Cancellation Reason"
      );
    },
    shiftCancellation(cancellation) {
      this.isSaveLoading = true;
      console.warn("CANCELLATION ARG", cancellation);
      this.cancelShift(cancellation.data)
        .then(() => {
          this.isSaveLoading = false;
          this.$toasted.info("This shift has been cancelled.").goAway(1500);
          this.modalVisible = false;
          this.loadShiftData();
        })
        .catch((error) => {
          this.isSaveLoading = false;
          this.$toasted.error("Error cancelling shift.").goAway(1500);
          // cancellation.context.errors.record(error.response.data.errors);
          // With Toaster?
          // let errs = parseErrors(error);
          // this.$toasted.error(errs).goAway(4500);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
span.sep {
  border: 1px solid #d6e0e5;
  margin: 0 0.8em;
}

.tag {
  border-radius: 3px;
}

table {
  border-radius: 4px;

  &.is-fixed {
    table-layout: fixed;
    border-collapse: collapsed;
  }
}

.comment-box {
  margin-bottom: 1em;
}

tbody {
  .one-td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  th {
    height: 50px;
    border: 1px solid #b2cddb;
    background-color: #eff5f5;
  }

  th,
  td {
    width: 50%;
    vertical-align: middle;
  }

  .cell-wide {
    width: 80%;
  }

  td.cell-wide {
    color: grey;
  }

  .generic-app-tag {
    background-color: #dbe1e5;
    border-radius: 3px;
    padding: 0.2em 0.5em;
  }
}

.extra-section {
  display: flex;
  justify-content: flex-end;
}

.flex-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label {
  font-size: 20px;
  margin-bottom: 10px;
  color: var(--color-secondary-50);
}
</style>
