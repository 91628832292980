<template lang="pug">
.shift-schedule-list
  template(v-if="$can('view-sectors')")
    .shift-filters
      .field
        p.control
          three-drop-filter(
            :sectors="sectors",
            :threeData="threeDropFilterObject",
            :resetFilters="resetRegionFilterBool",
            @search-three-drop="searchThreeDrop"
          ) 
          //- v-model="threeDropFilterObject",
    hr
  .shift-filters.filters-sub
    .field.shift-status(v-if="$can('view-sectors')")
      label.label Status
      //- label.label Open / Filled
      p.control
        multi-select(
          :options="optionsShiftStatus",
          track-by="name",
          label="name",
          :multiple="false",
          :showLabels="false",
          v-model="filter.shiftStatus",
          @select="changeShiftStatus",
          @remove="removeShiftStatus"
        )
    button.button.is-generic-app-blue.is-caps-lock.reset-filters(
      @click="resetFilters"
    )
      span Reset filters
    button-group-filter(
      :active="datePickerActiveButton",
      v-model="groupFilter",
      @click="changeDateGroup",
      @change-active="onChangeDateFilters"
    )
    span.info-tooltip(
      v-tooltip="{ content: `The page is filtered to the previous 6 months by default for performance purposes. In case you need to review older shifts, please adjust the filters accordingly.` }"
    )
      span.fa.fa-info.popup
    .field.shift-datetime
      label.label From
      p.control(:class="{ 'predefined-date': predefinedDateFrom }")
        date-picker(
          :date="filter.from",
          :option="dateTimeOption",
          @change="dateInputChanged"
        )
    .field.shift-datetime
      label.label To
      p.control
        date-picker(
          :date="filter.to",
          :option="dateTimeOption",
          @change="dateInputChanged"
        )
  shifts-table(
    :shifts="shifts",
    :visibleColumns="visibleColumns",
    :visibleToolIcons="visibleToolIcons",
    :filtersTable="filterSubTable",
    :resetClientCo="clientTableCo",
    :data-is-loading="dataIsLoading",
    :isLoadingClient="isLoadingClient",
    :shift-filters-instance="shiftFiltersInstance",
    @shiftSelected="shiftSelected",
    @search-by-filter="searchByFilter",
    @sort-results="sortResults",
    @get-data="getShifts(fetchParams)",
    v-if="isSubTableShow"
  )
  pagination(
    v-if="pagination && pagination.length !== 0 && !isPaginationLoading",
    :total-pages="pagination.total_pages || 1",
    :current-page="pagination.current_page",
    :isForceChange="false",
    @page-changed="pageHasChanged"
  ) 
  .spinner-wrap(v-if="isPaginationLoading")
    img(src="../../assets/images/comps/loader.svg")
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import MultiSelect from "vue-multiselect";
import Pagination from "../Pagination.vue";
import ShiftsTable from "../tables/ShiftsTable.vue";
import calendarOptions from "../common/calendarOptions.js";
import ButtonGroupFilter from "../shared/ButtonGroupFilter.vue";
import ThreeDropFilter from "../shared/ThreeDropFilter.vue";
import auth from "../../lib/auth.js";
// import { pickNonFalsyKeepZero } from '../../lib/helpers/function.js'
import moment from "moment";
import { Evt } from "../../lib/helpers/Evt.js";
import {
  VISIBLE_COLUMNS,
  VISIBLE_TOOL_ICONS,
} from "../../components/config/shifts.js";

import { STATUS } from "../config/shifts.js";
import _ from "underscore";
import axios from "axios";

export default {
  name: "shiftScheduleList",
  components: {
    ShiftsTable,
    Pagination,
    MultiSelect,
    ButtonGroupFilter,
    ThreeDropFilter,
  },
  /**
   * status can be: pending|active|completed|never_filled|cancelled
   */
  props: {
    isFirstLoad: { type: Boolean, default: true },
    predefinedDateFrom: { type: Boolean, default: true },
    activeTab: { type: Object, required: true },
    active: { type: Boolean, default: true },
    filtersGlobal: { type: Object },
    actionArr: { type: Array },
    cancelTokenShifts: { required: true },
    cancelTokenShiftsPagination: { required: true },
    isLoadingClient: { default: false },
    shiftFiltersInstance: { required: true },
  },
  data() {
    return {
      auth: auth,
      dateTimeOption: null,
      MultiShift: null,
      dataIsLoading: false,
      /*
       * Three drop-down filters Sector -> Client -> Category
       */
      threeDropFilterObject: {
        "sectors[][id]": [],
        "clients[][id]": null,
        "categories[][id]": null,
      },
      resetRegionFilterBool: false,
      clientsTableFiltered: null,
      clientTableCo: 1,
      /*
       * Group filter for today, tomorrow, this week, next week
       */
      groupFilter: {},
      filterSubTable: null,

      /**
       * Defines which columns should be visible
       */
      visibleColumns: VISIBLE_COLUMNS,
      visibleToolIcons: VISIBLE_TOOL_ICONS,

      optionsShiftStatus: [{ name: "Filled" }, { name: "Open" }],
      filter: {
        from: { time: "" },
        to: { time: "" },
        interval: null,
        shiftStatus: null,
      },

      sort: {
        by: "start_time",
        direction: "desc",
      },

      pauseFetchShifts: false,
      isSubTableShow: true,

      search: "",

      fetchParams: {},
      includes: [
        "shiftRequest.subcategories",
        "shiftRequest.createdBy",
        "location.client.locations",
        "previousTemp",
        "signedOffBy",
        "temp",
        "shiftParts",
      ],
      datePickerActiveButton: "",

      lastApiCallParams: {},

      //shouldPaginationLoadingBeActivated is used to stop pagination loading spinner on page change
      shouldPaginationLoadingBeActivated: true,
      isPaginationLoading: false,
    };
  },

  watch: {
    threeDropFilterObject: {
      handler(val) {
        // Reset table client when changing region
        const updateClientCoFunc = () => {
          if (this.clientTableCo > 60) {
            this.clientTableCo = 2;
          } else {
            this.clientTableCo++;
          }
        };

        const clients = this.fetchParams["clients[][id]"]?.length;
        // const clients = Array.isArray(this.fetchParams.clients) && this.fetchParams.clients.length;
        const locations = this.fetchParams["locations[][id]"]?.length;
        if (val["sectors[][id]"]?.length && (clients || locations)) {
          this.pauseFetchShifts = true;
          this.resetSubTableAPI("clients");
          updateClientCoFunc();
          this.pauseFetchShifts = false;
        }
      },
      deep: true,
    },
  },
  computed: {
    // ...mapState('locationClientManagement', ['sectors']),
    ...mapGetters({
      sectors: "locationClientManagement/getSectors", // Final
      shifts: "getShifts",
      pagination: "getPagination",
    }),
    serverStartDate() {
      return this.filter.from.time
        ? this.convertApiTime(this.filter.from.time)
        : null;
    },
    serverEndDate() {
      return this.filter.to.time
        ? this.convertApiTime(this.filter.to.time)
        : null;
    },
  },
  async created() {
    /*
     * Fetch data for threeDropFilterFeed
     */
    this.setupDateTimePickerOpt();

    const setupTimeFilter = (dateFrom, dateTo) => {
      // dateFrom, dateTo [ISO]

      // This is so the date params get sent
      const dateObj = {
        date_from: dateFrom || null,
        date_to: dateTo || null,
      };
      this.fetchParams = { ...this.fetchParams, ...dateObj };

      this.$set(
        this.filter.from,
        "time",
        this.convertToggleApiDateLocal(dateFrom) || ""
      );
      this.$set(
        this.filter.to,
        "time",
        this.convertToggleApiDateLocal(dateTo) || ""
      );
    };

    // const capitalize = (str) => {
    //   if (typeof str === 'string') {
    //     return str.slice(0, 1).toUpperCase() + str.slice(1)
    //   }
    //   return str;
    // }

    // Set filters on first load
    if (this.isFirstLoad) {
      const dateFrom = moment().subtract(6, "months").format("YYYY-MM-DD");
      this.$emit("change-first-load", false);
      setupTimeFilter(dateFrom, null);
    }

    // Return filtered search params
    const filterGlobalParams = this.filtersGlobal;
    if (filterGlobalParams) {
      // Return all filters
      this.pauseFetchShifts = true;
      this.fetchParams = Object.assign({}, filterGlobalParams);
      console.log(
        "🗃 Global filters",
        JSON.stringify(filterGlobalParams, null, 2)
      );

      const setupRegionFilter = () => {
        const regObj = {
          "sectors[][id]": [],
          "clients[][id]": null,
          "categories[][id]": null,
        };

        if (this.fetchParams.sectors) {
          const mappedSectors = this.fetchParams.sectors.map((el) => el.id);
          // this.sectors is grouped (so looking in subbed)
          const filteredSectors = this.sectors.reduce((acc, sectorGroup) => {
            for (const sub of sectorGroup.subs) {
              if (mappedSectors.includes(sub.id)) {
                acc.push(sub);
              }
            }
            return acc;
          }, []);
          regObj["sectors[][id]"] = filteredSectors;
          const filteredClients = this.fetchParams["clients[][id]"];
          // const filteredCategories = this.fetchParams["categories[][id]"];
          // console.log(this.fetchParams["categories"][0].id);
          // const filteredCategories = this.fetchParams["categories"][0].id;
          // console.log(filteredCategories);
          let filteredCategories;
          if (this.fetchParams.categories) {
            filteredCategories = this.fetchParams["categories"][0].id;
          } else {
            filteredCategories = this.fetchParams["categories[][id]"];
          }
          regObj["clients[][id]"] = filteredClients;
          regObj["categories[][id]"] = filteredCategories;
          this.threeDropFilterObject = regObj;
        } else {
          this.resetSectorsDrop();
        }
        // this.filterPropForThree = regObj
      };

      // const setupShiftStatus = () => {
      //   console.log('filter status', this.filter.shiftStatus)

      //   if (this.filter.shiftStatus) {
      //     this.filter.shiftStatus.name = capitalize(this.fetchParams.shiftStatus)
      //   }

      //   if (!this.filter.shiftStatus || typeof this.filter.shiftStatus !== 'object') {
      //     this.filter.shiftStatus = {} // Will be added in the next process
      //   }

      //   this.filter.shiftStatus.name = capitalize(this.fetchParams.shiftStatus)
      // }

      const dateFrom = this.fetchParams.date_from;
      const dateTo = this.fetchParams.date_to;

      setupRegionFilter();
      // setupShiftStatus();
      setupTimeFilter(dateFrom, dateTo);
      this.setupSubTableFilter();
      this.pauseFetchShifts = false;
    }

    if (this.$route.query.day) {
      // Day from calendar
      const day = this.$route.query.day;
      setupTimeFilter(day, day);
    }
    if (this.$route.query["shift-status"]) {
      const statusName = this.$route.query["shift-status"];
      const foundStatusObj = this.optionsShiftStatus.find(
        (status) => status.name.toLowerCase() === statusName
      );
      if (foundStatusObj) {
        this.pauseFetchShifts = true;
        this.changeShiftStatus(foundStatusObj);
        this.pauseFetchShifts = false;
        setTimeout(() => {
          this.filter.shiftStatus = foundStatusObj;
        }, 500);
      }
    }
    const isTempUser = this.auth.user.role && this.auth.user.role === "temp";
    const tempName = this.$route.query.temp;
    if (!isTempUser && tempName) {
      await this.findAgencyWorker({
        query: tempName,
        originallySelectedWorker: "",
      });
    }
    /*
     *     Add includes
     */
    if (this.initialStatus.can_use_shift_activity_tags) {
      this.includes.push("tags");
    }
    this.fetchParams.include = this.includes.join(",");
    /*
     *     Send active tab status
     */
    this.fetchParams.status = this.activeTab.status;

    //* Initially sort by start_time, order desc
    if (this.activeTab.name === "PENDING") {
      const sortData = {
        // column: this.sort.by,
        // direction: this.sort.direction,
        column: "start_time",
        direction: "asc",
      };
      this.setUpSortObj(sortData);
    } else {
      const sortData = {
        column: this.sort.by,
        direction: this.sort.direction,
      };
      this.setUpSortObj(sortData);
    }

    // Check if coming from Dashboard, in that case, add query to fetchParams
    // NOTE
    //   this will and should if present, override the status set above
    //   in cases when coming from Dashboard.
    if (this.$route.query.filter) {
      console.log(":: Coming from dashboard: ", this.fetchParams);

      const paramsMerged = {
        ...this.fetchParams,
        ...this.$route.query,
        dash_filter: this.$route.query.filter,
      };
      this.fetchParams = paramsMerged;

      /*
       *  Do not send status, but only and only if coming from dashboard!
       */
      delete this.fetchParams.status;

      // Set active tab accordingly
      // console.warn('change tab from dashboard');
      this.$store.commit("SET_ACTIVE_TAB", {
        name: _.invert(STATUS)[this.$route.query.status],
        status: this.$route.query.status,
      });
    } else {
      // Reset dashboard props
      delete this.fetchParams.dash_filter;
      delete this.fetchParams.filter;
      delete this.fetchParams.view;
    }

    if (this.active) {
      if (tempName) {
        this.fetchParams.name = tempName;
      }

      this.getShifts(this.fetchParams);
    }

    const tableHideColumns = () => {
      /**
       * Hide `action` column on CANCELLED tab
       */
      if (this.activeTab.name === "CANCELLED") {
        // console.warn('hide action column')
        this.visibleColumns = Object.assign({}, VISIBLE_COLUMNS, {
          action: false,
        });
      }
      /**
       * Hide `name` column on NEVER_FILLED tab
       */
      if (this.activeTab.name === "NEVER FILLED") {
        // console.warn('hide name column')
        this.visibleColumns = Object.assign({}, VISIBLE_COLUMNS, {
          name: false,
        });
      }
    };

    tableHideColumns();

    /**
     * Show editing square icons on following tabs
     * - PENDING
     * - ACTIVE
     */
    // TODO make better logic, this is not good, as it depends on order!
    if (["PENDING", "ACTIVE"].indexOf(this.activeTab.name) !== -1) {
      this.visibleToolIcons = Object.assign({}, VISIBLE_TOOL_ICONS, {
        timeEditingIcon: true,
        tempEditingIcon: true,
      });
    }
    if (this.activeTab.name === "COMPLETED") {
      this.visibleToolIcons = Object.assign({}, VISIBLE_TOOL_ICONS, {
        tempEditingIcon: true,
      });
    }
    if (this.activeTab.name === "PENDING") {
      this.visibleToolIcons = Object.assign({}, VISIBLE_TOOL_ICONS, {
        tempUnassignIcon: true,
        timeEditingIcon: true,
        tempEditingIcon: true,
      });
    }
  },
  beforeDestroy() {
    this.dateTimeOption.type = "min";
  },
  methods: {
    ...mapActions(["fetchShifts", "findAgencyWorker"]),
    onChangeDateFilters(evt) {
      this.datePickerActiveButton = evt;
      this.$emit("change-predefined-date", false);
    },
    changeDateGroup() {
      const dateFrom = this.groupFilter.from;
      const dateTo = this.groupFilter.to;

      const dateObj = {
        date_from: dateFrom || null,
        date_to: dateTo || null,
      };
      this.fetchParams = { ...this.fetchParams, ...dateObj };
      this.$set(
        this.filter.from,
        "time",
        this.convertToggleApiDateLocal(dateFrom) || ""
      );
      this.$set(
        this.filter.to,
        "time",
        this.convertToggleApiDateLocal(dateTo) || ""
      );

      this.fetchShiftWrap();
    },
    dateInputChanged() {
      // Reset button toggles active class
      this.$emit("change-predefined-date", false);
      this.datePickerActiveButton = "";

      // Only invoked when picked date from calendar
      this.fetchParams = {
        ...this.fetchParams,
        date_from: this.serverStartDate || null,
        date_to: this.serverEndDate || null,
      };

      this.fetchShiftWrap();
    },
    setupDateTimePickerOpt() {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.MultiShift = calendarOptions.MultiShift;
      this.dateTimeOption.type = "day";
      this.dateTimeOption.format = "DD/MM/YYYY";
    },
    searchThreeDrop(evt) {
      console.log("drop evt", evt);

      if (evt.id === "cli-rem") {
        this.resetSubTableAPI("clients", evt);
      } else if (evt.id === "sec-rem") {
        this.resetSubTableAPI("sectors", evt);
      } else if (evt.id === "cat-add") {
        // Add category
      } else if (evt.id === "cat-rem") {
        // Remove category [Support one]
        delete this.fetchParams.categories;
        delete this.fetchParams["categories[][id]"];
      }

      this.$nextTick(() => {
        // Becase of multiselect @select event is firing before the object changes
        this.fetchShiftWrap();
      });
    },
    convertSectorsForApi() {
      const sectors = this.threeDropFilterObject["sectors[][id]"];
      if (Array.isArray(sectors)) {
        const sectorsIds = sectors.map((el) => {
          return { id: el.id };
        });
        this.$set(this.fetchParams, "sectors", sectorsIds);
        this.convertClientAndCategory();
      }
    },
    convertClientAndCategory() {
      const categories = this.threeDropFilterObject["categories[][id]"];
      const clients = this.threeDropFilterObject["clients[][id]"];
      // Order is important ; from lesser to greater
      if (categories) {
        this.$set(this.fetchParams, "categories[][id]", categories);
      } else if (
        !Object.prototype.hasOwnProperty.call(
          this.threeDropFilterObject,
          "categories[][id]"
        )
      ) {
        this.$delete(this.fetchParams, "categories[][id]");
      }
      if (clients) {
        this.$set(this.fetchParams, "clients[][id]", clients);
      } else if (
        !Object.prototype.hasOwnProperty.call(
          this.threeDropFilterObject,
          "clients[][id]"
        )
      ) {
        this.$delete(this.fetchParams, "clients[][id]");
        this.$delete(this.fetchParams, "categories[][id]");
      }
    },
    setupSubTableFilter() {
      const keysSubFilter = [
        "name",
        "locations[][id]",
        "subcategories",
        // "subcategories[][id]",
        "clients",
        "id",
      ];

      const filterSubTable = {};
      for (const el of keysSubFilter) {
        filterSubTable[el] = this.fetchParams[el];
      }
      // console.warn(filterSubTable)
      this.filterSubTable = filterSubTable;
      return true;
    },
    resetFilters() {
      this.pauseFetchShifts = true;

      this.resetSubTableAPI();
      // Child - sub filters - vmodel > has to be after cleared params
      this.hideSubTable();

      // Main filters - params
      this.$delete(this.fetchParams, "shiftStatus");
      this.$delete(this.fetchParams, "date_from");
      this.$delete(this.fetchParams, "date_to");

      this.resetThreeDrop();

      // Main filters - vmodel
      this.$set(this.filter.from, "time", "");
      this.$set(this.filter.to, "time", "");
      this.filter.shiftStatus = null;
      // if (this.filter.shiftStatus && typeof this.filter.shiftStatus === 'object') {
      //   this.$delete(this.filter.shiftStatus, 'name')
      // }

      this.$store.commit("CLEAR_PAGINATION", null, { root: true });
      this.$set(this.fetchParams, "page", 1);

      // Reset query filters
      const currentQuery = this.$route.query;
      const newStatus = parseInt(
        this.$route.query.status || this.getActiveTabParsed.routeQuery.status,
        10
      );
      if (parseInt(currentQuery.status, 10) !== newStatus) {
        this.$router.replace({
          query: {
            status: newStatus,
          },
        });
      } else {
        this.$router.replace({
          query: {
            status: currentQuery.status,
          },
        });
      }

      this.pauseFetchShifts = false;
      this.fetchShiftWrap();
    },
    resetSubTableAPI(id, evt) {
      // Parent - sub filters - params
      const value = evt && evt.val;
      if (id === "clients") {
        this.$delete(this.fetchParams, "clients");
        this.$delete(this.fetchParams, "clients[][id]");
        this.$delete(this.fetchParams, "locations[][id]");
        this.$delete(this.fetchParams, "subcategories");
        this.$delete(this.fetchParams, "subcategories[][id]");
      } else if (id === "sectors") {
        // Remove all clients from the removed sectors
        this.removeClientsFromSectorApi(value);
      } else {
        this.$delete(this.fetchParams, "clients");
        this.$delete(this.fetchParams, "clients[][id]");
        this.$delete(this.fetchParams, "locations[][id]");
        this.$delete(this.fetchParams, "subcategories");
        this.$delete(this.fetchParams, "subcategories[][id]");
        this.$delete(this.fetchParams, "id");
        this.$delete(this.fetchParams, "tss_id");
        this.$delete(this.fetchParams, "external_id");
        this.$delete(this.fetchParams, "name");
      }
    },
    removeClientsFromSectorApi() {
      const sectors = this.threeDropFilterObject["sectors[][id]"];
      const remainingClients = sectors
        .reduce((acc, curr) => {
          const clientIds = curr.clients.map((client) => client.id);
          if (clientIds.length) {
            acc.push(clientIds);
          }
          return acc;
        }, [])
        .flat();

      const usedClient = this.fetchParams["clients[][id]"];
      if (!remainingClients.includes(usedClient)) {
        this.resetClientsDrop();
      }
    },
    resetSectorsDrop() {
      this.$delete(this.fetchParams, "sectors");
      this.resetClientsDrop();
      this.$set(this.threeDropFilterObject, "sectors[][id]", []);
    },
    resetClientsDrop() {
      this.$delete(this.fetchParams, "clients[][id]");
      this.$delete(this.fetchParams, "categories[][id]");
      this.$set(this.threeDropFilterObject, "categories[][id]", null);
      this.$set(this.threeDropFilterObject, "clients[][id]", null);
    },
    resetThreeDrop() {
      // Reset threedrop
      this.resetSectorsDrop();
      // this.$delete(this.fetchParams, 'sectors')
      // this.$delete(this.fetchParams, 'clients[][id]')
      // this.$delete(this.fetchParams, 'categories[][id]')

      // // Reset threedrop /2
      // const emptyRegFilters = {
      //   'categories[][id]': null,
      //   'clients[][id]': null,
      //   'sectors[][id]': []
      // }
      // this.threeDropFilterObject = emptyRegFilters;
      // this.filterPropForThree = emptyRegFilters;
      this.resetRegionFilterBool = !this.resetRegionFilterBool;
    },
    hideSubTable() {
      this.isSubTableShow = false;
      const isDoneSetting = this.setupSubTableFilter();
      if (isDoneSetting) {
        setTimeout(() => {
          this.isSubTableShow = true;
        }, 300);
      }
    },
    convertToggleApiDateLocal(d) {
      if (!d) {
        return "";
      }
      // Toggles from Local [DD/MM/YYYY] <-> [YYYY-MM-DD]
      if (moment(d, "DD/MM/YYYY").format("DD/MM/YYYY") === d) {
        return moment(d, "DD/MM/YYYY").format("YYYY-MM-DD");
      } else if (moment(d, "YYYY-MM-DD").format("YYYY-MM-DD") === d) {
        return moment(d, "YYYY-MM-DD").format("DD/MM/YYYY");
      }
      return d;
    },
    convertApiTime(d) {
      if (!d) {
        return null;
      }
      let dt = "";

      if (moment(d, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm") === d) {
        dt = moment(d, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss");
      } else if (moment(d, "DD/MM/YYYY").format("DD/MM/YYYY") === d) {
        dt = moment(d, "DD-MM-YYYY").format("YYYY-MM-DD");
      } else if (moment(d, "HH:mm").format("HH:mm") === d) {
        dt = moment(d, "HH:mm").format("HH:mm:ss");
      }

      return dt;
    },

    // when switching between tabs and first time component is created.
    // TODO make this method even more universal, so call it when any of filters apply
    getShifts() {
      Evt.fire("data-is-loading", true);
      this.dataIsLoading = true;
      // clear shifts from module state [ rows in table ]
      this.$store.commit("clear-shifts");
      // clear pagination object from root state
      this.$store.commit("CLEAR_PAGINATION", null, { root: true });

      /**
       * Conditional include
       */
      this.includes.push(
        this.activeTab.name === "ACTIVE" || this.activeTab.name === "COMPLETED"
          ? "signedOffBy"
          : null
      );

      this.fetchShiftWrap();
    },
    clearRangeFilter() {
      this.filter.from.time = "";
      this.filter.to.time = "";
    },

    /**
     * filterQuery is an object that contains passed in value from v-multi-select
     * TODO make use of filterBy, so we can search by multiple criteria...
     *
     * IMPORTANT filterByObject is recieved as parameter on event listen, and contains an object
     * with all currently active filters that should be applied in a request!
     */
    searchByFilter(filterObject, inputStr) {
      /**
       * TODO make better way (more dynamic) of assigning various filters to request
       */
      const checkExist = (val) => val && typeof val === "object";
      const checkExistArray = (val) =>
        val && typeof val === "object" && Array.isArray(val);
      const getArrID = (array) => array.map((el) => ({ id: el.id }));

      const getName = () => {
        const doesExist = checkExist(filterObject.selectedAgencyWorker);
        if (doesExist) {
          const firstName = filterObject.selectedAgencyWorker.first_name;
          const lastName = filterObject.selectedAgencyWorker.last_name;
          const nickName = filterObject.selectedAgencyWorker.nickname;

          const fullName = `${firstName.trim()} ${lastName.trim()}`.trim();
          return (fullName + ` (${nickName})`).trim();
        }
        return null;
      };

      const tempObj = {
        name: getName(),
        "locations[][id]":
          checkExist(filterObject.selectedLocation) &&
          filterObject.selectedLocation.id,
        // "subcategories[][id]":
        //   checkExist(filterObject.selectedSubcategory) &&
        //   filterObject.selectedSubcategory.id,
        subcategories:
          checkExistArray(filterObject.selectedSubcategory) &&
          getArrID(filterObject.selectedSubcategory),
        clients:
          checkExistArray(filterObject.selectedClient) &&
          getArrID(filterObject.selectedClient),
        id: checkExist(filterObject) && filterObject.shiftId,
        payroll_shift_statuses:
          checkExistArray(filterObject.selectedPayrollStatuses) &&
          getArrID(filterObject.selectedPayrollStatuses),
        tss_id:
          checkExist(filterObject) && (filterObject.externalTssId || undefined),
        client_external_id:
          checkExist(filterObject) &&
          (filterObject.client_external_id || undefined),
      };

      if (this.canUseNexusMiddleware) {
        tempObj.external_id =
          checkExist(filterObject) && (filterObject.externalTssId || undefined);
      } else {
        tempObj.tss_id =
          checkExist(filterObject) && (filterObject.externalTssId || undefined);
      }

      this.emitShiftScheduleFilterToGlobalParent(tempObj);
      this.fetchParams = Object.assign(this.fetchParams, tempObj);
      this.setupSubTableFilter();

      if (inputStr === "client") {
        // console.warn(inputStr)
        if (tempObj.clients?.length) {
          this.pauseFetchShifts = true;
          // this.resetThreeDrop();
          this.pauseFetchShifts = false;
        }
      }
      this.fetchShiftWrap();
    },

    /**
     * Sort object is an object that contains sort data passed by sort component
     */
    sortResults(sortData) {
      if (sortData.column === "") {
        delete this.fetchParams.sort_by_field;
        delete this.fetchParams.sort_by_direction;
      } else {
        this.setUpSortObj(sortData);
      }
      this.fetchShiftWrap();
    },
    setUpSortObj(sortData) {
      this.fetchParams.sort_by_field = sortData.column;
      this.fetchParams.sort_by_direction = sortData.direction;
    },
    formatParamsForGet(params) {
      const currentCat =
        params["categories[][id]"] && params["categories[][id]"].id;
      const newObj = { ...params };
      if (currentCat) {
        newObj.categories = [{ id: currentCat }];
      }
      delete newObj["categories[][id]"];

      if (!params.id) {
        delete newObj["id"];
      }
      return newObj;
    },
    async fetchShiftWrap(paramsSet = {}) {
      if (this.pauseFetchShifts) {
        return;
      }
      const rand = Math.round(Math.random() * 10);
      const currentActionTime = parseInt(
        "" + rand + new Date().getMilliseconds(),
        10
      );
      this.actionArr.push(currentActionTime);

      this.convertSectorsForApi();

      // this.fetchParams.page = pageNum // Used for global filter
      let apiParams = { ...this.fetchParams, ...paramsSet }; // Clone it so it doesn't affect other obj
      // console.warn('s-list params: ', apiParams, paramsSet);
      apiParams = this.formatParamsForGet(apiParams);
      if (this.activeTab.status === "1") {
        // DONT USE apiParams.status === "1", because apiParams.status is undefined
        if (this.canUseShiftConfirm || this.shiftConfirmEnablement) {
          apiParams.include += ",confirmationStatus";
          console.log(":: Added confirm status include", apiParams.include);
        }
      }

      this.emitShiftScheduleFilterToGlobalParent(apiParams); // UPDATE Global Filters OBJ
      if (
        JSON.stringify(apiParams) === JSON.stringify(this.lastApiCallParams)
      ) {
        console.log("same api params");
        return;
      }
      this.lastApiCallParams === Object.assign({}, apiParams);
      Evt.fire("data-is-loading", true);
      this.dataIsLoading = true;

      // If there is a request pending, cancel it
      if (this.cancelTokenShifts !== null) {
        this.cancelTokenShifts.cancel();
        this.$emit("cancel-shift-token", null);
      }
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // Remember the token for cancellation
      this.$emit("cancel-shift-token", source);

      try {
        const paramsObj = {
          params: apiParams,
          cancelTokenSource: source,
          raceObj: { curr: currentActionTime, arr: this.actionArr },
        };
        this.getPagination(paramsObj);
        await this.fetchShifts(paramsObj);
        this.dataIsLoading = false;
        Evt.fire("data-is-loading", false);
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          this.$toasted.error(err.response.data.message).goAway(1500);
        }
        console.warn("Request failed", err.message);
        // Evt.fire('data-is-loading', false)
        // this.dataIsLoading = false
      }
    },
    async getPagination(paramsObj) {
      const localParams = JSON.parse(JSON.stringify(paramsObj));
      localParams.params.metaOnly = true;

      if (this.cancelTokenShiftsPagination !== null) {
        this.cancelTokenShiftsPagination.cancel();
        this.$emit("cancel-shift-page-token", null);
      }
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      localParams.cancelTokenSource = source;

      // Remember the token for cancellation
      this.$emit("cancel-shift-page-token", source);

      this.conditionalySetIsPaginationLoading(true);
      try {
        await this.fetchShifts(localParams);

        if (
          this.$route.query.page &&
          this.pagination.current_page &&
          parseInt(this.$route.query.page, 10) !==
            parseInt(this.pagination.current_page, 10)
        ) {
          console.log(">> Replacing page query", this.pagination.current_page);
          const currentQuery = this.$route.query;
          this.$router.replace({
            query: { ...currentQuery, page: this.pagination.current_page },
          });
        }

        this.conditionalySetIsPaginationLoading(false);
      } catch (error) {
        this.conditionalySetIsPaginationLoading(false);
        console.warn(error);
      }
    },
    conditionalySetIsPaginationLoading(bool) {
      if (this.shouldPaginationLoadingBeActivated) {
        this.isPaginationLoading = bool;
      }
    },
    emitShiftScheduleFilterToGlobalParent(params) {
      this.$emit("update-filter-global", params);
    },
    pageHasChanged(pageNum) {
      // don't activate pagination loading spinner on page change
      this.shouldPaginationLoadingBeActivated = false;
      // keep all filters state, then repeat a query with &page=pageNum
      this.fetchShiftWrap({ page: pageNum });
      this.shouldPaginationLoadingBeActivated = true;
    },
    showDetails(id) {
      console.log(`show details catched in ShiftSchedule component: id: ${id}`);
    },
    shiftSelected() {
      console.info("SHIFT SELECTED");
    },
    changeShiftStatus(status) {
      console.log("Setting shift >>", status);

      const lowerName = status.name.toLowerCase();
      this.$set(this.fetchParams, "shiftStatus", lowerName);

      const currentQuery = this.$route.query;
      if (currentQuery["shift-status"] !== lowerName) {
        this.$router.replace({
          // name: 'shiftSchedule',
          params: { ...this.$route.params },
          query: { ...this.$route.query, "shift-status": lowerName },
        });
      } else {
        console.log(">> Shift status already set", currentQuery);
      }
      this.fetchShiftWrap();
    },
    removeShiftStatus() {
      const query = { ...this.$route.query };
      delete this.fetchParams.shiftStatus;
      delete query["shift-status"];

      this.$router.replace({
        // name: 'shiftSchedule',
        params: { ...this.$route.params },
        query,
      });
      this.fetchShiftWrap();
    },
  },
};
</script>
<style lang="scss" scoped>
.shift-schedule-list {
  .pagination {
    align-self: flex-end;
  }
}

.shift-filters {
  display: flex;
  margin-bottom: 19px;
  flex-wrap: wrap;
  align-items: flex-end;

  .field {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .shift-datetime {
    margin-left: 10px;

    ::v-deep {
      label {
        font-size: 20px !important;
      }

      input {
        line-height: 30px !important;
        font-size: 18px !important;
      }
    }
  }

  .predefined-date {
    &::v-deep input {
      background-color: rgb(253, 253, 180);
    }
  }

  .shift-status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 15px;

    .label {
      margin: 0 0 0.5em 0;
    }
  }

  .reset-filters {
    margin-right: auto;
  }
}

.shift-datetime {
  .label {
    float: left;
    position: relative;
    top: 7px;
  }

  .control {
    float: left;
  }
}

.spinner-wrap {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
}

.info-tooltip {
  border-radius: 50%;
  border: 1px solid silver;
  margin: 0 0 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 27px;
  min-height: 27px;
  width: 27px;
  height: 27px;

  .fa {
    color: #7c91ae;
  }
}

@media screen and (max-width: 490px) {
  .field:first-child {
    p {
      margin-left: auto;
    }
  }

  .field {
    width: 100%;
    justify-content: space-between;
  }
}
</style>
