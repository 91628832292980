<template lang="pug">
.confirm-modal
  .message {{ modalProps?.message }}
  .button-line
    button.button.is-generic-app-blue(@click="onSubmitAccept") {{ modalProps?.multiple_cancellation ? "Yes" : "Confirm" }}
    button.button.is-generic-app-red(@click="handleRejectCancel") {{ modalProps?.multiple_cancellation ? "No" : "Cancel" }}
</template>

<script setup>
const props = defineProps({
  notification: {
    type: String,
    required: false,
    default: "",
  },
  modalProps: {},
});
const emit = defineEmits([
  "confirm-action-confirm-modal",
  "close-confirm-modal",
]);

function handleRejectCancel() {
  console.log("reject");
  emit("close-confirm-modal");
}
function onSubmitAccept() {
  console.log("onSubmitAccept");
  emit("confirm-action-confirm-modal");
}
</script>

<style lang="scss" scoped>
.confirm-modal {
  display: flex;
  flex-direction: column;
  .message {
    //   display: flex;
    //   justify-content: space-between;
    text-align: center;
    margin: 10px 0 0 0;
    background: transparent;
  }
  .button-line {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    button {
      margin: 0 10px;
    }
  }
}
</style>
