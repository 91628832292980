<template lang="pug">
.wrapper
  .dialog-container
    .recalculation-confirmation
      img.btn-loader(
        v-if="loading",
        src="../../../assets/images/comps/loader.svg"
      )
      .message(
        :class="{ succesMessage: succesfulyMessage, errMessage: errorMessage || !isStatusCodeOk }"
      ) {{ message }}
    .buttons-centered
      span.loading-recalculate(v-if="canStartProcess")
        img(src="../../../assets/images/comps/loader.svg")
      button.button.is-generic-app-blue.is-caps-lock(
        data-cell="submit-button",
        type="submit",
        @click.prevent="startRecalculation",
        :disabled="canStartProcess || !isStatusCodeOk"
      )
        span Start
      button.button.is-outlined.is-caps-lock(
        data-cell="cancel-button",
        @click.prevent="callModalClose",
        :disabled="canStartProcess"
      ) CANCEL
</template>

<script>
import { Errors } from "../../../lib/helpers/Errors.js";
import { mapActions, mapGetters } from "vuex";

import MultiSelect from "vue-multiselect";
import { parseErrors } from "../../../lib/helpers/function.js";

export default {
  components: {
    MultiSelect,
  },
  props: ["modalProps"],
  data() {
    return {
      canStartProcess: false,
      progressData: { completed: 50, pending: 50, total: 100 },
      message: "",
      loading: false,
      params: [],
      succesfulyMessage: false,
      errorMessage: false,
    };
  },
  computed: {
    ...mapGetters({}),
    isStatusCodeOk() {
      if (this.modalProps.statusCode === 200) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.message = this.modalProps.calculationMessage;
    this.params = this.modalProps.params;
  },
  methods: {
    ...mapActions({
      startMassRecalculation: "startMassRecalculation",
    }),
    callModalClose() {
      this.$emit("cancel-modal");
    },
    async startRecalculation() {
      this.canStartProcess = true;
      try {
        const res = await this.startMassRecalculation(this.params);
        this.succesfulyMessage = true;
        this.message = res.data.message;
        // this.$toasted.success("Successfully added").goAway(2500);
        setTimeout(() => {
          this.$emit("start-recalculation");
          this.canStartProcess = false;
        }, 3000);
      } catch (err) {
        console.log("Error", err.message);
        const errs = parseErrors(err);
        if (errs) {
          this.errorMessage = true;
          this.message = errs;
          // this.$toasted.error(errs).goAway(1500);
          setTimeout(() => {
            this.callModalClose();
          }, 3000);
        }
        this.canStartProcess = false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
<style lang="scss" scoped>
.dialog-container {
  width: 100%;
  overflow: hidden;

  .recalculation-confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    margin: 10px 0 0 0;
    .message {
      font-size: 1rem;
      background-color: #ffffff;
      text-align: center;
    }
    .succesMessage {
      font-size: 1rem;
      color: #5cb85c;
      transition: 2s;
    }
    .errMessage {
      font-size: 1rem;
      color: #d9534f;
      transition: 2s;
    }
    // padding: 0 1.3em;

    label {
      margin-top: 0;
    }
  }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}

table.no-border {
  border: 0px;
}

.btn-loader {
  height: 18px;
  padding-right: 4px;
}
</style>
