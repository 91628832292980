<template>
  <div class="token-verification-wrapper">
    <h1 class="title is-3"><span class="sep"></span> Verification status</h1>
    <br />
    <div class="verification-message">
      <h2 :class="{ active: redText }">{{ message }}</h2>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      message: "",
      redText: true,
    };
  },
  mounted() {
    this.FunCheckToken();
  },
  methods: {
    ...mapActions({
      checkToken: "checkToken",
    }),
    async FunCheckToken() {
      const params = {
        token: this.$route.query.t,
      };
      try {
        const res = await this.checkToken(params);
        console.log(res);
        this.message = res.data.message;
        if (res.status === 200) {
          this.redText = false;
        }
      } catch (err) {
        this.message = err.response.data.message;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.token-verification-wrapper {
  margin: 20px 0 0 0;
  padding: 10px 20px;
  .active {
    color: red;
  }
  .verification-message {
    color: #4ad862;
    margin: 0 auto;
    font-size: 30px;
    text-align: center;
    margin-top: 50px;
  }
}

@media screen and (max-width: 1500px) {
  .token-verification-wrapper {
    .verification-message {
      font-size: 25px;
      margin-top: 25px;
    }
  }
}

@media screen and (max-width: 500px) {
  .token-verification-wrapper {
    margin: 10px 0 0 0;
    padding: 5px 10px;
    .title.is-3 {
      font-size: 1rem;
    }
    .verification-message {
      font-size: 20px;
    }
  }
}
</style>
