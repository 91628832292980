<template lang="pug">
section.section.candidate-page
  h1.title.is-3
    span(style="cursor: pointer", @click="goToRoute")
      svg(
        xmlns="http://www.w3.org/2000/svg",
        width="13",
        height="23",
        viewBox="0 0 13 23"
      )
        path(
          fill="#7591AE",
          stroke="#7991AE",
          d="M1.44295652,10.3728261 L10.8125217,1.00326087 C11.0429565,0.772826087 11.0429565,0.40326087 10.8125217,0.172826087 C10.582087,-0.0576086957 10.2125217,-0.0576086957 9.98208696,0.172826087 L0.195130435,9.95978261 C-0.0353043478,10.1902174 -0.0353043478,10.5597826 0.195130435,10.7902174 L9.98208696,20.5728261 C10.0951304,20.6858696 10.2473043,20.7467391 10.3951304,20.7467391 C10.5429565,20.7467391 10.6951304,20.6902174 10.8081739,20.5728261 C11.0386087,20.3423913 11.0386087,19.9728261 10.8081739,19.7423913 L1.44295652,10.3728261 Z",
          transform="translate(1 1)"
        )
    span.sep
    i.fa.icon-Edit-profile
    | {{ setName }}
  uni-tabs(
    :tabs="filteredTabs",
    :active-tab="activeTab",
    @tab-clicked="onTabClicked"
  )
  .candidate-inner-view
    router-view
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "CandidatePage",
  data() {
    return {
      activeTab: {
        label: "Profile",
      },
    };
  },
  computed: {
    ...mapGetters({
      userProfile: "getUserProfile",
      tempProfile: "getTempProfile",
    }),
    setName() {
      return this.userProfile &&
        this.userProfile?.data &&
        this.userProfile.data.name
        ? this.userProfile.data.name
        : this.tempProfile && this.tempProfile?.data && this.tempProfile?.data
        ? this.tempProfile.data.name
        : "";
    },
    tabs() {
      return [
        {
          label: "Profile",
          routeName: "edit-candidate",
          permission: this.gPermissions.canViewTempDetails,
          routeQuery: {
            page: undefined,
          },
        },
        {
          label: "Journal",
          routeName: "CandidateJournals",
          permission: this.gPermissions.canJournalViewTempJournal,
          routeQuery: {
            page: 1,
          },
        },
        {
          label: "Holiday requests",
          routeName: "CandidateHolidayRequests",
          systemPermissions: ["can_use_holiday_requests"],
          permission: this.gPermissions.canHolidayView,
          routeQuery: {
            page: 1,
          },
        },
        {
          label: "Annual leave",
          routeName: "AnnualLeaveTempPage",
          systemPermissions: ["can_use_accumulation"],
          permission: this.gPermissions.canAnnualLeaveView,
          routeQuery: {
            page: 1,
          },
        },
      ];
    },
    filteredTabs() {
      return this.tabs.filter((tab) => {
        const hasPerm =
          "permission" in tab
            ? typeof tab.permission === "string"
              ? this.$can(tab.permission)
              : tab.permission
            : true;
        const hasSysPerm = tab.systemPermissions?.length
          ? tab.systemPermissions.every(
              (tabPerm) => this.initialStatus[tabPerm]
            )
          : true;
        const hasCondition = tab.conditions?.length
          ? tab.conditions.every(Boolean)
          : true;
        return hasPerm && hasSysPerm && hasCondition;
      });
    },
  },
  destroyed() {
    this.resetTempProfile();
    this.resetUserProfile();
  },
  onDeactivated() {
    this.resetTempProfile();
    this.resetUserProfile();
  },
  mounted() {
    const arrRev = [...this.$route.matched].reverse();
    for (const routeMatch of arrRev) {
      const currentName = routeMatch.name;
      const foundTab = this.tabs.find((tab) => tab.routeName === currentName);
      if (foundTab) {
        this.activeTab.label = foundTab.label;
        break;
      }
    }
  },
  methods: {
    ...mapActions({
      resetTempProfile: "resetTempProfile",
      resetUserProfile: "resetUserProfile",
    }),
    goToRoute() {
      this.$router.push({
        name: "allCandidates",
      });
    },
    onTabClicked({ tab }) {
      this.activeTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
.candidate-page {
  display: flex;
  flex-direction: column;

  span.sep {
    border: 1px solid #d6e0e5;
    margin: 0 0.8em;
  }

  .candidate-inner-view {
    padding: 20px 0 0 0;
    display: flex;
    // overflow: hidden;
  }
}
</style>
