<template lang="pug">
tbody.generic-app-subtable(name="custom-animation", is="transition-group")
  edit-popup(
    key="editPopup",
    v-if="popup.show",
    @close="popupClose",
    @editPopup="popup.save",
    :data="popup.data",
    :levels="popup.levels",
    :offset="popup.offset",
    :target="popup.element",
    :isLoading="popup.isLoading",
    ref="popup"
  )
  tr(v-for="location in locations", :key="location.id")
    //- td(style="width: 1%")
      //- input(type="checkbox")
    td.subtable-name
      template(v-if="isEditingCostCentreId === location.id && editFieldName === 'name' ")
        input.input(
          :value="location.name",
          ref="locationInput",
          type="text",
          title="Save new name by pressing 'Enter'",
          @keyup.enter="changeLocationName($event, location)",
          @blur="isEditingCostCentreId = null"
        )
      template(v-else)
        .right-side(title="Save new name by pressing 'Enter'")
          button.button(
            :class="{ 'is-loading': isLoadingNameApi }",
            v-if="isLoadingNameApi"
          )
          svg(
            xmlns="http://www.w3.org/2000/svg",
            width="12",
            height="12",
            viewBox="0 0 12 12",
            v-if="gPermissions.canEditWard && !isLoadingNameApi",
            @click="editCostCentre(location, 'name')"
          )
            g(:fill="getGlobalPrimaryColorHex")
              polygon(points="10.383 3.089 8.886 1.593 4.25 6.234 5.764 7.713")
              path(
                d="M10.174549,0.309843137 L9.4074902,1.07707843 L10.9043137,2.57360784 L11.6713725,1.80635294 C12.0839608,1.393 12.0847059,0.723176471 11.6713725,0.309823529 C11.2581176,-0.103411765 10.5878039,-0.103137255 10.174549,0.309843137 Z"
              )
              polygon(points="2.731 9.25 5.285 8.174 3.798 6.72")
              polygon(
                points="10.467 4.394 9.023 5.817 9.023 10.519 1.399 10.519 1.399 2.925 6.195 2.925 7.531 1.514 0 1.514 0 11.981 10.467 11.981"
              )
        span(:title="location.name") {{ location.name }}
    td
      button.button.is-generic-app-teal.is-tiny(
        v-if="gPermissions.canCopyWard",
        @click="onCopyModalWard(location)"
      ) COPY
    td {{ getSectorNames(client) }}
    //- td
      span.generic-app-tag.is-wide-tag.popup-hook(
        data-popup,
        data-popup-users,
        @click="openModalUsers($event.target, location, client.id)",
        v-tooltip.info.top.bounce="'click to fill in'"
      ) <strong>{{ location.users.length }}</strong>
        img(src="../../assets/images/comps/Olovka.svg")
    td
      span.generic-app-tag.is-wide-tag.popup-hook(
        data-popup,
        data-popup-subcategories,
        @click="showPopup(['subcategories'], $event.target, location)",
        v-tooltip.info.top.bounce="'click to fill in'"
      ) <strong>{{ getSubcategoriesTotal(location) }}</strong>
        img(src="../../assets/images/comps/Olovka.svg")
    //- td
    //-   span.generic-app-tag.is-wide-tag.popup-hook(
    //-     data-popup,
    //-     data-popup-temps,
    //-     @click="showPopup(['temps'], $event.target, location)",
    //-     v-tooltip.info.top.bounce="'click to fill in'"
    //-   ) <strong>{{ (location.temps && location.temps.length) || 0 }}</strong>
    //-     img(src="../../assets/images/comps/Olovka.svg")
    template(v-if="hasAllowanceAgency")
      td.location-allowance-td
        //- label.label.c1
        //-   label.ctrl.ctrl--checkbox
        //-     input.ctrl--checkbox(@change="setLocationPropSwitch(!location.hasLocationAllowance,location)", type="checkbox", name="specialShift", :checked="getHasLocationAllowance(location)")
        //-     .ctrl__status
        //-       v-model='location.hasLocationAllowance'
        switches(
          v-model="location.hasLocationAllowance",
          theme="bulma",
          color="blue",
          :emitOnMount="false",
          :disabled="disableSwitchOnClick",
          @input="setLocationProp(location, 'hasLocationAllowance')"
        )
        //- Ako bude potreban spiner
        //- .loader(v-if="test")
      td.secure-allowance-td
        switches(
          v-model="location.hasSecureAllowance",
          theme="bulma",
          color="blue",
          :emitOnMount="false",
          @input="setLocationProp(location, 'hasSecureAllowance')",
          :disabled="disableSwitchOnClick"
        )
      td.community-allowance-td(v-if="hasCommunityAllowance")
        switches(
          v-model="location.hasCommunityAllowance",
          theme="bulma",
          color="blue",
          :emitOnMount="false",
          @input="setLocationProp(location, 'hasCommunityAllowance')",
          :disabled="disableSwitchOnClick"
        )
    td.community-allowance-td(v-if="hasOnCallShifts")
      switches(
        v-model="location.hasOnCall",
        theme="bulma",
        color="blue",
        :emitOnMount="false",
        @input="setLocationProp(location, 'hasOnCall')",
        :disabled="disableSwitchOnClick"
      )
    td.community-allowance-td(v-if="hasSleepoverShifts")
      switches(
        v-model="location.hasSleepover",
        theme="bulma",
        color="blue",
        :emitOnMount="false",
        @input="setLocationProp(location, 'hasSleepover')",
        :disabled="disableSwitchOnClick"
      )
    //- td(colspan="100")
    td.subtable-name.external_id(v-if="canUseLocationExternalId")
      template(v-if="isEditingCostCentreId === location.id && editFieldName === 'location_external_id' ")
        input.input(
          :value="location.location_external_id",
          ref="location_external_id",
          type="text",
          title="Save new location external id by pressing 'Enter'",
          @keyup.enter="changeLocationExternalId($event, location)",
          @blur="isEditingCostCentreId = null"
        )
      template(v-else)
        .right-side(v-if="$can('edit-location-external-id') " title="Save new external id by pressing 'Enter'")
          button.button(
            :class="{ 'is-loading': isLoadingNameApi }",
            v-if="isLoadingNameApi "
          )
          svg(
            xmlns="http://www.w3.org/2000/svg",
            width="12",
            height="12",
            viewBox="0 0 12 12",
            v-if="gPermissions.canEditWard && !isLoadingNameApi",
            @click="editCostCentre(location, 'location_external_id')"
          )
            g(:fill="getGlobalPrimaryColorHex")
              polygon(points="10.383 3.089 8.886 1.593 4.25 6.234 5.764 7.713")
              path(
                d="M10.174549,0.309843137 L9.4074902,1.07707843 L10.9043137,2.57360784 L11.6713725,1.80635294 C12.0839608,1.393 12.0847059,0.723176471 11.6713725,0.309823529 C11.2581176,-0.103411765 10.5878039,-0.103137255 10.174549,0.309843137 Z"
              )
              polygon(points="2.731 9.25 5.285 8.174 3.798 6.72")
              polygon(
                points="10.467 4.394 9.023 5.817 9.023 10.519 1.399 10.519 1.399 2.925 6.195 2.925 7.531 1.514 0 1.514 0 11.981 10.467 11.981"
              )
        span(:title="location.location_external_id") {{ location.location_external_id }}
    td(v-else)
    td
    td
    td(colspan="100")
      .wrapper-activation(v-if="gPermissions.canArchiveLocation")
        button.button.is-success.is-tiny.is-caps-lock(
          v-if="location.archived",
          :class="{ 'is-loading': isLoadingCostCentreArchive === location.id }",
          @click="onChangeLocationArchive(location, false)"
        ) Reactivate
        button.button.is-generic-app-teal.is-tiny.is-caps-lock(
          v-if="!location.archived",
          :class="{ 'is-loading': isLoadingCostCentreArchive === location.id }",
          @click="onChangeLocationArchive(location, true)"
        ) Archive
  tr(
    v-show="add_newward",
    key="add_newward",
    v-if="gPermissions.canAddNewWard"
  )
    td
      .flexer
        span
          img(
            src="../../assets/images/comps/remove.svg",
            style="visibility: hidden"
          )
        input.input(
          v-model="newCostCenterTemplate.name",
          placeholder="Type in ward name",
          @keyup.enter="createWard"
        )
    td(
      v-if="$can('edit-location-external-id')",
      colspan=2
    )
        input.input(
          v-model="newCostCenterTemplate.location_external_id",
          placeholder="Location external id",
          @keyup.enter="createWard"
        )
    td(colspan=100)
  tr(key="save_cancel", v-if="gPermissions.canAddNewWard")
    td(colspan=100)
      .block.controls
        transition(name="fadeRight")
          span(v-show="add_newward")
            button.button.is-generic-app-blue(@click="createWard") SAVE
            button.button.is-generic-app-blue(@click="add_newward = false") CANCEL
        span
          button.button.is-generic-app-light-blue.new-document(
            @click="addWardBegin",
            :disabled="add_newward"
          )
            img(src="../../assets/images/comps/Plus.svg")
            span(style="margin-left: 5px") Add new ward
  simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>

<script>
import _ from "underscore";
import EditPopup from "../EditPopup.vue";

import { mapActions, mapGetters, mapState } from "vuex";

import { flatten, parseErrors } from "../../lib/helpers/function.js";

export default {
  components: {
    EditPopup,
  },
  props: [
    "client",
    "isFetchedTempsOnce",
    "hasOnCallShifts",
    "hasSleepoverShifts",
  ],
  data() {
    return {
      disableSwitchOnClick: false,
      locations: [],
      isEditingCostCentreId: null,
      editFieldName: null,
      modalSize: 750,
      modalVisible: false,
      clientIsVatApplied: false,
      newCostCenterTemplate: {
        id: 0,
        name: "",
        location_external_id: "",
        // --------
        subcategories: [],
        temps: [],
        sector: "",
        client_id: this.client.id,
      },

      popup: {
        active_costcenter_id: null,
        offset: {
          offsetX: 20,
          offsetY: 55,
        },
        element: null,
        show: false,
        data: null,
        levels: [],
        save: (data, totalCount) => {
          return this.refreshPopupState(data, totalCount);
        },
        isLoading: false,
      },
      add_newward: false,
      isLoadingNameApi: false,
      cancelCheckTempsPopup: false,
      isLoadingCostCentreArchive: null,
    };
  },
  computed: {
    ...mapGetters({
      clients: "locationClientManagement/getClients",
      categories: "getAllCategories", // job types
      temps: "getTemps",
      users: "getUsers",
    }),
    hasCommunityAllowance() {
      return this.locations.every((cc) =>
        Object.prototype.hasOwnProperty.call(cc, "hasCommunityAllowance")
      );
    },
    hasAllowanceAgency() {
      return this.initialStatus.can_view_allowances;
    },
  },
  watch: {
    hasCommunityAllowance() {
      this.$emit("hasCommunityAllowance", this.hasCommunityAllowance);
    },
  },
  mounted() {
    this.locations = this.client.locations;
    this.fetchAllCategoriesList();

    if (!this.isFetchedTempsOnce) {
      const params = {};
      // params.per_page = 2000
      params.per_page = "all";
      this.fetchAllTemps(params);
    }

    this.$emit("hasCommunityAllowance", this.hasCommunityAllowance);
    this.$emit("is-fetched-temps-once", true);
  },
  methods: {
    ...mapActions({
      fetchClients: "locationClientManagement/fetchClientsOptimized",
      fetchAllCategoriesList: "fetchAllCategoriesList",
      fetchAllTemps: "fetchAllTemps",
      fetchUsers: "fetchUsers",
      updateCostCenterSubcategories:
        "locationClientManagement/updateCostCenterSubcategories",
      updateCostCenterTemps: "locationClientManagement/updateCostCenterTemps",
      updateCostCenterUsers: "locationClientManagement/updateCostCenterUsers",
      createCostCenterForClient:
        "locationClientManagement/createCostCenterForClient",
      updateCostCenterAllowance:
        "locationClientManagement/updateCostCenterAllowance",
      updateCostCenter: "locationClientManagement/updateCostCenter",
    }),
    getSectorNames(client) {
      // Only if has selectable
      return client.sectors && client.sectors.map((sec) => sec.name).join(", ");
    },
    getSubcategoriesTotal(location) {
      let totalSubcats = 0;
      location["categories"]?.map((category) => {
        totalSubcats += category["subcategories"].length;
      });
      return totalSubcats;
    },

    getLocationsNames(client) {
      return client
        .map((cc) => {
          return cc.name;
        })
        .join(", ");
    },
    async showPopup(levels, e, location) {
      console.log(levels);

      let apiCheckedIds = [];

      if (_.indexOf(levels, "subcategories") >= 0) {
        apiCheckedIds = this.findCheckedItems("subcategories", location);
        // console.log('API CHECKED IDS :: ', apiCheckedIds)

        this.popup.data = this.getSubcategoriesByCategories(
          this.categories,
          apiCheckedIds
        );
        // console.log(this.popup.data)
      } else if (_.indexOf(levels, "temps") >= 0) {
        this.$set(this.popup, "isLoading", true);
        apiCheckedIds = this.findCheckedItems("temps", location);
        // console.log('API CHECKED IDS :: ', apiCheckedIds)

        this.$nextTick(async () => {
          this.cancelCheckTempsPopup = false;
          try {
            await this.waitForDataArrToLoad(
              "temps",
              () => this.cancelCheckTempsPopup
            );
            this.$set(this.popup, "data", [
              {
                id: 0,
                name: "Temps",
                temps: this.getCheckedTemps(this.temps, apiCheckedIds),
              },
            ]);
          } catch (err) {
            // ignored (Popup should be closed in this step)
          }
          this.$set(this.popup, "isLoading", false);
        });
      }
      // if (_.indexOf(levels, 'users') >= 0) {
      //   apiCheckedIds = this.findCheckedItems('users', location)
      //   console.log('VUEX USERS: ', this.users)
      //   this.popup.data = [{id: 0, name: 'Users', 'users': this.getCheckedUsers(this.users, apiCheckedIds)}]
      // }
      this.popup.active_costcenter_id = location.id;
      this.popup.levels = levels;

      // we collected the data we need, safe to show popup
      this.popup.show = true;
      this.popup.element = e;
    },

    // fetchCCUsers (e, location, clientId) {
    //   this.fetchUsers({'client_id': clientId})
    //   .then(() => {
    //     this.showPopup(['users'], e, location)
    //   })
    // },

    popupClose() {
      this.popup.show = false;
      this.cancelCheckTempsPopup = true;
    },
    async waitForDataArrToLoad(variableToCheckPaths, cancelTokenFuncCheck) {
      // Generic reusable func
      function deepFind(obj, path) {
        const paths = path.split(".");
        let current = obj;

        for (let i = 0; i < paths.length; ++i) {
          if (current[paths[i]] == undefined) {
            return undefined;
          } else {
            current = current[paths[i]];
          }
        }
        return current;
      }

      return new Promise((resolve, reject) => {
        const recheckFunc = () => {
          const tVal = setTimeout(() => {
            const dataToLoadArr = deepFind(this, variableToCheckPaths);
            if (
              !dataToLoadArr ||
              (dataToLoadArr && dataToLoadArr.length === 0)
            ) {
              if (cancelTokenFuncCheck && cancelTokenFuncCheck()) {
                clearTimeout(tVal);
                reject();
              } else {
                console.log("Recheck data");
                recheckFunc();
              }
            } else {
              resolve();
            }
          }, 1000);
        };
        recheckFunc();
      });
    },

    refreshPopupState(
      {
        data,
        entity,
        // eslint-disable-next-line no-unused-vars
      },
      totalCount
    ) {
      console.log("entity", entity[0]);
      if (entity[0] === "temps") {
        // Popup data is still a hierarchy, even though we will most likely only have only one root
        const ids = data.map((rootTemp) => {
          return rootTemp.temps
            .filter((temp) => {
              if (temp.checked === true) {
                return temp.id;
              }
            })
            .map((temp) => temp.id);
        });

        const temps = flatten(ids).map((id) => ({
          id: id,
        }));

        if (this.popup.active_costcenter_id !== 0) {
          this.updateCostCenterTemps({
            ccId: this.popup.active_costcenter_id,
            ids: temps,
          }).then(() => {
            this.$forceUpdate();
            this.$toasted.info("Temps Updated!").goAway(1500);
            this.popupClose();
          });
        } else {
          /*
           * NEW COSTCENTER
           */
        }

        return;
      } else if (entity[0] === "subcategories") {
        const ids = data.map((category) => {
          return category.subcategories
            .filter((subcat) => {
              if (subcat.checked === true) {
                return subcat.id;
              }
            })
            .map((subcat) => subcat.id);
        });

        const subcategories = flatten(ids).map((id) => ({
          id: id,
        }));

        if (this.popup.active_costcenter_id !== 0) {
          this.updateCostCenterSubcategories({
            ccId: this.popup.active_costcenter_id,
            ids: subcategories,
          }).then(() => {
            this.$forceUpdate();
            this.$toasted
              .info("Subcategories updated successfully!")
              .goAway(1500);
            this.popupClose();
          });
          return;
        }

        return; // safety measure
      } else if (entity[0] === "users") {
        // This is not used anymore > now change is in clients profile
        console.log("Refresh users", data);

        const ids = data.map((rootUser) => {
          return rootUser.users
            .filter((user) => {
              if (user.checked === true) {
                return user.id;
              }
            })
            .map((user) => user.id);
        });

        const users = flatten(ids).map((id) => ({
          id: id,
        }));

        console.log("Checked users :: ", users);

        if (this.popup.active_costcenter_id !== 0) {
          this.updateCostCenterUsers({
            ccId: this.popup.active_costcenter_id,
            ids: users,
          }).then(() => {
            this.$forceUpdate();
            this.$toasted.info("Users Updated!").goAway(1500);
            this.popupClose();
          });
        }

        return; // safety measure
      }
    },
    addWardBegin() {
      this.add_newward = true;
      console.log("Add Ward triggered.");
    },

    resetCreateNewFields() {
      this.newCostCenterTemplate = Object.assign(
        {},
        this.newCostCenterTemplate,
        {
          id: 0,
          name: "",
          location_external_id: "",
          subcategories: [],
          temps: [],
          county: "",
          client_id: this.client.id,
        }
      );
    },
    onCopyModalWard(location) {
      // Clones the ward and creates a new copy
      // ward - cost centre - location
      this.$emit("on-show-modal", {
        modal: "client-copy-cost-center",
        modalData: { location, client: this.client },
        modalTitle: "Copy Ward",
      });
    },
    onCreateCloneWard(paramsEvt) {
      console.warn("on Create", paramsEvt);
    },
    createWard() {
      this.add_newward = false;
      console.log("Creating for :: ", this.newCostCenterTemplate["client_id"]);
      const param ={
        clientId: this.newCostCenterTemplate["client_id"],
        name: this.newCostCenterTemplate["name"],
      };
      if(this.$can('edit-location-external-id') && this.canUseLocationExternalId){
        param.location_external_id = this.newCostCenterTemplate["location_external_id"];
      }
      this.createCostCenterForClient(param)
        .then(() => {
          this.$toasted.info("Ward successfully created!").goAway(1500);
          this.$forceUpdate();
          this.resetCreateNewFields();
        })
        .catch((err) => {
          if (err.message && err.response.data.message) {
            this.$toasted.error(`${err.response.data.message}`).goAway(1500);
          } else {
            this.$toasted.error("Creating ward failed!").goAway(1500);
          }
        });
    },

    findCheckedItems(type, location) {
      const checkedItems = [];
      if (type === "subcategories") {
        location["categories"].map((obj) => {
          obj["subcategories"].map((subcategory) => {
            checkedItems.push(subcategory["id"]);
          });
        });
      }
      if (type === "temps" && location["temps"]?.length) {
        location["temps"].map((temp) => {
          checkedItems.push(temp["id"]);
        });
      }
      if (type === "users") {
        location["users"].map((temp) => {
          checkedItems.push(temp["id"]);
        });
      }

      return checkedItems;
    },

    getCategories(categories, ids = []) {
      return _.each(categories, (cat) => {
        cat.checked = _.contains(ids, cat.id);
      });
    },

    getSubcategoriesByCategories(categories, ids = []) {
      return _.each(categories, (category) =>
        category.subcategories.map((subcat) => {
          subcat.checked = _.contains(ids, subcat.id);
          if (subcat.checked) {
            // console.log('subcat id :: ', subcat.id)
          }
        })
      );
    },

    getCheckedTemps(temps, ids = []) {
      return _.each(temps, (temp) => {
        temp.checked = _.contains(ids, temp.id);
      });
    },

    getCheckedUsers(users, ids = []) {
      return _.each(users, (user) => {
        user.checked = _.contains(ids, user.id);
      });
    },

    async setLocationProp(location, propToSet) {
      this.disableSwitchOnClick = true;
      console.log(`Setting ${propToSet} allowance to: ${location[propToSet]}`);
      let propKeyValue = {};
      if (propToSet === "hasLocationAllowance") {
        propKeyValue = { has_allowance: location.hasLocationAllowance };
      } else if (propToSet === "hasSecureAllowance") {
        propKeyValue = { has_secure_allowance: location.hasSecureAllowance };
      } else if (propToSet === "hasCommunityAllowance") {
        propKeyValue = {
          has_community_allowance: location.hasCommunityAllowance,
        };
      } else if (propToSet === "hasOnCall") {
        propKeyValue = {
          has_on_call: location.hasOnCall,
        };
      } else if (propToSet === "hasSleepover") {
        propKeyValue = {
          has_sleepover: location.hasSleepover,
        };
      } else {
        console.log("propToSet > Not set");
      }
      const params = {
        ccId: location.id,
        type: propToSet,
        hasAllowanceParObj: propKeyValue,
      };
      await this.updateCostCenterAllowance(params);
      this.disableSwitchOnClick = false;
    },
    async onChangeLocationArchive(location, shouldArchive = true) {
      const updateCostCenterArchive = async () => {
        this.isLoadingCostCentreArchive = location.id;
        const params = {
          id: location.id,
          payload: {},
        };
        if (shouldArchive) {
          params.payload.archived = true;
        } else {
          params.payload.archived = false;
        }
        try {
          await this.updateCostCenter(params);
          this.$toasted.success("Ward updated successfully").goAway(2000);
          this.isLoadingCostCentreArchive = null;
        } catch (err) {
          console.warn(err.message);
          const errs = parseErrors(err);
          this.$toasted.error(errs).goAway(2500);
          this.isLoadingCostCentreArchive = null;
        }
      };

      const label = shouldArchive
        ? "archive this ward"
        : "reactivate this ward";
      try {
        await this.confirmAlert(label);
        await updateCostCenterArchive();
      } catch (err) {
        // ignored
      }
    },
    editCostCentre(location, fieldName = "name") {
      this.isEditingCostCentreId = location.id;
      this.editFieldName = fieldName;
      this.$nextTick(() => {
        // Focus clicked element (it's always first)
        if(fieldName === "location_external_id") {
          this.$refs.location_external_id[0].focus();
        } else {
          this.$refs.locationInput[0].focus();
        }
        
      });
    },
    async changeLocationName(event, location) {
      if (location.name !== event.target.value) {
        try {
          await this.confirmAlert("change the name of the ward");
          this.isEditingCostCentreId = null;
          this.isLoadingNameApi = true;
          const params = {
            id: location.id,
            payload: {
              name: event.target.value,
            },
          };
          await this.updateCostCenter(params);
          this.isLoadingNameApi = false;
          this.$toasted.success("Ward updated successfully").goAway(2000);
        } catch (err) {
          this.isLoadingNameApi = false;
          console.warn(err.message);
          const errs = parseErrors(err);
          this.$toasted.error(errs).goAway(2500);
        }
      } else {
        this.isEditingCostCentreId = null;
        this.editFieldName = null;
      }
    },
    confirmAlert(strAct) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    // change ExternalID 
     async changeLocationExternalId(event, location) {
      if (location.location_external_id !== event.target.value) {
        try {
          await this.confirmAlertID("change the external id of the ward");
          this.isEditingCostCentreId = null;
          this.isLoadingNameApi = true;
          const params = {
            id: location.id,
            payload: {
              location_external_id: event.target.value,
            },
          };
          await this.updateCostCenter(params);
          this.isLoadingNameApi = false;
          this.$toasted
            .success("Ward updated successfully")
            .goAway(2000);
        } catch (err) {
          this.isLoadingNameApi = false;
          console.warn(err.message);
          const errs = parseErrors(err);
          this.$toasted.error(errs).goAway(2500);
        }
      } else {
        this.isEditingCostCentreId = null;
        this.editFieldName = null;
      }
    },
    confirmAlertID(strAct) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${strAct}?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    // openModalUsers(e, location, clientId) {
    //   this.popup.data = [
    //     {
    //       id: 0,
    //       name: "Users",
    //       users: [],
    //     },
    //   ];
    //   this.popup.active_costcenter_id = location["id"];
    //   this.popup.levels = ["users"];
    //   this.popup.element = e;
    //   this.popup.show = true;
    //   this.$set(this.popup, "isLoading", true);

    //   this.fetchUsers({
    //     client_id: clientId,
    //     per_page: 999,
    //   })
    //     .then(() => {
    //       const apiCheckedIds = this.findCheckedItems("users", location);
    //       this.$set(this.popup.data, 0, {
    //         id: 0,
    //         name: "Users",
    //         users: this.getCheckedUsers(this.users, apiCheckedIds),
    //       });
    //       this.$set(this.popup, "isLoading", false);
    //     })
    //     .catch((err) => {
    //       console.warn("Failed", err.message);
    //     });
    // },
  },
};
</script>

<style lang="scss" scoped>
.custom-animation-enter,
.custom-animation-leave-to {
  transform: scale(0);
}

.custom-animation-enter-active,
.custom-animation-leave-active {
  transition: all 0.3s ease-out, opacity 1s ease-out;
}

table.no-radius {
  border-radius: 0;

  td {
    background-color: #f2f7fa;
  }
}

.is-wide-tag {
  min-width: 50px;
  display: inline-flex;
  justify-content: space-between;
}

.generic-app-tag {
  position: relative;
  background-color: #e3ebed;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.generic-app-tag.generic-app-tag--deadline {
  background-color: #d2ecf5;
}

.generic-app-tag.generic-app-tag--expiry {
  background-color: #ffcc6a;
}

tbody.generic-app-subtable {
  z-index: 500;

  tr {
    background-color: #f2f7fa;

    td.location-allowance-td,
    td.secure-allowance-td,
    td.community-allowance-td {
      text-align: center;
      // padding: 0 3% 0 4%;
      .loader {
        border: 5px solid #dbdbdb;
        border-radius: 290486px;
        border-right-color: transparent;
        border-top-color: transparent;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .subtable-name {
    width: 15%;
    padding-left: 50px;
    text-overflow: ellipsis;
    max-width: 410px;
    overflow: hidden;

    > .right-side {
      // display: inline;
      float: left;
      margin-right: 5px;

      svg {
        cursor: pointer;
      }

      button {
        height: 20px;
        background: transparent;
        border: none;
      }
    }
  }
  
}
.external_id{
    padding-left: 0.5em !important;
}

.wrapper-activation {
  text-align: right;
}

.block.controls {
  button {
    margin-right: 10px;
  }
}
</style>
