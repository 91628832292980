<template lang="pug">
.modal.is-active(@keyup.esc="onCloseModal", tabindex="0")
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  .modal-background(v-if="stopEventPropagation", @click.stop="")
  .modal-background(v-else, @click="onCloseModal")
  .modal-content(:style="style")
    .panel
      .panel-heading.has-text-centered(v-show="!headless")
        //- :style="{ background: titleBackground }"
        i(v-if="hasIcon", :class="faicon")
        span.modal-title(style="vertical-align: middle").
          {{title}}
      .panel-block
        .success(v-if="success")
          p.notification.is-success.
            Operation completed with success!
        slot THIS MODAL IS MISSING A CONTENT
  button.modal-close(@click="onCloseModal", v-if="showCloseButton")
</template>
<script>
// .wrapp-panel-content was set for v-click-outside   (v-click-outside="onCloseModal")
// in stlye is set display flex center..
import { Evt } from "../../lib/helpers/Evt.js";
export default {
  name: "Modal",
  props: {
    title: {
      type: String,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    size: {
      type: [Number, String],
    },
    minWidth: {
      type: [Number, String],
    },
    headless: {
      default: false,
    },
    scrollable: {
      default: false,
    },
    showCloseButton: {
      default: true,
      type: Boolean,
    },
    stopEventPropagation: {
      default: false,
    },
    scrollBlock: {
      default: false,
    },
    overflowVisible: {
      default: false,
    },
    // titleBackground: {},
  },
  data() {
    return {
      success: false,
      isDataChanged: false,
    };
  },
  created() {
    //SLOT LISTENER
    this.$on("has-changed", this.setIsDataChanged);
    this.$on("close-modal", this.onCloseModal);
  },
  mounted() {
    Evt.fire("zap-opened", false);
    // console.log(this.$children[0].$options.propsData.modalProps)
  },
  computed: {
    faicon() {
      return "fa fa-" + this.icon;
    },
    style() {
      return {
        width: `${this.size}px`,
        "max-width": "90vw",
        maxHeight: "90vh",
        "min-width": this.minWidth + "px",
        "overflow-y": this.scrollBlock
          ? "hidden"
          : this.scrollable
          ? "auto"
          : "visible",
        overflow: this.overflowVisible && "visible",
      };
    },
  },
  components: {},
  methods: {
    async onCloseModal() {
      if (this.isDataChanged) {
        await this.confirmClose();
        this.$emit("close");
      } else {
        this.$emit("close");
      }
    },
    confirmClose() {
      const label = "Are you sure you want to close this dialog?";
      return new Promise((resolve) => {
        const alert = {
          title: `${label}`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    setIsDataChanged(bool) {
      this.isDataChanged = bool;
    },
  },
};
</script>
<style lang="scss">
.modal {
  label {
    margin-top: 0.5em;
    color: #7c91ae;
  }
}

.modal-content {
  display: flex;
  overscroll-behavior: none;
}

.modal-title {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin-left: 0.4em;
}

.panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 90vh; // This is to prevent overflow scrolling

  // justify-content: center;
  // align-items: center;

  // .wrapp-panel-content {
  //   min-height: fit-content;
  // }
  .panel-heading {
    margin: auto 0 0 0; // This is to center child
  }

  .panel-block {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: white;
    padding: 0 1.3em;
    justify-content: center;
    // overflow: auto;
    margin: 0 0 auto 0; // This is to center child
  }

  .has-text-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .panel-heading {
    background-color: white;
  }

  button {
    align-self: flex-end;
  }
}
</style>
