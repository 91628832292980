<template lang="pug">
.confirm-rehire-wrapper
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  form.mt(autocomplete="on")
  .lost-center
    .load-wrap(
      style="display: flex; justify-content: center",
      v-if="isFetchTempLoading"
    )
      img.profile-loading(src="../../../assets/images/comps/loader.svg")
    .lost-shift
      p.control(
        data-cell="first_name",
        v-if="this.tempDetails && shouldDisplayAttribute('first_name')"
      )
        label.label First name
        input.input.is-medium(
          v-model="tempDetails.first_name",
          type="text",
          placeholder="FIRST NAME",
          disabled
        )
      p.control(
        data-cell="last_name",
        v-if="shouldDisplayAttribute('last_name')"
      )
        label.label Last name
        input.input.is-medium(
          v-model="tempDetails.last_name",
          type="text",
          placeholder="LAST NAME",
          disabled
        )
      p.control(data-cell="know_as", v-if="shouldDisplayAttribute('nickname')")
        label.label Known as
        input.input.is-medium(
          v-model="tempDetails.nickname",
          type="text",
          placeholder="NICKNAME",
          disabled
        )
      p.control(
        data-cell="date-of-birth",
        v-if="shouldDisplayAttribute('date_of_birth')"
      )
        label.label Date of birth
        uni-date-picker(
          v-model="tempDetails.date_of_birth",
          placeholder="DATE OF BIRTH",
          :propConfig="contractStartDateConfig",
          is-disabled
        )
      p.control(
        data-cell="home_address_1",
        v-if="shouldDisplayAttribute('home_address')"
      )
        label.label Home Address Line 1
        input.input.is-medium(
          v-model="tempDetails.home_address",
          type="text",
          placeholder="Home Address Line 1",
          disabled
        )
      p.control(
        data-cell="home_address_2",
        v-if="shouldDisplayAttribute('home_address_2')"
      )
        label.label Home Address Line 2
        input.input.is-medium(
          v-model="tempDetails.home_address_2",
          type="text",
          placeholder="Home Address Line 2",
          disabled
        )
      p.control(
        data-cell="home_address_3",
        v-if="shouldDisplayAttribute('home_address_3')"
      )
        label.label Home Address Line 3
        input.input.is-medium(
          v-model="tempDetails.home_address_3",
          type="text",
          placeholder="Home Address Line 3",
          disabled
        )
      p.control(
        data-cell="mobile_phone",
        v-if="shouldDisplayAttribute('mobile_phone')"
      )
        label.label Mobile Number
        input.input.is-medium(
          v-model="tempDetails.mobile_phone",
          type="text",
          placeholder="Mobile phone",
          disabled
        )
      p.control(
        data-cell="phone_mobile",
        v-if="shouldDisplayAttribute('home_phone')"
      )
        label.label Phone Number
        input.input.is-medium(
          v-model="tempDetails.home_phone",
          type="text",
          placeholder="Home phone",
          disabled
        )
      p.control(data-cell="email", v-if="shouldDisplayAttribute('email')")
        label.label Email address
        input.input.is-medium(
          v-model="tempDetails.email",
          type="text",
          placeholder="Email",
          disabled
        )
      p.control(data-cell="gender", v-if="shouldDisplayAttribute('gender')")
        label.label Gender
        input.input.is-medium(
          v-model="tempDetails.gender",
          type="text",
          placeholder="Gender",
          disabled
        )
      p.control(
        data-cell="start_date",
        v-if="shouldDisplayAttribute('contract_start_date')"
      )
        label.label Start Date
        uni-date-picker(
          v-model="tempDetails.contract_start_date",
          placeholder="START DATE",
          :propConfig="contractStartDateConfig"
        )
      //- p.control(data-cell="last_name", v-if="shouldDisplayAttribute('name')")
      //-   label.label Next of Kin details
      //-   input.input.is-medium(
      //-     v-model="waitingKeysFromBackend",
      //-     type="text",
      //-     :placeholder="placeholderWaitingKeysFromBackend",
      //-     disabled
      //-   )
      //- p.control(data-cell="county", v-if="shouldDisplayAttribute('name')")
      //-   label.label County
      //-   input.input.is-medium(
      //-     v-model="waitingKeysFromBackend",
      //-     type="text",
      //-     :placeholder="placeholderWaitingKeysFromBackend",
      //-     disabled
      //-   )
      p.control(data-cell="eircode", v-if="shouldDisplayAttribute('eircode')")
        label.label Eircode
        input.input.is-medium(
          v-model="tempDetails.eircode",
          type="text",
          placeholder="EIRCODE",
          disabled
        )
      p.control(data-cell="iban", v-if="shouldDisplayAttribute('iban')")
        label.label Iban
        input.input.is-medium(
          v-model="tempDetails.iban",
          type="text",
          placeholder="IBAN",
          disabled
        )
      p.control(data-cell="bic", v-if="shouldDisplayAttribute('bic')")
        label.label Bic
        input.input.is-medium(
          v-model="tempDetails.bic",
          type="text",
          placeholder="BIC",
          disabled
        )
      p.control(
        data-cell="nationality_name",
        v-if="canUseNexusMiddleware && shouldDisplayAttribute('nationality_name')"
      )
        label.label Nationality
        input.input.is-medium(
          v-model="tempDetails.nationality_name",
          type="text",
          placeholder="Nationality",
          disabled
        )
      p.control(
        data-cell="nationality_code",
        v-if="tempDetails.relatives && tempDetails.relatives[0] && shouldDisplayAttribute('relatives')"
      )
        label.label Next of Kin Name
        input.input.is-medium(
          v-model="tempDetails.relatives[0].name",
          type="text",
          placeholder="Next of Kin Name",
          disabled
        )
      p.control(
        data-cell="relatives_phone",
        v-if="tempDetails.relatives && tempDetails.relatives[0] && shouldDisplayAttribute('relatives')"
      )
        label.label Next of Kin Contact Number
        input.input.is-medium(
          v-model="tempDetails.relatives[0].phone",
          type="text",
          placeholder="Next of Kin Contact Number",
          disabled
        )
      p.control(
        data-cell="relativetype_name",
        v-if="tempDetails.relatives && tempDetails.relatives[0] && shouldDisplayAttribute('relatives')"
      )
        label.label Next of Kin Relationship
        input.input.is-medium(
          v-model="tempDetails.relatives[0].relativetype_name",
          type="text",
          placeholder="Next of Kin Relationship",
          disabled
        )
      p.control.btn
        button#btn.button.is-info.is-fullwidth(
          data-cell="submit-button",
          :class="{ 'is-loading': isLoadingConfirmRehire }",
          :disabled="isLoadingConfirmRehire",
          @click.prevent="confirm"
        )
          span Confirm
</template>

<script>
import _ from "underscore";
import { mapActions } from "vuex";
import { parseErrors } from "../../../lib/helpers/function";
import Pagination from "../../../components/Pagination.vue";
import moment from "moment";

export default {
  name: "ConfirmRehire",
  components: {
    Pagination,
  },
  props: {},
  data() {
    return {
      // testTempData: {
      //   external_id: "1199309S",
      //   first_name: "AnnNV",
      //   last_name: "OShea",
      //   home_address: "6 Ardbracken Kilteragh",
      //   home_address_2: "Dooradoyle",
      //   home_address_3: "Limerick",
      //   mobile_phone: "+353877975890",
      //   usr_insurancenumber: "****720Q",
      //   email: "nemanjaa11@yahoo.com",
      //   date_of_birth: "1979-04-16",
      //   contract_start_date: "2022-09-08",
      //   iban: "****8626",
      //   bic: "****DXXX",
      //   gender: "F",
      //   ready_to_work: true,
      //   relatives: [
      //     {
      //       name: "TEST",
      //       phone: "TEST",
      //       relativetype_name: "TEST",
      //       is_for_external_update: true,
      //     },
      //   ],
      // },
      isLoadingConfirmRehire: false,
      isFetchTempLoading: false,
      tempDetails: {},
      waitingKeysFromBackend: "",
      placeholderWaitingKeysFromBackend: "Waiting for the keys",
    };
  },
  computed: {
    convertedDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    contractStartDateConfig() {
      return {
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        enableTime: false,
      };
    },
  },
  mounted() {
    this.loadTemp();
  },

  methods: {
    ...mapActions({
      sendWelcomeEmail: "sendWelcomeEmail",
      editUserAction: "editUserAction",
      getCountries: "getCountries",
      confirmRehire: "confirmRehire",
    }),
    loadTemp() {
      this.tempDetails = this.$attrs.modalProps.temp;
      // this.tempDetails = this.testTempData;
    },
    shouldDisplayAttribute(attribute) {
      return (
        this.tempDetails.hasOwnProperty(attribute) &&
        this.tempDetails[attribute] !== ""
      );
    },
    // filtrateTempDetails(obj) {
    //   return Object.entries(obj).filter(([key, value]) => value !== "");
    // },
    filtrateTempDetails(obj) {
      return Object.entries(obj).reduce((acc, [key, value]) => {
        if (value !== "") {
          acc[key] = value;
        }
        return acc;
      }, {});
    },
    async confirm() {
      const filtratedParams = this.filtrateTempDetails(this.tempDetails);
      filtratedParams.status = 1;
      filtratedParams.rehired = true;
      // const params = {
      //   url: "temps/" + this.$attrs.modalProps.tempId,
      //   params: filtratedParams,
      // };
      // try {
      // this.isDisabledBtn = true;
      //   await this.editUserAction(params);
      // } catch (error) {
      //   console.log(error);
      // }
      return new Promise(() => {
        const alert = {
          title: `Would you like the system to generate a new password and send the welcome email ?`,
          type: "warning",
          message: `If "no" is selected details will be updated, but the password will not be reset and no email wiil be sent.`,
          useConfirmBtn: true,
          customConfirmBtnText: "Yes",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "No",
          customCloseBtnClass: "button is-outlined",
          onConfirm: this.onConfirm,
          onClose: this.onClose,
          disableOverlayClick: true,
          showXclose: true,
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    async onConfirm() {
      this.confirmRehireLogic(true);
    },
    async onClose() {
      this.confirmRehireLogic(false);
    },
    async confirmRehireLogic(sendPass = false) {
      this.isLoadingConfirmRehire = true;
      const params = {
        tempId: this.$attrs.modalProps.tempId,
        autogenerate_password: sendPass,
        contract_start_date: this.tempDetails.contract_start_date,
        // include:
        //   "locations,region,subcategories,specialities,specialities.temps,clients.incrementRates,categories.increments,increments,wtd,preferredCounties",
      };
      try {
        const res = await this.confirmRehire(params);
        this.$emit("cancel-modal-from-rehire");
      } catch (error) {
        const errs = parseErrors(error);
        if (errs) {
          this.$toasted.error(errs).goAway(2500);
        }
        this.$emit("cancel-modal");
      } finally {
        this.isLoadingConfirmRehire = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-rehire-wrapper {
  overflow: auto;
  .btn {
    margin: 20px 0 10px 0;
  }
}
</style>
