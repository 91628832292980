<template lang="pug">
section.all-candidates
  transition(name="fade")
    modal(
      :title="modalTitle",
      icon="",
      :size="modalSize",
      :scrollBlock="modalAdditionalParams.scrollBlock",
      :scrollable="modalAdditionalParams.scrollable",
      v-if="modalVisible",
      @close="modalCloseCancel"
    )
      component(
        :is="inmodal",
        :modalProps="modalData",
        :initialSectors="initialSectors",
        @should-fetch-all-temps="fetchAllTempsWrap",
        @cancel-modal="modalCloseCancel",
        @cancel-modal-from-rehire="modalCloseFromRehire",
        @dispose-modal="modalCloseChange"
      )
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  section.section
    h1.title.is-3
      i.fa.icon-All-Candidates
      | All Candidates
    .divider
      .columns.candidate-filters
        .column.searcher
          .field
            p.control
              input.input(
                @input="callSearchApi",
                type="text",
                placeholder="Search by name, last name, known as",
                v-model="search.name"
              )
              i.fa.fa-search
              .breaker
          .field
            p.control
              multi-select(
                :options="statusesOptions",
                :multiple="false",
                :close-on-select="true",
                select-label="",
                deselect-label="",
                track-by="id",
                label="name",
                v-model="searchDrop.status",
                placeholder="Filter by status"
              )
              .breaker
        .column.searcher
          .field
            p.control
              input.input(
                @input="callSearchApi",
                type="text",
                placeholder="Search by email",
                v-model="search.email"
              )
              i.fa.fa-search
              .breaker
          .field
            p.control
              multi-select(
                :options="tagsOptions",
                :multiple="false",
                :close-on-select="true",
                select-label="",
                deselect-label="",
                track-by="id",
                label="name",
                v-model="searchDrop.tags",
                placeholder="Filter by tags"
              )
              .breaker
        .column.searcher
          .field
            p.control
              input.input(
                @input="callSearchApi",
                type="text",
                placeholder="Search by phone number",
                v-model="search.mobile_phone"
              )
              i.fa.fa-search
              .breaker
          .field(v-if="!canUseNexusMiddleware")
            p.control
              input.input(
                @input="callSearchApi",
                type="text",
                placeholder="Search by ext. sys. id",
                v-model="search.tss_id_search"
              )
              i.fa.fa-search
              .breaker
          .field(v-if="canUseNexusMiddleware")
            p.control
              input.input(
                @input="callSearchApi",
                type="text",
                placeholder="Search by Worker Reference ID",
                v-model="search.external_id_search"
              )
              i.fa.fa-search
              .breaker
        .column.searcher
          .field
            p.control
              multi-select(
                :options="categories_subcategories",
                :multiple="false",
                :close-on-select="true",
                track-by="id",
                label="name",
                group-label="category",
                group-values="subs",
                v-model="searchDrop.subcategory",
                placeholder="Choose from grades"
              )
              .breaker
          .field
            p.control(style="padding-top: 20px")
              multi-select(
                :options="allSectors",
                :multiple="true",
                :close-on-select="true",
                :loading="isLoadingSector",
                group-label="name",
                group-values="subs",
                track-by="id",
                label="name",
                placeholder="Filter by region",
                v-model="searchDrop.sectors"
              )
              .breaker
        .column.searcher
          .field
            p.control
              multi-select(
                :options="counties",
                :multiple="false",
                :close-on-select="true",
                track-by="id",
                label="name",
                v-model="searchDrop.county",
                placeholder="Choose county"
              )
              .breaker
    button#register.button.is-generic-app-teal.is-caps-lock.is-pulled-right(
      data-cell="new-worker-button",
      v-if="gPermissions.canCreateTemp",
      @click="showModal('register-temp', {}, 'Register New Worker', 800, { scrollable: true })"
    ) Register New Worker
    //- :class="{ 'is-loading': isLoadingSector }",
    //-   :disabled="isLoadingSector",
    table.table
      thead
        tr
          //- Unindent this in the future to enable select all
            th
              label.ctrl.ctrl--checkbox Select All
                input.ctrl--checkbox(type="checkbox", :value="allTempsSelected", @change="allTempsSelected = !allTempsSelected")
          //-
          th {{ this.canUseNexusMiddleware ? "Worker Reference ID" : "Ext. Sys. ID" }}
            sort-buttons(column-name="id", v-model="selectedSortColumn")
          th NAME
            sort-buttons(column-name="name", v-model="selectedSortColumn")
          th EMAIL
            sort-buttons(column-name="email", v-model="selectedSortColumn")
          th PHONE
          //- th(colspan="3") CONSULTANT
          //-   sort-buttons(
          //-     column-name="consultant",
          //-     v-model="selectedSortColumn"
          //-   )
          th(style="width: 250px") TAGS
          th WARDS
          th CALENDAR
          th(v-if="gPermissions.canViewTempDetails") DETAILS
          th STATUS
          th COMPLIANCE
          th(v-if="canUseNexusMiddleware && $can('rehire-temp')") REHIRE
          th(v-if="$can('manage-logout-user')") LOGOUT
          //- th(v-if="$can('view-documents')") COMPLIANCE
      tfoot
      tbody
        tr(v-if="loadingCandidates")
          td.center-text(colspan="100")
            img(src="../assets/images/comps/loader.svg")
        tr(
          v-for="temp in temps",
          v-if="temps && !loadingCandidates",
          :id="temp.id"
        )
          //-
            td
             label.ctrl.ctrl--checkbox
               <input :id="id" type="checkbox" :value="{ id: id }" v-model="selectedTemps" @change="tempsChanged">
               .ctrl__status
          //-
          td(data-cell="id")
            | {{ canUseNexusMiddleware ? temp.external_id : temp.tssId }}
          td(data-cell="name")
            | {{ temp.name }}
          td(data-cell="email")
            | {{ temp.email }}
          td(data-cell="mobile-phone")
            | {{ temp.mobilePhone }}
          //- td(data-cell="consultant", colspan="3")
          //-   template(v-if="temp.consultant") {{ temp.consultant.name }}
          td(data-cell="tags", :title="getTempTagNames(temp)")
            template(v-if="Array.isArray(temp.tags) && temp.tags.length > 0") {{ getTempTagNames(temp) }}
          td.locations(data-cell="locations")
            span.generic-app-tag.is-wide-tag.popup-hook(
              v-tooltip.info.top.bounce="'click to fill in'",
              v-if="loadingLocationTempId !== temp.id",
              :class="{ disabled: !gPermissions.canEditTemp }",
              @click="onClickTempLoc(temp)"
            )
              strong {{ temp.locationCount || 0 }}
              img(src="../assets/images/comps/Olovka.svg")
            .loading-wrapper(v-if="loadingLocationTempId === temp.id")
              img(src="@/assets/images/comps/loader.svg")
          td.has-button.calendar
            router-link.is-tiny(
              :to="{ name: 'view-temp-calendar', params: { tempId: temp.id, isFromCandPage: true } }"
            )
              i.fa.fa-calendar
              //- span Calendar
          td.has-button(
            data-cell="details",
            v-if="gPermissions.canViewTempDetails"
          )
            router-link.button.is-generic-app-blue.is-caps-lock.is-tiny(
              :to="{ name: 'CandidatePage', params: { tempId: temp.id } }"
            ) details
          td.has-button(data-cell="dnr")
            span.button.is-caps-lock.is-tiny(
              :class="setStatus(temp.status)",
              @click="onClickStatus(temp)"
            ) {{ getStatusText(temp.status) }}
          //- td.has-button(v-if="$can('view-documents')")
          td.has-button(data-cell="view")
            router-link.button.is-caps-lock.is-tiny(
              :class="[setViewComplianceClass(temp), { 'is-loading': isLoadingDocExpiry[temp.id] }]",
              :to="{ name: 'temp-compliance', params: { tempId: temp.id } }"
            ) VIEW
          td.has-button(
            data-cell="view",
            v-if="canUseNexusMiddleware && $can('rehire-temp')"
          )
            button.button.is-caps-lock.is-tiny(
              @click="checkAreYouSureWannaUpdate(temp)",
              :class="{ 'is-loading': disableRehire && temp.id === isLoadingRehireButton }",
              :style="{ background: '#3eca5d', color: 'white' }",
              :disabled="isTempRehireDisabled(temp)"
            ) REHIRE
          td.has-button(
            data-cell="manage-logout",
            v-if="$can('manage-logout-user')"
          )
            button.button.is-generic-app-blue.is-caps-lock.is-tiny(
              @click.stop="logoutSpecUser(temp.id)"
            ) LOGOUT
    pagination(
      v-if="candidatePagination.total_pages",
      :total-pages="candidatePagination.total_pages",
      :current-page="candidatePagination.current_page",
      @page-changed="pageHasChanged"
    )
</template>
<script>
import axios from "axios";
import { diffArrayOfObjects, pickNonFalsy } from "../lib/helpers/function.js";
import _ from "underscore";
import Pagination from "./Pagination.vue";
import { mapActions, mapGetters } from "vuex";
import SearchFilter from "./PopupSelectors/SearchFilter.vue";

import EditCandidateSettings from "./in-modal/EditCandidateSettings.vue";
import LocationPrefDialog from "./in-modal/all-candidates/LocationPrefDialog.vue";

import RegisterTemp from "./in-modal/all-candidates/RegisterTemp.vue";
import ConfirmRehire from "./in-modal/all-candidates/ConfirmRehire.vue";
import MultiSelect from "vue-multiselect";

import moment from "moment";
import { parseErrors } from "../lib/helpers/function";
import { ref } from "vue";

export default {
  name: "allCandidates",
  components: {
    SearchFilter,
    Pagination,
    EditCandidateSettings,
    RegisterTemp,
    LocationPrefDialog,
    MultiSelect,
    ConfirmRehire,
  },
  // Page we are currently on, coming from route injected props
  props: ["page"],
  data() {
    return {
      rehireTemp: "",
      loadingCandidates: false,
      isLoadingSector: true,
      cancelToken: null,
      cancelTokenLocations: null,

      filterSet: [],
      lastParamsUsed: {},
      selectedTemps: [],
      allTempsSelected: false,
      modalSize: 800,
      modalVisible: false,
      isLoadingDocExpiry: {},
      loadingLocationTempId: null,
      statusesOptions: [
        {
          id: 1,
          name: "Active",
        },
        {
          id: 2,
          name: "Review",
        },
        {
          id: 3,
          name: "Inactive",
        },
        {
          id: 4,
          name: "Approval",
        },
      ],
      tagsOptions: [],

      search: {
        name: "",
        email: "",
        mobile_phone: "",
        tss_id_search: "",
      },
      searchDrop: {
        status: null,
        subcategory: null,
        county: null,
        tags: null,
        sectors: [],
      },

      selectedSortColumn: {
        column: "",
        direction: "",
      },
      tempDocsExpiry: [],
      tempLocationList: {},

      candidatePagination: {},
      canLoadAlive: false,

      currentTempId: null,
      modalAdditionalParams: {},
      clickedTempId: null,
    };
  },
  computed: {
    ...mapGetters({
      temps: "getTemps",
      allSectors: "locationClientManagement/getSectors",
      sectors: "locationClientManagement/getFlatSectors",
      counties: "reports/getCounties",
      categories_subcategories: "getCategoriesWithSubcategories",
    }),
    // Computed values for dropdowns (Grades, County)
    drops() {
      return {
        subcategory_id: this.searchDrop.subcategory
          ? this.searchDrop.subcategory.id
          : null,
        region_id: this.searchDrop.county ? this.searchDrop.county.id : null,
        status: this.searchDrop.status ? this.searchDrop.status.id : null,
        tag_id: this.searchDrop.tags ? this.searchDrop.tags.id : null,
        sectors: this.searchDrop.sectors.map((sec) => ({ id: sec.id })),
      };
    },

    initialSectors() {
      const tempId = this.currentTempId;
      if (!tempId) {
        return [];
      }

      const theTemp = this.tempLocationList[tempId];
      if (!theTemp) {
        // If not found
        console.log("Temp for id: " + tempId + " not found in Vuex.");
        return [];
      }

      const findCheckedItems = (type, temp) => {
        // Used for type=locations
        return temp[type].map((typeItem) => typeItem.id);
      };

      const apiCheckedIds = findCheckedItems("locations", theTemp);
      checkedIdObjects = this.setPreferredStatusAndCopyReasons(
        this.sectors,
        theTemp
      );
      // FIX - var sideeffect!!
      // eslint-disable-next-line no-var
      var checkedIdObjects = this.getCheckedCCs(this.sectors, apiCheckedIds);

      return checkedIdObjects;
    },
  },
  watch: {
    // When any of search dropdowns changes
    // Send request for candidates again
    // County, Grades
    drops() {
      this.callSearchApi();
    },
    allTempsSelected: function () {
      if (!this.temps) return;

      if (!this.allTempsSelected) {
        this.selectedTemps = diffArrayOfObjects(this.selectedTemps, this.temps);
      } else {
        this.temps.map((e) => {
          if (!_.findWhere(this.selectedTemps, { id: e.id })) {
            this.selectedTemps.push({ id: e.id });
          }
        });
      }
      this.$emit("shiftSelected", this.selectedShifts);
    },
    selectedSortColumn(value) {
      this.sortResults(value);
    },
  },
  created() {
    // Check if we have a query.filter set!?
    // (in case when coming from Dashboard)
    if (this.$route.query.filter) {
      this.lastParamsUsed = Object.assign(
        {},
        this.lastParamsUsed,
        this.$route.query
      );
    }
    this.getCandidates({});
    const sectorIncludes = ["clients.locations", "withArchived"].join();
    this.fetchSectors({ includes: sectorIncludes }).then(() => {
      this.isLoadingSector = false;
    });
    this.fetchRegions();
    this.fetchAllCategoriesList();

    if (this.$can("manage-temp-tags")) this.fetchAllTags();
  },
  activated() {
    if (this.canLoadAlive) {
      this.fetchAllTempsWrap(this.lastParamsUsed);
    } else {
      this.canLoadAlive = true;
    }
  },
  setup() {
    const isLoadingRehireButton = ref(null);
    const disableRehire = ref(false);
    function onClose() {
      // console.log("close");
    }
    return { onClose, disableRehire, isLoadingRehireButton };
  },
  methods: {
    ...mapActions({
      fetchAllTemps: "fetchAllTemps",
      fetchTempsCancellable: "fetchTempsCancellable",
      clearTemps: "clearTemps",
      fetchSectors: "locationClientManagement/fetchSectors",
      fetchAllCategoriesList: "fetchAllCategoriesList",
      fetchRegions: "reports/fetchRegions",
      getTempTags: "getTempTags",
      getTempLocations: "getTempLocations",
      fetchTempInfoFromTss: "fetchTempInfoFromTss",
      externalTempinfo: "externalTempinfo",
    }),
    isTempRehireDisabled(temp) {
      return (
        !this.canUseNexusMiddleware ||
        !this.$can("rehire-temp") ||
        !this.isDateInThePast(temp) ||
        this.disableRehire ||
        temp.status !== 3
      );
    },
    modalCloseFromRehire() {
      this.modalVisible = false;
      this.loadingLocationTempId = null;
      this.fetchAllTempsWrap(this.lastParamsUsed);
    },
    isDateInThePast(temp) {
      if (!temp.cessation_date || temp.cessation_date === "") {
        return false;
      } else {
        const isBeforeToday = moment(temp.cessation_date).isBefore(moment());
        return isBeforeToday;
      }
    },
    onConfirm() {
      this.showModal(
        "confirm-rehire",
        { temp: this.rehireTemp, tempId: this.clickedTempId },
        `Details summary`,
        450
      );
    },
    async checkAreYouSureWannaUpdate(temp) {
      this.isLoadingRehireButton = temp.id;
      this.disableRehire = true;
      // if (this.isTempRehireDisabled(temp)) {
      //   console.log(
      //     "Can use nexus middleware",
      //     this.canUseNexusMiddleware,
      //     "Rehire temp",
      //     this.$can("rehire-temp"),
      //     "Date past",
      //     this.isDateInThePast(temp),
      //     "disableRwehire",
      //     this.disableRehire,
      //     "temp status treba d abude razlicit od 3",
      //     temp.status
      //   );
      //   this.disableRehire = false;
      //   return;
      // }
      try {
        // const res = await this.fetchTempInfoFromTss({
        //   id: this.canUseNexusMiddleware
        //     ? temp.external_id
        //     : temp.tssId,
        // });
        const params = { tempId: temp.id };
        const res = await this.externalTempinfo(params);
        this.disableRehire = false;
        this.rehireTemp = res.data;
        this.clickedTempId = temp.id;

        return new Promise(() => {
          const alert = {
            title: `Are you sure you wish to update the details ?`,
            type: "warning",
            message: `Which Reference ID is "${
              this.canUseNexusMiddleware ? temp.external_id : temp.tssId
            }" and worker name "${temp.name}". `,
            useConfirmBtn: true,
            customConfirmBtnText: "Confirm",
            customConfirmBtnClass: "button is-danger",
            customCloseBtnText: "Cancel",
            customCloseBtnClass: "button is-outlined",
            onConfirm: this.onConfirm,
            onClose: this.onClose,
            disableOverlayClick: false,
          };
          this.$refs.simplert.openSimplert(alert);
        });
      } catch (error) {
        this.disableRehire = false;
        const errs = parseErrors(error);
        if (errs) {
          this.$toasted.error(errs).goAway(2500);
        }
      }
    },
    onClickStatus(temp) {
      if (this.gPermissions.canEditTemp) {
        this.showModal(
          "edit-candidate-settings",
          { tempId: temp.id, name: temp.name, dnrStatus: temp.status, temp },
          `Candidate's status`,
          450
        );
      }
    },
    onClickTempLoc(temp) {
      if (this.gPermissions.canEditTemp) {
        this.showCCPrefModal(temp.id);
      } else {
        console.warn("Missing edit-temp permission to open locations");
      }
    },
    componentSearch: function (obj) {
      console.log(obj);
    },
    defineQuery: function (obj) {
      console.log(obj);
      this.filterSet = this.filterSet.concat(obj);
    },
    async fetchAllTags() {
      try {
        const res = await this.getTempTags();
        this.tagsOptions = res.data.data;
      } catch (error) {
        console.warn(error);
      }
    },
    setStatus(s) {
      let c;
      switch (s) {
        case 1:
          c = "is-success";
          break;
        case 2:
          c = "is-warning";
          break;
        case 3:
          c = "is-danger";
          break;
        case 4:
          c = "is-lime-green";
          break;
      }
      return c;
    },
    getStatusText(s) {
      let c;
      switch (s) {
        case 1:
          c = "Active";
          break;
        case 2:
          c = "Review";
          break;
        case 3:
          c = "Inactive";
          break;
        case 4:
          // Manager approval/Compliance Exception
          c = "Approval";
          break;
      }
      return c;
    },
    setViewComplianceClass(temp) {
      // const foundUpdatedTemp = this.tempDocsExpiry.find(
      //   (tempDoc) => tempDoc.id === temp.id
      // );
      // if (foundUpdatedTemp) {
      if (
        temp.hasExpiredDocument ||
        temp.hasNonoptionalDocuments === false ||
        temp.hasNonoptionalDocuments === 0
      ) {
        return ["is-danger"];
      } else if (temp.hasNearExpiryDocument) {
        return ["is-orange-btn"];
      }
      // } else {
      // ignoring temp
      // }

      return ["is-success"];
    },
    tempsChanged() {
      this.$emit("tempsSelected", this.selectedTemps);
    },
    async getCandidates({ page }) {
      this.loadingCandidates = true;
      const params = {
        include: "consultant,locationCount,documentExpiryChecks,tags",
        // include: "consultant,locations,tags",
        ...this.search,
        ...this.drops,
      };
      this.lastParamsUsed = pickNonFalsy(
        Object.assign({}, this.lastParamsUsed, params)
      );
      // If there is a request pending, cancel it
      if (this.cancelToken !== null) {
        this.cancelToken.cancel();
        this.cancelToken = null;
        // Restart loading spinner
      }
      this.resetPreviousLocationToken();

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // Remember the token for cancellation
      this.cancelToken = source;
      if (page || this.page) {
        this.$set(this.lastParamsUsed, "page", page || this.page);
      }

      let response = null;
      try {
        response = await this.fetchTempsCancellable({
          // API mutates obj, so the reference needs to be removed
          query: { ...this.lastParamsUsed },
          cancelTokenSource: source,
        });
        this.candidatePagination = response.data.meta.pagination;
        this.loadingCandidates = false;

        // this.fetchDocumentExpiryChecks();
      } catch (err) {
        if (axios.isCancel(err)) {
          this.loadingCandidates = true;
        } else {
          this.loadingCandidates = false;
        }
        console.log(err.message);
      }
      return response;
    },
    resetPreviousLocationToken() {
      // If there is a request pending, cancel it
      if (this.cancelTokenLocations !== null) {
        this.cancelTokenLocations.cancel();
        this.cancelTokenLocations = null;
      }
    },
    async fetchDocumentExpiryChecks() {
      // Unused
      this.resetPreviousLocationToken();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // Remember the token for cancellation
      this.cancelTokenLocations = source;

      this.tempDocsExpiry = [];
      for (const temp of this.temps) {
        this.$set(this.isLoadingDocExpiry, temp.id, true);
        const params = {
          query: {
            "temps[]": [temp.id],
            include: "documentExpiryChecks",
          },
          cancelTokenSource: source,
        };

        const promise = async (params) => {
          try {
            const res = await this.getTempLocations(params);
            this.$set(this.isLoadingDocExpiry, temp.id, false);
            const responseDataArr = res.data.data;
            this.tempDocsExpiry.push(...(responseDataArr || []));
          } catch (err) {
            // console.log(err);
          }
        };

        promise(params);
      }
    },
    async fetchLocationDataFromTemp(tempId) {
      this.resetPreviousLocationToken();
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // Remember the token for cancellation
      this.cancelTokenLocations = source;

      const params = {
        query: {
          "temps[]": [tempId],
          include: ["locations", "withArchived"].join(),
        },
        cancelTokenSource: source,
      };

      try {
        const res = await this.getTempLocations(params);
        this.$set(this.tempLocationList, tempId, res.data.data?.[0] || {});
        return true;
      } catch (err) {
        console.warn("ERR", err.message);
      }
      return false;
    },
    async pageHasChanged(pageNum) {
      // keep all filters state, then repeat a query with &page=pageNum
      this.$set(this.lastParamsUsed, "page", pageNum);

      try {
        await this.fetchAllTempsWrap(this.lastParamsUsed);
        console.log(this.lastParamsUsed);
      } catch (err) {
        console.log(err.message);
      } finally {
        this.loadingCandidates = false;
      }
    },
    async fetchAllTempsWrap(lastParamsUsed) {
      this.loadingCandidates = true;
      this.resetPreviousLocationToken();

      try {
        const response = await this.fetchAllTemps({ ...lastParamsUsed });
        this.loadingCandidates = false;
        this.candidatePagination = response.data.meta.pagination;

        // this.fetchDocumentExpiryChecks();
      } catch (err) {
        this.loadingCandidates = false;
        console.log(err.message);
      }
    },
    async showCCPrefModal(tempId) {
      this.loadingLocationTempId = tempId;
      const isSuccess = await this.fetchLocationDataFromTemp(tempId);
      if (isSuccess) {
        this.currentTempId = tempId;
        const params = {
          // sectors: checkedIdObjects,
          tempId: tempId,
          isCancelPresent: true,
        };
        this.loadingLocationTempId = null;
        this.showModal(
          "location-pref-dialog",
          params,
          "Ward Preference",
          null,
          { scrollBlock: true }
        );
      } else {
        console.log(">> Canceled location modal for temp:", tempId);
      }
    },
    showModal(
      modal,
      modalData = {},
      modalTitle = "Modal title",
      modalSize = "800",
      params = {}
    ) {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalSize = modalSize;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalAdditionalParams = params;
      this.modalVisible = true;
    },
    modalCloseChange() {
      this.modalCloseCancel();
      this.getCandidates({});
    },
    modalCloseCancel() {
      this.modalVisible = false;
      this.loadingLocationTempId = null;
    },
    setPreferredStatusAndCopyReasons(sectors, temp) {
      // Contains preferences in the following format
      // {tag: 'preferred', id: 2}
      // tag values: 'preferred', 'unpreferred', 'neither'
      const aggregatedPreferences = [];
      const dataToAppend = {};

      temp["locations"].map((location) => {
        if (location.isTempPreferred === true) {
          aggregatedPreferences.push({ tag: "preferred", id: location["id"] });
        } else if (location.isTempPreferred === false) {
          aggregatedPreferences.push({
            tag: "unpreferred",
            id: location["id"],
          });
        } else {
          aggregatedPreferences.push({ tag: "neither", id: location["id"] });
        }
        dataToAppend[location["id"]] = location["reason"];
      });

      return _.each(sectors, (sector) => {
        return _.each(sector.clients, (client) => {
          return _.each(client.locations, (location) => {
            // Find each respective preference
            for (let i = 0; i < aggregatedPreferences.length; i++) {
              const pref = aggregatedPreferences[i];
              if (pref["id"] === location["id"]) {
                let valueToSet;
                if (pref["tag"] === "preferred") {
                  valueToSet = true;
                } else if (pref["tag"] === "unpreferred") {
                  valueToSet = false;
                } else if (pref["tag"] === "neither") {
                  valueToSet = null;
                }
                location["isTempPreferred"] = valueToSet;
              }
            }
            // If still undefined - set null anyway, so that it's undefined on the modal
            if (location["isTempPreferred"] === undefined) {
              location["isTempPreferred"] = null;
            }

            location["reason"] = dataToAppend[location["id"]];
          });
        });
      });
    },
    getCheckedCCs(sectors, ids = []) {
      return _.each(sectors, (sector) => {
        // console.log('Checking Sector :: ', sector)
        return _.each(sector.clients, (client) => {
          // console.log('Checking Client :: ', client)
          return _.each(client.locations, (location) => {
            // console.log('Checking CC :: ', location)
            location.checked = _.contains(ids, location.id);
          });
        });
      });
    },
    callSearchApi: _.debounce(function () {
      // console.log('Search invoked for :: ', this.search)
      // Set pagination (local)
      this.$router.replace({ query: { page: 1 } }).catch(() => ({}));
      this.getCandidates({ page: 1 });
    }, 400),
    async sortResults(sortData) {
      this.lastParamsUsed.sort_by_field = sortData.column;
      this.lastParamsUsed.sort_by_direction = sortData.direction;
      if (sortData.column === "") {
        delete this.lastParamsUsed.sort_by_field;
        delete this.lastParamsUsed.sort_by_direction;
      }
      this.loadingCandidates = true;
      await this.getCandidates(this.lastParamsUsed);
      this.loadingCandidates = false;
    },
    info(e) {
      console.log(e);
    },
    logoutSpecUser(id) {
      console.warn(id);
    },
    getTempTagNames(temp) {
      if (temp.tags && Array.isArray(temp.tags)) {
        return temp.tags.map((tag) => tag.name).join(", ");
      }
      // console.warn("Error parsing", temp.tags, temp);
      return "";
    },
  },
};
</script>
<style scoped lang="scss">
$color--light-gray: #dde6eb;
$color--light-blue: #8399a6;
$color--blue: #2669b0;
$color--border: #9bb4c1;
$color--white: #ffffff;
$color--light-grey: #e3ebed;
$color--dark-blue: #405168;
$color--dark-grey: #7b7b7b;
$color--primary: #2669b0;
$color--secondary: #7c9ec9;

.is-wide-tag {
  min-width: 50px;
  display: inline-flex;
  justify-content: space-between;
}

.generic-app-tag {
  position: relative;
  background-color: #e3ebed;
  border-radius: 3px;
  padding: 0.2em 0.5em;

  &.disabled {
    opacity: 0.7;
  }
}

.divider {
  margin-top: 50px;
  padding: 10px 0 20px 0;
  border-top: 2px solid $color--light-gray;
  border-bottom: 2px solid $color--light-gray;
  min-height: 150px;

  .breaker {
    position: absolute;
    width: 1px;
    height: 100px;
    background-color: $color--light-gray;
    right: 0;
    top: 35px;
  }
}

.candidate-filters {
  .searcher {
    position: relative;
    padding-right: 25px;
    margin-right: 15px;

    .control {
      padding-top: 25px;
    }
  }

  input {
    box-shadow: none;
    border: 1px solid $color--border;
    height: 37px;

    &::placeholder {
      color: $color--light-blue;
      font-size: 15px;
    }
  }

  .control {
    i {
      top: 37px;
      position: absolute;
      right: 15px;
      font-size: 14px;
      color: $color--light-blue;
    }
  }
}

.table {
  margin-top: 20px;

  thead {
    tr {
      height: 45px;
    }
  }

  th {
    text-align: left;
    color: #7c91ae;
    font-size: 13px;
    font-weight: 900;
    border-width: 0 0 1px;
  }

  td {
    color: #405168;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;

    &.locations {
      height: 50px;

      .loading-wrapper {
        height: 20px;
        width: 35px;
        padding: 0 0 0 10px;
      }
    }
  }
}

.ctrl {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  line-height: 12px;
  color: $color--blue;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.ctrl__status {
  position: absolute;
  top: -5px;
  height: 12px;
  width: 12px;
  background: $color--white;
  border: 1px solid $color--secondary;
  border-radius: 2px;

  .ctrl input:checked ~ & {
    background: $color--primary;
  }

  .ctrl input:disabled ~ & {
    background: $color--light-grey;
    opacity: 0.6;
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;

    .ctrl input:checked ~ & {
      display: block;
    }

    .ctrl--checkbox & {
      left: 3px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid $color--white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    .ctrl--checkbox input:disabled ~ & {
      border-color: $color--dark-grey;
    }
  }
}

#register {
  margin: 1em 0;
}

td.has-button {
  &.calendar {
    $icon-col: rgb(100, 100, 100);
    text-align: center;
    i {
      color: $icon-col;
      font-size: 18px;

      &:hover {
        color: lighten($color: $icon-col, $amount: 20);
      }
    }
  }

  .button {
    min-width: 80px;
  }
}

@media (max-width: 768px) {
  .breaker {
    display: none;
  }
}
</style>
