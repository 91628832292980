import { default as axios } from "../config.js";
import { Evt } from "../../lib/helpers/Evt.js";

const api = {
  fetchInvoices: (params) => {
    const q = {
      page: params.page || 1,
    };
    if (params.clientId) {
      q.clientId = params.clientId;
    }
    if (params.invoiceExternalId) {
      q.inv_externalid = params.invoiceExternalId;
    }
    q.timestamp = new Date().getTime();
    return axios.get("/invoices", {
      params: q,
    });
  },

  fetchBackup: (invoice) => {
    let url = `/invoices/${invoice.id}/backup.csv`;
    const a = document.createElement("a");
    document.body.appendChild(a);
    return axios
      .get(url, {
        responseType: "arraybuffer",
        params: {
          timestamp: new Date().getTime(),
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/csv" });
        url = window.URL.createObjectURL(blob);
        const fileName = response.headers["x-multi-file-name"];
        a.download = fileName;
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        Evt.fire("enableDownloadInvoices");
      });
  },

  fetchPayAndBill: (invoice) => {
    let url = `/invoices/${invoice.id}/pay-and-bill.csv`;
    const a = document.createElement("a");
    document.body.appendChild(a);
    return axios
      .get(url, {
        responseType: "arraybuffer",
        params: {
          timestamp: new Date().getTime(),
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/csv" });
        url = window.URL.createObjectURL(blob);
        const fileName = response.headers["x-multi-file-name"];
        a.download = fileName;
        a.href = url;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        Evt.fire("enableDownloadInvoices");
      });
  },

  fetchPDF: (invoice) => {
    let url = `/invoices/${invoice.id}/invoice.pdf`;
    const a = document.createElement("a");
    return axios
      .get(url, {
        responseType: "arraybuffer",
        params: {
          timestamp: new Date().getTime(),
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        url = window.URL.createObjectURL(blob);
        const fileName = response.headers["x-multi-file-name"];
        a.download = fileName;
        a.href = url;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        Evt.fire("enableDownloadInvoices");
      });
  },
  fetchShiftInvoices: (pageNum) => {
    return axios.get("/shift-invoices", {
      params: {
        page: pageNum,
        timestamp: new Date().getTime(),
      },
    });
  },
  deleteInvoice: (invoice) => {
    return axios.delete(`/invoices/${invoice}`);
  },

  fetchBillingDetails: (invoice) => {
    return axios.get(`/shift-invoices/${invoice}`, {
      params: {
        timestamp: new Date().getTime(),
      },
    });
  },

  createInvoice: (client) => {
    return axios.post("/invoices", {
      client_id: client,
    });
  },

  backupInvoicedShift: (data) => {
    let url = `/shift-invoices/${data.id}/backup.csv`;
    const a = document.createElement("a");
    document.body.appendChild(a);
    axios
      .get(url, {
        responseType: "arraybuffer",
        params: {
          timestamp: new Date().getTime(),
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/csv" });
        url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `backup_invoiced_shift_${data.agencyShiftId}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  },

  fetchLatestInvoices: () => {
    return axios.get("/invoices/get-latest", {
      params: {
        timestamp: new Date().getTime(),
      },
    });
  },
  backupInvoiceCSV: (params) => {
    const url = `/invoices/${params.id}/backup.csv`;
    return axios.get(url, {
      responseType: "arraybuffer",
      params: {
        timestamp: new Date().getTime(),
        zip: params.zip,
      },
    });
  },
  backupInvoiceExcel: (params) => {
    const url = `/invoices/${params.id}/pay-and-bill.csv`;
    return axios.get(url, {
      responseType: "arraybuffer",
      params: {
        timestamp: new Date().getTime(),
        zip: params.zip,
      },
    });
  },
  backupInvoicePDF: (params) => {
    const url = `/invoices/${params.id}/invoice.pdf`;
    return axios.get(url, {
      responseType: "arraybuffer",
      params: {
        timestamp: new Date().getTime(),
        zip: params.zip,
      },
    });
  },
};

export default api;
