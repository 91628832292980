var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.promptModal,{attrs:{"message":_setup.promtObj.message,"visible":_setup.promtObj.visible,"isError":_setup.promtObj.isError},on:{"cancel":_setup.promtObj.handleClosePrompt,"confirm":_setup.promtObj.handleClosePrompt}}),_c('section',{staticClass:"section"},[_vm._m(0),_c('div',{staticClass:"section-wrapper"},[_c('div',[_c('label',{staticClass:"label"},[_vm._v("Entity Type")]),_c('multi-select',{staticStyle:{"width":"300px"},attrs:{"disabled":_setup.disabledParams.entity_type_obj,"options":_setup.entities,"label":"name","placeholder":"Select Entity Type","track-by":"id","deselect-label":"Can't remove this value","allow-empty":false,"multiple":false,"showLabels":true},on:{"input":function($event){return _setup.clearErrorMap('entity_type')},"select":_setup.handleDisablingFilters},model:{value:(_setup.params.entity_type_obj),callback:function ($$v) {_vm.$set(_setup.params, "entity_type_obj", $$v)},expression:"params.entity_type_obj"}}),(_setup.errorMap?.entity_type)?_c('p',{staticClass:"error-text"},[_vm._v(" "+_vm._s(_setup.errorMap.entity_type[0])+" ")]):_vm._e()],1)]),_c('div',{staticClass:"section-wrapper"},[_c('div',[_c('label',{staticClass:"label"},[_vm._v("Cut Off Date/Time")]),_c('p',{staticClass:"control"},[_c('uni-date-picker',{directives:[{name:"tooltip",rawName:"v-tooltip.info.top.bounce",value:(
              _setup.disabledParams.cut_off
                ? `This filter is not available when ${_setup.params?.entity_type_obj?.name ?? ''} entity type is selected.`
                : ''
            ),expression:"\n              disabledParams.cut_off\n                ? `This filter is not available when ${params?.entity_type_obj?.name ?? ''} entity type is selected.`\n                : ''\n            ",modifiers:{"info":true,"top":true,"bounce":true}}],attrs:{"placeholder":"Select Cut Off Date/Time","isDisabled":_setup.disabledParams.cut_off,"isFormatToLocal":false},on:{"input":function($event){return _setup.clearErrorMap('cut_off')}},model:{value:(_setup.params.cut_off),callback:function ($$v) {_vm.$set(_setup.params, "cut_off", $$v)},expression:"params.cut_off"}})],1),(_setup.errorMap?.cut_off)?_c('p',{staticClass:"error-text"},[_vm._v(" "+_vm._s(_setup.errorMap.cut_off[0])+" ")]):_vm._e()])]),_vm._m(1),_c('div',{staticClass:"section-wrapper"},[_c('div',[_c('div',{staticClass:"label"},[_vm._v(" Clients "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.info.top.bounce",value:('Add Comma Separated Client External IDs'),expression:"'Add Comma Separated Client External IDs'",modifiers:{"info":true,"top":true,"bounce":true}}],staticClass:"info"},[_c('span',{staticClass:"fa fa-question popup"})])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_setup.params.client_external_id),expression:"params.client_external_id"},{name:"remove-spaces-from-input",rawName:"v-remove-spaces-from-input"},{name:"tooltip",rawName:"v-tooltip.info.top.bounce",value:(
            _setup.disabledParams.client_external_id
              ? `This filter is not available when ${_setup.params?.entity_type_obj?.name ?? ''} entity type is selected.`
              : ''
          ),expression:"\n            disabledParams.client_external_id\n              ? `This filter is not available when ${params?.entity_type_obj?.name ?? ''} entity type is selected.`\n              : ''\n          ",modifiers:{"info":true,"top":true,"bounce":true}}],attrs:{"disabled":_setup.disabledParams.client_external_id,"placeholder":"Add Comma Separated Client External IDs","rows":"10","cols":"30"},domProps:{"value":(_setup.params.client_external_id)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_setup.params, "client_external_id", $event.target.value)},function($event){return _setup.clearErrorMap('client_external_id')}]}}),(_setup.errorMap?.client_external_id)?_c('p',{staticClass:"error-text"},[_vm._v(" "+_vm._s(_setup.errorMap.client_external_id[0])+" ")]):_vm._e()]),_c('div',[_c('div',{staticClass:"label"},[_vm._v(" Candidates "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.info.top.bounce",value:('Add Comma Separated Candidate External IDs'),expression:"'Add Comma Separated Candidate External IDs'",modifiers:{"info":true,"top":true,"bounce":true}}],staticClass:"info"},[_c('span',{staticClass:"fa fa-question popup"})])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_setup.params.temp_external_id),expression:"params.temp_external_id"},{name:"remove-spaces-from-input",rawName:"v-remove-spaces-from-input"},{name:"tooltip",rawName:"v-tooltip.info.top.bounce",value:(
            _setup.disabledParams.temp_external_id
              ? `This filter is not available when ${_setup.params?.entity_type_obj?.name ?? ''} entity type is selected.`
              : ''
          ),expression:"\n            disabledParams.temp_external_id\n              ? `This filter is not available when ${params?.entity_type_obj?.name ?? ''} entity type is selected.`\n              : ''\n          ",modifiers:{"info":true,"top":true,"bounce":true}}],attrs:{"disabled":_setup.disabledParams.temp_external_id,"placeholder":"Add Comma Separated Candidate External IDs","rows":"10","cols":"30"},domProps:{"value":(_setup.params.temp_external_id)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_setup.params, "temp_external_id", $event.target.value)},function($event){return _setup.clearErrorMap('temp_external_id')}]}}),(_setup.errorMap?.temp_external_id)?_c('p',{staticClass:"error-text"},[_vm._v(" "+_vm._s(_setup.errorMap.temp_external_id[0])+" ")]):_vm._e()]),_c('div',[_c('div',{staticClass:"label"},[_vm._v(" Entity Type IDs "),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: `Add Comma Separated ${_setup.params.entity_type_obj?.name ?? 'Entity Type'} IDs. Only numbers and commas are allowed.`,
              classes: 'info top bounce',
            }),expression:"{\n              content: `Add Comma Separated ${params.entity_type_obj?.name ?? 'Entity Type'} IDs. Only numbers and commas are allowed.`,\n              classes: 'info top bounce',\n            }"}],staticClass:"info"},[_c('span',{staticClass:"fa fa-question popup"})])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_setup.params.entity_ids),expression:"params.entity_ids"},{name:"only-numbers-commas",rawName:"v-only-numbers-commas"},{name:"tooltip",rawName:"v-tooltip.info.top.bounce",value:(
            _setup.disabledParams.entity_ids
              ? `This filter is not available when ${_setup.params?.entity_type_obj?.name ?? ''} entity type is selected.`
              : ''
          ),expression:"\n            disabledParams.entity_ids\n              ? `This filter is not available when ${params?.entity_type_obj?.name ?? ''} entity type is selected.`\n              : ''\n          ",modifiers:{"info":true,"top":true,"bounce":true}}],attrs:{"disabled":_setup.disabledParams.entity_ids,"placeholder":`Add Comma Separated ${_setup.params.entity_type_obj?.name ?? 'Entity Type'} IDs. Only numbers and commas are allowed.`,"rows":"10","cols":"30"},domProps:{"value":(_setup.params.entity_ids)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_setup.params, "entity_ids", $event.target.value)},function($event){return _setup.clearErrorMap('entity_ids')}]}}),(_setup.errorMap?.entity_ids)?_c('p',{staticClass:"error-text"},[_vm._v(" "+_vm._s(_setup.errorMap.entity_ids[0])+" ")]):_vm._e()])]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip.info.top.bounce",value:('Download Report'),expression:"'Download Report'",modifiers:{"info":true,"top":true,"bounce":true}}],staticClass:"button is-generic-app-blue",class:{ 'is-loading': _setup.downloadReportIsLoading },staticStyle:{"margin":"20px 10px"},attrs:{"disabled":_setup.downloadReportIsLoading},on:{"click":_setup.downloadNexusReport}},[_vm._v(" Download Report ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h2',{staticClass:"title is-3"},[_c('i',{staticClass:"icon-Reports"}),_vm._v("Nexus Reports")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section-wrapper"},[_c('div',{staticClass:"mb_0"},[_c('label',{staticClass:"label"},[_vm._v("If no data is inputted in the below filters, the system will search the whole system across the board.")])])])
}]

export { render, staticRenderFns }