<template lang="pug">
.recalculation-progress
  .track-bar
    .thumb-bar(:style="getProgressStyle")
</template>

<script>
/* {
  pending: [Num],
  completed: [Num],
  error: [Num],
  total: [Num],
} */
export default {
  props: {
    progressData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getProgressStyle() {
      let progresStatus = true;
      let progressNum;
      // Here we set the conditions for displaying the progress bar
      if (this.progressData.completed <= this.progressData.total) {
        progressNum =
          (this.progressData.completed / this.progressData.total) * 100;
        if (progressNum > 100 || progressNum < 0) {
          progresStatus = false;
        }
      } else {
        progresStatus = false;
      }
      this.$emit("progress-status", {
        progresStatus: progresStatus,
        progressNum: progressNum,
      });
      return {
        width: `${progressNum}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.recalculation-progress {
  height: 20px;
  width: 500px;

  .track-bar {
    width: 100%;
    height: 100%;
    box-shadow: inset 0 0 10px 2px #80808040;
    position: relative;
    overflow: hidden;
  }

  .thumb-bar {
    width: 0;
    background: lightblue;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    transition: all 0.5s ease;
  }
}
</style>
