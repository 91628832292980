<template>
  <div id="main-app">
    <progress-block
      v-if="isProgressRefreshToken || isLoadingDomainConfig || !canLoadContent"
    ></progress-block>
    <template
      v-if="!isProgressRefreshToken && !isLoadingDomainConfig && canLoadContent"
    >
      <transition name="bounceUp">
        <modal
          v-if="showSettingsModal"
          :scrollable="isModalScrollable"
          title="Settings"
          icon="cog"
          size="459"
          @close="showSettingsModal = false"
        >
          <user-settings-form></user-settings-form>
        </modal>
      </transition>
      <nav v-if="auth.user.authenticated" class="nav has-shadow">
        <div class="nav-has-nothing"></div>
        <div class="nav-has-logo">
          <div class="nav-item">
            <a>
              <img :src="getGlobalImageLogoApi" alt />
              <!-- <img src="../assets/images/cpltabs.png" alt="" /> -->
            </a>
          </div>
        </div>
        <div class="nav-right nav-menu">
          <a
            v-if="auth.user.authenticated && $route.name != 'TokenVerification'"
            class="nav-item nav-item--expanded"
            @click.prevent="userActions = !userActions"
          >
            {{ auth.user.profile.name }}
            <i
              class="fa drop-arrow"
              :class="{
                'fa-angle-up': !userActions,
                'fa-angle-down': userActions,
              }"
            ></i>
          </a>

          <router-link
            v-if="!auth.user.authenticated"
            :to="{ name: 'signin' }"
            class="nav-item nav-item--expanded"
            >Sign in</router-link
          >
          <transition name>
            <div v-if="userActions" class="action-cont">
              <!-- This shouldn't even be here!! -->
              <!-- <router-link
              v-if="auth.user.authenticated && auth.user.role === 'temp'"
              :to="{ name: 'editProfile' }"
              class="nav-item"
              @click.prevent="userActions = !userActions"
            >
              Profile
              <i class="fa icon-Edit-profile alignment"></i>
              </router-link>-->
              <a
                v-if="auth.user.authenticated"
                class="nav-item"
                @click.prevent="
                  showSettingsModal = true;
                  userActions = !userActions;
                "
              >
                Settings
                <i class="fa fa-cog alignment"></i>
              </a>
              <a
                v-if="auth.user.authenticated"
                class="nav-item"
                @click.prevent="signout"
              >
                Logout
                <i class="fa fa-share alignment"></i>
              </a>
            </div>
          </transition>
        </div>
      </nav>
      <div
        class="main-content"
        :class="{
          isOpen: zapOpened,
          'is-fixed-page-height': isFixedPageComp,
          'is-100-wid': isFixedWidthComp,
          'login-background': hasSpecialBackground(),
          'modern-main-content': modernUiComps,
        }"
      >
        <div v-if="!modernUiComps" class="container is-fluid">
          <div class="columns">
            <div class="column col-12">
              <transition name="fadeCustom" mode="out-in">
                <keep-alive :include="compsToKeep">
                  <router-view></router-view>
                </keep-alive>
              </transition>
            </div>
          </div>
        </div>
        <div v-else-if="modernUiComps" class="modern-wrapper">
          <keep-alive :include="compsToKeep">
            <router-view></router-view>
          </keep-alive>
        </div>
      </div>

      <zap-slideout
        v-if="auth.user.authenticated && $route.name != 'TokenVerification'"
      ></zap-slideout>
    </template>
  </div>
</template>
<script>
import ZapSlideout from "./ZapSlideout.vue";
import ProgressBlock from "./ProgressBlock/ProgressBlock.vue";

import auth from "../lib/auth.js";
import { Evt } from "../lib/helpers/Evt.js";
import UserSettingsForm from "./forms/UserSettingsForm.vue";
import { mapActions, mapState } from "vuex";

import {
  cssVar,
  getHexColor,
  hexToHSLArr,
} from "../lib/helpers/cssVariablesUtil.js";

export default {
  name: "App",
  components: {
    ZapSlideout,
    UserSettingsForm,
    ProgressBlock,
  },
  data() {
    return {
      showSettingsModal: false,
      userActions: false,
      auth: auth,
      zapOpened: false,
      compsToKeep: [],
      isProgressRefreshToken: true,
      isLoadingDomainConfig: true,
      canLoadContent: false,
    };
  },
  computed: {
    modernUiComps() {
      const modernComps = [
        "ExpensesPageAwaiting",
        "ExpensesPageApproved",
        "ExpensesPageRejected",
        "dashboard",
      ];
      return modernComps.includes(this.$route.name);
    },
    routeName() {
      return this.$route.name;
    },
    isModalScrollable() {
      return true;
    },
    isFixedPageComp() {
      const fixedPages = [
        "ManageRates",
        "fileUploaderPage",
        "ClientDetailsPage",
        "securityPage",
        "CandidateJournals",
        "ClientJournalInfo",
        "RatesManageExpenses",
        "locationClientManagement",
        "shiftSchedule",
      ];
      return this.$route.matched.some(({ name }) => {
        return fixedPages.includes(name);
      });
    },
    isFixedWidthComp() {
      const fixedPages = ["RatesManageExpenses"];
      return this.$route.matched.some(({ name }) => {
        return fixedPages.includes(name);
      });
    },
  },
  watch: {
    $route(comp, oldComp) {
      this.getKeepAliveComp(comp, oldComp);
    },
  },
  async created() {
    // For setting up on load [k-a]
    this.getKeepAliveComp(this.$route, {});

    this.getColumnNamesLocl();

    Evt.listen("zap-opened", (isOpen) => {
      this.zapOpened = isOpen;
    });
    Evt.listen("disposeModal", () => {
      this.showSettingsModal = false;
    });

    await this.fetchStatusInitialLoad();
    await this.checkRefreshToken();

    await this.checkSystemPermissionForRoute();
  },
  mounted() {
    const year = new Date().getFullYear();
    if (year === 2021) {
      // Remove code after 2022
      const shouldRedirect = window.HTTPS_REDIRECT_VUE === true;
      if (shouldRedirect && window.location.href.indexOf("http://") === 0) {
        console.log(
          ">> Changing url",
          "https://" + window.location.href.substring(7)
        );
        window.location.href = "https://" + window.location.href.substring(7);
      }
    }

    /**
     * Populate array of permissions
     * event sent from auth.js on login
     * TODO check if there is some better way of doing this (probably there is)
     */
    Evt.listen("setAccessList", (parameter) => {
      const permissions = parameter.permissions;
      this.$access(permissions);
      console.log(this.$access());

      // First time
      const canAccessFirstLink = this.$can(this.$route.meta.permission);
      if (!canAccessFirstLink && this.$route.meta.permission !== undefined) {
        this.$router.replace("/");
      }
    });
    this.$nextTick(function () {
      console.log("auth.check() method has run...");
      // let jwtd = auth.check()
      auth.check();
    });
  },
  beforeDestroy() {
    Evt.off("zap-opened");
    Evt.off("disposeModal");
  },
  methods: {
    ...mapActions([
      "showLogoutMessageCred",
      "getStatusInitialLoad",
      "getColumnNamesLocl",
    ]),
    async checkRefreshToken() {
      const isSuccess = await auth.refreshToken(this.$route);
      console.log("[RT] >> App loading");
      if (isSuccess) {
        // Future > Should load the app if only token is refreshed
        console.log("[RT] >> App valid loaded");
        this.isProgressRefreshToken = false;
      } else {
        console.log("[RT] >> App invalid loaded");
        this.isProgressRefreshToken = false;
      }
    },
    signout() {
      // Cleanup calendar shift params
      delete localStorage.shiftRowDetailsOnce;
      delete localStorage.shiftRowDetailsOnceTimestamp;

      this.$access("any");
      /**
       * when next time logged in, prevents opening of dropdown
       */
      this.userActions = false;
      auth.signout();
    },
    hasSpecialBackground() {
      const locationCheck = [
        "signin",
        "register",
        "forgot-password",
        "password-recovery",
      ].includes(this.routeName);
      return !auth.user.authenticated && locationCheck;
    },
    // eslint-disable-next-line no-unused-vars
    getKeepAliveComp(comp, oldComp) {
      let tempComps = [];
      if (comp.params.resetKeepAlive) {
        // Improve in future
        // Reset k-a
        tempComps = tempComps.filter((compKeep) => compKeep !== comp.name);
        this.$nextTick(() => {
          // Re-add k-a
          tempComps.push(comp);
        });
      }

      const keepAliveComps = this.$route.meta["has-keep-alive"];
      if (Array.isArray(keepAliveComps)) {
        for (const comp of keepAliveComps) {
          tempComps.push(comp);
        }
      }

      this.compsToKeep = [...new Set(tempComps)];
    },
    async fetchStatusInitialLoad(params = {}) {
      try {
        const res = await this.getStatusInitialLoad(params);
        // Setup global colors (icons are set automatically per img source)
        this.setupAppInitialColors(res.data);

        this.isLoadingDomainConfig = false;
      } catch (err) {
        console.warn(err.message);
        setTimeout(() => {
          if (err.response.status === 429) {
            console.log(
              ">> Reloading url",
              "https://" + window.location.href.substring(7)
            );
            location.reload();
          }
        }, 5000);
      }
    },
    setupAppInitialColors(responseData) {
      let colors = responseData;
      console.log(">> Agency settings: ", colors);
      if (!colors) {
        // Use default fallback:
        // 1 - blue
        // 2 - red
        colors = {
          color_1: "#0d6bbb",
          color_2: "#34495e",
          color_toggle_on: "#4ad862",
          color_toggle_off: "#bdbdbd",
        };
      }

      const colorHex1 = getHexColor(colors.color_1);
      const colorHex2 = getHexColor(colors.color_2);
      const colorHslArr1 = hexToHSLArr(colorHex1);
      const colorHslArr2 = hexToHSLArr(colorHex2);
      // Toggle
      const colorHexToggleOn = getHexColor(colors.color_toggle_on);
      const colorHexToggleOff = getHexColor(colors.color_toggle_off);
      const colorHslArrToggleOn = hexToHSLArr(colorHexToggleOn);
      const colorHslArrToggleOff = hexToHSLArr(colorHexToggleOff);
      // Primary
      cssVar("--color-primary-h", colorHslArr1[0]);
      cssVar("--color-primary-s", `${colorHslArr1[1]}%`);
      cssVar("--color-primary-l", `${colorHslArr1[2]}%`);

      // Secondary
      cssVar("--color-secondary-h", colorHslArr2[0]);
      cssVar("--color-secondary-s", `${colorHslArr2[1]}%`);
      cssVar("--color-secondary-l", `${colorHslArr2[2]}%`);

      // Css toggle
      cssVar("--color-toggle-on-h", colorHslArrToggleOn[0]);
      cssVar("--color-toggle-on-s", `${colorHslArrToggleOn[1]}%`);
      cssVar("--color-toggle-on-l", `${colorHslArrToggleOn[2]}%`);

      cssVar("--color-toggle-off-h", colorHslArrToggleOff[0]);
      cssVar("--color-toggle-off-s", `${colorHslArrToggleOff[1]}%`);
      cssVar("--color-toggle-off-l", `${colorHslArrToggleOff[2]}%`);
    },
    checkSystemPermissionForRoute() {
      let canEnterPage = false;

      if (this.$route.meta.altPermissions) {
        const hasPerm = this.checkAltPermissions(this.$route.meta);
        canEnterPage = hasPerm;
      } else {
        const metaSystemPerms = this.$route.meta.systemPermissions;
        canEnterPage = Array.isArray(metaSystemPerms)
          ? metaSystemPerms.every((perm) => this.initialStatus[perm])
          : true;
      }

      if (!canEnterPage) {
        // Return to index
        this.$router.replace("/");
      }
      this.canLoadContent = true;
    },
    checkAltPermissions(obj) {
      console.warn(obj);
      return obj.altPermissions.some((permGroup) => {
        if (permGroup.systemPermissions) {
          const hasSysPerm = permGroup.systemPermissions?.length
            ? permGroup.systemPermissions.every((tabPerm) => {
                const hasPerm = this.initialStatus[tabPerm];
                // console.warn("[ROUTE] Check sys perm", tabPerm, hasPerm);
                return hasPerm;
              })
            : true;
          if (!hasSysPerm) return false;
        }

        if (permGroup.notSystemPermissions) {
          const notHasSysPerm = permGroup.notSystemPermissions?.length
            ? !permGroup.notSystemPermissions.every((tabPerm) => {
                const hasPerm = this.initialStatus[tabPerm];
                // console.warn("[ROUTE] Check sys perm NOT", tabPerm, hasPerm);
                return hasPerm;
              })
            : true;
          if (!notHasSysPerm) return false;
        }

        if (permGroup.permissions) {
          const hasPerm = permGroup.permissions?.length
            ? permGroup.permissions.every((tabPerm) => {
                const hasPerm = this.$can(tabPerm);
                // console.warn("[ROUTE] Check perm", tabPerm, hasPerm);
                return hasPerm;
              })
            : true;
          if (!hasPerm) return false;
        }
        return true;
      });
    },
  },
};
</script>

<style src="../assets/styles/sidebar-icomoon.css"></style>

<style>
.no-flexbox .flatpickr-input {
  height: auto;
}

.no-flexbox span.flatpickr-weekday {
  display: inline-block;
  width: 14.2857143%;
}
.no-flexbox span.flatpickr-day {
  width: 14.2857143%;
  margin: 0 2.491071428571428px;
}
@media screen and (min-width: 0\0) {
  .no-flexbox span.flatpickr-current-month {
    padding-top: 0px !important;
  }
}
</style>
<style lang="scss" src="../assets/styles/app.scss"></style>
<style lang="scss">
body {
  background-color: white;
  min-height: 99vh;
  // min-height: 100vh; // Shows scrollbar on win-chrome
  display: flex;
  flex-direction: column;
}

#main-app {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
}

.is-fixed-page-height {
  #manage-rates,
  #manage-file-upload,
  #client-details-page,
  #security-section,
  .schedule-tabs,
  .candidate-page {
    $header-height: 80px;
    $offset-height: 4vh; // For paddings
    height: calc(100vh - #{$header-height} - #{$offset-height});
    max-height: calc(100vh - #{$header-height} - #{$offset-height});
  }

  .location-client-management {
    $header-height: 80px;
    $offset-height: 5vh; // For paddings
    height: calc(100vh - #{$header-height} - #{$offset-height});
    max-height: calc(100vh - #{$header-height} - #{$offset-height});
  }
}

.is-100-wid {
  overflow: hidden;

  .columns {
    overflow: hidden;
  }

  .column.col-12 {
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .is-fixed-page-height {
    overflow: auto;
  }
}

// SimpleRT Modal
// Fixes text overflow
.simplert__title {
  font-size: 25px !important;
  overflow-x: auto;
  overflow-y: hidden;
}

.nav {
  // index: 3 used on button groups :focus
  // above dropdown menus
  z-index: 51;
}

.nav-has-nothing {
  flex: 1;
}
.nav-has-logo {
  text-align: center;
  align-items: center;
  img {
    max-height: 60px;
    width: auto;
  }
}
.nav-right {
  flex: 1;
}
.login-background {
  min-height: 100vh;
  padding-top: 5.5em;
  margin-top: 0;
  top: 0px !important;
  background-color: #f0f6f6 !important;
}
.fadeCustom-enter-active,
.fadeCustom-leave-active {
  transition: opacity 0.2s ease;
}

.fadeCustom-enter,
.fadeCustom-leave-to {
  opacity: 0;
}
.user-profile {
  margin: 0.5em;
  border: 2px solid #eee;
}
.menu-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  min-height: 5.5em;
}
.alignment {
  opacity: 0.25;
  font-size: 16px;
  text-align: right;
  flex: 1 auto;
}
.action-cont {
  position: absolute;
  top: 60px;
  right: 15px;
  width: 180px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding: 0 25px;
}
.drop-arrow {
  opacity: 0.25;
  padding-left: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.75s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

// @media screen and (max-width: 2000px) {
html {
  overflow: auto;
}
// }

.modern-wrapper {
  display: flex;
  background: #fafcfe;
  height: 100%;
}
</style>
