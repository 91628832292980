<template lang="pug">
#client-invoice-list
  //- span This is an example for invoice list page
  span.row.empty-row
    .control-row.address-id
      label.label Address ID
      input.input(
        v-model="blankRow.split_customid",
        type="text",
        placeholder="ID"
      )
    .control-row.name-field
      label.label Name
      input.input(
        v-model="blankRow.split_name",
        type="text",
        placeholder="Name"
      )
    .control-row.subcat-field
      label.label Subcategory
      multi-select(
        :options="filteredCategories(blankRow)",
        :multiple="true",
        :close-on-select="false",
        select-label="",
        deselect-label="",
        track-by="id",
        label="name",
        group-label="name",
        group-values="subcategories",
        :group-select="true",
        placeholder="Select category",
        :value="parseItemCategories(blankRow, true)",
        @select="onSelectCategories($event, blankRow, false)",
        @remove="onRemoveCategories($event, blankRow, false)"
      )
    .control-row.location-field(v-if="canDisplayLocations")
      label.label Ward
      multi-select(
        :options="filteredLocations(blankRow)",
        :multiple="true",
        :close-on-select="false",
        select-label="",
        deselect-label="",
        track-by="id",
        label="name",
        placeholder="Select wards",
        :disabled="!canUseNexusMiddleware",
        :value="parseItemLocations(blankRow, true)",
        @select="onSelectLocations($event, blankRow, false)",
        @remove="onRemoveLocations($event, blankRow, false)"
      )
    button.button.is-success.is-caps-lock(
      style="margin-top: auto",
      :disabled="!isPendingNewRow",
      @click="onAddBlankRow"
    ) Add row
  span.separator
  ul.pending-list
    li.row(v-for="(item, index) in list", :key="item.id")
      //- span {{ item.label }}
      .control-row.address-id
        input.input(
          v-model="item.split_customid",
          type="text",
          placeholder="ID",
          @input="setPendingForm()"
        )
      .control-row.name-field
        input.input(
          v-model="item.split_name",
          type="text",
          placeholder="Name",
          @input="setPendingForm()"
        )
      .control-row.subcat-field
        multi-select(
          :options="filteredCategories(item)",
          :multiple="true",
          :close-on-select="false",
          select-label="",
          deselect-label="",
          track-by="id",
          label="name",
          group-label="name",
          group-values="subcategories",
          :group-select="true",
          placeholder="Select category",
          :value="parseItemCategories(item)",
          @select="onSelectCategories($event, item)",
          @remove="onRemoveCategories($event, item)"
        )
      .control-row.location-field(v-if="canDisplayLocations")
        multi-select(
          :options="filteredLocations(item)",
          :multiple="true",
          :close-on-select="false",
          select-label="",
          deselect-label="",
          track-by="id",
          label="name",
          placeholder="Select wards",
          :disabled="!canUseNexusMiddleware",
          :value="parseItemLocations(item)",
          @select="onSelectLocations($event, item)",
          @remove="onRemoveLocations($event, item)"
        )
      button.button.remove-action(
        data-cell="delete-button",
        title="Delete row",
        @click="onDeleteRow($event, item, index)"
      )
        span.fa.fa-times
</template>

<script>
import MultiSelect from "vue-multiselect";
export default {
  components: {
    MultiSelect,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    locations: {
      type: Array,
      default: () => [],
    },
    isFormReady: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      blankRow: {},
      resObs: null,
    };
  },
  computed: {
    flatSubcategories() {
      return this.categories.map((cat) => cat.subcategories).flat();
    },
    // allUsedSubcategories() {
    //   const blankRowList = this.blankRow.split_categories_names || [];
    //   const originalList = this.list
    //     .map((item) => item.split_categories_names)
    //     .flat();
    //   return Array.from(new Set(blankRowList.concat(originalList)));
    // },
    allUsedLocations() {
      const blankRowList = this.blankRow.split_locations_names || [];
      const originalList = this.list
        .map((item) => item.split_locations_names)
        .flat();
      return Array.from(new Set(blankRowList.concat(originalList)));
    },
    isPendingNewRow() {
      return Object.keys(this.blankRow).length;
    },
    canDisplayLocations() {
      return this.canUseNexusMiddleware;
    },
  },
  mounted() {
    // this.setupListResizeObserver();
  },
  methods: {
    onDeleteRow(evt, item, index) {
      const payload = {
        item,
        index,
      };
      this.$emit("delete-row", payload);
    },
    setPendingForm(isTrue = true) {
      this.$emit("form-ready", isTrue);
    },
    onAddBlankRow() {
      if (this.isPendingNewRow) {
        this.$emit("added-row", this.blankRow);
        this.blankRow = {};
        this.setPendingForm();
      }
    },
    onSelectCategories(evt, item, isPatch = true) {
      if (isPatch) {
        this.setPendingForm();
      }

      const values = Array.isArray(evt) ? evt : [evt];
      const mapValues = values.map((item) => ({
        id: item.id,
        name: item.name,
      }));

      if (Array.isArray(item.split_categories_names)) {
        item.split_categories_names.push(...mapValues);
      } else {
        this.$set(item, "split_categories_names", mapValues);
      }
    },
    onSelectLocations(evt, item, isPatch = true) {
      if (isPatch) {
        this.setPendingForm();
      }

      const values = Array.isArray(evt) ? evt : [evt];
      const mapValues = values.map((item) => ({
        id: item.id,
        name: item.name,
      }));

      if (Array.isArray(item.split_locations_names)) {
        item.split_locations_names.push(...mapValues);
      } else {
        this.$set(item, "split_locations_names", mapValues);
      }
    },
    onRemoveCategories(evt, item, isPatch = true) {
      if (isPatch) {
        this.setPendingForm();
      }

      const selected = Array.isArray(evt) ? evt : [evt];

      if (Array.isArray(item.split_categories_names)) {
        // This should always be true
        item.split_categories_names = item.split_categories_names.filter(
          (itemObj) => !selected.some((obj) => obj.id === itemObj.id)
        );
      } else {
        console.warn("No subcategories array");
      }
    },
    onRemoveLocations(evt, item, isPatch = true) {
      if (isPatch) {
        this.setPendingForm();
      }

      const selected = Array.isArray(evt) ? evt : [evt];

      if (Array.isArray(item.split_locations_names)) {
        // This should always be true
        item.split_locations_names = item.split_locations_names.filter(
          (itemObj) => !selected.some((obj) => obj.id === itemObj.id)
        );
      } else {
        console.warn("No locations array");
      }
    },
    parseItemCategories(item, isBlankRow = false) {
      return item.split_categories_names || [];
    },
    parseItemLocations(item, isBlankRow = false) {
      return item.split_locations_names || [];
    },
    filteredCategories(item) {
      // const usedSubcategoriesByOtherInvoice = this.allUsedSubcategories.filter(
      //   (ausId) => !(item.split_categories_names || []).includes(ausId)
      // );
      const usedSubcategoriesByOtherInvoice = this.flatSubcategories;

      return this.categories.map((cat) => {
        return {
          ...cat,
          subcategories: cat.subcategories.filter(
            (sc) => !usedSubcategoriesByOtherInvoice.includes(sc.id)
          ),
        };
      });
    },
    filteredLocations(item) {
      const arr = item.split_locations_names || [];
      const usedSubcategoriesByOtherInvoice = this.allUsedLocations.filter(
        (itemObj) => !arr.some((obj) => obj.id === itemObj)
      );

      return this.locations.map((item) => {
        return {
          ...item,
        };
      });
    },
    // setupListResizeObserver () {
    //   this.resObs = new ResizeObserver(entries => {
    //     const el = document.querySelector('.pending-list');
    //     if (el && el.scrollHeight > el.clientHeight) {
    //       el.classList.add('auto');
    //     } else {
    //       el.classList.remove('auto');
    //     }
    //   });

    //   const el = document.querySelector('.pending-list');
    //   this.resObs.observe(el, {});
    // },
  },
};
</script>

<style lang="scss" scoped>
#client-invoice-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: hidden;

  .pending-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    // max-height: 640px;
    padding: 10px 0;
    overflow: auto;
  }

  .row {
    display: flex;
    // align-items: center;
    gap: 10px;

    .control-row {
      display: flex;
      flex-direction: column;
    }

    .input {
      height: 40px;
    }

    .address-id {
      input {
        width: 150px;
      }
    }

    .name-field {
      input {
        min-width: 170px;
      }
    }

    .subcat-field {
      width: 250px;
    }

    .location-field {
      width: 250px;
    }

    .remove-action {
      margin-left: 10px;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      transition: 0.2s ease all;
      align-self: center;

      &:hover {
        background: lightcoral;
        color: white;
      }
    }
  }

  .separator {
    width: 100%;
    border-top: 1px solid silver;
  }
}
</style>

<style lang="scss">
#client-invoice-list {
  .row {
    .multiselect {
      .multiselect__tags {
        width: 250px;
        max-height: 400px;
        overflow: auto;
      }
    }
  }
}
</style>
