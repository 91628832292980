<template lang="pug">
#paybill-invoices-balances.section
  .columns(data-attr="filters")
    template(v-if="canUseNexusMiddleware")
      .field-left(style="padding: 0 0 20px 0")
        multi-select(
          :options="typeOptions",
          select-label="",
          deselect-label="",
          :allow-empty="false",
          placeholder="Filter by type",
          title="Filter by type",
          v-model="invoicesSection",
          v-if="canUseNexusMiddleware"
        )
    //- .field.right
    //-   button.button.is-generic-app-teal(
    //-     data-action,
    //-     :disabled="closeModal",
    //-     @click="onCreateInvoice(true)",
    //-     v-if="(!canUseNexusMiddleware && $can('create-invoices-invoice')) || (!canUseNexusMiddleware && $can('create-invoices-invoices-for-all-clients'))"
    //-   )
    //-     img(src="../../assets/images/comps/Plus.svg")
    //-     | CREATE INVOICES FOR ALL CLIENTS
  .inner-content
    template(v-if="invoicesSection === 'General'")
      TabInvoices(
        :hospitals="hospitals",
        :closeModal="closeModal",
        :loading="loading",
        @filterInvoices="$emit('filterInvoices', $event)",
        @page-has-changed="$emit('page-has-changed', $event)"
      )
      //- pagination(
      //-   v-if="pagination",
      //-   :total-pages="pagination.total_pages",
      //-   :current-page="pagination.current_page",
      //-   @page-changed="pageHasChanged"
      //- )
    template(v-else)
      invoices-billing-period
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
import TabInvoices from "@/components/Invoices/TabInvoices.vue";
import InvoicesBillingPeriod from "./Invoices/InvoicesBillingPeriod.vue";
import Pagination from "../Pagination.vue";
import { mapActions } from "vuex";
import { parseErrors } from "../../lib/helpers/function";
import { Evt } from "@/lib/helpers/Evt.js";
import MultiSelect from "vue-multiselect";

export default {
  components: {
    TabInvoices,
    InvoicesBillingPeriod,
    Pagination,
    MultiSelect,
  },
  props: ["hospitals", "closeModal", "loading"],
  data() {
    return {
      // invoicesInvoices: [],
      typeOptions: ["General", "Billing period"],
      invoicesSection: "General",
      // isLoadingTable: true,
      // isLoadingCreatingInvoice: false,
      // pagination: {
      //   total: 0,
      //   total_pages: 0,
      //   current_page: parseInt(this.$route.query?.page, 10) || 1,
      // },
    };
  },
  mounted() {
    // this.setSection();
    // this.fetchPayBillInvoicesReq({});
  },
  methods: {
    ...mapActions({
      // getPayBillInvoicesReq: "getPayBillInvoicesReq",
      // postPayBillInvoicesReq: "postPayBillInvoicesReq",
    }),
    // async fetchPayBillInvoicesReq({ pageNum = 1 }) {
    //   const params = {
    //     page: pageNum,
    //   };
    //   try {
    //     const res = await this.getPayBillInvoicesReq(params);
    //     this.invoicesInvoices = res.data.data;
    //     if (res.data.meta?.pagination) {
    //       this.pagination = res.data.meta.pagination;
    //     }
    //     this.isLoadingTable = false;
    //   } catch (err) {
    //     console.warn(err.message);
    //     this.isLoadingTable = false;
    //   }
    // },
    // async onCreateInvoice() {
    //   const message =
    //     "generate a new invoice. This action will invoice all unprocessed requests.";

    //   try {
    //     await this.confirmApplyChanges(message);
    //     this.isLoadingCreatingInvoice = true;
    //     try {
    //       const params = {};
    //       const res = await this.postPayBillInvoicesReq(params);
    //       console.warn(res.data);
    //       this.isLoadingCreatingInvoice = false;
    //       this.fetchPayBillInvoicesReq({
    //         pageNum: this.pagination.current_page,
    //       });
    //     } catch (err) {
    //       console.error(err.message);
    //       this.isLoadingCreatingInvoice = false;
    //       const errs = parseErrors(err);
    //       if (errs) {
    //         this.$toasted.error(errs).goAway(1500);
    //       }
    //     }
    //   } catch (err) {
    //     // ignored
    //   }
    // },
    setSection() {
      if (this.canUseNexusMiddleware) {
        // Change to second tab
        this.invoicesSection = this.typeOptions[1];
      }
    },
    // pageHasChanged(pageNum) {
    //   this.fetchPayBillInvoicesReq({ pageNum });
    // },
    // confirmApplyChanges(name = ``) {
    //   return new Promise((resolve) => {
    //     const alert = {
    //       title: `Are you sure you want to ${name}`,
    //       message: "",
    //       type: "warning",
    //       useConfirmBtn: true,
    //       customConfirmBtnText: "Confirm",
    //       customConfirmBtnClass: "button is-danger",
    //       customCloseBtnText: "Cancel",
    //       customCloseBtnClass: "button is-outlined",
    //       onConfirm: () => resolve(),
    //     };
    //     this.$refs.simplert.openSimplert(alert);
    //     setTimeout(() => {
    //       // Timeout because of the transition
    //       const el = this.$refs.simplert.$el;
    //       const btns = el.querySelectorAll("button");
    //       if (btns.length) {
    //         btns[0].focus();
    //       }
    //     }, 600);
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
#paybill-invoices-balances {
  .columns {
    padding: 10px 10px 0;

    .multiselect {
      width: 300px;
    }
  }

  > .inner-content {
    .multiselect {
      width: 300px;
    }
  }

  .right {
    margin-left: auto;
  }

  button {
    display: flex;
    gap: 10px;
  }

  .disabled {
    opacity: 0.5;
  }

  .field {
    display: flex;
    margin-bottom: 30px;
    gap: 10px;
    align-items: center;

    label {
      margin: 0 1em;
    }
  }

  .label {
    color: #7c91ae;
  }
}
</style>
