import { Promised } from "vue-promised";
import SortButtons from "./SortButtons.vue";
import Modal from "./Modal.vue";
import Simplert from "vue2-simplert";
import Switches from "./VueSwitches/Switches.vue";
import Datepicker from "./Datepicker.vue";
import FromToDatepicker from "./FromToDatepicker.vue";
import CheckboxListMenu from "./CheckboxListMenu/CheckboxListMenu.vue";
import UniTabs from "@/components/UniTabs.vue";
import UniDatePicker from "@/components/shared/UniDatePicker.vue";
import UniPopup from "@/components/shared/UniPopup.vue";
import stickerComponent from "@/components/shared/stickerComponent.vue";
import LoadingComp from "@/components/LoadingComp.vue";
import MultiSelect from "vue-multiselect";

const SharedComponents = {
  install: function (Vue, options) {
    Vue.component(SortButtons.name, SortButtons);
    Vue.component(Modal.name, Modal);
    Vue.component("Simplert", Simplert);
    Vue.component(FromToDatepicker.name, FromToDatepicker);
    Vue.component(Datepicker.name, Datepicker);
    Vue.component("Switches", Switches);
    Vue.component("CheckboxListMenu", CheckboxListMenu);
    Vue.component("Promised", Promised);
    Vue.component("UniTabs", UniTabs);
    Vue.component("UniDatePicker", UniDatePicker);
    Vue.component("UniPopup", UniPopup);
    Vue.component("stickerComponent", stickerComponent);
    Vue.component("loading-comp", LoadingComp);
    Vue.component("MultiSelect", MultiSelect);
  },
};

export default SharedComponents;
