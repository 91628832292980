<template lang="pug">
section#client-details-page
  modal(
    v-if="modalVisible",
    :title="modalTitle",
    icon="",
    :size="modalSize",
    :scrollable="isModalScrollable",
    stopEventPropagation="true",
    @close="modalVisible = false",
    @click.native.stop=""
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @changed-status="onUpdateClient",
      @cancel-modal="modalVisible = false"
    )
  h1.title.is-3
    router-link(:to="{ name: 'locationClientManagement' }")
      svg(
        xmlns="http://www.w3.org/2000/svg",
        width="13",
        height="23",
        viewBox="0 0 13 23"
      )
        path(
          fill="#7591AE",
          stroke="#7991AE",
          d="M1.44295652,10.3728261 L10.8125217,1.00326087 C11.0429565,0.772826087 11.0429565,0.40326087 10.8125217,0.172826087 C10.582087,-0.0576086957 10.2125217,-0.0576086957 9.98208696,0.172826087 L0.195130435,9.95978261 C-0.0353043478,10.1902174 -0.0353043478,10.5597826 0.195130435,10.7902174 L9.98208696,20.5728261 C10.0951304,20.6858696 10.2473043,20.7467391 10.3951304,20.7467391 C10.5429565,20.7467391 10.6951304,20.6902174 10.8081739,20.5728261 C11.0386087,20.3423913 11.0386087,19.9728261 10.8081739,19.7423913 L1.44295652,10.3728261 Z",
          transform="translate(1 1)"
        )
    span.sep
    i.fa.icon-Location-client-management
    .right-text
      span Client Profile
      template(v-if="clientData.name")
        span -
        span {{ clientData.name }}
      span.button.is-caps-lock.is-tiny.status-btn(
        @click.stop="changeClientStatus(clientData)",
        :class="[setStatus(clientData.active), { 'is-loading': loadingClientStatus }]",
        :disabled="loadingClientStatus"
      ) {{ getStatusText(clientData.active) }}
  uni-tabs(
    :tabs="filteredTabs",
    :active-tab="activeTab",
    @tab-clicked="onTabClicked"
  )
  .loading-wrap(v-if="isLoading")
    .loading-wrapper
      img.client-loading(src="../../assets/images/comps/loader.svg")
  template(v-if="!isLoading")
    router-view(:client-data="clientData", @client-updated="onUpdateClient")
</template>

<script>
import { mapActions } from "vuex";
import EditClientStatus from "../LocationClientManagement/EditClientStatus.vue";
import { parseErrors } from "../../lib/helpers/function";
export default {
  name: "ClientDetailsPage",
  components: {
    EditClientStatus,
  },
  data() {
    return {
      clientData: {},
      isLoading: true,
      loadingClientStatus: false,
      modalSize: 650,
      modalVisible: false,
      isModalScrollable: true,
      activeTab: {},
    };
  },
  computed: {
    tabs() {
      return [
        {
          label: "Info",
          routeName: "ClientProfileInfo",
          permission: "view-client",
          routeQuery: {
            page: undefined,
          },
        },
        {
          label: "Approvers",
          routeName: "ClientApproversInfo",
          permission: "view-client-admins",
          routeQuery: {
            page: 1,
          },
        },
        {
          label: "Documents",
          routeName: "ClientDocumentsInfo",
          permission: "view-documents-on-client-page",
          routeQuery: {
            page: 1,
          },
        },
        {
          label: "Contacts",
          routeName: "ClientContactsInfo",
          permission: "view-contacts",
          routeQuery: {
            page: 1,
          },
        },
        {
          label: "Finances",
          routeName: "ClientFinancesInfo",
          permission: "edit-service-fees",
          routeQuery: {
            page: undefined,
          },
        },
        {
          label: "Invoice",
          routeName: "ClientInvoiceInfo",
          // permission: "split-invoice-by-subcategory",
          altPermissions: [
            {
              permissions: ["edit-client-invoice-addresses"],
              systemPermissions: ["can_use_nexus_middleware"],
            },
            {
              permissions: ["split-invoice-by-subcategory"],
              notSystemPermissions: ["can_use_nexus_middleware"],
            },
          ],
          routeQuery: {
            page: undefined,
          },
        },
        {
          label: "Journal",
          routeName: "ClientJournalInfo",
          permission: "view-client-journal",
          routeQuery: {
            page: 1,
          },
        },
      ];
    },
    filteredTabs() {
      return this.tabs.filter((tab) => {
        if (tab.permission) {
          return this.$can(tab.permission);
        }
        if (tab.altPermissions && Array.isArray(tab.altPermissions)) {
          return this.checkAltPermissions(tab);
        }
        return true;
      });
    },
  },
  mounted() {
    this.fetchSingleClientData();

    const arrRev = [...this.$route.matched].reverse();
    for (const routeMatch of arrRev) {
      const currentName = routeMatch.name;
      const foundTab = this.tabs.find((tab) => tab.routeName === currentName);
      if (foundTab) {
        this.activeTab.label = foundTab.label;
        break;
      }
    }
  },
  methods: {
    ...mapActions({
      fetchClientInfo: "locationClientManagement/fetchClientInfo",
    }),
    async fetchSingleClientData() {
      const params = {
        id: this.$route.params.id,
        include: "sectors,calcEngine,region,locations",
      };
      try {
        const res = await this.fetchClientInfo(params);
        this.clientData = res.data.data;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.warn(err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    checkAltPermissions(tab) {
      return tab.altPermissions.some((permGroup) => {
        if (permGroup.systemPermissions) {
          const hasSysPerm = permGroup.systemPermissions?.length
            ? permGroup.systemPermissions.every((tabPerm) => {
                const hasPerm = this.initialStatus[tabPerm];
                // console.warn("[APP] Check sys perm", tabPerm, hasPerm);
                return hasPerm;
              })
            : true;
          if (!hasSysPerm) return false;
        }

        if (permGroup.notSystemPermissions) {
          const notHasSysPerm = permGroup.notSystemPermissions?.length
            ? !permGroup.notSystemPermissions.every((tabPerm) => {
                const hasPerm = this.initialStatus[tabPerm];
                // console.warn("[APP] Check sys perm NOT", tabPerm, hasPerm);
                return hasPerm;
              })
            : true;
          if (!notHasSysPerm) return false;
        }

        if (permGroup.permissions) {
          const hasPerm = permGroup.permissions?.length
            ? permGroup.permissions.every((tabPerm) => {
                const hasPerm = this.$can(tabPerm);
                // console.warn("[APP] Check perm", tabPerm, hasPerm);
                return hasPerm;
              })
            : true;
          if (!hasPerm) return false;
        }
        return true;
      });
    },
    onUpdateClient() {
      this.fetchSingleClientData();
    },
    changeClientStatus(client) {
      // If using modal
      this.showModal("edit-client-status", { client }, `Client's status`, 650);

      // Directly changing
      // const changeStatusApi = async (status) => {
      //   this.loadingClientStatus = true
      //   const apiParams = {
      //     clientId: client.id,
      //     params: {
      //       active: status
      //     }
      //   }
      //   try {
      //     await this.putClientStatus(apiParams)
      //     // If: the store doesn't get updated fast / API doesn't return the value
      //     // Then: update the value manually
      //     this.loadingClientStatus = false
      //   } catch (err) {
      //     console.log(err.message);
      //     this.loadingClientStatus = false
      //   }
      // }
      // if (client.active === false) {
      //   // Enable
      //   changeStatusApi(true)
      // } else {
      //   // Disable
      //   changeStatusApi(false)
      // }
    },
    setStatus(s) {
      let c;
      switch (s) {
        case true:
        default:
          c = "is-success";
          break;
        // case 2:
        //   c = 'is-warning'
        //   break
        case false:
          c = "is-danger";
          break;
      }
      return c;
    },
    getStatusText(s) {
      let c;
      switch (s) {
        case true:
        default:
          c = "Active";
          break;
        // case 2:
        //   c = 'Review'
        //   break
        case false:
          c = "Inactive";
          break;
        // case 4:
        //   c = 'Approval'
        //   break
      }
      return c;
    },
    showModal(
      modal,
      modalData = {},
      modalTitle = "Modal title",
      isModalScrollable = true,
      modalSize = 650
    ) {
      this.isModalScrollable = isModalScrollable;
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalSize = modalSize;
      this.modalVisible = true;
    },
    onTabClicked({ tab }) {
      this.activeTab = tab;
    },
  },
};
</script>

<style lang="scss" scoped>
#client-details-page {
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem 0;

  .title {
    display: flex;

    .right-text {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .status-btn {
      margin-left: 20px;
    }
  }

  .loading-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
  }
}
</style>
