<template lang="pug">
#expenses.section.modern-ui-22
  .page-header-section
    h1.title.is-3
      inline-svg(
        :src="pageIcon",
        height="25",
        width="25",
        style="margin-right: 10px"
      )
      | Expenses
    .tab-wrapper
      uni-tabs(:tabs="tabs", :modern-ui="true", @tab-clicked="setActiveTab")
    .dummy
  ExpensesTable(
    :expenses="expenses",
    :expense-type="expenseTypes",
    :activeTab="activeTabObject",
    :data-is-loading="dataIsLoading",
    :is-loading-client="isLoadingClient",
    :pagination="pagination",
    v-if="activeTabObject",
    @fetch-expenses="fetchExpenses",
    @search-by-filter="searchByFilter",
    @search-by-filter-client="searchByClientFilter"
  )
  pagination(
    v-if="pagination && pagination.length !== 0",
    :total-pages="pagination.total_pages || 1",
    :current-page="pagination.current_page",
    :isForceChange="false",
    @page-changed="pageHasChanged"
  ) 
  simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>

<script>
import UniTabs from "../UniTabs.vue";
import ExpensesTable from "./ExpensesTable.vue";
import pageIcon from "@/assets/images/expensesIcon.svg";
import Pagination from "../Pagination.vue";
import { mapActions } from "vuex";
import axios from "axios";
import moment from "moment";
export default {
  name: "ExpensesPage",
  components: {
    UniTabs,
    ExpensesTable,
    Pagination,
  },
  data() {
    return {
      dataIsLoading: true,
      isLoadingClient: false,
      currentFilters: {},
      expenseTypes: {},
      expenses: [],
      pageIcon,
      cancelToken: null,
      pagination: {
        total: 0,
        total_pages: 0,
        current_page: parseInt(this.$route.query?.page, 10) || 1,
        currentPageData: {},
      },
      activeTabObject: null,
    };
  },
  computed: {
    tabs() {
      return [
        {
          id: 1,
          label: "Awaiting approval",
          name: "awaiting",
          routeName: "ExpensesPageAwaiting",
          statusType: ["A"],
          routeQuery: {
            page: 1,
          },
        },
        {
          id: 2,
          label: "Approved",
          name: "approved",
          routeName: "ExpensesPageApproved",
          statusType: ["P"],
          routeQuery: {
            page: 1,
          },
        },
        {
          id: 3,
          label: "Rejected",
          name: "rejected",
          routeName: "ExpensesPageRejected",
          statusType: ["C", "R"],
          routeQuery: {
            page: 1,
          },
        },
      ];
    },
    filteredTabs() {
      return this.tabs.filter((tab) => {
        const hasPerm =
          "permission" in tab
            ? typeof tab.permission === "string"
              ? this.$can(tab.permission)
              : tab.permission
            : true;
        const hasSysPerm = tab.systemPermissions?.length
          ? tab.systemPermissions.every(
              (tabPerm) => this.initialStatus[tabPerm]
            )
          : true;
        const hasCondition = tab.conditions?.length
          ? tab.conditions.every(Boolean)
          : true;
        return hasPerm && hasSysPerm && hasCondition;
      });
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.activeTabObject = this.getActiveTabParsed();
  },
  methods: {
    ...mapActions(["getExpenses", "getExpensesTypes", "fetchClientsList"]),
    async init() {
      const page = this.pagination.current_page;
      await this.fetchExpensesTypes();
      this.fetchExpenses({ page });
      this.getClients();
    },
    getActiveTabParsed() {
      const foundTab = this.filteredTabs.find(
        (tab) => tab.routeName === this.$route.name
      );
      return foundTab || this.filteredTabs?.[0] || null;
    },
    getParentCat(itemId) {
      const recSearch = (arr) => {
        return (
          arr.find((child) => {
            if (child.upt_id === itemId) {
              return true;
            } else if (child.children) {
              const found = recSearch(child.children);
              if (found) return true;
            }
            return false;
          }) || null
        );
      };
      if (this.expenseTypes?.children) {
        return recSearch(this.expenseTypes.children);
      }
      return null;
    },
    formatResData(arr = []) {
      return arr.map((item) => {
        return {
          ...item,
          parentType: this.getParentCat(item.expenseType.id),
        };
      });
    },
    async fetchExpenses(params = { page: 1 }) {
      this.dataIsLoading = true;

      if (this.cancelToken !== null) {
        this.cancelToken.cancel();
        this.cancelToken = null;
      }

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      this.cancelToken = source;

      const wholeParams = {
        params: {
          ...this.currentFilters,
          include: [
            "temp",
            "client",
            "expenseType",
            "files",
            "details",
            "userRejected",
            "userProcessed",
            "userCancelled",
          ].join(),
          statuses: this.activeTabObject.statusType,
          ...params,
        },
        cancelTokenSource: source,
      };
      if ("id" in wholeParams.params && !wholeParams.params.id) {
        delete wholeParams.params.id;
      }

      try {
        const tempFilters = JSON.parse(JSON.stringify(wholeParams.params));
        const res = await this.getExpenses(wholeParams);
        this.currentFilters = tempFilters;

        if (res.data.meta?.pagination) {
          this.$router
            .replace({
              query: {
                page: params.page || this.pagination.current_page,
              },
            })
            .catch(() => ({}));
          Object.assign(this.pagination, res.data.meta.pagination);
        }
        const formattedRes = this.formatResData(res.data.data || []);
        this.expenses = formattedRes;
        this.dataIsLoading = false;
      } catch (error) {
        console.warn(error);
        if (error?.code !== "ERR_CANCELED") {
          this.dataIsLoading = false;
        }
      }
    },
    async fetchExpensesTypes() {
      try {
        const res = await this.getExpensesTypes();
        this.expenseTypes = res.data.data;
      } catch (error) {
        console.warn(error);
      }
    },
    async getClients() {
      this.isLoadingClient = true;
      try {
        // const clientIncludes = ["locations", "withArchived"].join();
        await this.fetchClientsList({
          // includes: clientIncludes,
          per_page: 999,
        });
        this.isLoadingClient = false;
      } catch (err) {
        this.isLoadingClient = false;
        console.warn(err.message);
      }
    },
    setActiveTab({ tab }) {
      this.activeTabObject = this.filteredTabs.find(
        (tabItem) => tabItem.id === tab.id
      );
      this.expenses = [];
      this.fetchExpenses({ page: 1 });
    },
    parseFilters(filters) {
      const params = {};
      params.id = filters.expenseId;
      // filters.expenseTypes&& (params.types = filters.expenseType);
      Array.isArray(filters.selectedAgencyWorker) &&
        (params["temps"] = filters.selectedAgencyWorker.map((temp) => ({
          id: temp.id,
        })));
      Array.isArray(filters.selectedClient) &&
        (params["clients"] = filters.selectedClient.map((client) => ({
          id: client.id,
        })));
      Array.isArray(filters.expenseType) &&
        (params["types[]"] = filters.expenseType.map((type) => type.upt_id));

      params.date_from = filters.dateFrom?.time
        ? this.dateToIso(filters.dateFrom.time)
        : undefined;
      params.date_to = filters.dateTo?.time
        ? this.dateToIso(filters.dateTo.time)
        : undefined;

      return params;
    },
    dateToIso(str) {
      return moment(str, "DD-MM-YYYY").format("YYYY-MM-DD");
    },
    searchByFilter(filters) {
      const params = this.parseFilters(filters);
      this.fetchExpenses({ page: 1, ...params });
    },
    searchByClientFilter(filters) {
      const params = this.parseFilters(filters);
      this.fetchExpenses({ page: 1, ...params });
    },
    pageHasChanged(pageNum) {
      // this.$emit("fetch-expenses", { page: pageNum });
      this.fetchExpenses({ page: pageNum });
    },
  },
};
</script>

<style lang="scss" scoped>
#expenses {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .tab-wrapper {
    display: flex;
    justify-content: center;
  }

  .pagination {
    margin-top: 20px;
    align-self: flex-end;
  }
}
</style>
