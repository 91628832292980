<template lang="pug">
.cessation-date-wrapper 
  .warn-msg 
    p(v-if="showMessageOne") {{ warnMsgAfter }}
    br
    p(v-if="showMessageTwo") {{ warnMsg2Before }}
  .cess-table 
    table.table
      thead
        tr
          th
            span Shift ID
          th
            span Client Name
          th
            span Cost Centre
          th
            span Date
          th
            span Time
          th
            span Sub Category
          th(v-if="checkStatus")
            span Status
      tbody(v-if="!isShiftsLoading")
        tr(
          v-for="(item, index) in shiftDetails",
          :key="index",
          :class="{ backgroundShift: item.shiftEndAfterCessationDate }"
        )
          td
            p.control2
              | {{ item.shiftId }}
          td
            p.control2
              | {{ item.clientName }}
          td
            p.control2
              | {{ item.costCentre }}
          td
            p.control2
              | {{ item.date }}
          td
            p.control2
              | {{ item.time }}
          td
            p.control2
              | {{ item.subCategory }}
          td(v-if="checkStatus")
            p.control2
              | {{ item.status }}
      tbody.spiner(v-if="isShiftsLoading")
        tr
          td.center-text(colspan="10")
            img(src="../../../assets/images/comps/loader.svg")
      tbody(v-if="shiftDetails.length === 0 || isShiftsLoading")
        tr
          td(colspan="100")
            | No data in table
  .pagi 
    pagination(
      v-if="listReportsPagination.total_pages",
      :total-pages="listReportsPagination.total_pages",
      :current-page="listReportsPagination.current_page",
      @page-changed="pageHasChanged"
    )
  .cess-buttons
    button.button(@click="confirm", ref="ok", v-if="showOkButton") OK
    button.button.is-outlined.is-caps-lock(@click="cancel", ref="cancel") Cancel
</template>

<script>
import _ from "underscore";
import { mapActions } from "vuex";
import { parseErrors } from "../../../lib/helpers/function";
import Pagination from "../../../components/Pagination.vue";

export default {
  name: "CessationDate",
  components: {
    Pagination,
  },
  props: {},
  data() {
    return {
      isShiftsLoading: false,
      listReportsPagination: {
        total_pages: 3,
        current_page: 2,
      },
      warnMsgAfter: `You cannot proceed as the worker has the following shifts booked after the cessation date (highlighted shifts). Please reallocate/remove the worker from these shifts and enter the cessation date again.`,
      warnMsg2Before: `IMPORTANT Please ensure that any non-highlighted shifts in the list below are approved ahead of the cessation date as this may result in a delay in payment to the worker.`,
      shiftDetails: [],
      showMessageOne: false,
      showMessageTwo: false,
    };
  },
  computed: {
    checkStatus() {
      for (let i = 0; i < this.shiftDetails.length; i++) {
        if (this.shiftDetails[i].status) {
          return true;
        }
      }
      return false;
    },
    showOkButton() {
      if (
        this.shiftDetails &&
        this.shiftDetails.length &&
        this.shiftDetails[0].canSetCessationDate
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.loadData();
    this.$nextTick(() => {
      this.showOkButton ? this.$refs.ok.focus() : this.$refs.cancel.focus();
    });
  },
  methods: {
    ...mapActions({ getShiftsCessationDates: "getShiftsCessationDates" }),
    confirm() {
      if (this.shiftDetails[0].canSetCessationDate) {
        this.$emit("confirm-modal");
      } else {
        this.$emit("cant-set-cessation-date");
      }
    },
    async pageHasChanged(clickedPage) {
      this.isShiftsLoading = true;
      const params = {
        tempId: this.$attrs.modalProps.tempId,
        page: clickedPage,
        cessation_date: this.$attrs.modalProps.cessation_date,
      };
      const res = await this.getShiftsCessationDates(params);
      this.shiftDetails = res.data.data;
      this.listReportsPagination = res.data.meta.pagination;
      this.isShiftsLoading = false;
    },
    loadData() {
      this.shiftDetails = this.$attrs.modalProps.data.data;
      this.listReportsPagination = this.$attrs.modalProps.data.meta.pagination;
      this.checkArray();
    },
    checkArray() {
      this.shiftDetails.forEach((shift) => {
        if (shift.shiftEndAfterCessationDate) {
          this.showMessageOne = true;
        } else if (!shift.shiftEndAfterCessationDate) {
          this.showMessageTwo = true;
        }
      });
    },
    cancel() {
      this.$emit(
        "cant-set-cessation-date",
        this.$attrs.modalProps.initial_cessation_date
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.cessation-date-wrapper {
  .pagi {
    margin: 0 0 10px 0;
    display: flex;
    justify-content: center;
  }
  .warn-msg {
    display: flex;
    justify-content: center;
    margin: 10px 250px 10px 250px;
    text-align: center;
    font-size: 1rem;
    flex-direction: column;
  }
  .cess-table {
    .table {
      tbody {
        .backgroundShift {
          background-color: #dd7e7e2f;
        }
      }
    }
  }
  .cess-buttons {
    display: flex;
    justify-content: center;
    margin: 20px 0 5px 0;
    gap: 10px;
  }
}
</style>
