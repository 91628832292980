var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cessation-date-wrapper"},[_c('div',{staticClass:"warn-msg"},[(_vm.showMessageOne)?_c('p',[_vm._v(_vm._s(_vm.warnMsgAfter))]):_vm._e(),_c('br'),(_vm.showMessageTwo)?_c('p',[_vm._v(_vm._s(_vm.warnMsg2Before))]):_vm._e()]),_c('div',{staticClass:"cess-table"},[_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),(_vm.checkStatus)?_c('th',[_c('span',[_vm._v("Status")])]):_vm._e()])]),(!_vm.isShiftsLoading)?_c('tbody',_vm._l((_vm.shiftDetails),function(item,index){return _c('tr',{key:index,class:{ backgroundShift: item.shiftEndAfterCessationDate }},[_c('td',[_c('p',{staticClass:"control2"},[_vm._v(_vm._s(item.shiftId))])]),_c('td',[_c('p',{staticClass:"control2"},[_vm._v(_vm._s(item.clientName))])]),_c('td',[_c('p',{staticClass:"control2"},[_vm._v(_vm._s(item.costCentre))])]),_c('td',[_c('p',{staticClass:"control2"},[_vm._v(_vm._s(item.date))])]),_c('td',[_c('p',{staticClass:"control2"},[_vm._v(_vm._s(item.time))])]),_c('td',[_c('p',{staticClass:"control2"},[_vm._v(_vm._s(item.subCategory))])]),(_vm.checkStatus)?_c('td',[_c('p',{staticClass:"control2"},[_vm._v(_vm._s(item.status))])]):_vm._e()])}),0):_vm._e(),(_vm.isShiftsLoading)?_c('tbody',{staticClass:"spiner"},[_vm._m(6)]):_vm._e(),(_vm.shiftDetails.length === 0 || _vm.isShiftsLoading)?_c('tbody',[_vm._m(7)]):_vm._e()])]),_c('div',{staticClass:"pagi"},[(_vm.listReportsPagination.total_pages)?_c('pagination',{attrs:{"total-pages":_vm.listReportsPagination.total_pages,"current-page":_vm.listReportsPagination.current_page},on:{"page-changed":_vm.pageHasChanged}}):_vm._e()],1),_c('div',{staticClass:"cess-buttons"},[(_vm.showOkButton)?_c('button',{ref:"ok",staticClass:"button",on:{"click":_vm.confirm}},[_vm._v("OK")]):_vm._e(),_c('button',{ref:"cancel",staticClass:"button is-outlined is-caps-lock",on:{"click":_vm.cancel}},[_vm._v("Cancel")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("Shift ID")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("Client Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("Cost Centre")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("Date")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("Time")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("Sub Category")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"center-text",attrs:{"colspan":"10"}},[_c('img',{attrs:{"src":require("../../../assets/images/comps/loader.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{attrs:{"colspan":"100"}},[_vm._v("No data in table")])])
}]

export { render, staticRenderFns }