<template lang="pug">
div
  h1.title.is-3(:style="{ 'font-size': '1rem' }")
    <router-link :to="{ name: 'shiftSchedule', query:{status:this.$route.query.status.status} }">
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="23" viewBox="0 0 13 23">
        <path fill="#7591AE" stroke="#7991AE" d="M1.44295652,10.3728261 L10.8125217,1.00326087 C11.0429565,0.772826087 11.0429565,0.40326087 10.8125217,0.172826087 C10.582087,-0.0576086957 10.2125217,-0.0576086957 9.98208696,0.172826087 L0.195130435,9.95978261 C-0.0353043478,10.1902174 -0.0353043478,10.5597826 0.195130435,10.7902174 L9.98208696,20.5728261 C10.0951304,20.6858696 10.2473043,20.7467391 10.3951304,20.7467391 C10.5429565,20.7467391 10.6951304,20.6902174 10.8081739,20.5728261 C11.0386087,20.3423913 11.0386087,19.9728261 10.8081739,19.7423913 L1.44295652,10.3728261 Z" transform="translate(1 1)"/>
      </svg>
    </router-link>
    span.sep
    i.fa.fa-clock-o
    |
    | Shift details
    uni-tabs(:tabs="filteredTabs")
    router-view.inner-page
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from "underscore";
import {
  momentRangeFromApi,
  momentDateFromApi,
  momentTimeFromApi,
  momentDiff,
  parseDateObjWithTimeZone,
  parseErrors,
} from "../../lib/helpers/function.js";
// import { LOG_TYPES } from '../config/shifts.js'

export default {
  name: "shiftDetails",
  data() {
    return {
      shift: null,
      pre: {
        whiteSpace: "pre-wrap",
        fontStyle: "italic",
      },
    };
  },
  computed: {
    ...mapGetters(["getShift"]),
    tabs() {
      return [
        {
          id: 1,
          name: "Regular shift changes",
          label: "Shift Details",
          routeName: "Regular",
          // systemPermissions: ["can_use_nexus_middleware"],
        },
        {
          id: 2,
          name: "Corrections",
          label: "Corrections",
          routeName: "Corrections",
          permission: "view-payroll-shift-logs",
          systemPermissions: ["can_use_nexus_middleware"],
        },
        {
          id: 3,
          name: "ExternalHistoryLog",
          label: "External History Log",
          routeName: "ExternalHistoryLog",
          permission: "view-calculation-external-information",
          systemPermissions: ["can_use_nexus_middleware"],
        },
      ];
    },
    filteredTabs() {
      return this.tabs.filter((tab) => {
        // console.log(tab);
        const hasPerm =
          "permission" in tab
            ? typeof tab.permission === "string"
              ? this.$can(tab.permission)
              : tab.permission
            : true;
        const hasSysPerm = tab.systemPermissions?.length
          ? tab.systemPermissions.every(
              (tabPerm) => this.initialStatus[tabPerm]
            )
          : true;
        const hasCondition = tab.conditions?.length
          ? tab.conditions.every(Boolean)
          : true;
        return hasPerm && hasSysPerm && hasCondition;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.title.is-3 {
  margin-top: 25px;
}
span.sep {
  border: 1px solid #d6e0e5;
  margin: 0 0.8em;
}

.tag {
  border-radius: 3px;
}

table {
  border-radius: 4px;

  &.is-fixed {
    table-layout: fixed;
    border-collapse: collapsed;
  }
}

.comment-box {
  margin-bottom: 1em;
}

tbody {
  .one-td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  th {
    height: 50px;
    border: 1px solid #b2cddb;
    background-color: #eff5f5;
  }

  th,
  td {
    width: 50%;
    vertical-align: middle;
  }

  .cell-wide {
    width: 80%;
  }

  td.cell-wide {
    color: grey;
  }

  .generic-app-tag {
    background-color: #dbe1e5;
    border-radius: 3px;
    padding: 0.2em 0.5em;
  }
}
</style>
