<template lang="pug">
div.backPay
  modal.reports-modal(
    v-if="modalVisible",
    @close="cancelModal",
    :title="modalTitle",
    :scrollable="false",
    :size="modalSize"
  )
    //- :size="85%",
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="cancelModal",
      @start-recalculation="startRecalculation"

      @close-confirm-modal="cancelRevert"
      @confirm-action-confirm-modal="confirmRevert"
    )
  modal(
    v-if="isModalVisible",
    :title="'Downloading...'",
    :size="450",
    @close="closeModalZip"
  )
    progress-bar(
      :currentProgress="currentProgress",
    )
    .backup-fail(v-if="isBackupComplete && failedToBackup.length > 0")
      p Backpay Client Excel failed for the following invoices:
      table.table(style="color: #000")
        thead
          tr
            th #
            th Invoice ID
            th Client
        tbody
          tr(v-for="(invoice, index) in failedToBackup")
            td {{ index + 1 }}
            td {{ invoice.id }}
            td {{ invoice.client }}
      button.button.is-danger(@click="closeModalZip") Close

  section.section.backpay-title
    h1.title.is-3
      inline-svg.color-svg(:src="correctionsImage", alt="", srcset="")
      | Backpay
    .backpay-image
      img#backpay-info-icon(
        src="../../assets/images/comps/backpay-description-icon.png",
        alt="backpay-description"
      )
  
  .columns(v-if="!this.showProgress && previewCalculations.length === 0")
    .column
      .filter
        #clients.control
          label.label Filtering type
          multi-select(
            :options="filteringType",
            track-by="order",
            label="label",
            placeholder="Filter type",
            :value="selectedFilteringType",
            :multiple="false",
            :showLabels="false",
            @input="selectFilteringType"
            )

  .divider(
    v-if="$can('mark-shifts-for-mass-recalculation') && selectedFilteringType && !showProgress"
  )
    .columns(v-if="!this.showProgress && previewCalculations.length === 0 && $can('mark-shifts-for-mass-recalculation') && selectedFilteringType && !showFilterGroup('invoice_id')")
      .right-side(data-filter="date")
        .column
          label.label(v-if="showFilterGroup('date_from')") FROM
          div(v-if="showFilterGroup('date_from')", @click="selectedInterval = ''")
            date-picker(
              :date="startDate",
              :option="dateTimeOption",
              :isDisabled="showProgress"
            )
          label.label(v-if="showFilterGroup('date_to')") TO
          div(v-if="showFilterGroup('date_to')", @click="selectedInterval = ''")
            date-picker(
              :date="endDate",
              :option="dateTimeOption",
              :isDisabled="showProgress"
            ) 
          label.label(v-if="showFilterGroup('calculation_type')" style="white-space:nowrap") Recalculation Scenarios
          multi-select(
            v-if="showFilterGroup('calculation_type')"
            :options="setRecalculationScenario",
            track-by="order",
            label="label",
            v-model="selectedRecalculationScenario",
            placeholder="Calculation types",
            :multiple="false",
            :showLabels="false"
            style="min-width: 250px"
          )

    .columns(v-if="!this.showProgress && previewCalculations.length === 0")
      .breaker-line
      .column(v-if="showFilterGroup('clients')")
        .filter
          #clients.control
            label.label Client
            multi-select(
              :options="filterClients",
              track-by="id",
              label="name",
              placeholder="Clients",
              :value="selectedClients",
              :loading="isLoadingLocations",
              :multiple="true",
              :showLabels="false",
              ref="clientMultiselect",
              @input="isChangedClientsList"
            )
      .column(v-if="showFilterGroup('categories')")
        .filter
          #categories.control
            label.label Category
            multi-select(
              :options="allCategories",
              track-by="id",
              label="category",
              v-model="selectedCategories",
              placeholder="Categories",
              :multiple="true",
              :showLabels="false"
            )
      .column(v-if="showFilterGroup('subcategories')")
        .filter
          #subcategories.control
            label.label Subcategory
            .subcat
              multi-select(
                :options="allCategories",
                track-by="id",
                label="name",
                v-model="selectedSubcategories",
                placeholder="Subcategories",
                group-label="category",
                group-values="subs",
                :multiple="true",
                :showLabels="false"
              )
              //- multi-select(
              //-   :options="filteredSubcategories",
              //-   :multiple="true",
              //-   track-by="id",
              //-   label="name",
              //-   placeholder="SELECT SUBCATEGORY",
              //-   v-model="query.selectedSubcategories",
              //-   group-label="category",
              //-   group-values="subs",
              //-   :showLabels="false"
              //- )
      .column(v-if="showFilterGroup('temps')")
        .filter
          #temp.control
            label.label Candidate
            multi-select#agencyWorker(
              :options="agencyWorkers",
              :close-on-select="true",
              :searchable="true",
              :options-limit="50",
              select-label="",
              track-by="id",
              label="name",
              :loading="isLoadingAgencyWorker",
              :value="selectedAgencyWorker",
              :max="3",
              :multiple="true",
              placeholder="Search worker",
              :internal-search="false",
              @search-change="asyncFindWorker",
              @input="onSearchMultiple"
            )
      .column(v-if="showFilterGroup('statuses')")
        .filter(v-if="$can('mark-shifts-for-mass-recalculation')")
          #statuses.control
            label.label Candidate Status
            .status
              multi-select(
                :options="optionsStatus",
                track-by="id",
                label="label",
                :allow-empty="true",
                v-model="selectedStatus",
                placeholder="Statuses",
                :showLabels="false",
                :multiple="true",
                @input="onSelectStatus"
              )
      .column(v-if="showFilterGroup('invoice_id')", style="max-width:400px")
        .filter(v-if="$can('mark-shifts-for-mass-recalculation')")
          .control
            label.label Invoice ID
            input.input.inputId(
              type="text"
              placeholder="Invoice ID"
              v-model="invoiceID"
            )
      .column(v-if="showFilterGroup('calculation_type') && showFilterGroup('invoice_id')")
        .filter
          .control
            label.label Recalculation Scenarios
            multi-select(
              :options="setRecalculationScenario",
              track-by="order",
              label="label",
              v-model="selectedRecalculationScenario",
              placeholder="Calculation types",
              :multiple="false",
              :showLabels="false"
            )
    .columns(v-if="!this.showProgress && previewCalculations.length === 0 && $can('mark-shifts-for-mass-recalculation') && showFilterGroup('excludeShiftsCsvString')")
      .breaker-line
      .column()
        .filter
          #excludeShifts
            span.horizontal
              label.label Exclude shifts
              span.info(
                v-tooltip="{ content: `Comma separated shift IDs will be excluded from backpay process.`, classes: 'export-status-tooltip' }"
              )
                span.fa.fa-question.popup
            textarea.input(
              :value="excludeShiftsCsvString",
              placeholder="Comma separated shift IDs to exclude."
              @input="onChangeExcludeShiftsCsvString"
            )
  .divider(
    v-if="$can('mark-shifts-for-mass-recalculation') && showProgress",
    :class="{ recalculationInProgress: showProgress }"
    )
    p(v-if="showProgress") {{ numberOfShifts }} shifts are currently being recalculated. Please wait until the process is completed. You cannot start a new recalculation process until the current one is completed. Thank you for your patience.
      br
    .progres-bar
      RecalculationProgressBar(
        v-if="this.showProgress",
        :progressData="recalculationProgres",
        @progress-status="progresStatus($event)"
      )
      p(v-if="this.showProgress") {{ percentage }} / 100 %
      tbody(v-if="showProgress")
        tr
          td
            img(src="../../assets/images/comps/loader.svg")
  .columns(v-if="$can('mark-shifts-for-mass-recalculation') && selectedFilteringType")
    .right-side(data-filter="date")
      //- .column
      //-   label.label(v-if="showFilterGroup('date_from')") FROM
      //-   div(v-if="showFilterGroup('date_from')", @click="selectedInterval = ''")
      //-     date-picker(
      //-       :date="startDate",
      //-       :option="dateTimeOption",
      //-       :isDisabled="showProgress"
      //-     )
      //-   label.label(v-if="showFilterGroup('date_to')") TO
      //-   div(v-if="showFilterGroup('date_to')", @click="selectedInterval = ''")
      //-     date-picker(
      //-       :date="endDate",
      //-       :option="dateTimeOption",
      //-       :isDisabled="showProgress"
      //-     ) 
      //-   .control(v-if="showFilterGroup('calculation_type') && !showFilterGroup('invoice_id')")
      //-     label.label Recalculation Scenarios
      //-     multi-select(
      //-       :options="setRecalculationScenario",
      //-       track-by="order",
      //-       label="label",
      //-       v-model="selectedRecalculationScenario",
      //-       placeholder="Calculation types",
      //-       :multiple="false",
      //-       :showLabels="false"
      //-     )

      button.button.recalculate-btn.revertBtn(
          v-if="!this.showProgress && previewCalculations.length === 0 && setActions('revert_calculations') && $can('revert-backpay-invoice')",
          @click="checkRevert",
          :disabled="!invoiceID"
        ) Revert
      button.button.recalculate-btn(
        v-if="!this.showProgress && previewCalculations.length === 0 && setActions('start_mass_recalc')",
        @click="submitRecalculation",
        :disabled="disablingStartCalculation || showProgress || loadingRecalculateBtn || isDateFromToInvalid"
      ) Commence
      span.info(
        v-if="!this.showProgress && previewCalculations.length === 0 && setActions('start_mass_recalc')",
        v-tooltip="{ content:showFilterGroup('date_to') ? `Date range and recalculation scenario are required filters.`: `Recalculation scenario is a required filter for the Invoice ID filter option. <br> However, it is not applicable for the Revert option.<br> In case you wish to revert an invoice, please only enter the invoice ID and click Revert.`, classes: 'export-status-tooltip' }"
      )
        span.fa.fa-question.popup
      span.info.warn(
        v-if="isDateFromToInvalid && selectedFilteringType",
        v-tooltip="{ content: `FROM time interval must be less than TO`, classes: 'export-status-tooltip' }"
      )
        span.fa.fa-exclamation-circle.popup
      span.loading-recalculate(v-if="loadingRecalculateBtn")
        img(src="../../assets/images/comps/loader.svg")
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  section.section
    h1.title.is-3
      | Preliminary Backpay
  .table-wrap.preview-table(
    ref="list",
    v-if="$can('view-mass-recalculation-report') && canSeePreviewRecalculation"
  )
    table.table
      thead
        tr
          th
            span Created by
          th
            span Calculation type
          th
            span Created at
          th
            span Number of shifts
          th
            span Failed recalculation
          th
            span Recalculation report
          th
            span Recalculation Confirm
          th
            span Recalculation Cancel
      tbody(
        v-if="previewCalculations && previewCalculations.length > 0 && !isPreviewCalculationsLoading"
      )
        tr(v-for="(calc, index) in previewCalculations", :key="index")
          td
            p.control2
              | {{ calc.createdByName }}
          td
            p.control2(v-if="calc.hasOwnProperty('calculationType')")
              stickerComponent(
                v-if="getLabel(calc.calculationType)"
                :text=" getLabel(calc.calculationType) ? getLabel(calc.calculationType).sticker : getLabel(calc.calculationType)",
                :tooltipText="getLabel(calc.calculationType) ? getLabel(calc.calculationType).label : getLabel(calc.calculationType)",
              )
          td
            p.control2
              | {{ calc.createdAt }}
          td
            p.control2
              | {{ calc.shiftCount }}
          td
            p.control2(v-if="calc.hasErrorsReport")
              button.button(
                @click="errorReportDownload(calc.id)",
                :disabled="loadingRecalculateBtn"
              ) DOWNLOAD
          td
            p.control2(v-if="calc.hasMassRecalcReport")
              button.button(
                @click="ReportDownload(calc.id)",
                :disabled="loadingRecalculateBtn"
              ) DOWNLOAD
          td
            p.control2(v-if="$can('mark-shifts-for-mass-recalculation') && calc.hasMassRecalcReport")
              button.button(
                @click="confirmationPreviewReportsConfirm(calc.id)",
                :disabled="loadingRecalculateBtn || previewReportsConfirmBtn"
              ) Activate calculations
          td
            p.control2
              button.button(
                @click="confirmationPreviewReportsCancel(calc.id)",
                :disabled="loadingRecalculateBtn || previewReportsCancelBtn"
              ) Cancel Batch
      tbody(v-if="isPreviewCalculationsLoading")
        tr
          td.center-text(colspan="100")
            img(src="../../assets/images/comps/loader.svg")
      tbody(
        v-if="previewCalculations.length === 0 || isPreviewCalculationsLoading"
      )
        tr(
          v-if="previewCalculations.length === 0 && !isPreviewCalculationsLoading"
        )
          td(colspan="100")
            | No data in table
  section.section
    h1.title.is-3
      | Backpay History
  .table-wrap(ref="list", v-if="$can('view-mass-recalculation-report')")
    table.table
      thead
        tr
          th
            span Created by
          th
            span Calculation type
          th
            span Created at
          th
            span Number of shifts
          th
            span Failed recalculation
          th
            span Recalculation report
          th(v-if="$can('backpay-invoice-download-all-csv')")
            span Backpay Client Excel
      tbody(
        v-if="calculations && calculations.length > 0 && !isCalculationsLoading"
      )
        tr(v-for="(calc, index) in calculations", :key="index")
          td
            p.control2
              | {{ calc.createdByName }}
          td
            p.control2(v-if="calc.hasOwnProperty('calculationType')")
              stickerComponent(
                v-if="getLabel(calc.calculationType)"
                :text=" getLabel(calc.calculationType) ? getLabel(calc.calculationType).sticker : getLabel(calc.calculationType)",
                :tooltipText="getLabel(calc.calculationType) ? getLabel(calc.calculationType).label : getLabel(calc.calculationType)",
              )
          td
            p.control2
              | {{ calc.createdAt }}
          td
            p.control2
              | {{ calc.shiftCount }}
          td
            p.control2(v-if="calc.hasErrorsReport")
              button.button(
                @click="errorReportDownload(calc.id)",
                :disabled="loadingRecalculateBtn"
              ) DOWNLOAD
          td
            p.control2(v-if="calc.hasMassRecalcReport")
              button.button(
                @click="ReportDownload(calc.id)",
                :disabled="loadingRecalculateBtn"
              ) DOWNLOAD
          td(v-if="$can('backpay-invoice-download-all-csv')")
            p.control2(v-if="calc.hasBackupInvoice")
              button.button(
                @click="getBackpayInvoices(calc.id)",
                :disabled="loadingRecalculateBtn"
              ) DOWNLOAD
      tbody(v-if="isCalculationsLoading")
        tr
          td.center-text(colspan="100")
            img(src="../../assets/images/comps/loader.svg")
      tbody(v-if="calculations.length === 0 || isCalculationsLoading")
        tr(v-if="calculations.length === 0 && !isCalculationsLoading")
          td(colspan="100")
            | No data in table
    pagination(
      v-if="listReportsPagination.total_pages",
      :total-pages="listReportsPagination.total_pages",
      :current-page="listReportsPagination.current_page",
      @page-changed="pageHasChanged"
    )
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import calendarOptions from "../common/calendarOptions.js";
import MultiSelect from "vue-multiselect";
import ReportPreview from "../in-modal/reports/ReportPreview.vue";
import moment from "moment";
import _ from "underscore";
import CorrectionsMessagee from "../in-modal/Corrections/CorrectionsMessage.vue";
import RecalculationProgressBar from "./Progress/ProgressRecalculation.vue";
import Pagination from "../../components/Pagination.vue";
import { Evt } from "../../lib/helpers/Evt.js";
import axios from "axios";
import { parseErrors } from "@/lib/helpers/function";
import correctionsImage from "@/assets/images/comps/corrections-logo.svg";
import confirmModal from "../in-modal/confirmModal.vue";
import messageModal from "../in-modal/messageModal.vue";
import { ref } from "vue";
import JSZip from "jszip";
import ProgressBar from "../in-modal/invoices/ProgressBar.vue";


export default {
  name: "Backpay",
  components: {
    MultiSelect,
    ReportPreview,
    CorrectionsMessagee,
    RecalculationProgressBar,
    Pagination,
    confirmModal,
    ProgressBar,
    messageModal,
  },
  setup() {
    // const isPreviewCalculationsLoading = ref(false);
    // const canSeePreviewRecalculation = ref(true);
    // const previewCalculations = ref([]);
    return {
      // isPreviewCalculationsLoading,
      // canSeePreviewRecalculation,
      // previewCalculations,
    };
  },
  data() {
    return {
      correctionsImage,
      recalcStatusCode: "",
      loadingRecalculateBtn: false,
      numberOfShifts: 0,
      progressInterval: null,
      percentage: 0,
      listReportsPagination: {},
      showProgress: false,
      recalculationProgres: { total: 1, completed: 0 },
      modalSize: 600,
      calculationMessage: "",
      isCalculationsLoading: false,
      isPreviewCalculationsLoading: true,
      // calculations:[],
      calculations: [],
      canSeePreviewRecalculation: true,
      previewCalculations: [],
      previewReportsConfirmBtn: false,
      previewReportsCancelBtn: false,
      selectedAgencyWorker: [],
      isLoadingAgencyWorker: false,
      dateTimeOption: null,
      MultiShift: null,

      modalVisible: false,
      inmodal: "",

      startDate: { time: "" },
      endDate: { time: "" },
      isEmpty: false,
      error: "These fields are required!",

      multiselectLabel: "",

      selectedSectors: [],

      optionsClients: [],
      selectedClients: [],

      selectedLocation: [],
      isLoadingLocations: false,

      optionsCategories: [],
      selectedCategories: [],

      selectedSubcategories: [],

      excludeShiftsCsvString: null,

      search: "",
      isPreviewingReport: false,

      // optionsInterval: ["This week", "Last week"],
      selectedInterval: "",
      recalculationType: ["Immediately recalculation", "Night recalculation"],
      selectedRecalculationType: "",
      reportsHardcodedPerm: {
        "general-client-schedule-report": null,
        "unbilled-shifts-report": "view-unbilled-shifts-report", // Probably not used perm
        "unprocessed-shifts-report": null,
        "client-assigned-report": "view-client-assigned-report",
        // R15 view-client-activity-report
        // R16 view-client-unassigned-activity-report
      },
      // localReports: [],
      optionsStatus: [
        {
          id: 0, // This will fallthrough
          label: "All",
          backColor: "white",
        },
        {
          id: 1,
          label: "Active",
          backColor: "#23d160",
        },
        {
          id: 2,
          label: "Review",
          color: "black",
          backColor: "#ffdb4a",
        },
        {
          id: 3,
          label: "Inactive",
          backColor: "#ff2b56",
        },
        // {
        //   id: 4,
        //   label: "Approval",
        //   color: "black",
        //   backColor: "#a8bc46",
        // },
      ],
      selectedStatus: {},
      configuration:  {  // set to null
                // "regular_filters": {
                //     "label": "Regular filters",
                //     "order": 0,
                //     "filters": {
                //         "date_from": {
                //             "label": "FROM",
                //             "placeholder": "When?",
                //             "required": true,
                //             "enabled": true,
                //         },
                //         "date_to": {
                //             "label": "TO",
                //             "placeholder": "When?",
                //             "required": true,
                //             "enabled": true,
                //         },
                //         "clients": {
                //             "label": "Client",
                //             "placeholder": "Clients",
                //             "required": false,
                //             "enabled": true,
                //         },
                //         "categories": {
                //             "label": "Category",
                //             "placeholder": "Categories",
                //             "required": false,
                //             "enabled": true,
                //         },
                //         "subcategories": {
                //             "label": "Subcategory",
                //             "placeholder": "Subcategories",
                //             "required": false,
                //             "enabled": true,
                //         },
                //         "temps": {
                //             "label": "Candidate",
                //             "placeholder": "Search worker",
                //             "required": false,
                //             "enabled": true,
                //         },
                //         "statuses": {
                //             "label": "Candidate Status",
                //             "placeholder": "Statuses",
                //             "required": false,
                //             "enabled": true,
                //         },
                //         "calculation_type": {
                //             "label": "Calculation types",
                //             "placeholder": "Calculation types",
                //             "required": true,
                //             "enabled": true,
                //             "items": [
                //                 {
                //                     "label": "Pay and then Bill",
                //                     "order": 0,
                //                     "code": "PYBL",
                //                 },
                //                 {
                //                     "label": "Bill and then Pay",
                //                     "order": 1,
                //                     "code": "BLPY",
                //                 },
                //                 {
                //                     "label": "Only Bill",
                //                     "order": 2,
                //                     "code": "ONBL",
                //                 },
                //                 {
                //                     "label": "Only Pay",
                //                     "order": 3,
                //                     "code": "ONPY",
                //                 }
                //             ],
                //         },
                //     },
                // },
                // "invoice_filters": {
                //     "label": "Invoice ID filters",
                //     "order": 1,
                //     "filters": {
                //         "invoice_id": {
                //             "label": "Invoice ID",
                //             "placeholder": "Invoice ID",
                //             "required": true,
                //             "enabled": true,
                //         },
                //         "calculation_type": {
                //             "label": "Calculation types",
                //             "placeholder": "Calculation types",
                //             "required": true,
                //             "enabled": true,
                //             "items": [
                //                 {
                //                     "label": "Pay and then Bill",
                //                     "order": 0,
                //                     "code": "PYBL",
                //                 },
                //                 {
                //                     "label": "Bill and then Pay",
                //                     "order": 1,
                //                     "code": "BLPY",
                //                 },
                //                 {
                //                     "label": "Only Bill",
                //                     "order": 2,
                //                     "code": "ONBL",
                //                 },
                //                 {
                //                     "label": "Only Pay",
                //                     "order": 3,
                //                     "code": "ONPY",
                //                 }
                //             ],
                //         },
                //     },
                // },
            },
      // new 
      filteringType:[
      ],
      selectedFilteringType: null,
      recalculationScenario:[], // dynamic set values in setRecalculationScenario computed property
      selectedRecalculationScenario: null,
      invoiceID: null,

      // for zip
      selectedJobId:null,
      jszip: new JSZip(),
      zipNameBase: "",
      fileList: [],
      currentProgress: 0,
      failedToBackup: [],
      isBackupComplete: false,
      isModalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      getSectors: "locationClientManagement/getSectors", // Final
      clients: "getAllClients",
      getPreviewData: "reports/getPreviewData",
      getReports: "reports/getReports",
      getClientsWithLocations: "getClientsWithLocations",
      agencyWorkers: "getAgencyWorkers",
      allCategories: "getCategoriesWithSubcategories",
      getRecalculationScenario: "reports/getRecalculationScenario",
    }),
    filterClients() {
      // logic to return clients for multiple selection of Sectors
      // Say this is the filtered result for now
      // const values = this.selectedSectors;
      // if (values.length) {
      //   return values.reduce((acc, next) => {
      //     // Find clients for current sector iteration
      //     for (const sectorGroup of this.getSectors) {
      //       const sectors = sectorGroup.subs;
      //       const found = _.findWhere(sectors, { id: next.id });
      //       if (found) {
      //         // Push its clients into the result
      //         acc.push(...found.clients);
      //       }
      //     }
      //     return acc;
      //   }, []);
      // }
      // Or some other logic
      // Otherwise, if non of Sectors is selected
      // give back all clients (from getter)!
      return this.clients;
    },
    filterLocation() {
      if (Array.isArray(this.selectedClients) && this.selectedClients.length) {
        return this.getClientsWithLocations.filter((cc) => {
          const clientNames = this.selectedClients.map((client) => client.name);
          return clientNames.includes(cc.client);
        });
      }
      // return this.getClientsWithLocations
      return [];
    },
    serverStartDate: function () {
      return this.startDate.time
        ? this.convertApiTime(this.startDate.time)
        : null;
    },
    disablingStartCalculation() {
      if(this.showFilterGroup('date_to') && this.showFilterGroup('date_from')){
        if (this.startDate.time && this.endDate.time && this.selectedRecalculationScenario) {
          return false;
        } else {
          return true;
        }
      } else if (this.showFilterGroup('invoice_id')){
        if (this.invoiceID && this.selectedRecalculationScenario) {
          return false;
        } else {
          return true;
        }
      } else if (this.selectedRecalculationScenario){
          return false;
      }
      return true;

    },
    isDateFromToInvalid() {
      console.log(!(this.showFilterGroup('date_to') && this.showFilterGroup('date_from')));
      if(!(this.showFilterGroup('date_to') && this.showFilterGroup('date_from'))){
        return false;
      }
      const start = new Date(this.startDate.time);
      const end = new Date(this.endDate.time);
      if (start > end) {
        return true;
      } else {
        return false;
      }
    },
    filtratedByIdSelectedClients() {
      return this.selectedClients.map((client) => ({
        id: client.id,
      }));
    },
    filtratedByIdSelectedCategories() {
      return this.selectedCategories.map((category) => ({
        id: category.id,
      }));
    },
    filtratedByIdSelectedTemps() {
      return this.selectedAgencyWorker.map((worker) => ({
        id: worker.id,
      }));
    },
    filtratedByIdSelectedSubcategories() {
      return this.selectedSubcategories.map((subcategories) => ({
        id: subcategories.id,
      }));
    },
    filtratedByIdSelectedStatuses() {
      return this.selectedStatus.map((status) => ({
        id: status.id,
      }));
    },
    serverEndDate: function () {
      return this.endDate.time ? this.convertApiTime(this.endDate.time) : null;
    },

    filteredCategories() {
      const mappedSubs = this.selectedSubcategories.map((sub) => sub.id);
      return this.optionsCategories.filter((cat) => {
        if (mappedSubs.length) {
          const isCatSubs = cat.subcategories.some((sub) =>
            mappedSubs.includes(sub.id)
          );
          return isCatSubs;
        }
        return true;
      });
    },
    filteredSubcategories() {
      const mappedCats = this.selectedCategories.map((cat) => cat.id);
      return this.optionsCategories.filter((cat) => {
        if (this.selectedCategories && this.selectedCategories.length) {
          return mappedCats.includes(cat.id);
        }
        return true;
      });
    },
    setRecalculationScenario() {
      // this is done dynamically by choice filter type, because in the future they may differ from the filter type
      if(this.selectedFilteringType) {
        if( Object.prototype.hasOwnProperty.call(this.configuration[this.selectedFilteringType.key]?.filters,"calculation_type")) {
          return this.configuration[this.selectedFilteringType.key].filters.calculation_type.items;
        } else 
         return [];
      }
      return [];
    },
  },
  created() {
    this.setupDateTimePickerOpt();
    this.getShiftsMassRecalculationFilters();
    // this.setFilteringType(this.configuration);
  },
  mounted() {
    this.fetchClientsList({
      // includes: clientIncludes,
      per_page: 999,
      optimise: true,
      // includes: [ 'locations.scategories.subcategories' ].join(',')
    }).then(() => {
      this.fillOptionsClients();
      this.isLoadingLocations = false;
    });
    this.fetchAllCategoriesList();
    this.selectedStatus = [];

    // non_empty param specifies that only sectors that have at least
    // one client will be returned
    // this.fetchReportList();
    // this.fetchSectors({
    //   includes: ["clients", "clients.locations"].join(","),
    //   forClient: 1,
    // });
    this.isLoadingLocations = true;
    // const clientIncludes = ["locations", "withArchived"].join();

    this.getInformationForProgressBar();
    this.intervalProgress();
    this.getInformationListOfReports();
    this.getInformationListOfPreviewReports();
  },
  destroyed(){
    // do not call the api when the user is not on the page
    this.clearIntervalFunc();
  },
  methods: {
    ...mapActions({
      // fetchSectors: "locationClientManagement/fetchSectors",
      // 'fetchSectors': 'reports/fetchSectors',
      // fetchReportList: "reports/fetchReportList",
      fetchClientsList: "fetchClientsList",
      fetchClientCategories: "reports/fetchClientCategories",
      fetchPreviewReports: "reports/fetchPreviewReports",
      fetchDownloadReport: "reports/fetchDownloadReport",
      findAgencyWorker: "findAgencyWorker",
      progressBackpay: "progressBackpay",
      getListOfReports: "getListOfReports",
      getListOfPreviewReports: "getListOfPreviewReports",
      confirmPreviewReports: "confirmPreviewReports",
      cancelPreviewReports: "cancelPreviewReports",
      checkMassRecalculation: "checkMassRecalculation",
      shiftsMassRecalculationFilters: "shiftsMassRecalculationFilters",
      fetchAllCategoriesList: "fetchAllCategoriesList",
      massRecalculationGetBackpayInvoicesOnJobId: "massRecalculationGetBackpayInvoicesOnJobId",
      massRecalculationGetBackpayInvoiceFile: "massRecalculationGetBackpayInvoiceFile",
      revertCalculations: "revertCalculations",

    }),
    onSearchMultiple: _.debounce(function (selected, id) {
      console.log(selected);
      console.log(id);
      switch (id) {
        case "client":
          this.selectedClients = selected;
          break;
        case "agencyWorker":
          this.selectedAgencyWorker = selected;
          break;
        case "expense-type-drop":
          this.filterBy.expenseType = selected;
          break;
      }
      this.$emit("search-by-filter-client", this.filterBy);
    }, 300),
    asyncFindWorker: _.debounce(async function (query) {
      if (query === "") return;
      this.isLoadingAgencyWorker = true;
      await this.findAgencyWorker({ query, originallySelectedWorker: "" });
      this.isLoadingAgencyWorker = false;
    }, 400),
    onSelectStatus(statuses) {
      const isAllOptionSelected =
        statuses.findIndex((status) => status.id === 0) > -1;

      // if 'all' is selected with rest of the options being already selected - unselect all
      if (
        this.selectedStatus.length === this.optionsStatus.length - 1 &&
        isAllOptionSelected
      ) {
        this.selectedStatus = [];
        return;
      }

      // if 'all' is selected and not all options are selected manually - select all
      if (isAllOptionSelected) {
        this.selectedStatus = this.optionsStatus.filter(
          (status) => status.id !== 0
        );
        return;
      }

      // default handler
      this.selectedStatus = statuses;
    },
    onChangeExcludeShiftsCsvString(evt) {
      let val = evt.target.value || '';
      val = val.replace(/\s+/g, '');
      this.excludeShiftsCsvString = val;
    },
    setupDateTimePickerOpt() {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.MultiShift = calendarOptions.MultiShift;
      this.dateTimeOption.type = "day";
      this.dateTimeOption.format = "YYYY-MM-DD";
    },
    cancelModal() {
      this.modalVisible = false;
      this.loadingRecalculateBtn = false;
      this.recalcStatusCode = "";
      this.getInformationForProgressBar();
      setTimeout(() => {
        this.intervalProgress(1000);
      }, 2000);
      // this.intervalProgress(1000);
    },
    fillOptionsClients() {
      this.optionsClients = this.clients.map((client) => {
        return {
          id: client.id,
          name: client.name,
        };
      });
    },

    isChangedSectorsList() {
      // @param values Holds all currently chosen sectors(or regions)
      // Every time `values` changes, we have to calculate clients
      // for those, and show them in the clients dropdown list
      this.resetClientCostCentre();
      this.selectedCategories = [];
      this.selectedSubcategories = [];
      // console.warn(values);
    },
    async isChangedClientsList(clientList) {
      if (clientList.length) {
        this.selectedClients = clientList;
        const params = {
          clients: clientList.map((client) => client.id),
          include: ["subcategories", "withArchived"].join(),
          // include: "subcategories",
        };

        // Filtering available cost centres based on client
        const clientSubIds = [];
        for (const client of clientList) {
          // Sub ids must be unique
          const clientLocationObj = this.filterLocation.find(
            (ccObj) => ccObj.client === client.name
          );
          if (clientLocationObj) {
            clientSubIds.push(...clientLocationObj.subs.map((sub) => sub.id));
          }
        }
        this.selectedLocation = this.selectedLocation.filter((cc) =>
          clientSubIds.includes(cc.id)
        );

        try {
          const res = await this.fetchClientCategories(params);
          this.optionsCategories = res.data.data;
        } catch (err) {
          console.log(err.message);
          this.optionsCategories = [];
          this.selectedCategories = [];
          this.selectedSubcategories = [];
        }
      } else {
        // Reset cc if no clients
        this.resetClientCostCentre();
        this.selectedCategories = [];
        this.selectedSubcategories = [];
        this.optionsCategories = [];
      }
    },
    resetClientCostCentre() {
      this.selectedClients = [];
      this.selectedLocation = [];
    },
    convertApiTime(d) {
      return moment(d, "DD-MM-YYYY").format("YYYY-MM-DD");
    },
    setReportObj(key, val, report) {
      const foundObj = this.getReports.find((el) => el.index === report.index);
      if (foundObj) {
        this.$set(foundObj, key, val);
      }
    },
    showModal(
      modal,
      modalData = {},
      modalTitle = "Modal title",
      modalSize = "600"
    ) {
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle;
      this.modalVisible = true;
      this.modalSize = modalSize;
    },
    getRecalcualtionType() {
      if (this.selectedRecalculationType === "Immediately recalculation") {
        console.log("Immediately recalculation");
      } else if (this.selectedRecalculationType === "Night recalculation") {
        console.log("Night recalculation");
      }
    },
    async submitRecalculation() {
      this.loadingRecalculateBtn = true;

      // moved Nemanja's logic to the setParams method due to new filter selection conditions
      // const params2 = this.prepareParams();

      // const params = {
      //   clients: params2.clients,
      //   categories: params2.categories,
      //   subcategories: this.filtratedByIdSelectedSubcategories,
      //   temps: this.filtratedByIdSelectedTemps,
      //   statuses: this.filtratedByIdSelectedStatuses,
      //   recalculationType: this.selectedRecalculationType,
      //   date_from: this.startDate.time,
      //   date_to: this.endDate.time,
      // };

      const params = this.setParams();

      const paramsToArray = Object.entries(params);
      const filteredParamsIfEmpty = paramsToArray.filter(
        ([key, value]) => value.length !== 0
      );
      const checkedFiltratedParams = Object.fromEntries(filteredParamsIfEmpty);
   
      await this.getShiftsWillBeMarkedForCalculation(params);
      const sendingDataToModal = {
        params: checkedFiltratedParams,
        // messages from bek
        calculationMessage: this.calculationMessage,
        statusCode: this.recalcStatusCode,
      };

      this.showModal("CorrectionsMessagee", sendingDataToModal, "WARNING");
    },
    setParams() {
      const params = {
        filter_type: this.selectedFilteringType.key,
        calculation_type: this.selectedRecalculationScenario.code,
      };
      if(this.selectedFilteringType.key === "invoice_filters"){
        params.invoice_id = this.invoiceID;
        
      } else { // this.selectedFilteringType.key === "regular_filters" // make this a condition if there are more filter types in the future

         // moved Nemanja's logic from submitRecalculation due to the choice of filter type
         const params2 = this.prepareParams();
      
          params.clients = params2.clients;
          params.categories = params2.categories;
          params.subcategories = this.filtratedByIdSelectedSubcategories;
          params.temps = this.filtratedByIdSelectedTemps;
          params.statuses = this.filtratedByIdSelectedStatuses;
          params.recalculationType = this.selectedRecalculationType;
          params.date_from = this.startDate.time;
          params.date_to = this.endDate.time;
          if (Boolean(this.excludeShiftsCsvString)) params.excludeShiftsCsvString = this.excludeShiftsCsvString;
      }
      return params;
    },

    isDateEmpty() {
      this.isEmpty = this.startDate.time === "" || this.endDate.time === "";
      return this.isEmpty;
    },

    getParam(filter) {
      if (filter) {
        return filter.map((value) => {
          return {
            id: value.id,
          };
        });
      } else {
        return [];
      }
    },

    prepareParams() {
      // Make logic for when Sector/regions is selected
      // then send complete list of clients for them
      // Otherwise, send just the selected ones!
      let clients = null;
      if (this.selectedClients.length) {
        clients = this.selectedClients;
      } else if (this.selectedSectors.length) {
        // Collect clients for all selected Sectors
        clients = this.filterClients; // Using the fact, that this dropdown already has all logic applied
      }
      const paramObj = {
        clients: this.getParam(clients),
        categories: this.getParam(this.selectedCategories),
        subcategories: this.getParam(this.selectedSubcategories),
        temps: this.getParam(this.selectedAgencyWorker),
        statuses: this.getParam(this.selectedStatus),
      };

      if (this.selectedLocation.length) {
        paramObj.locations = this.getParam(this.selectedLocation);
      }

      return paramObj;
    },
    async ReportDownload(id) {
      this.loadingRecalculateBtn = true;
      this.downloadingItemId = id;
      // const name = "report.csv";
      const apiUrl = `/api/shifts-mass-recalculation/jobs/${id}/report.csv`;
      const a = document.createElement("a");
      document.body.appendChild(a);
      try {
        const response = await axios.get(apiUrl, {
          responseType: "arraybuffer",
          params: {
            timestamp: new Date().getTime(),
          },
        });
         const name = response.headers["x-multi-file-name"];
        const blob = new Blob([response.data], { type: "application/csv" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
        this.downloadingItemId = null;
      } catch (err) {
        console.error(err.message);
        this.loadingRecalculateBtn = false;
        this.downloadingItemId = null;
        const errs = parseErrors(err);
        if (errs) {
          this.$toasted.error(errs).goAway(1500);
        }
      }
      document.body.removeChild(a);
      this.loadingRecalculateBtn = false;
    },
    async errorReportDownload(id) {
      this.loadingRecalculateBtn = true;
      this.downloadingItemId = id;
      // const name = "error-report.csv";
     
      const apiUrl = `/api/shifts-mass-recalculation/jobs/${id}/errors-report.csv`;
      const a = document.createElement("a");
      document.body.appendChild(a);
      try {
        const response = await axios.get(apiUrl, {
          responseType: "arraybuffer",
          params: {
            timestamp: new Date().getTime(),
          },
        });
         const name = response.headers["x-multi-file-name"];
        const blob = new Blob([response.data], { type: "application/csv" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);
        this.downloadingItemId = null;
      } catch (err) {
        console.error(err.message);
        this.loadingRecalculateBtn = false;
        this.downloadingItemId = null;
        const errs = parseErrors(err);
        if (errs) {
          this.$toasted.error(errs).goAway(1500);
        }
      }
      document.body.removeChild(a);
      this.loadingRecalculateBtn = false;
    },
    async getShiftsWillBeMarkedForCalculation(params) {
      try {
        const res = await this.checkMassRecalculation(params);
        this.calculationMessage = res.data.message;
        this.recalcStatusCode = res.status;
      } catch (err) {
        const errs = parseErrors(err);
        if (errs) {
          this.calculationMessage = errs;
        } else {
          this.calculationMessage = err.response.data.message;
          this.recalcStatusCode = err.response.data.status_code;
          this.loadingRecalculateBtn = false;
          this.getInformationForProgressBar();
        }
      }
    },
    async getInformationForProgressBar() {
      try {
        const res = await this.progressBackpay();
        this.numberOfShifts = res.data.meta.total;
        if (res.data.meta.total > 0) {
          this.showProgress = true;
          this.isPreviewCalculationsLoading = true;
        } else {
          this.isCalculationsLoading = true;
          this.getInformationListOfReports();
          this.getInformationListOfPreviewReports();
          this.showProgress = false;
          this.clearIntervalFunc();
        }
        this.recalculationProgres = res.data.meta;
      } catch (err) {
        console.log(err.message);
      }
    },
    async intervalProgress(timeout = 5000) {
      if (!this.recalculationProgres.total > 0) {
        this.clearIntervalFunc();
      } else {
        this.progressInterval = setInterval(() => {
          this.getInformationForProgressBar();
        }, timeout);
      }
    },
    clearIntervalFunc() {
      clearTimeout(this.progressInterval);
      this.progressInterval = null;
    },
    progresStatus(event) {
      this.showProgress = event.progresStatus;
      if (event.progressNum) {
        event.progressNum == 100
          ? (this.percentage = event.progressNum)
          : (this.percentage = event.progressNum.toFixed(2));
      }
    },
    async getInformationListOfReports(clickedPage) {
      const params = {
        page: clickedPage,
      };

      try {
        const res = await this.getListOfReports(params);
        this.calculations = res.data.data;
        this.listReportsPagination = res.data.meta.pagination;
        this.isCalculationsLoading = false;
      } catch (err) {
        console.log(err.message);
      }
    },
    async getInformationListOfPreviewReports(clickedPage) {
      const params = {
        page: clickedPage,
      };
      try {
        const res = await this.getListOfPreviewReports(params);
        this.previewCalculations = res.data.data;
        if (!this.showProgress) {
          this.isPreviewCalculationsLoading = false;
        } else {
          this.isPreviewCalculationsLoading = true;
        }
      } catch (err) {
        console.log(err.message);
      }
    },
    confirmationPreviewReportsConfirm(id) {
      return new Promise((resolve) => {
        const alert = {
          title: "Warning",
          message:
            "Are you sure you wish to apply the calculations? Please make sure that all the necessary checks have been done before confirming as this action is irreversible.",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => this.previewReportsConfirm(id),
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    async previewReportsConfirm(id) {
      this.previewReportsConfirmBtn = true;
      this.isPreviewCalculationsLoading = true;
      const params = {
        id: id,
      };
      try {
        await this.confirmPreviewReports(params);
        await this.getInformationListOfPreviewReports();
        this.getInformationListOfReports();
        this.previewReportsConfirmBtn = false;
        this.isPreviewCalculationsLoading = false;
      } catch (err) {
        console.log(err.message);
        this.previewReportsConfirmBtn = false;
        this.isPreviewCalculationsLoading = false;
      }
    },
    confirmationPreviewReportsCancel(id) {
      return new Promise((resolve) => {
        const alert = {
          title: "Warning",
          message:
            "Are you sure you wish to cancel this action? This action will cancel all the recalculations pending to be applied.",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => this.previewReportsCancel(id),
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    async previewReportsCancel(id) {
      this.previewReportsCancelBtn = true;
      this.isPreviewCalculationsLoading = true;
      const params = {
        id: id,
      };
      try {
        await this.cancelPreviewReports(params);
        await this.getInformationListOfPreviewReports();
        this.previewReportsCancelBtn = false;
        this.isPreviewCalculationsLoading = false;
      } catch (err) {
        console.log(err.message);
        this.previewReportsCancelBtn = false;
        this.isPreviewCalculationsLoading = false;
      }
    },
    async pageHasChanged(clickedPage) {
      this.isCalculationsLoading = true;
      await this.getInformationListOfReports(clickedPage);
    },
    startRecalculation() {
      this.isPreviewCalculationsLoading = true;
      this.percentage = 0;
      // A variable that prevents the Recalculate button from being clicked multiple times
      this.loadingRecalculateBtn = false;

      this.modalVisible = false;
      this.showProgress = true;

      this.getInformationForProgressBar();

      setTimeout(() => {
        this.intervalProgress();
      }, 5000);
    },
    selectFilteringType(e) {
      this.selectedFilteringType = e;
      this.clearFilters();
    },
    showFilterGroup(filterName){
      if( Object.prototype.hasOwnProperty.call(this.configuration, this.selectedFilteringType?.key)){
        if( Object.prototype.hasOwnProperty.call(this.configuration[this.selectedFilteringType.key].filters, filterName)    ){
          return true;
        }
      } 
      return false;

    },
    setActions(actionParam) {
      if(this.configuration[this.selectedFilteringType.key].actions &&
        this.configuration[this.selectedFilteringType.key].actions.hasOwnProperty(actionParam)) {
          return this.configuration[this.selectedFilteringType.key].actions[actionParam].enabled;
      }
      return false;
    },
    async getShiftsMassRecalculationFilters() {
      try {
        const res = await this.shiftsMassRecalculationFilters();
        this.configuration= res.data.data.filter_types;
        this.setFilteringType(this.configuration);
      } catch (error) {
        console.log(error);
      }
    },
    setFilteringType(configuration) {
      for(const filter in configuration){
        const objectF = {
          label: configuration[filter].label,
          order: configuration[filter].order,
          key: filter,
        };
        this.filteringType.push(objectF);
      }
    },
    clearFilters(){
       this.selectedClients = [];
       this.selectedSectors = [];
       this.selectedCategories = [];
       this.selectedSubcategories = [];
       this.selectedAgencyWorker = [];
       this.selectedStatus = [];
       this.selectedLocation = [];
       this.startDate.time = '';
       this.endDate.time = '';
       this.selectedRecalculationScenario = null;
       this.invoiceID = null;
       this.excludeShiftsCsvString = null;
    },
    getLabel(code){

    return code ? this.getRecalculationScenario.find(obj => obj.code === code) : code;
    // return this.getRecalculationScenario.find(obj => obj.code === code);
    },

    // function for ZIP data are going here..
    async getBackpayInvoices(id){
      // massRecalculationGetBackpayInvoicesOnJobId
      // massRecalculationGetBackpayInvoiceFile
      this.resetBackupState();
      this.fileList = [];
      console.log(id);
      const params = {
        id,
      };
      try {
        const res = await this.massRecalculationGetBackpayInvoicesOnJobId(params);
        // console.log(res.data.invoices);
        this.fileList = res.data.invoices;
        if (!this.fileList) {
            this.$toasted
              .error("Something went wrong. Please try again.11")
              .goAway(2000);
            return;
          }
        if (this.fileList && this.fileList.length === 0) {
            this.$toasted
              .error("There are no data.")
              .goAway(3000);
            return;
          }
          this.selectedJobId = id;
          this.isModalVisible = true;
          this.callBackup(this.fileList, 0);
        
      } catch (err) {
        console.log(err);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(1500);
      }

    },
    async callBackup(fileList, indexFunc){
      const i = indexFunc;
      this.zipNameBase = "backpay_client_excel"; //"backpay_client_excel";
      if( fileList.length === i){
        this.finishBackup();
        return;
      }
      const id = fileList[i].id;
      const client = fileList[i].clientName;
      try {
        const params = {
          job_id: this.selectedJobId,
          invoice_id: id,

        };
        const res = await this.massRecalculationGetBackpayInvoiceFile(params);
        const blob = new Blob([res.data], { type: "application/csv" });
        const fileTitle = res.headers["x-multi-file-name"];

        this.jszip.file(fileTitle, blob);
        this.currentProgress = ((i + 1) * 100) / fileList.length;
        this.callBackup(fileList, i + 1);

      } catch (error) {
        console.log(error);
        this.failedToBackup.push({ id, client });
        this.callBackup(fileList, i + 1);
      }

      
     },
      async finishBackup() {
        // console.log('finish Backup');
      this.isBackupComplete = true;
      // reset state if user closes modal before the function is called from Promise (global data might be polluted - ie. files in jszip)
      if (!this.isModalVisible) {
        this.resetBackupState();
        return;
      }
      await this.downloadZip();
      // setTimeout(() => {
        this.$nextTick(() => {
        if (this.failedToBackup.length === 0) {
          this.closeModalZip();
        }
      });
      // }, 50);
      
    },
     async downloadZip() {
      this.currentProgress = 100;
      if (
        Object.keys(this.jszip.files).length === 0 &&
        this.jszip.files.constructor === Object
      ) {
        console.log("no files", this.jszip.files);
        this.$toasted.error("No files").goAway(1500);
        return;
      }
      try {
        const content = await this.jszip.generateAsync({ type: "blob" });
        const blob = new Blob([content]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        const currDate = moment().format("DD-MM-YYYY");
        a.download = `${this.zipNameBase} - ${currDate}.zip`;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (err) {
        console.log("Downloading Zip Failed: ", err.message);
      }
    },
     resetBackupState() {
      this.jszip = new JSZip();
      this.currentProgress = 0;
      this.isBackupComplete = false;
      this.failedToBackup = [];
    },
    closeModalZip() {
      this.resetBackupState();
      this.isModalVisible = false;
    },
    // REVERT OPTIONS
    checkRevert() {
      console.log('checkRevert');
      const message = "Are you sure you want to revert back to the original calculation on the invoice that has an ID "+this.invoiceID+"?";
      this.showModal("confirmModal", { message }, "WARNING");
    },
    async confirmRevert(){
      console.log('confirmrevert ');
       const params = {
          filter_type : this.selectedFilteringType.key,
          invoice_id : this.invoiceID,
      };
      try {
        const res = await this.revertCalculations(params);
        // console.log(res.data); // message key
        this.clearFilters();
        this.cancelModal();
        // this.$toasted.info("Revert successfully!").goAway(1500);
        this.showModal(
            "messageModal",
            {
              message: res.data.message,
              error: false,
            },
            "Success"
          );
      } catch (error) {
        this.cancelModal();
        const errs = parseErrors(error);
        this.showModal(
            "messageModal",
            {
              message: errs.toString(),
              error: true,
            },
            "Error"
          );
        // console.log(errs);
        // this.$toasted.error(errs).goAway(1500);
      }
    },
    cancelRevert() {
      console.log('cancel revert ');
      this.cancelModal();
    },
  },
};
</script>

<style lang="scss" scoped>
$color--light-gray: #dde6eb;
$color--light-blue: #8399a6;
$color--border: #9bb4c1;
.preview-table {
  margin-bottom: 35px;
}
.color-svg {
  width: 30px;
  margin: 0 15px 0 0;
  height: 30px;
  :deep() {
    path {
      fill: black;
    }
  }
}

tbody {
  tr {
    height: 60px;
  }
}
.recalculationInProgress {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.divider {
  padding: 25px 0 45px 0;
  border-top: 2px solid $color--light-gray;
  min-height: 50px;
  .progres-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin: 0 10px 0 10px;
    }
  }
  &.below {
    .label {
      margin: 0 5px;
    }
  }
}

.input {
  border: 1px solid $color--light-gray;
  height: 37px;
  width: 200px;

  &::placeholder {
    color: $color--light-blue;
    font-size: 15px;
  }
}
.inputId{
  width: 100%;
}

.control {
  i {
    top: 10px;
    position: absolute;
    right: 20px;
    font-size: 14px;
    color: $color--light-blue;
  }
}

.filter {
  .control {
    min-width: 250px;
    max-width: 500px;

    i {
      position: absolute;
      right: 0;
      top: 0;
    }

    .label {
      margin-left: 5px;
    }

    .status,
    .subcat {
      display: flex;
      align-items: center;

      .info {
        border-radius: 50%;
        border: 1px solid silver;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 27px;
        min-height: 27px;
        width: 27px;
        height: 27px;

        .fa {
          color: #7c91ae;
        }
      }
    }
  }
}

.flex {
  display: flex;
  align-items: center;

  .date-dropdown {
    width: 180px;
  }
}

.field {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  label {
    margin: 0 1em;
  }
}

.nivelate {
  position: relative;
  top: 5px;
  margin-right: 10px;
}

.interval {
  .field {
    float: right;
  }
}

.label {
  color: #7c91ae;
}

.help {
  .is-danger {
    position: relative;
    left: 150px;
    margin-bottom: 20px;
  }
}

.error {
  width: 100%;
  text-align: right;

  p {
    margin-right: 150px;
    margin-bottom: 10px;
  }
}

.field {
  .label {
    position: relative;
    top: -5px;
  }
}

.columns {
  margin-top: 0;

  .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .button {
      // margin: 10px 0 0 0px;
      min-width: 80px;
      background-color: #1a7ccb;
      color: #ffffff;
      cursor: pointer;
    }

    .info {
      border-radius: 50%;
      border: 1px solid silver;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 27px;
      min-height: 27px;
      width: 27px;
      height: 27px;

      .fa {
        color: #7c91ae;
      }
    }
  }

  #excludeShifts {
    textarea {
      min-width: 27px;
      min-height: 27px;
      width: 380px;
      height: 60px;
    }
  }

  .horizontal {
    display: flex;
    flex-direction: row;
    margin: 3px;
    padding: 3px;
  }

  .right-side {
    display: flex;
    align-items: flex-start;
    .recalculate-btn {
      margin: 10px 0 0 0;
    }
    .loading-recalculate {
      margin: 15px 10px 0 10px;
    }
    .info {
      border-radius: 50%;
      border: 1px solid silver;
      margin: 15px 0 5px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 27px;
      min-height: 27px;
      width: 27px;
      height: 27px;
      .fa {
        color: #7c91ae;
      }
      // .export-status-tooltip {
      //   // border-radius: 10px;
      //   border: 1px solid transparent;
      //   color: white;
      //   font-weight: 600;
      // }
    }
    .warn {
      border: 1px solid silver;
      .fa {
        color: #d9534f;
      }
    }

    .column {
      align-items: center;
      flex-direction: row;
      gap: 10px;
      align-items: space-between ;

      label {
        margin-bottom: 0;
      }
    }
  }
}
.revertBtn{
  // margin: auto;
  // margin-left: 0;
  // margin-bottom: 3px;
  margin-right: 50px!important;
}

.divider {
  margin-top: 40px;
  padding: 10px 0 20px 0;
  border-top: 2px solid $color--light-gray;
  border-bottom: 2px solid $color--light-gray;
  min-height: 150px;

  .breaker {
    position: absolute;
    width: 1px;
    height: 100px;
    background-color: $color--light-gray;
    right: 0;
    top: 35px;
  }
}
.control2 {
  button {
    background-color: #1a7ac9;
    height: 2.75em;
    border-color: transparent;
    color: #fff;
    border-radius: 3px;
    font-size: 0.75rem;
    text-transform: uppercase;
    cursor: pointer;
  }
}
@media (max-width: 768px) {
  .breaker {
    display: none;
  }
}

.backup-fail {
  margin: 10px auto;
  width: 90%;
  text-align: center;
}

.backup-fail p {
  margin: 8px;
}

</style>
<style lang="scss">
.backPay .panel-block {
  height: 200px;
}

.backPay .progress-bar-wrapper {
  padding-top: 30px;
}
.backpay-title {
  display: flex;
  h1 {
    position: relative;
  }
  .backpay-image {
    &:hover::after {
      content: "Please select the filtering type in order to commence the backpay process. You can choose between two types: \a\a  - Invoice ID \a  - recalculation and Regular filters. \a\a Please note that with either type, the Recalculation Scenario is a required filter. \a\a With the Invoice ID Filter, enter the invoice ID you would like to recalculate. This will target the shifts that are specifically on this invoice. \a If you select the Regular filters, this will target the shifts as per your preferred filters. \a\a Once started, the system will start to recalculate the eligible shifts based on your selected filters. \a After the recalculation is completed, the new calculations can be reviewed in the Recalculation Report under the Preliminary Backpay segment. \a\a Pressing the Activate Calculations button will activate the calculations  and they will move to the Backpay history section (until they are activated, the calculations are not effective and remain suspended!). \a Once activated, the calculations cannot be reversed unless using the Revert button with the Invoice ID filter. \a\a Pressing Cancel will delete the calculations from the Preliminary Backpay section and they will be deleted. \a Until you have either activated or cancelled the pending calculations, you cannot commence a new recalculation process. \a\a Please note that the followign actions are blocked in the system while the recalculation is under way: \a\a Updating worker increment \a Updating client service fee \a Updating temps \a Updating rates \a Updating shifts \a Creating invoices \a ";
      position: absolute;
      top: 110px;
      left: 40px;
      background-color: #34495e;
      padding: 14px;
      border-radius: 5px;
      z-index: 1;
      width: 2100px;
      font-family: "Lato";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #ffffff;
      cursor: pointer;
      white-space: pre-line;
    }
  }
  img {
    width: 20px;
    height: 21px;
    margin: 10px 0 0 16px;
    cursor: pointer;
  }
}

.reports-modal {
  .modal-content {
    width: 85%;
    overflow: hidden;
  }

  .panel {
    width: 100%;

    .panel-block {
      overflow: hidden;
    }
  }
}

.report-status-tooltip {
  // border-radius: 10px;
  border: 1px solid transparent;
  color: white;
  font-weight: 600;
}
</style>
