<template lang="pug">
#category-tree-node
  .node(
    :class="[{ active: isSelectedCategoryActive }, { hidden: category.hidden }]",
    @click="nodeClicked"
  )
    .arrow(
      :class="[{ expanded: isVisibleChildren }]",
      v-if="category.subcategories && category.subcategories.length"
    )
      img(src="../../../../assets/images/comps/forms/triangle-select.svg")
    .name {{ category.name }}
    .action
      .edit.btn(
        title="Edit category",
        v-if="isParent && canEdit",
        @click.stop="$emit('edit-category', category)"
      )
        .fa.fa-edit
      .add.btn(
        title="Add new subcategory",
        v-if="isParent && canAdd",
        @click.stop="onCreateSubCategory"
      )
        .fa.fa-plus
      .delete(
        title="Delete subcategory",
        v-if="!isParent && canDelete",
        @click.stop="onDeleteSubCategory"
      )
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  transition(name="slide-down")
    ul.children(v-if="isVisibleChildren && category.subcategories")
      li(v-for="(subcategory, index) in category.subcategories", :key="index")
        category-tree-node(
          :category="subcategory",
          :parent-category="category",
          :node-select-evt="nodeSelectEvt",
          :selected-category="selectedCategory",
          :can-add="canAdd",
          :can-edit="canEdit",
          :can-delete="canDelete",
          @change="$emit('change')"
        )
</template>

<script>
import { mapActions } from "vuex";
import { parseErrors } from "../../../../lib/helpers/function";
export default {
  name: "CategoryTreeNode",
  props: {
    nodeSelectEvt: Function,
    parentCategory: [Object, null],
    category: {
      type: Object,
      required: true,
    },
    selectedCategory: [Object, null],
    isParent: Boolean,
    canAdd: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isVisibleChildren: false,
    };
  },
  computed: {
    isSelectedCategoryActive() {
      return (
        this.selectedCategory && this.selectedCategory.id === this.category.id
      );
    },
  },
  methods: {
    ...mapActions({
      deleteRatesCategories: "deleteRatesCategories",
    }),
    nodeClicked() {
      this.isVisibleChildren = !this.isVisibleChildren;

      // Only call if not parent
      if (!this.isParent) {
        // Event handler
        const params = {
          category: this.category,
          parent: this.parentCategory,
          command: "edit",
        };
        this.nodeSelectEvt(params);
      }
    },
    onCreateSubCategory() {
      const params = {
        category: this.category,
        parent: this.parentCategory,
        command: "add",
      };
      this.nodeSelectEvt(params);
    },
    async onDeleteSubCategory() {
      const params = {
        catId: this.category.id,
      };
      try {
        await this.confirmDelete();
        const response = await this.deleteRatesCategories(params);
        this.$toasted.info(response.data.message).goAway(2000);
        this.$emit("change");
        // console.log('Deleted node', this.category.name);
      } catch (err) {
        console.log(err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    confirmDelete() {
      const name = this.category.name;

      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to delete "${name}"?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#category-tree-node {
  $gray-element: rgba(10, 10, 10, 0.2);
  $red-element: rgba(238, 96, 124, 0.733);

  .node {
    background: transparent;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;

    > .arrow {
      display: flex;
      margin-right: 5px;
      transform: rotate(-90deg);
      transition: transform 0.3s ease;
      filter: grayscale(1);

      &.expanded {
        transform: rotate(0deg);
      }
    }

    .action {
      margin: 0 0 0 auto;
      display: flex;
      align-items: center;
      gap: 10px;

      .btn {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .fa {
          color: darken($color: $gray-element, $amount: 10);
        }

        &:hover {
          .fa {
            color: lighten($color: royalblue, $amount: 10);
          }
        }
      }

      .delete {
        margin-left: 5px;
        background: $gray-element;

        &:hover {
          background: $red-element;
        }
      }
    }

    &.active {
      background: darken($color: #e0e0e0ce, $amount: 10);
    }

    &.hidden {
      background: rgba(219, 219, 219, 0.335);
      opacity: 0.4;
      color: rgb(39, 39, 39);
    }

    &:hover {
      background: darken($color: #e0e0e0ce, $amount: 5);
    }
  }

  .children {
    padding: 0 0 0 20px;
    border-left: 1px solid #e0e0e0;
  }

  .slide-down-enter-active {
    transition: all 0.4s;
  }

  .slide-down-leave-active {
    transition: none;
  }

  .slide-down-enter {
    transform: translateY(-30px);
    opacity: 0;
  }

  .slide-down-leave-to {
    transform: translateY(-30px);
    opacity: 0;
  }
}
</style>
