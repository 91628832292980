import { cssVar } from "@/lib/helpers/cssVariablesUtil.js";
import { mapState } from "vuex";
// Always same until page refreshes
const uniqueTimeStamp = Date.now();

export default {
  data() {
    return {
      enableLane1: false,
      shiftConfirmEnablement: false,
      enableLanePre: false,
    };
  },
  computed: {
    ...mapState({
      initialStatus: (state) => state.initialStatus,
    }),
    isProductionApp() {
      return process.env.NODE_ENV === "production";
    },
    getGlobalImageLogoApi() {
      return window.ApiURL + "/api/get-logo?t=" + uniqueTimeStamp;
    },
    canUseOnCallShifts() {
      return this.initialStatus?.can_use_on_call;
    },
    canUseSleepoverShifts() {
      return this.initialStatus?.can_use_sleepover;
    },
    canUseExpenses() {
      return this.initialStatus?.can_use_expenses;
    },
    canUseNexusMiddleware() {
      // return true;
      return this.initialStatus?.can_use_nexus_middleware;
    },
    canUseLocationExternalId() {
      return this.initialStatus?.can_use_location_external_id;
    },
    canUseAlAccumulation() {
      return this.initialStatus?.can_use_accumulation;
    },
    canUseShiftConfirm() {
      return this.initialStatus.can_use_shift_confirmation_engine;
    },
    getGlobalPrimaryColorHex() {
      // Was #B0BDCE
      return cssVar("--color-primary");
    },
    gPermissions() {
      const futureKeys = [];
      const keys = {
        canEditBreakTimes: "edit-break-times",
        canManageRates: "manage-rates",
        canViewDefaultShiftTimes: "view-default-shift-times",
        canCreateDefaultShiftTimes: "create-default-shift-time",
        canDeleteDefaultShiftTimes: "delete-default-shift-time",
        canSetAutorepush: "set-autorepush-interval",
        canClientProfileCreate: "create-client",
        canClientProfileEdit: "edit-client",
        canClientProfileView: "view-client", // Was "edit-client"
        canAddNewWard: "create-location",
        canEditWard: "edit-location",
        canCopyWard: "copy-location",
        canViewRulesCalcEng: "view-calc-engines",
        canEditRulesCalcEng: "edit-client-calc-engine",
        canAddContact: "create-contacts",
        canEditContact: "edit-contacts",
        canDeleteContact: "delete-contacts",
        canHolidayView: "view-holiday-requests",
        canAnnualLeaveView: "view-accumulation-transactions",
        canJournalViewTempJournal: "view-temp-journal",
        canJournalClientSave: "create-client-journal",
        canJournalTempSave: "create-temp-journal",
        canJournalClientEdit: "edit-client-journal",
        canJournalTempEdit: "edit-temp-journal",
        canJournalClientExport: "export-client-journal-report",
        canJournalTempExport: "export-temp-journal-report",
        canJournalClientViewPrivate: "view-private-client-journal",
        canJournalTempViewPrivate: "view-private-temp-journal",
        canJournalClientCreatePrivate: "create-private-client-journal",
        canJournalTempCreatePrivate: "create-private-temp-journal",
        canJournalClientViewSystem: "view-system-client-journal",
        canJournalTempViewSystem: "view-system-temp-journal",
        canRelativeView: "view-relativetypes",
        canCreateTemp: "create-temp",
        canEditTemp: "edit-temp",
        canViewTempDetails: "view-temp-details",
        canViewIncrementWeekCounter: "view-increment-weeks-counter",
        canEditIncrementWeekCounter: "edit-increment-weeks-counter",
        canEditTempIncrement: "edit-temp-increment",
        canDeleteTempIncrement: "delete-temp-increment",
        canAssignTempToShift: "assign-temp-to-shift",
        canArchiveLocation: "archive-location",
        canViewTempContractedHours: "view-temp-contracted-hours",
        canEditContractedHours: "edit-temp-contracted-hours",
        canEditShiftConfirmInterval: "manage-client-intervals",
        canUseContactEmails: "view-shift-confirmation-contacts",
        canEditTempExternalId: "edit-temp-external-id",
        canEditShiftPriceOverwriteFeatureToggle:
          "edit-can-use-shift-price-overwrite",
      };

      const entries = Object.entries(keys).map(([key, val]) => {
        if (futureKeys.includes(key)) {
          return [key, true];
        }

        return [key, this.$can(val)];
      });

      return Object.fromEntries(entries);
    },
  },
};
