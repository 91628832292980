<template lang="pug">
#category-tree
  ul
    li(v-for="(category, index) in categories", :key="index")
      category-tree-node(
        :category="category",
        :node-select-evt="nodeSelectEvt",
        :selected-category="selectedCategory",
        :is-parent="true",
        :can-add="canAdd",
        :can-edit="canEdit",
        :can-delete="canDelete",
        @change="$emit('change', $event)",
        @edit-category="$emit('edit-category', $event)"
      )
</template>

<script>
import CategoryTreeNode from "./CategoryTreeNode.vue";
export default {
  components: {
    CategoryTreeNode,
  },
  props: {
    nodeSelectEvt: Function,
    categories: {
      type: Array,
      required: true,
    },
    selectedCategory: [Object, null],
    canAdd: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#category-tree {
  overflow: auto;
}
</style>
