<template lang="pug">
#paybill-expenses-balances.section
  .columns(data-attr="filters")
    .field-left(:class="{ 'field-auto': canUseNexusMiddleware }")
      multi-select(
        :options="typeOptions",
        select-label="",
        deselect-label="",
        :allow-empty="false",
        placeholder="Filter by type",
        title="Filter by type",
        v-model="expensesSection",
        v-if="canUseNexusMiddleware"
      )
    .field(v-if="canUseNexusMiddleware && expensesSection === 'General'")
      p.control
        input.input(
          @input="fetchPayBillExpensesReq",
          v-model="invoiceExternalId",
          placeholder="Search invoices by external ID",
          title="Search invoices by external ID"
        )
        i.fa.fa-search
    .field.right
      button.button.is-generic-app-teal(
        data-action,
        :disabled="closeModal",
        @click="onCreateInvoice(true)",
        v-if="(!canUseNexusMiddleware && $can('create-expenses-invoice')) || (!canUseNexusMiddleware && $can('create-expenses-invoices-for-all-clients'))"
      )
        img(src="../../assets/images/comps/Plus.svg")
        | CREATE INVOICES FOR ALL CLIENTS
  .inner-content
    template(v-if="expensesSection === 'General'")
      PayBillExpensesTable(:list="expensesInvoices", :loading="isLoadingTable")
      pagination(
        v-if="pagination",
        :total-pages="pagination.total_pages",
        :current-page="pagination.current_page",
        @page-changed="pageHasChanged"
      )
    template(v-else)
      expenses-billing-period
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
import PayBillExpensesTable from "@/components/PayBill/Expenses/PayBillExpensesTable.vue";
import ExpensesBillingPeriod from "@/components/PayBill/Expenses/ExpensesBillingPeriod.vue";
import Pagination from "../Pagination.vue";
import { mapActions } from "vuex";
import { parseErrors } from "../../lib/helpers/function";
import { Evt } from "@/lib/helpers/Evt.js";
import MultiSelect from "vue-multiselect";

export default {
  components: {
    PayBillExpensesTable,
    ExpensesBillingPeriod,
    Pagination,
    MultiSelect,
  },
  props: ["closeModal"],
  data() {
    return {
      expensesInvoices: [],
      typeOptions: ["General", "Billing period"],
      expensesSection: "General",
      isLoadingTable: true,
      isLoadingCreatingInvoice: false,
      invoiceExternalId: null,
      pagination: {
        total: 0,
        total_pages: 0,
        current_page: parseInt(this.$route.query?.page, 10) || 1,
      },
    };
  },
  mounted() {
    this.setSection();
    this.fetchPayBillExpensesReq({});
  },
  methods: {
    ...mapActions({
      getPayBillExpensesReq: "getPayBillExpensesReq",
      postPayBillExpensesReq: "postPayBillExpensesReq",
    }),
    async fetchPayBillExpensesReq({ pageNum = 1 }) {
      const params = {
        page: pageNum,
      };
      if (this.canUseNexusMiddleware && this.invoiceExternalId) {
        params.inv_externalid = this.invoiceExternalId;
      }
      try {
        const res = await this.getPayBillExpensesReq(params);
        this.expensesInvoices = res.data.data;
        if (res.data.meta?.pagination) {
          this.pagination = res.data.meta.pagination;
        }
        this.isLoadingTable = false;
      } catch (err) {
        console.warn(err.message);
        this.isLoadingTable = false;
      }
    },
    async onCreateInvoice() {
      const message =
        "generate a new invoice. This action will invoice all unprocessed requests.";

      try {
        await this.confirmApplyChanges(message);
        this.isLoadingCreatingInvoice = true;
        try {
          const params = {};
          const res = await this.postPayBillExpensesReq(params);
          console.warn(res.data);
          this.isLoadingCreatingInvoice = false;
          this.fetchPayBillExpensesReq({
            pageNum: this.pagination.current_page,
          });
        } catch (err) {
          console.error(err.message);
          this.isLoadingCreatingInvoice = false;
          const errs = parseErrors(err);
          if (errs) {
            this.$toasted.error(errs).goAway(1500);
          }
        }
      } catch (err) {
        // ignored
      }
    },
    setSection() {
      if (this.canUseNexusMiddleware) {
        // Change to second tab
        this.expensesSection = this.typeOptions[1];
      }
    },
    pageHasChanged(pageNum) {
      this.fetchPayBillExpensesReq({ pageNum });
    },
    confirmApplyChanges(name = ``) {
      return new Promise((resolve) => {
        const alert = {
          title: `Are you sure you want to ${name}`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
        setTimeout(() => {
          // Timeout because of the transition
          const el = this.$refs.simplert.$el;
          const btns = el.querySelectorAll("button");
          if (btns.length) {
            btns[0].focus();
          }
        }, 600);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#paybill-expenses-balances {
  .columns {
    padding: 10px 10px 0;

    .multiselect {
      width: 300px;
    }
  }

  > .inner-content {
    .multiselect {
      width: 300px;
    }
  }

  .right {
    margin-left: auto;
  }

  button {
    display: flex;
    gap: 10px;
  }

  .disabled {
    opacity: 0.5;
  }

  .field {
    display: flex;
    margin-bottom: 30px;
    gap: 10px;
    align-items: center;

    label {
      margin: 0 1em;
    }
  }

  .label {
    color: #7c91ae;
  }

  .control {
    i {
      top: 13px;
      position: absolute;
      right: 15px;
      font-size: 14px;
    }
  }

  .input {
    width: 300px;
    height: 40px;
  }

  .field-auto {
    margin-right: 10px;
  }
}
</style>
