/* eslint-disable no-unused-vars */
import api from "../../api/invoices";
import { Evt } from "../../lib/helpers/Evt.js";
import { parseErrors } from "@/lib/helpers/function.js";
import Vue from "vue";

const state = {
  invoices: [],
  invoicedShifts: [],
  billingDetails: [],
};

const getters = {
  getInvoices: (state) => state.invoices,
  getAllInvoicedShifts: (state) => state.invoicedShifts,
  getBillingDetails: (state) => state.billingDetails,
};

const mutations = {
  STORE_INVOICES(state, payload) {
    state.invoices = payload.data;
  },
  STORE_SHIFT_INVOICES(state, payload) {
    state.invoicedShifts = payload.data;
  },
  STORE_BILLING_DETAILS(state, payload) {
    state.billingDetails = payload.data;
  },
};

const actions = {
  fetchInvoices: ({ commit }, params = {}) => {
    Evt.fire("isLoadingInvoice", true);
    api.fetchInvoices(params).then((response) => {
      commit("STORE_INVOICES", response.data);
      commit("SET_PAGINATION", response.data, { root: true });
      Evt.fire("isLoadingInvoice", false);
    });
  },

  fetchBackup: async ({ commit }, invoice) => {
    try {
      await api.fetchBackup(invoice);
    } catch (err) {
      const errs = parseErrors(err);
      Vue.toasted.error(errs || "Error").goAway(4500);
    }
  },

  fetchPayAndBill: async ({ commit }, invoice) => {
    try {
      await api.fetchPayAndBill(invoice);
    } catch (err) {
      const errs = parseErrors(err);
      Vue.toasted.error(errs || "Error").goAway(4500);
    }
  },

  fetchPDF: async ({ commit }, invoice) => {
    try {
      await api.fetchPDF(invoice);
    } catch (err) {
      const errs = parseErrors(err);
      Vue.toasted.error(errs || "Error").goAway(4500);
    }
  },

  fetchShiftInvoices: ({ commit }, params = {}) => {
    Evt.fire("isLoadingShiftsInvoice", true);
    const pageNum = params.page;
    api.fetchShiftInvoices(pageNum).then((response) => {
      commit("STORE_SHIFT_INVOICES", response.data);
      commit("SET_PAGINATION", response.data, { root: true });
      Evt.fire("isLoadingShiftsInvoice", false);
    });
  },

  deleteInvoice: ({ commit, dispatch }, invoice) => {
    Evt.fire("isLoadingInvoice", true);
    return api
      .deleteInvoice(invoice)
      .then((response) => {
        Evt.fire("isLoadingInvoice", false);
        return Promise.resolve(true);
      })
      .catch((err) => {
        Evt.fire("isLoadingInvoice", false);
        return Promise.reject(err);
      });
  },

  fetchBillingDetails: ({ commit }, invoice) => {
    return api.fetchBillingDetails(invoice).then((response) => {
      commit("STORE_BILLING_DETAILS", response.data);
      return Promise.resolve();
    });
  },
  createInvoice: ({ commit }, client) => {
    return api
      .createInvoice(client)
      .then(() => {
        return Promise.resolve();
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  backupInvoicedShift: ({ commit }, data) => {
    api.backupInvoicedShift(data);
  },

  fetchLatestInvoices: ({ commit }) => {
    return api.fetchLatestInvoices();
  },

  backupInvoiceCSV: ({ commit }, params) => api.backupInvoiceCSV(params),
  backupInvoiceExcel: ({ commit }, params) => api.backupInvoiceExcel(params),
  backupInvoicePDF: ({ commit }, params) => api.backupInvoicePDF(params),
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
