<template>
  <div class="zap-slideout" v-bind:class="{ isOpen: isOpen }">
    <a class="nav-item nav-item--toggle zap-slideout-opener" @click="toggle">
      <button class="button is-medium toggle-button">☰</button>
    </a>
    <ul class="menu-list zap-slideout-menu">
      <!-- <li class="menu&#45;title nav&#45;item&#45;&#45;sep"> -->
      <!--   <img src="../assets/images/cpltabs.png" alt="CPL"></li> -->
      <li></li>

      <li v-if="$can('view-dashboard')" data-cell="dashboard">
        <router-link :to="{ name: 'dashboard' }">
          <i class="fa icon-Dashboard"></i>
          <span>Dashboard</span>
        </router-link>
      </li>

      <li v-if="$can('view-calendar')" data-cell="client-calendar">
        <router-link :to="{ name: 'calendar' }">
          <i class="fa icon-Calendar"></i>
          <span>Client Calendar</span>
        </router-link>
      </li>

      <li v-if="$can('view-temp-calendar')" data-cell="candidate-calendar">
        <router-link :to="{ name: 'temp-calendar' }">
          <i class="fa icon-Calendar"></i>
          <span v-if="$can('view-calendar')">Candidate Calendar</span>
          <span v-if="!$can('view-calendar')">Calendar</span>
        </router-link>
      </li>

      <li v-if="$can('create-shift-request')" data-cell="book-shifts">
        <router-link :to="{ name: 'submitShift' }">
          <i class="fa icon-Submit-shift"></i>
          <span>Book Shifts</span>
        </router-link>
      </li>

      <li v-if="$can('view-shifts')" data-cell="shift-schedule">
        <router-link :to="{ name: 'shiftSchedule' }">
          <i class="fa icon-Shift-Schedule"></i>
          <span>Shift Schedule</span>
        </router-link>
      </li>
      <li v-if="$can('view-temp-shifts')" data-cell="my-shifts">
        <router-link :to="{ name: 'shiftSchedule' }">
          <i class="fa icon-Shift-Schedule"></i>
          <span>My Shifts</span>
        </router-link>
      </li>

      <li v-if="$can('view-expenses')">
        <router-link :to="{ name: 'ExpensesPageAwaiting' }">
          <inline-svg :src="pageIcon" alt="" fill="white"></inline-svg>
          <span>Expenses</span>
        </router-link>
      </li>

      <li v-if="$can('manage-rates')" data-cell="rate-management">
        <router-link :to="{ name: 'ManageRates' }">
          <i class="fa icon-rate-manage"></i>
          <span>Rate management</span>
        </router-link>
      </li>

      <li
        v-if="$can('view-temps&view-documents-for-approval')"
        data-cell="compliance"
      >
        <router-link :to="{ name: 'compliance' }">
          <i class="fa icon-Compliance-management"></i>
          <span>Compliance</span>
        </router-link>
      </li>
      <li v-if="$can('view-temp-documents')" data-cell="my-documents">
        <router-link :to="{ name: 'temp-documents' }">
          <i class="fa icon-Compliance-management"></i>
          <span>My Documents</span>
        </router-link>
      </li>

      <li v-if="$can('view-documents')" data-cell="documents">
        <router-link :to="{ name: 'documents' }">
          <i class="fa icon-Compliance-documents"></i>
          <span>Documents</span>
        </router-link>
      </li>

      <li v-if="$can('view-all-candidates-page')" data-cell="all-candidates">
        <router-link
          :to="{ name: 'allCandidates', params: { resetKeepAlive: true } }"
        >
          <i class="fa icon-All-Candidates"></i>
          <span>All Candidates</span>
        </router-link>
      </li>

      <li v-if="$can('create-user')" data-cell="user-management">
        <router-link :to="{ name: 'clientUserManagement' }">
          <i class="fa icon-Client-user-management"></i>
          <span>User Management</span>
        </router-link>
      </li>

      <li
        v-if="$can('view-client-management-page')"
        data-cell="client-management"
      >
        <router-link
          :to="{
            name: 'locationClientManagement',
            params: { resetKeepAlive: true },
          }"
        >
          <i class="fa icon-Location-client-management"></i>
          <span>Client Management</span>
        </router-link>
      </li>

      <!-- <li v-if="$can('view-billing|delete-billing')" data-cell="pay-bill"> -->
      <li
        v-if="
          $can(
            'view-billing|view-holiday-requests-billing|view-expenses-billing|release-premium-holiday-pay'
          )
        "
        data-cell="pay-bill"
      >
        <router-link :to="{ name: 'InvoicesPage' }">
          <i class="fa icon-Billing"></i>
          <span>Pay & Bill</span>
        </router-link>
      </li>

      <li v-if="$can('view-report|schedule-report')" data-cell="reports">
        <router-link :to="{ name: 'reports' }">
          <i class="fa icon-Reports"></i>
          <span>Reports</span>
        </router-link>
      </li>

      <li v-if="$can('edit-profile')" data-cell="my-profile">
        <router-link :to="{ name: 'temp-profile' }">
          <i class="fa icon-Edit-profile"></i>
          <span>My Profile</span>
        </router-link>
      </li>

      <li v-if="$can('manage-security')">
        <router-link :to="{ name: 'securityPage' }">
          <i class="fa icon-Security"></i>
          <span>Settings</span>
        </router-link>
      </li>

      <li v-if="$can('bulk-upload-timesheet')">
        <router-link :to="{ name: 'fileUploaderPage' }">
          <i class="fa icon-file-cloud-upload"></i>
          <span>File uploader</span>
        </router-link>
      </li>

      <li v-if="$can('view-notificationcentre-messages')">
        <router-link :to="{ name: 'NotificationCentrePage' }">
          <i class="fa icon-envelope"></i>
          <span>Notification Centre</span>
        </router-link>
      </li>
      <li v-if="this.canUseNexusMiddleware && $can('view-backpay-page')">
        <router-link :to="{ name: 'Backpay' }">
          <!-- <i class="fa fa-calculator"></i> -->
          <inline-svg
            :src="correctionsImage"
            alt=""
            srcset=""
            class="corrections-sidebar-image"
          ></inline-svg>
          <span>Backpay</span>
        </router-link>
      </li>
      <li v-if="this.canUseNexusMiddleware && $can('view-nexus-module-page')">
        <router-link :to="{ name: 'Nexus' }">
          <i class="fa nexus-icon"></i>
          <span>Nexus</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { Evt } from "../lib/helpers/Evt.js";
import pageIcon from "@/assets/images/expensesIcon.svg";
import correctionsImage from "@/assets/images/comps/corrections-logo.svg";

export default {
  data: () => ({
    isOpen: false,
    pageIcon,
    correctionsImage,
  }),
  created() {
    Evt.listen("zap-opened", (isOpen) => {
      this.isOpen = isOpen;
    });
  },
  mounted() {
    this.toggle(); // Open sidebar by default!
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
      Evt.fire("zap-opened", this.isOpen);
    },
  },
  beforeDestroy() {
    this.isOpen = false;
    Evt.fire("zap-opened", this.isOpen);
  },
};
</script>
<style lang="scss">
/* Our Zap Slideout styles...  */
*,
:after,
:before {
  box-sizing: border-box !important;
}
.corrections-sidebar-image {
  width: 40px;
  height: 40px;
}
.menu-title img {
  max-width: 65px;
}
.menu-list li {
  text-align: center;
}
.menu-list li a {
  text-transform: uppercase;
  min-height: 85px !important;

  svg {
    width: 33px;
    height: 33px;

    path {
      fill: white;
    }

    &:hover {
      path {
        fill: var(--color-primary-90);
        transition: all 0.3s ease;
      }
    }
  }
  &.is-active {
    background-color: inherit;
    // color: var(--color-secondary-40);
    color: var(--color-primary-60);

    svg {
      path {
        fill: var(--color-primary-90);
      }
    }
  }
}
.menu-list li:nth-child(2) a {
  margin-top: 1.2em;
}

.main-content {
  margin-top: 5.5em;
  position: relative;
  background: white;
  transition: margin-left 0.3s;
  transition-delay: 0.04s;
  //transform: translateX(0);
  width: 100%;

  &.isOpen {
    margin-left: 130px;
  }

  > .container {
    .columns {
      // TODO Remove BULMA ASAP > Doesn't have flex for mobile view

      display: flex;
    }
  }

  &.modern-main-content {
    width: auto;
    flex: 1;
  }
}

.zap-slideout {
  position: fixed;
  width: 130px;
  height: 100vh;
  top: 0;
  z-index: 80;
  background-color: var(--color-secondary);
  transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transition: transform 0.3s;

  &.isOpen {
    transform: translateX(0);
    -ms-transform: translateX(0);
  }
}

.zap-slideout-opener {
  position: absolute;
  top: 20px;
  left: 100%;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: #fff;
  cursor: pointer;
}

.zap-slideout-menu {
  font-weight: 600;
  color: #fff;
  overflow-y: auto;
  height: 100%;
  .fa {
    font-size: 33px;
  }
  li a {
    font-size: 12px;

    span {
      font-weight: 600;
    }
  }
  li a:hover {
    background-color: inherit;
    color: var(--color-primary-90);
  }
}

.zap-slideout-menu-item,
.zap-slideout-menu-item--small {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  & + & {
    margin-top: 20px;
  }
}

.zap-slideout-menu-item {
  font-size: 36px;

  & + .zap-slideout-menu-item--small {
    margin-top: 30px;
  }
}

.zap-slideout-menu-item--small {
  font-size: 18px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: #ecf0f1;
}

/* The famed Zap agency logo (TM) */
.zap-emoji {
  height: 120px;
}

// .no-flexbox .zap-slideout-menu {
//   .fa {
//     display: block;
//     font-size: 33px;
//   }
// }
</style>
