<template lang="pug">
div
  table.table
    thead
      th
        span INVOICES ID
        //- i.fa.fa-long-arrow-up.fa-rotate-180
        //- i.fa.fa-long-arrow-up
      th
        span DATE
        //- i.fa.fa-long-arrow-up.fa-rotate-180
        //- i.fa.fa-long-arrow-up
      th
        span CLIENT
        //- i.fa.fa-long-arrow-up.fa-rotate-180
        //- i.fa.fa-long-arrow-up
      th
        span TOTAL HOURS
        //- i.fa.fa-long-arrow-up.fa-rotate-180
        //- i.fa.fa-long-arrow-up
      th
        span CREATED AT
        //- i.fa.fa-long-arrow-up.fa-rotate-180
        //- i.fa.fa-long-arrow-up
      th.is-narrow(
        v-if="permissions.canDownloadAllCsv || permissions.canDownloadSingleBackup"
      )
        span BACKUP EXCEL
      th.is-narrow(
        v-if="permissions.canDownloadAllExcel || permissions.canDownloadSingleExcel"
      )
        span EXCEL
      //- th.is-narrow
        span PDF
      th.is-narrow(v-if="permissions.canDeleteInvoice")
        span DELETE
    tbody(v-if="!loading")
      backup-all(
        :invoices="invoices",
        v-if="permissions.canDownloadAllCsv || permissions.canDownloadAllExcel"
      )
      tr(
        v-for="(data, index) in invoices",
        :class="[setGroupedClass(data, index)]"
      )
        td
          span # {{ data.number }}
        td
          span {{ data.name }}
        td
          span {{ data.clientName }}
        td
          span {{ data.totalHours }}
        td
          span {{ data.createdAt }}
        td.is-narrow(
          v-if="permissions.canDownloadAllCsv || permissions.canDownloadSingleBackup"
        )
          button.button.is-tiny.is-generic-app-blue(
            :title="getLastDownloadDate(data.lastBackup)",
            :disabled="disableDownload",
            v-if="permissions.canDownloadSingleBackup",
            @click="backup(data.id, data.clientName, data.clientCustomId)"
          ) BACKUP
        td.is-narrow(
          v-if="permissions.canDownloadAllExcel || permissions.canDownloadSingleExcel"
        )
          button.button.is-tiny.is-generic-app-blue(
            :title="getLastDownloadDate(data.lastPayAndBill)",
            :disabled="disableDownload",
            v-if="permissions.canDownloadSingleExcel",
            @click="payAndBill(data.id, data.clientName, data.clientCustomId)"
          ) PAY &amp; BILL
        //- td.is-narrow
          button.button.is-tiny.is-generic-app-blue(
            @click="getPDF(data.id, data.clientName, data.clientCustomId)",
            :title="getLastDownloadDate(data.lastInvoice)",
            :disabled="disableDownload"
          ) INVOICE
        td.is-narrow(v-if="permissions.canDeleteInvoice")
          button.button.is-tiny.is-outlined.is-generic-app-reject(
            @click="deleteInvoice(data)"
          ) DELETE
    tbody(v-if="loading")
      tr
        td.center-text(colspan="10")
          img(src="../../assets/images/comps/loader.svg")
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Evt } from "../../lib/helpers/Evt.js";
import moment from "moment";
import BackupAll from "./BackupAll.vue";

export default {
  components: {
    BackupAll,
  },
  props: ["loading"],
  data() {
    return {
      disableDownload: false,
    };
  },
  computed: {
    ...mapGetters({
      invoices: "invoices/getInvoices",
    }),
    permissions() {
      return {
        canDeleteInvoice: this.$can("delete-invoice"),
        canDownloadAllCsv: this.$can("invoice-download-all-csv"),
        canDownloadAllExcel: this.$can("invoice-download-all-excel"),
        canDownloadSingleBackup: this.$can("invoice-download-single-backup"),
        canDownloadSingleExcel: this.$can("invoice-download-single-excel"),
      };
    },
  },
  mounted() {
    Evt.listen("enableDownloadInvoices", () => {
      this.disableDownload = false;
    });
  },
  beforeDestroy() {
    Evt.off("enableDownloadInvoices");
  },
  methods: {
    ...mapActions({
      fetchBackup: "invoices/fetchBackup",
      fetchPayAndBill: "invoices/fetchPayAndBill",
      fetchPDF: "invoices/fetchPDF",
    }),
    backup(invoice, clientName, clientCustomId) {
      this.disableDownload = true;
      this.fetchBackup({ id: invoice, clientName, clientCustomId });
    },
    payAndBill(invoice, clientName, clientCustomId) {
      this.disableDownload = true;
      this.fetchPayAndBill({ id: invoice, clientName, clientCustomId });
    },
    getPDF(invoice, clientName, clientCustomId) {
      this.disableDownload = true;
      this.fetchPDF({ id: invoice, clientName, clientCustomId });
    },

    async deleteInvoice(invoice) {
      console.log(">> Invoice obj: ", invoice);
      const confirmDelete = () => {
        return new Promise((resolve) => {
          const alert = {
            title: `Are you sure you want to delete this invoice: "#${invoice.number}"?`,
            message: "",
            type: "warning",
            useConfirmBtn: true,
            customConfirmBtnText: "Confirm",
            customConfirmBtnClass: "button is-danger",
            customCloseBtnText: "Cancel",
            customCloseBtnClass: "button is-outlined",
            onConfirm: () => resolve(),
          };
          this.$refs.simplert.openSimplert(alert);
        });
      };
      await confirmDelete();
      Evt.fire("deleteInvoice", invoice.id);
    },

    getLastDownloadDate(dateObj) {
      if (!dateObj) {
        return "Not downloaded yet";
      }
      return (
        "Last downloaded at:\n" +
        moment(dateObj.date).format("DD/MM/YY HH:mm:ss")
      );
    },
    setGroupedClass(item, currIndex) {
      // v1.0 - Newer in FileListManagement
      const groupedDate = item.createdAt_timestamp?.date;
      const groupedId = item.clientId;
      const array = this.invoices;
      const totalLeng = array.length;

      const classSet = new Set();
      let hasPrepend = false;
      let hasPostpend = false;
      const startIndex = Math.max(currIndex - 1, 0);
      const endIndex = Math.min(currIndex + 2, totalLeng);

      for (let localIndex = startIndex; localIndex < endIndex; localIndex++) {
        if (localIndex === currIndex) {
          continue;
        }
        const currentRowEl = array[localIndex];
        const localTimestamp =
          currentRowEl.createdAt_timestamp &&
          currentRowEl.createdAt_timestamp.date;
        const localClientId = currentRowEl.clientId;

        if (groupedDate === localTimestamp && groupedId === localClientId) {
          classSet.add("grouped");
          if (currIndex < localIndex) {
            hasPrepend = true;
          } else if (currIndex > localIndex) {
            hasPostpend = true;
          }
        }
      }

      if (hasPrepend && !hasPostpend) {
        classSet.add("first");
      } else if (!hasPrepend && hasPostpend) {
        classSet.add("last");
      }

      return Array.from(classSet);
    },
  },
};
</script>
<style lang="scss" scoped>
.columns {
  padding-left: 10px;
}

tr {
  &.grouped {
    &:not(.first):not(.last) {
      td {
        border-top: none;
        border-bottom: none;
      }
    }

    &.first {
      td {
        border-bottom: none;
      }
    }

    &.last {
      td {
        border-top: none;
      }
    }
  }
}

td.is-narrow {
  width: 120px !important;
}

th.is-narrow {
  width: 120px !important;
}

$color--light-gray: #b7bbbd;
$color--dark-gray: #7c91ae;

th {
  span {
    margin-right: 5px;
    color: $color--dark-gray;
  }

  .fa {
    font-size: 15px;
    margin-top: 3px;
    color: $color--light-gray;
  }
}

td {
  .fa.fa-eye {
    font-size: 15px;
    position: relative;
    left: -45px;
  }

  button {
    span {
      position: relative;
      left: 15px;
    }
  }

  .fa.fa-file-text-o {
    font-size: 15px;
    position: relative;
    left: -61px;
  }
}
</style>
