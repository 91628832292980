<template lang="pug">
#category-form
  .name {{ labelCatNameAction }} category name
  input.input(v-model="newName", autofocus, ref="nameInput", type="text")
  button.button.is-generic-app-teal(
    :class="{ 'is-loading': isSavingForm }",
    :disabled="!newName || isSavingForm",
    @click="onApplyForm"
  ) Apply
  button.button.cancel(@click="cancelForm") Cancel
</template>

<script>
import { mapActions } from "vuex";
import { parseErrors } from "../../../../lib/helpers/function";
export default {
  props: {
    sector: [Object, null],
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newName: "",
      isSavingForm: false,
    };
  },
  computed: {
    category() {
      return this.formData.category;
    },
    labelCatNameAction() {
      return this.formData.command === "add" ? "Add" : "Change";
    },
  },
  mounted() {
    this.$refs.nameInput.focus();
    if (this.formData.command === "edit") {
      this.newName = this.category.name;
    }
  },
  methods: {
    ...mapActions({
      postCategory: "postCategory",
      patchCategory: "patchCategory",
    }),
    cancelForm() {
      this.$emit("cancel");
    },
    async onApplyForm() {
      this.isSavingForm = true;
      const params = {
        sct_id: this.sector.id,
        cat_name: this.newName,
      };

      try {
        if (this.formData.command === "edit") {
          params.catId = this.category.id;
          await this.patchCategory(params);
          this.$toasted.info("Category changed").goAway(2000);
          this.$emit("change");
        } else {
          await this.postCategory(params);
          this.$toasted.info("New category created").goAway(2000);
          this.$emit("change");
        }
        this.isSavingForm = false;
      } catch (err) {
        console.log(err.message);
        this.isSavingForm = false;
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
      console.log("apply", params);
    },
  },
};
</script>

<style lang="scss" scoped>
#category-form {
  .name {
    margin: 0 0 20px 0;
  }

  .cat-hidden {
    margin: 10px 0;
    display: flex;
    align-items: center;

    .lbl {
      margin-right: 10px;
    }
  }

  .button {
    margin: 10px 0 0 0;

    &.cancel {
      margin-left: 10px;
    }
  }
}
</style>
