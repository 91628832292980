<template lang="pug">
div
  .before-wrapper 
    .message {{ Object.values(beforeMessages)[messsageNumber] }}
    .button(v-if="messsageNumber == 0")
      button.button.is-generic-app-blue(
        @click="recalc",
        :disabled="disableButton"
      ) Yes
      button.button.is-generic-app-blue(
        :style="{ color: '#FFFFFF', border: 'none', cursor: 'pointer' }",
        @click="changeMessage"
      ) No
    .button(v-if="messsageNumber == 1")
      button.button.is-generic-app-blue(@click="closeModal") OK
</template>
<script>
import { parseErrors } from "../../../lib/helpers/function.js";
import { mapActions } from "vuex";
export default {
  props: ["modalProps"],
  data() {
    return {
      beforeMessages: {},
      messsageNumber: 0,
      disableButton: false,
    };
  },
  mounted() {
    console.log(this.modalProps);
    this.beforeMessages = this.modalProps.beforeApiData.checkRecalculateData;
  },
  methods: {
    ...mapActions(["clientRecalculateShift"]),
    closeModal() {
      this.$emit("dispose-modal");
    },
    changeMessage() {
      this.messsageNumber++;
    },
    async recalc() {
      this.disableButton = true;
      const params = {
        ...this.modalProps.params,
      };

      try {
        await this.clientRecalculateShift(params);
        this.$toasted.success("Shift recalculated").goAway(2000);
        this.closeModal();
        this.disableButton = false;
      } catch (error) {
        this.closeModal();
        this.disableButton = false;
        const errorMsg = parseErrors(error);
        this.$toasted.error(errorMsg).goAway(2000);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.before-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 10px 0;
  .message {
    background-color: white;
    text-align: center;
  }
  .button {
    border: none;
    margin: 0 5px 0px 5px;
    button {
      width: 60px;
      height: 2.75em;
      border: none;
    }
  }
}
</style>
