export const GENDER_MAP = [
  { key: "M", label: "Male" },
  { key: "F", label: "Female" },
  { key: "O", label: "Other" },
  { key: "U", label: "Prefer not to say" },
  { key: "N", label: "Non-Binary" },
];

export const SHIFT_TYPES = {
  normal: {
    key: "NRML",
    label: "Normal",
  },
  oncall: {
    key: "ONCL",
    label: "On call",
  },
  sleepover: {
    key: "SLPO",
    label: "Sleepover",
  },
  awaken: {
    key: "AWKE",
    label: "Awaken",
  },
};

export const SHIFT_REQUEST_TYPES = {
  TEMPS_NOT_SELECTED_PUSH_SHIFT_REQUEST_TYPE: 1,
  TIMESHEET_ENTRY_TYPE: 2,
  TEMPS_SELECTED_PUSH_SHIFT_REQUEST_TYPE: 3,
  BULK_UPLOADED_TIMESHEET_SHIFT_REQUEST_TYPE: 4,
  BULK_UPLOADED_OPEN_SHIFT_REQUEST_TYPE: 5,
  TEMP_ASSIGNED_SHIFT_REQUEST_TYPE: 6,
};
