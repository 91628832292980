<template lang="pug">
.dialog-container.cancel-reason-modal
  .inputs
    .field
      label.label Please Select Cancellation Reason
      p.control
        multi-select(
          :options="cancellationReasons",
          :close-on-select="true",
          :searchable="true",
          :loading="isLoading",
          track-by="id",
          label="reason",
          v-model="selectedCancellationReason",
          placeholder="CANCELLATION REASON"
        )
          span(slot="noResult") Nothing found.
        span.help.is-danger(
          v-if="errors.has('shift_cancellation_reason_id')",
          v-html="errors.get('shift_cancellation_reason_id')"
        ) 
  .buttons-centered
    button.button.is-small.is-generic-app-blue.is-caps-lock(
      :disabled="!selectedCancellationReason || isSaveLoading",
      @click.prevent="cancelIt"
    ) Submit
    button.cancel-btn.button.is-small.is-outlined.is-caps-lock(
      @click.prevent="callModalClose"
    ) Cancel
</template>

<script>
import MultiSelect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";
import { Errors } from "../../lib/helpers/Errors.js";

export default {
  name: "CancellationReason",
  props: ["modalProps", "isSaveLoading"],
  data() {
    return {
      selectedCancellationReason: null,
      optionsCancellationReasons: [],
      shiftIds: null, // will be holding an array of shifts to be cancelled
      shiftObjs: null,
      isLoading: false,
      multipleCancelation: false,
      showMultipleCancelModal: false,
      errors: new Errors(),
    };
  },
  mounted() {
    this.shiftIds = this.modalProps.shiftIds;
    if (this.modalProps?.shiftObjs) {
      this.shiftObjs = this.modalProps?.shiftObjs;
      this.showMultipleCancelModal = true;
    }
    this.multipleCancelation = this.modalProps.multipleCancelation;
    this.fetchCancellationReasons();
    this.optionsCancellationReasons = this.cancellationReasons;
  },
  computed: {
    ...mapGetters({
      cancellationReasons: "getCancellationReasons",
    }),
  },
  methods: {
    ...mapActions(["fetchCancellationReasons"]),
    cancelIt() {
      /**
       * Send context along with event data,
       * so that we can receive any error that might occur.
       * This methods fires an event in both cases, when cancelling single shift
       * as well when cancelling multiple shifts using CANCELL ALL button below the table
       *
       * CANCELL ALL button appears only when using select checkbox.
       */
      this.$emit("evtCancelShift", {
        data: {
          shifts: this.shiftIds,
          shiftObjs: this.shiftObjs,
          shift_cancellation_reason_id: this.selectedCancellationReason.id,
          successfullMessage: this.modalProps.successfullMessage,
          multiple_cancelation: this.multipleCancelation,
        },
        context: this,
        showMultipleCancelModal: this.showMultipleCancelModal,
      });
    },
    callModalClose() {
      this.$emit("dispose-modal");
    },
  },
  components: { MultiSelect },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  width: 100%;
}

.inputs {
  display: flex;
  justify-content: center;

  .field {
    width: 100%;
  }
}

.buttons-centered {
  display: flex;
  justify-content: center;
  align-items: center;

  button.button {
    margin: 17px 0.3em;
    min-width: 90px;
    height: 30px;
  }
}
</style>
