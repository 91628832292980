<template lang="pug">
section.section.is-special
  .container.is-fluid(
    v-show="!isSectorsPage && !isIncrementsPage && !isRelativesPage"
  )
    .columns
      .column.is-mobile
        .register-temp-modal(
          autocomplete="off",
          @submit.prevent="register",
          @keydown="errors.clear($event.target.name)",
          v-if="!form.success"
        )
          p.control
            //- input.is-medium.input.is-medium(
            //-   type="text",
            //-   name="name",
            //-   :class="{ 'is-danger': errors.get('name') }",
            //-   v-model="form.name",
            //-   placeholder="Name"
            //- )
            //- span.help.is-danger(
            //-   data-cell="name-error",
            //-   v-if="errors.has('name')",
            //-   v-html="errors.get('name')"
            //- )
            input.is-medium.input.is-medium(
              type="text",
              name="first_name",
              :class="{ 'is-danger': errors.get('first_name') }",
              v-model="form.first_name",
              placeholder="First name",
              :disabled="disabledFilds.includes('first_name')"
            )
            span.help.is-danger(
              data-cell="first_name-error",
              v-if="errors.has('first_name')",
              v-html="errors.get('first_name')"
            )
          p.control
            input.is-medium.input.is-medium(
              type="text",
              name="last_name",
              :class="{ 'is-danger': errors.get('last_name') }",
              v-model="form.last_name",
              placeholder="Last name",
              :disabled="disabledFilds.includes('last_name')"
            )
            span.help.is-danger(
              data-cell="last_name-error",
              v-if="errors.has('last_name')",
              v-html="errors.get('last_name')"
            )
          p.control.nickname-wrap
            span.sub.nickname-group(data-cell="nickname")
              input.is-medium.input.is-medium(
                type="text",
                name="nickname",
                :class="{ 'is-danger': errors.get('nickname') }",
                v-model="form.nickname",
                placeholder="Known as",
                :disabled="disabledFilds.includes('nickname')"
              )
              span.help.is-danger(
                data-cell="nickname-error",
                v-if="errors.has('nickname')",
                v-html="errors.get('nickname')"
              )
            span.sub.contract-start(
              v-if="gPermissions.canViewTempContractedHours",
              data-cell="contract-start-date"
            )
              .action
                uni-date-picker(
                  v-model="form.contract_start_date",
                  placeholder="START DATE",
                  :propConfig="contractStartDateConfig",
                  :is-disabled="isDisabledNexusStartDate"
                )
              span.help.is-danger(
                data-cell="contract_start_date-error",
                v-if="errors.has('contract_start_date')",
                v-html="errors.get('contract_start_date')"
              )

          p.control.tss-id(data-cell="tss-id")
            input.input.is-medium(
              v-if="!canUseNexusMiddleware",
              type="text",
              name="tss",
              placeholder="External system id number",
              v-model="form.tss_id",
              @keydown.enter="fetchDataFromTss",
              :disabled="disabledFilds.includes('tss_id')"
            )
            input.input.is-medium(
              v-if="canUseNexusMiddleware",
              type="text",
              name="external",
              placeholder="Worker Reference ID",
              v-model="form.external_id",
              @keydown.enter="fetchDataFromTss",
              :disabled="disabledFilds.includes('external_id')"
            )
            button.button.is-info(
              data-cell="tss-id-btn",
              :class="[{ 'is-loading': isTssLoading }, { 'is-disabled': isTssInvalid }]",
              @click.prevent="fetchDataFromTss"
            ) Pull From ext. system

          p.control
            input.input.is-medium(
              v-model="form.date_of_birth",
              :class="{ 'is-danger': errors.get('date_of_birth') }",
              name="date_of_birth",
              v-facade="'##/##/####'",
              type="text",
              placeholder="Date of birth",
              :disabled="disabledFilds.includes('date_of_birth')"
            )
            span.help.is-danger(
              data-cell="date-of-birth-error",
              v-if="errors.has('date_of_birth')",
              v-html="errors.get('date_of_birth')"
            )

          p.control
            input.input.is-medium(
              type="text",
              name="home_address",
              :class="{ 'is-danger': errors.get('home_address') }",
              v-model="form.home_address",
              placeholder="Home Address Line 1",
              :disabled="disabledFilds.includes('home_address')"
            )
            span.help.is-danger(
              data-cell="home-address-error",
              v-if="errors.has('home_address')",
              v-html="errors.get('home_address')"
            )

          p.control
            input.input.is-medium(
              type="text",
              :class="{ 'is-danger': errors.get('home_address_2') }",
              v-model="form.home_address_2",
              placeholder="Home Address Line 2",
              :disabled="disabledFilds.includes('home_address_2')"
            )
            span.help.is-danger(
              v-if="errors.has('home_address_2')",
              v-html="errors.get('home_address_2')"
            )

          p.control
            input.input.is-medium(
              type="text",
              :class="{ 'is-danger': errors.get('home_address_3') }",
              v-model="form.home_address_3",
              placeholder="Home address Line 3",
              :disabled="disabledFilds.includes('home_address_3')"
            )
            span.help.is-danger(
              v-if="errors.has('home_address_3')",
              v-html="errors.get('home_address')"
            )
          p.control
            multi-select(
              :options="optionsCounties",
              :multiple="false",
              :allow-empty="false",
              :close-on-select="true",
              track-by="id",
              label="name",
              v-model="form.selectedRegion",
              placeholder="Choose county",
              @select="errors.clear('region_id')",
              :disabled="disabledFilds.includes('region_id')"
            )
            span.help.is-danger(
              data-cell="counties-error",
              v-if="errors.has('region_id')",
              v-html="errors.get('region_id')"
            )
          .control(v-if="$can('view-client-assigned-report')")
            multi-select(
              :options="optionsCounties",
              :multiple="false",
              :allow-empty="false",
              :close-on-select="true",
              track-by="id",
              label="name",
              v-model="form.selectedPreferredRegions",
              placeholder="Choose preferred county",
              @select="errors.clear('preferred_regions')",
              :disabled="disabledFilds.includes('preferred_regions')"
            )
            span.help.is-danger(
              v-if="errors.has('preferred_regions')",
              v-html="errors.get('preferred_regions')"
            )
          p.control
            input.input.is-medium(
              type="text",
              v-model="form.eircode",
              :placeholder="getEirCodeLabelKey",
              :disabled="disabledFilds.includes('eircode')"
            )
          p.control(
            data-cell="country_register_temp",
            v-if="canUseNexusMiddleware"
          )
            multi-select(
              @search-change="asyncFindCountry",
              :options="filteredCountrys",
              track-by="id",
              label="name",
              placeholder="Search Nationality",
              v-model="form.selectedCountry",
              :loading="isLoadingCountry",
              :multiple="false",
              :showLabels="false",
              :disabled="disabledFilds.includes('nationality_name')"
            )
            span.help.is-danger(
              data-cell="country-register-temp-error",
              v-if="errors.has('nationality_id')",
              v-html="errors.get('nationality_id')"
            )
          p.control
            input.input.is-medium(
              type="text",
              name="mobile_phone",
              :class="{ 'is-danger': errors.get('mobile_phone') }",
              v-model="form.mobile_phone",
              placeholder="Mobile number",
              :disabled="disabledFilds.includes('mobile_phone')"
            )
            span.help.is-danger(
              data-cell="mobile-error",
              v-if="errors.has('mobile_phone')",
              v-html="errors.get('mobile_phone')"
            )
          p.control
            input.input.is-medium(
              type="text",
              v-model="form.home_phone",
              placeholder="Phone number",
              :disabled="disabledFilds.includes('home_phone')"
            )
            span.help.is-danger(
              v-if="errors.has('home_phone')",
              v-html="errors.get('home_phone')"
            )

          p.control
            input.is-medium.input.is-medium(
              type="email",
              name="email",
              :class="{ 'is-danger': errors.get('email') }",
              placeholder="Email",
              v-model="form.email",
              :disabled="disabledFilds.includes('email')"
            )
            span.help.is-danger(
              data-cell="email-error",
              v-if="errors.has('email')",
              v-html="errors.get('email')"
            )
          p.control
            multi-select(
              :options="genderOptions",
              :close-on-select="true",
              track-by="key",
              label="label",
              select-label="",
              selected-label="",
              deselect-label="",
              :value="getSelectedGender",
              placeholder="Select gender",
              @select="onSelectGender",
              :disabled="disabledFilds.includes('gender')"
            )
            span.help.is-danger(
              v-if="errors.has('gender')",
              v-html="errors.get('gender')"
            )
          .control(v-if="$can('manage-temp-tags')")
            multi-select(
              :options="allTags",
              :close-on-select="false",
              multiple,
              track-by="id",
              label="name",
              select-label="",
              selected-label="",
              deselect-label="",
              :value="form.tags",
              placeholder="Select tag",
              @select="onSelectTag",
              @remove="onRemoveTag",
              :disabled="disabledFilds.includes('tags')"
            )
            span.help.is-danger(
              v-if="errors.has('tags')",
              v-html="errors.get('tags')"
            )
          p.control
            input.is-medium.input.is-medium(
              type="number",
              placeholder="Contracted Hours",
              v-model="form.contractHours",
              :disabled="disabledFilds.includes('contract_hours')"
            )
            //- :class="{ 'is-danger': errors.get('email') }",
            span.help.is-danger(
              v-if="errors.has('contract_hours')",
              v-html="errors.get('contract_hours')"
            )
          p.control(v-if="!canUseNexusMiddleware")
            date-picker.is-medium.is-medium.special-wide(
              :date="form.contractStartDate",
              :option="dateTimeOption",
              v-if="dateTimeOption !== null",
              :is-disabled="disabledFilds.includes('contract_start_date')"
            )
            span.help.is-danger(
              v-if="errors.has('contract_start')",
              v-html="errors.get('contract_start')"
            )
          .control(v-if="gPermissions.canRelativeView")
            label.label Next of kin:
            template(v-for="relativePerson in form.relatives")
              .blue-button-action(@click="onAddNewRelative(relativePerson)")
                span.label(:title="relativePerson.name") {{ (relativePerson.name || "").slice(0, 50) }}
            .blue-button-action(
              @click="onAddNewRelative",
              v-if="!form.relatives || form.relatives.length === 0"
            )
              span.label Add new
          //- p.control
          //-   multi-select(
          //-       :options='optionsLocations',
          //-       :multiple="true",
          //-       :close-on-select="false",
          //-       track-by="id",
          //-       label="name",
          //-       group-label="client",
          //-       group-values="subs",
          //-       :group-select="true",
          //-       :maxHeight='250'
          //-       v-model="form.selectedLocations",
          //-       placeholder="Choose from wards",
          //-       @select="errors.clear('locations')"
          //-       )
          //-   span.help.is-danger(v-if="errors.has('locations')", v-html="errors.get('locations')")

          p.control
            button#btn-cost-centre.button.is-info.is-fullwidth(
              :disabled="isLoadingSectors",
              :class="{ 'is-loading': isLoadingSectors }",
              @click.prevent="openLocation"
            ) Choose from wards
            span.help.is-danger(
              data-cell="locations-error",
              v-if="errors.has('locations')",
              v-html="errors.get('locations')"
            )
          p.control
            multi-select(
              :options="optionsSubcategories",
              :multiple="true",
              :close-on-select="false",
              track-by="id",
              label="name",
              group-label="category",
              group-values="subs",
              :group-select="true",
              :optionHeight="40",
              v-model="form.selectedSubcategories",
              placeholder="Choose from subcategories",
              @select="onSelectSubcategories",
              @remove="onRemoveSubcategories",
              :disabled="disabledFilds.includes('subcategories')"
            )
            span.help.is-danger(
              data-cell="subcategories-error",
              v-if="errors.has('subcategories')",
              v-html="errors.get('subcategories')"
            )

          .control(v-if="form.selectedSubcategories.length")
            label.label Increment point # / Subcategories:
            template(v-for="sub in form.selectedSubcategories")
              .blue-button-action(@click="showIncrementsPage(sub)")
                span.label {{ sub.name }}
                //- span.inc {{ sub.name }}
            span.help.is-danger(
              v-if="errors.has('increments')",
              v-html="errors.get('increments')"
            )
          //- p.control
          //-   multi-select(
          //-     :options="optionsCounties",
          //-     :multiple="false",
          //-     :allow-empty="false",
          //-     :close-on-select="true",
          //-     track-by="id",
          //-     label="name",
          //-     v-model="form.selectedRegion",
          //-     placeholder="Choose county",
          //-     @select="errors.clear('region_id')"
          //-   )
          //-   span.help.is-danger(
          //-     data-cell="counties-error",
          //-     v-if="errors.has('region_id')",
          //-     v-html="errors.get('region_id')"
          //-   )

          .control(
            style="display: flex; justify-content: space-between",
            v-if="hasAllowanceAgency"
          )
            label.label Community allowance
            span.rectangle.group
              switches(
                v-model="form.has_community_allowance",
                theme="bulma",
                color="blue",
                :emitOnMount="false"
              )
                //- @input="toggleClientReport"

          //- p.control
          //-   input.input.is-medium(
          //-     type="text",
          //-     v-model="form.eircode",
          //-     :placeholder="getEirCodeLabelKey"
          //-   )

          gnib-doc-selection(
            :errors="errors",
            :form="form",
            :selectedGnibDocId="selectedGnibDocId",
            :selectedGnibMaxWeekValue="selectedGnibMaxWeekValue",
            :isModifiedGnibDocs="isModifiedGnibDocs",
            @select-gnib-doc="onSelectGnibDoc",
            @remove-gnib-doc="onRemoveGnibDoc",
            @input-gnib-hours="onInputGnib",
            v-if="(gPermissions.canEditTemp || gPermissions.canViewTempDetails) && hasGnibAgency"
          )

          p.control(
            style="display: flex; justify-content: space-between; margin: 20px 0 8px"
          )
            //- v-if="$can('can_create_own_shift')"
            label.label Self submission of shifts
            span.rectangle.group
              switches(
                v-model="form.can_create_own_shift",
                theme="bulma",
                color="blue",
                :emitOnMount="false"
              )
          p.control(
            style="display: flex; justify-content: space-between; margin: 20px 0 8px",
            v-if="canUseOnCallShifts"
          )
            label.label Standby / On call shifts
            span.rectangle.group
              switches(
                v-model="form.can_create_oncall_shift",
                theme="bulma",
                color="blue",
                :emitOnMount="false"
              )
          p.control(
            style="display: flex; justify-content: space-between; margin: 20px 0 8px",
            v-if="canUseSleepoverShifts"
          )
            label.label Sleepover shifts
            span.rectangle.group
              switches(
                v-model="form.can_create_sleepover_shift",
                theme="bulma",
                color="blue",
                :emitOnMount="false"
              )
          p.control(
            style="display: flex; justify-content: space-between; margin: 20px 0 8px",
            v-if="canUseExpenses"
          )
            label.label Expenses
            span.rectangle.group
              switches(
                v-model="form.can_create_expenses",
                theme="bulma",
                color="blue",
                :emitOnMount="false"
              )
          p.control(style="display: flex; justify-content: space-between")
            label.label Auto generate password and Send Email
            span.rectangle.group
              switches(
                v-model="form.autogenerate_password",
                theme="bulma",
                color="blue",
                :emitOnMount="false"
              )
          template(v-if="!form.autogenerate_password")
            p.control
              label.label Enter new password
              input.input.is-medium(
                type="password",
                :class="{ 'is-danger': errors.get('password') }",
                name="password",
                v-model="form.password",
                placeholder="Password"
              )
            PasswordPolicy(
              :passwordCharacters="form.password",
              @password-valid="handlePasswordIsValid"
            )
            p.control
              input.input.is-medium(
                type="password",
                name="passwordConfirm",
                :class="{ 'is-danger': errors.get('password') }",
                v-model="form.password_confirmation",
                placeholder="Re-enter password"
              )
              span.help.is-danger(
                v-if="errors.has('password')",
                v-html="errors.get('password')"
              )

          hr
          p(
            :class="{ showMessagePasswordConfirmation: isShowMessagePasswordConfirmation, hideMessagePasswordConfirmation: isHideMessagePasswordConfirmation }"
          ) {{ passwordConformationMessage }}
          p.control
            button#btn-register.button.is-info.is-fullwidth(
              data-cell="submit-button",
              @click.prevent="register",
              :class="{ 'is-loading': isSaveLoading }",
              :disabled="(disableConfirm && isPasswordGeneratedDisabled) || isSaveLoading"
            )
              span(v-if="form.autogenerate_password") Generate password And Send Email
              span(v-if="!form.autogenerate_password") Create worker

  .container.is-fluid(v-show="isSectorsPage", v-if="firstLoadCentres")
    location-pref-dialog(
      :manageAction="true",
      :modalProps="locationData",
      :initialSectors="initialSectors",
      @cost-centre-check-change="locationCheckChanged",
      @temp-pref="tempPrefChanged",
      @reason-change="locationReasonChange"
    )
    button.back.button.is-info(@click="isSectorsPage = false") Back

  .container.is-fluid(v-if="isIncrementsPage")
    register-temp-increments(
      :modalProps="incrementData",
      @confirm-increments="onChangeIncrementsCat",
      @cancel-form="isIncrementsPage = false"
    )

  .container.is-fluid(v-if="isRelativesPage")
    manage-next-of-kin(
      :modalProps="relativesData",
      @save-next-of-kin="onSaveNextOfKin",
      @cancel-modal="isRelativesPage = false"
    )
</template>
<script>
import { Errors } from "../../../lib/helpers/Errors.js";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  getFormattedTime,
  parseErrors,
} from "../../../lib/helpers/function.js";
import LocationPrefDialog from "./LocationPrefDialog.vue";

import MultiSelect from "vue-multiselect";
import RegisterTempIncrements from "./RegisterTempIncrements.vue";
import ManageNextOfKin from "./ManageNextOfKin.vue";
import _ from "underscore";
import moment from "moment";
import { GENDER_MAP } from "../../../shared/mapData.js";
import calendarOptions from "../../common/calendarOptions.js";
import GnibDocSelection from "@/components/shared/GnibDocSelection.vue";
import PasswordPolicy from "@/components/forms/PasswordPolicyValidation.vue";

function getInitialDataState() {
  return {
    filteredCountrys: [
      // { id: 45, name: "Serbia" },
    ],
    isLoadingCountry: false,
    passwordConformationMessage: `The "Create worker" button will become available once the password criteria is met and the identical password is entered in both fields.`,
    isHideMessagePasswordConfirmation: true,
    form: {
      // name: null,
      first_name: null,
      last_name: null,
      nickname: null,
      contract_start_date: null,
      email: null,
      date_of_birth: null,
      home_address: null,
      home_address_2: null,
      home_address_3: null,
      selectedCountry: null,
      eircode: null,
      tss_id: null,
      external_id: null,
      mobile_phone: null,
      home_phone: null,
      has_community_allowance: false,
      // password: null,
      // password_confirmation: null,
      tac: false,
      autogenerate_password: true, // Auto generate password
      gender: null,
      tags: [],
      relatives: [],

      selectedSubcategories: [],
      selectedLocations: [],
      selectedRegion: null,
      selectedPreferredRegions: null,
      success: false,

      contractStartDate: {},
      contractHours: null,
      can_create_own_shift: false,
      can_create_oncall_shift: false,
      can_create_sleepover_shift: false,
      can_create_expenses: false,
      password_is_valid: false,
    },
    dateTimeOption: null,
    genderOptions: GENDER_MAP,
    allTags: [],
    errors: new Errors(),
    locationData: {
      // sectors: [],
      tempId: null,
    },
    usedSubcategoryIds: [], // This is not used for DB
    incrementData: {},
    selectedIncrementsArr: [],
    relativesData: {},
    firstLoadCentres: false,
    isTssLoading: false,
    isSaveLoading: false,
    isSectorsPage: false,
    isIncrementsPage: false,
    isRelativesPage: false,
    isLoadingSectors: true,
    selectedIncrementWeeks: [],
    selectedGnibDocId: null,
    selectedGnibMaxWeekValue: null,
    isModifiedGnibDocs: false,
    isPasswordGeneratedDisabled: true,
    disabledFilds: [],
  };
}

export default {
  name: "register-new-temp",
  components: {
    MultiSelect,
    LocationPrefDialog,
    RegisterTempIncrements,
    ManageNextOfKin,
    GnibDocSelection,
    PasswordPolicy,
  },
  data() {
    return getInitialDataState();
  },
  computed: {
    ...mapState({
      initialStatus: (state) => state.initialStatus,
    }),
    ...mapGetters({
      /**
       * Options for vue-multiselect, Categories -> Subcategories groupings.
       */
      optionsSubcategories: "getCategoriesWithSubcategories",
      optionsLocations: "getClientsWithLocations",
      /**
       * Options for vue-multiselect
       */
      optionsCounties: "reports/getCounties",
      sectors: "locationClientManagement/getFlatSectors",
    }),
    contractStartDateConfig() {
      return {
        altFormat: "d/m/Y",
        dateFormat: "Y-m-d",
        enableTime: false,
      };
    },
    isDisabledNexusStartDate() {
      const isDisabledKey = this.disabledFilds.includes("contract_start_date");
      const hasPermEdit = this.gPermissions.canEditContractedHours;
      return isDisabledKey || !hasPermEdit;
    },
    disableConfirm() {
      if (!this.form.password) {
        return false;
      }
      return !(
        this.form.password_is_valid &&
        this.form.password_confirmation === this.form.password
      );
    },
    isShowMessagePasswordConfirmation() {
      if (!this.form.password) {
        return false;
      }
      return !(
        this.form.password_is_valid &&
        this.form.password_confirmation === this.form.password
      );
    },
    autoGeneratePassOrNot() {
      return this.form.autogenerate_password;
    },
    hasAllowanceAgency() {
      return this.initialStatus.can_view_allowances;
    },
    hasGnibAgency() {
      return this.initialStatus.show_gnib_rules;
    },
    /**
     * Date of Birth in server format
     */
    getEirCodeLabelKey() {
      return this.$store.getters.getColumnNameLocl("usr_eircode");
    },
    getSelectedGender() {
      if (!this.form.gender) {
        return null;
      }
      return this.genderOptions.find((go) => go.key === this.form.gender);
    },
    dobServer() {
      return getFormattedTime(
        this.form.date_of_birth,
        "YYYY-MM-DD",
        "DD/MM/YYYY"
      );
    },
    replaceValue() {
      return this.canUseNexusMiddleware
        ? this.form.external_id
        : this.form.tss_id;
    },
    isTssInvalid() {
      return this.canUseNexusMiddleware
        ? !this.form.external_id || this.form.external_id.trim().length === 0
        : !this.form.tss_id || this.form.tss_id.trim().length === 0;
    },
    initialSectors() {
      return this.getCheckedCCs(this.sectors);
    },
  },
  watch: {
    isIncrementsPage(val) {
      if (!val) {
        // Reset modal data
        this.incrementData = {};
      }
    },
    autoGeneratePassOrNot(val) {
      if (val) {
        this.isPasswordGeneratedDisabled = false;
        this.form = _.omit(this.form, "password", "password_confirmation");
      } else {
        this.isPasswordGeneratedDisabled = true;
      }
    },
    // form.autogenerate_password
    // isAutoGenerate(val) {
    //   if (val) {
    //     // omit
    //     // enable button
    //     val = this.form.autogenerate_password;
    //   }
    //   return
    // },
  },
  mounted() {
    if (this.canUseNexusMiddleware) {
      this.getExternalDisabledFilds();
    }
    this.isLoadingSectors = true;
    this.fetchAllCategoriesList();

    this.fetchRegions().then(async () => {
      await this.waitForDataArrToLoad("sectors");
      this.setSectors();
      this.isLoadingSectors = false;
    });
    this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
    this.dateTimeOption.type = "day";
    this.dateTimeOption.format = "DD/MM/YYYY";
    this.dateTimeOption.placeholder = "Contracted Start Date";

    if (this.$can("manage-temp-tags")) {
      this.getTempTags()
        .then((res) => {
          this.allTags = res.data.data;
        })
        .catch((err) => {
          console.warn(err);
        });
    }
    this.getCountriesFunc();
  },
  methods: {
    ...mapActions({
      fetchAllCategoriesList: "fetchAllCategoriesList",
      fetchRegions: "reports/fetchRegions",
      registerTemp: "clientUserManagement/registerTemp",
      fetchTempInfoFromTss: "fetchTempInfoFromTss",
      getTempTags: "getTempTags",
      getCountries: "getCountries",
      getExternalServiceDisabledFields: "getExternalServiceDisabledFields",
    }),
    async getExternalDisabledFilds() {
      try {
        const res = await this.getExternalServiceDisabledFields();
        console.log(res.data);
        this.disabledFilds = res.data?.data ? res.data.data : res.data;
      } catch (error) {
        const errs = parseErrors(error);
        this.$toasted.error(errs).goAway(1500);
      }
    },
    async getCountriesFunc() {
      const params = {};
      const res = await this.getCountries(params);
      this.filteredCountrys = res.data.data;
    },
    asyncFindCountry: _.debounce(async function (query) {
      if (query === "") return;
      this.isLoadingCountry = true;
      try {
        await this.getCountries({ nationality_name: query });
      } catch (error) {
        const errs = parseErrors(error);
        this.$toasted.error(errs).goAway(1500);
      }
      this.isLoadingCountry = false;
    }, 400),
    handlePasswordIsValid(data) {
      this.form.password_is_valid = data.isPasswordValid;
    },
    async waitForDataArrToLoad(variableToCheckPaths, cancelTokenFuncCheck) {
      // Generic reusable func
      function deepFind(obj, path) {
        const paths = path.split(".");
        let current = obj;

        for (let i = 0; i < paths.length; ++i) {
          if (current[paths[i]] == undefined) {
            return undefined;
          } else {
            current = current[paths[i]];
          }
        }
        return current;
      }

      return new Promise((resolve, reject) => {
        const recheckFunc = () => {
          const tVal = setTimeout(() => {
            const dataToLoadArr = deepFind(this, variableToCheckPaths);
            if (
              !dataToLoadArr ||
              (dataToLoadArr && dataToLoadArr.length === 0)
            ) {
              if (cancelTokenFuncCheck && cancelTokenFuncCheck()) {
                clearTimeout(tVal);
                reject();
              } else {
                console.log("Recheck data");
                recheckFunc();
              }
            } else {
              resolve();
            }
          }, 1000);
        };
        recheckFunc();
      });
    },
    register() {
      // collect form data!
      this.isSaveLoading = true;
      const subcategories = this.form.selectedSubcategories.map((sub) => {
        return { id: sub.id };
      });
      const countyId = this.form.selectedRegion && this.form.selectedRegion.id;
      const preferredRegions =
        this.form.selectedPreferredRegions &&
        this.form.selectedPreferredRegions.id
          ? [this.form.selectedPreferredRegions]
          : [];

      const params = {
        ..._.omit(
          this.form,
          "tss_id",
          "date_of_birth",
          "selectedLocations",
          "selectedSubcategories",
          "contractHours",
          "contractStartDate",
          "relatives",
          "can_create_oncall_shift",
          "can_create_sleepover_shift",
          "can_create_expenses",
          "contract_start_date"
        ),
        nationality_id:
          this.form && this.form.selectedCountry
            ? this.form.selectedCountry.id
            : null,
        subcategories: subcategories,
        locations: this.form.selectedLocations,
        gender: this.form.gender,
        region_id: countyId,
        preferred_regions: preferredRegions,
        date_of_birth: this.dobServer,
        increments: this.selectedIncrementsArr,
        tags: this.form.tags.map((tag) => {
          return {
            id: tag.id,
          };
        }),
      };

      // if (this.autoGeneratePassOrNot) {
      //   delete (this.form, "password");
      //   delete (this.form, "password_conformation");
      //   // const params = {
      //   //   ..._.omit(
      //   //     this.form,
      //   //     this.form.password_confirmation,
      //   //     this.form.password
      //   //   ),
      //   // };
      // }
      // Changed the name of the parameter from tss_id to external_id, temp_referene_id CR
      // if (this.form.tss_id) {
      //   this.canUseNexusMiddleware
      //     ? (params.external_id = this.form.tss_id)
      //     : (params.tss_id = this.form.tss_id); // Although it says tss_id, this is an external id in this case
      // }

      if (this.form.tss_id && !this.canUseNexusMiddleware) {
        params.tss_id = this.form.tss_id;
      }
      if (this.form.external_id && this.canUseNexusMiddleware) {
        params.external_id = this.form.external_id;
      }

      if (
        (params.external_id && params.external_id.trim().length === 0) ||
        !params.external_id
      ) {
        params.external_id = null;
      }

      // if (this.form.password && this.autoGeneratePassOrNot) {
      //   params.password = this.form.password;
      // }

      // if (this.form.password_confirmation && this.autoGeneratePassOrNot) {
      //   params.password_confirmation = this.form.password_confirmation;
      // }

      if (this.form.contractStartDate.time && !this.canUseNexusMiddleware) {
        params.contract_start = this.formatDateLocToIso(
          this.form.contractStartDate.time
        );
      }

      if (this.form.contractHours) {
        params.contract_hours = this.form.contractHours;
      }

      if (this.selectedIncrementWeeks.length) {
        params.userIncrementCounters = this.selectedIncrementWeeks;
      }

      if (this.selectedGnibDocId) {
        params.include = "gnibConfig";
        params.gnib_dtp_id = this.selectedGnibDocId;
        if (this.selectedGnibMaxWeekValue) {
          params.gnib_max_week_hours = this.selectedGnibMaxWeekValue;
        }
      }

      if (this.gPermissions.canRelativeView) {
        params.relatives = this.form.relatives;
      }

      if (this.canUseOnCallShifts) {
        params.has_on_call = this.form.can_create_oncall_shift;
      }

      if (this.canUseSleepoverShifts) {
        params.has_sleepover = this.form.can_create_sleepover_shift;
      }

      if (this.canUseExpenses) {
        params.has_expenses = this.form.can_create_expenses;
      }

      if (this.gPermissions.canEditContractedHours) {
        params.contract_start_date = this.form.contract_start_date;
      }

      // If value for nationality_id is null delete
      Object.entries(params).forEach(([key, value]) => {
        if (value === null && key === "nationality_id") {
          delete params[key];
        }
      });

      // send data for registration!
      this.registerTemp(params)
        .then(() => {
          this.$toasted.info("Temp registered successfully!").goAway(2500);
          this.$emit("should-fetch-all-temps");
          this.$emit("dispose-modal");
          this.isSaveLoading = false;
        })
        .catch((error) => {
          this.isSaveLoading = false;
          if (error.response.data && error.response.data.errors) {
            error = this.formatInvalidDateErrorMessage(error);
            this.errors.record(error.response.data.errors);
          } else {
            // For invalid email
            const errorMsg = {
              email: [error.response.data.message],
            };
            this.errors.record(errorMsg);
          }
          this.$toasted
            .show("Please correct the following errors.")
            .goAway(2500);
          this.errors.all().map((e) => {
            this.$toasted.error(e).goAway(2500);
          });
        })
        .finally(() => {
          this.isSaveLoading = false;
        });
    },
    onSelectGnibDoc(evtPayload) {
      this.isModifiedGnibDocs = true;
      this.selectedGnibDocId = evtPayload.doc;
      this.selectedGnibMaxWeekValue = evtPayload.hours;
    },
    onRemoveGnibDoc(isModified = true) {
      this.selectedGnibMaxWeekValue = null;
      this.selectedGnibDocId = null;
      this.isModifiedGnibDocs = isModified;
    },
    onInputGnib(evtPayload) {
      this.selectedGnibMaxWeekValue = evtPayload.hours;
      if (!this.selectedGnibDocId) {
        this.selectedGnibDocId = evtPayload.doc;
      }
      this.errors.clear("gnib_max_week_hours");
    },
    formatInvalidDateErrorMessage(error) {
      if (error.response.data.errors.date_of_birth) {
        for (
          let i = 0;
          i < error.response.data.errors.date_of_birth.length;
          i++
        ) {
          const errDob = error.response.data.errors.date_of_birth[i];
          if (errDob === "The date of birth does not match the format Y-m-d.") {
            error.response.data.errors.date_of_birth[i] =
              "The date of birth does not match the format DD/MM/YYYY.";
          } else if (
            errDob.indexOf("The date of birth must be a date before") === 0
          ) {
            // If starts with ...
            const stringToCut = "The date of birth must be a date before ";
            // Firefox and maybe other browsers don't handle datetime parsing from string
            const slicedDate = errDob.slice(stringToCut.length, -1);
            error.response.data.errors.date_of_birth[i] =
              `The date of birth must be a date before ${moment(
                slicedDate
              ).format("DD/MM/YYYY")}`;
          }
        }
      }

      return error;
    },
    formatDateLocToIso(isoDate) {
      return getFormattedTime(isoDate, "YYYY-MM-DD", "DD/MM/YYYY");
    },
    async fetchDataFromTss() {
      this.isTssLoading = true;
      const tssId = this.form.tss_id;
      const externalId = this.form.external_id;
      try {
        const res = await this.fetchTempInfoFromTss({
          id: this.canUseNexusMiddleware ? externalId : tssId,
          isNexusMiddkeware: this.canUseNexusMiddleware,
        });
        const formattedData = this.formatDataFromTssFetch(res.data);
        // reset this component data when external ID is pulled
        this.resetFormData();
        this.form = Object.assign(this.form, formattedData);
        this.errors.clear();
      } catch (err) {
        // reset this component data when external ID is pulled
        this.resetFormData();
        this.$toasted.error(err.response.data.message).goAway(2500);
      } finally {
        this.isTssLoading = false;
      }
    },
    formatDateIsoToLoc(isoDate) {
      return getFormattedTime(isoDate, "DD/MM/YYYY", "YYYY-MM-DD");
    },
    formatDataFromTssFetch(data) {
      return {
        home_address: data.address_1,
        home_address_2: data.address_2,
        home_address_3: data.address_3,
        mobile_phone: data.mobileNumber,
        home_phone: data.phoneNumber,
        ...data,
        date_of_birth: this.formatDateIsoToLoc(data.date_of_birth),
        selectedCountry: this.filteredCountrys.find(function (item) {
          return (
            item.name.toLowerCase() === data.nationality_name?.toLowerCase()
          );
        }),
      };
    },
    setSectors() {
      // Need to set checked
      // const sectorsWithChecked = this.getCheckedCCs(this.sectors)

      this.locationData = {
        // sectors: sectorsWithChecked,
        tempId: null,
      };
    },
    getCheckedCCs(sectors, ids = []) {
      return _.each(sectors, (sector) => {
        // console.log('Checking Sector :: ', sector)
        return _.each(sector.clients, (client) => {
          // console.log('Checking Client :: ', client)
          return _.each(client.locations, (location) => {
            // console.log('Checking CC :: ', location)
            location.checked = _.contains(ids, location.id);
          });
        });
      });
    },
    // setChecked (sectors) {
    //   // Set all to unchecked
    //   return sectors.map(sector => {
    //     const clients = sector.clients.map(client => {
    //       const centres = client.locations.map(centre => {
    //         return Object.assign(centre, { checked: false, isTempPreferred: null })
    //       })

    //       return Object.assign(client, { locations: centres })
    //     })

    //     return Object.assign(sector, { clients })
    //   })
    // },
    locationCheckChanged(evtObj) {
      if (evtObj.value) {
        this.form.selectedLocations.push({
          id: evtObj.id,
          is_temp_preferred: null,
        });
      } else {
        this.form.selectedLocations = this.form.selectedLocations.filter(
          (centre) => centre.id !== evtObj.id
        );
      }

      // Check errors
      if (this.form.selectedLocations && this.form.selectedLocations.length) {
        this.errors.clear("locations");
      }
    },
    tempPrefChanged(evtObj) {
      const foundObj = this.form.selectedLocations.find(
        (centre) => centre.id === evtObj.id
      );
      if (foundObj) {
        // Has to be found
        if (evtObj.is_temp_preferred) {
          this.$set(foundObj, "is_temp_preferred", true);
        } else if (evtObj.is_temp_preferred === false) {
          this.$set(foundObj, "is_temp_preferred", false);
        } else {
          this.$set(foundObj, "is_temp_preferred", null);
        }
      }
    },
    locationReasonChange(evtObj) {
      const foundObj = this.form.selectedLocations.find(
        (centre) => centre.id === evtObj.id
      );
      if (foundObj) {
        // Has to be found
        this.$set(foundObj, "reason", evtObj.reason);
      }
    },
    onSelectSubcategories() {
      this.errors.clear("subcategories");

      this.$nextTick(() => {
        const subs = this.form.selectedSubcategories;
        this.usedSubcategoryIds = subs.map((sub) => sub.id);
      });
    },
    onRemoveSubcategories(evt) {
      // Remove and clean leftover data
      this.selectedIncrementWeeks = this.selectedIncrementWeeks.filter(
        (item) => item.subcategory_id !== evt.id
      );
      this.selectedIncrementsArr = this.selectedIncrementsArr.filter(
        (item) => item.category_id !== evt.id
      );
      this.usedSubcategoryIds = this.usedSubcategoryIds.filter(
        (id) => id !== evt.id
      );
    },
    openLocation() {
      this.firstLoadCentres = true;

      // Toggle page visibility
      this.changePageView("isSectorsPage");
    },
    onChangeIncrementsCat(evt) {
      const { increments, incWeeks = null } = evt;

      if (incWeeks) {
        const foundIndex = this.selectedIncrementWeeks.findIndex(
          (item) => item.subcategory_id === incWeeks.subcategory_id
        );
        if (foundIndex !== -1) {
          this.selectedIncrementWeeks[foundIndex] = incWeeks;
        } else {
          this.selectedIncrementWeeks.push(incWeeks);
        }
      }
      // Remove all increments that start with specified subcategory and replace them with new list
      if (Array.isArray(increments) && increments.length) {
        const evtCategoryid = increments[0].category_id;
        this.selectedIncrementsArr = this.selectedIncrementsArr.filter(
          (subInc) => subInc.category_id !== evtCategoryid
        );
        this.selectedIncrementsArr.push(...increments);
      }

      // Return to previous page
      this.isIncrementsPage = false;
    },
    showIncrementsPage(sub) {
      // Toggle page visibility
      this.changePageView("isIncrementsPage");

      this.incrementData.subcategory = sub;
      this.incrementData.increments = [];
      const currentIncrementWeek = this.selectedIncrementWeeks.find(
        (item) => item.subcategory_id === sub.id
      );
      if (currentIncrementWeek) {
        this.incrementData.currentIncrementWeek = currentIncrementWeek;
      } else {
        this.incrementData.currentIncrementWeek = null;
      }

      const currentIncrements = this.selectedIncrementsArr.filter(
        (idl) => idl.category_id === sub.id
      );
      if (currentIncrements.length) {
        this.incrementData.increments = currentIncrements;
      }
    },
    onAddNewRelative(relativePerson) {
      // Toggle page visibility
      this.changePageView("isRelativesPage");

      console.log("Person clicked", relativePerson);
      const payload = {
        relatives:
          (this.form.relatives &&
            JSON.parse(JSON.stringify(this.form.relatives))) ||
          [],
        person: relativePerson,
      };

      this.relativesData = payload;
    },
    onSelectGender(evt) {
      this.$set(this.form, "gender", evt.key);
      this.errors.clear("gender");
    },
    onSelectTag(evt) {
      this.form.tags.push(evt);
      this.errors.clear("tag");
    },
    onRemoveTag(evt) {
      const deletingTagIndex = this.form.tags.findIndex(
        (tag) => tag.id === evt.id
      );
      this.form.tags.splice(deletingTagIndex, 1);
    },
    changePageView(key, isReverse = false) {
      this.isSectorsPage = false;
      this.isIncrementsPage = false;
      this.isRelativesPage = false;
      if (!isReverse) {
        this[key] = true;
      }
    },
    onSaveNextOfKin(evt) {
      this.form.relatives = evt;
      this.changePageView("", true);
    },
    resetFormData() {
      const initialData = getInitialDataState();
      const initialFormData = initialData.form;
      Object.keys(initialFormData).forEach(
        (key) => (this.form[key] = initialFormData[key])
      );
    },
  },
};
</script>
<style lang="scss" scoped>
$lightgray: #7c91ae;
$border-color: #9bb4c1;

.is-special {
  width: 100%;
  padding: 0.5rem 0 1rem;
  overflow: auto;
  .hideMessagePasswordConfirmation {
    color: red;
    display: none;
  }
  .showMessagePasswordConfirmation {
    color: red;
    display: block;
    text-align: center;
  }
}

.nickname-wrap {
  display: flex;
  gap: 5px;
  width: 100%;
  align-items: stretch;

  .nickname-group {
    width: 100%;

    input {
      flex: 1;
    }
  }

  .contract-start {
    width: 200px;

    .action {
      // height: 100%;
      height: 2.285em;

      :deep() {
        input {
          height: 100%;
        }
      }
    }
  }
}

.tss-id {
  display: flex;
  align-items: center;

  button {
    margin-left: 7px;
  }
}

#btn-register {
  margin: 1em 0;
}

.back {
  margin-top: 10px;
  min-width: 90px;
  height: 30px;
}
</style>
<style lang="scss">
.special-wide {
  display: block !important;
  padding-left: 0;
  padding-right: 0;
  border: none;
  box-shadow: default;

  input.cov-datepicker {
    width: 100%;
    padding-left: 11px !important;
    font-size: 1.1rem !important;
  }
}
</style>
