<template lang="pug">
#expenses-table
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    :size="getModalSize",
    :scrollable="isModalScrollable"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      :isSaveLoading="isSaveLoading",
      @dispose-modal="disposeModalFunc",
      @is-save-loading="isSaveLoading = $event",
      @cancel-modal="modalVisible = false",
      @get-data="onEmitGetData"
    )
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  .filters
    .left-side
      input.input(
        type="text",
        :value="searchById",
        placeholder="Filter by expense ID",
        v-facade="'###########'",
        @input="onFireSearch($event.target.value, 'expense-id')"
      )
      multi-select#agencyWorker(
        :options="agencyWorkers",
        :close-on-select="true",
        :searchable="true",
        :options-limit="50",
        select-label="",
        track-by="id",
        label="name",
        :loading="isLoadingAgencyWorker",
        :value="filterBy.selectedAgencyWorker",
        :max="1",
        :multiple="true",
        placeholder="Search worker",
        :internal-search="false",
        @search-change="asyncFindWorker",
        @input="onSearchMultiple"
      )
      //- span(slot="noResult") Nothing found.

      multi-select#client.client-select(
        style="width: 280px",
        :options="clients",
        :searchable="true",
        :options-limit="999",
        select-label="",
        :close-on-select="false",
        track-by="id",
        label="name",
        :multiple="true",
        v-model="filterBy.selectedClient",
        :loading="isLoadingClient",
        placeholder="Filter by client",
        @input="onSearchMultiple"
      )
      multi-select#expense-type-drop(
        :options="filterExpenseTypes",
        :close-on-select="true",
        :searchable="true",
        :options-limit="999",
        :multiple="true",
        select-label="",
        track-by="upt_id",
        label="upt_name",
        :value="filterBy.expenseType",
        placeholder="Filter by expense type",
        v-if="expenseType",
        @input="onSearchMultiple"
      )
      //- group-label="upt_code",
      //- group-values="children",
      //- span(slot="noResult") Nothing found.
    .right-side
      .field.shift-datetime
        label.label From
        p.control
          date-picker(
            :date="filterBy.dateFrom",
            :option="dateTimeOption",
            @change="dateInputChanged"
          )
      .field.shift-datetime
        label.label To
        p.control
          date-picker(
            :date="filterBy.dateTo",
            :option="dateTimeOption",
            @change="dateInputChanged"
          )
      button.button.is-generic-app-blue.is-caps-lock(@click="resetDateFilter")
        span Reset dates
  table.table
    thead
      tr
        //- th
        //-   input(
        //-     type="checkbox",
        //-     v-model="allExpensesSelected",
        //-     ref="selectAllCheckbox"
        //-   )
        //-   | Select All
        th
          span.column-label EXPENSES ID
          //- span.sort-buttons
          //-   sort-buttons(column-name="type", v-model="selectedSortColumn")
        th
          span.column-label CLIENT NAME
          //- span.sort-buttons
          //-   sort-buttons(column-name="status", v-model="selectedSortColumn")
        th
          span.column-label TEMP NAME
          //- span.sort-buttons
          //-   sort-buttons(
          //-     column-name="subcategory",
          //-     v-model="selectedSortColumn"
          //-   )
        th
          span.column-label DATE OF EXPENSE
          //- span.sort-buttons
          //-   sort-buttons(
          //-     column-name="start_time",
          //-     v-model="selectedSortColumn"
          //-   )
        th
          span.column-label TYPE OF EXPENSE
          // column name start space is intentionally left
          // because we have date column that use
          // same field for sort
          //- span.sort-buttons
          //-   sort-buttons(
          //-     column-name=" start_time",
          //-     v-model="selectedSortColumn"
          //-   )
        th(style="min-width: 90px")
          span.column-label {{ activeTab.id === 2 ? "APPROVED AMOUNT" : "AMOUNT" }}
        th(v-if="activeTab.id === 2", style="min-width: 90px")
          span.column-label APPROVER
        th(
          v-if="activeTab.id === 2 && canUseNexusMiddleware",
          style="min-width: 110px"
        )
          span.column-label PAYROLL WEEK NUMBER
          //- span.sort-buttons
        th.is-narrow(colspan="100")
          span.column-label
          //- span.sort-buttons
    tfoot
    tbody
      tr(v-if="dataIsLoading")
        td.center-text(colspan="100")
          img(src="../../assets/images/comps/loader.svg")
      tr(v-if="expenses.length === 0 && !dataIsLoading")
        td.center-text(colspan="100")
          span No data in table.
      tr(
        v-if="expenses && !dataIsLoading",
        v-for="expense in expenses",
        :class="[expense.parentType && expense.parentType.upt_code]"
      )
        //- td(data-cell="checkbox")
        //-   input(
        //-     :id="expense.id",
        //-     type="checkbox",
        //-     :value="{ id: expense.id }",
        //-     v-model="selectedExpenses",
        //-   )
        td(data-cell="expense-id")
          span {{ getExpensesId(expense) }}
        td(data-cell="client-name")
          .flexer
            span {{ expense.client && expense.client.name }}
        td(data-cell="temp-name") 
          span {{ expense.temp && expense.temp.name }}
        td.subcat(data-cell="expense-date")
          span {{ parseDate(expense.startTime || expense.date) }}
        td.date(data-cell="expense-type")
          span.generic-app-tag {{ expense.expenseType && expense.expenseType.label }}
        td.time(
          data-cell="expense-amount",
          :class="{ 'new-value': expense.old_value && expense.old_value != expense.value && [2, 3].includes(activeTab.id) }"
        )
          .flexer
            //- ONLY ON APPROVED AND REJECTED TAB
            span {{ parseExpenseValue(expense.value) }}
        td.approver(data-cell="expense-approver", v-if="activeTab.id === 2")
          span(v-if="expense.userProcessed && expense.userProcessed.name") {{ expense.userProcessed.name }}
        td.payroll-week-number(
          data-cell="payroll-week-number",
          v-if="activeTab.id === 2 && canUseNexusMiddleware"
        )
          span(v-if="expense.payroll_payment_period") {{ expense.payroll_payment_period }}
        td.has-button(data-cell="action-status", v-if="canUseNexusMiddleware")
          button.button.is-generic-app-blue.is-caps-lock.is-tiny(
            :class="{ 'is-loading': isLoadingStatus === expense.id }",
            :style="{ 'background-color': expense.payroll_status_color + ' !important', color: 'black' }",
            v-if="expense.payroll_status"
          ) {{ expense.payroll_status }}
        td.has-button(data-cell="action-view")
          button.button.is-generic-app-blue.is-caps-lock.is-tiny(
            :class="{ 'is-loading': isLoadingFileExpenseId === expense.id }",
            v-if="expense.files && expense.files.length",
            @click="onShowExpense(expense)"
          ) View
        td.has-button(data-cell="action-details", v-if="canShowApprove")
          button.button.is-generic-app-blue.is-caps-lock.is-tiny(
            :class="{ 'is-loading': isLoadingReviewExpenseId === expense.id }",
            @click="onReviewExpense(expense)"
          )
            span Review
            //- span(v-if="canAcceptSignOff") Approve
            //- span(v-if="!canAcceptSignOff") Reject
        td.has-button(data-cell="action-details")
          button.button.is-generic-app-blue.is-caps-lock.is-tiny.is-outlined(
            :class="{ 'is-loading': isLoadingDetailsExpenseId === expense.id }",
            @click="onShowDetails(expense)"
          ) 
            span(v-if="!(isLoadingDetailsExpenseId === expense.id)") Details
  //- .button-wrap
  //-   transition(name="fade")
  //-     button.button.is-generic-app-blue(
  //-       v-if="selectedExpenses.length",
  //-       @click.prevent="showModal()"
  //-     ) REJECT ALL
</template>
<script>
import { diffArrayOfObjects } from "../../lib/helpers/function.js";
import _ from "underscore";

import MultiSelect from "vue-multiselect";
import ExpenseDetails from "../in-modal/expenses/ExpenseDetails.vue";
// import ExpenseView from "../in-modal/expenses/ExpenseView.vue";
import ExpenseSignoff from "../in-modal/expenses/ExpenseSignoff.vue";

import { Evt } from "../../lib/helpers/Evt.js";
import auth from "../../lib/auth.js";

import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import calendarOptions from "../common/calendarOptions.js";
import { IExpensesData } from "@/TS/types";
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "ExpensesTable",
  components: {
    MultiSelect,
    ExpenseDetails,
    // ExpenseView,
    ExpenseSignoff,
  },
  props: {
    expenses: { default: () => [], type: [] },
    // expenses: { default: () => [], type: [] as PropType<IExpensesData> },
    expenseType: { default: () => ({}) },
    activeTab: { type: Object },
    dataIsLoading: { required: false },
    filtersTable: { required: false },
    isLoadingClient: { default: false },
  },
  data() {
    return {
      dateTimeOption: null,
      auth: auth,
      isLoadingAgencyWorker: false,
      isSaveLoading: false,
      modalData: null, // will fetch dynamically, depending on where clicked and which modal
      modalVisible: false,
      inmodal: "", // will switch depending on which modal we are showing
      allExpensesSelected: false,
      selectedExpenses: [],
      selectedShiftObjs: [],
      searchById: "",
      filters: {
        visible: true,
      },
      filterBy: {
        selectedAgencyWorker: [],
        selectedClient: [],
        expenseId: "",
        expenseType: null,
        dateFrom: { time: "" },
        dateTo: { time: "" },
      },
      selectedSortColumn: {
        column: "",
        direction: "",
      },
      isLoadingStatus: null,
      isLoadingFileExpenseId: null,
      isLoadingReviewExpenseId: null,
      isLoadingDetailsExpenseId: null,
    };
  },
  computed: {
    ...mapGetters({
      agencyWorkers: "getAgencyWorkers",
      clientsWithLocations: "getClientsWithLocations",
      clients: "getAllClients",
    }),
    canShowApprove() {
      const validReject = ["Cancelled", "Rejected"];
      if (Array.isArray(this.expenses) && this.expenses.length) {
        return this.expenses.some((exp) => !validReject.includes(exp.status));
      }
      return false;
    },
    canAcceptSignOff() {
      if (Array.isArray(this.expenses) && this.expenses.length) {
        return this.expenses.some((exp) => exp.status !== "Processed");
      }
      return false;
    },
    filterExpenseTypes() {
      return this.expenseType.children
        ? this.getRecChildren(this.expenseType.children)
        : [];
    },
    getModalSize() {
      switch (this.inmodal) {
        case "ExpenseDetails":
          return 750;
        // case "ExpenseView":
        //   return 500;
        default:
          return 550;
      }
    },
    isModalScrollable() {
      return this.inmodal === "repush-for-temps";
    },
    isAllChecked() {
      // [].every() returns true
      if (this.expenses.length === 0) {
        return false;
      } else {
        return this.expenses.every((shift) =>
          this.selectedExpenses
            .map((selShift) => selShift.id)
            .includes(shift.id)
        );
      }
    },
  },
  watch: {
    allExpensesSelected() {
      console.log("WATCH: all shifts selection triggered");
      if (!this.expenses) {
        return;
      }

      if (!this.allExpensesSelected) {
        this.selectedExpenses = diffArrayOfObjects(
          this.selectedExpenses,
          this.expenses
        );

        this.selectedShiftObjs = diffArrayOfObjects(
          this.selectedShiftObjs,
          this.expenses
        );
      } else {
        this.expenses.map((e) => {
          if (!_.findWhere(this.selectedExpenses, { id: e.id })) {
            this.selectedExpenses.push({ id: e.id });
            if (!this.isProcessed(e)) this.selectedShiftObjs.push(e);
          }
        });
      }
      this.$emit("shiftSelected", this.selectedExpenses);
    },
    selectedSortColumn(value) {
      this.$emit("sort-results", value);
    },
    // isAnyChecked(val) {
    //   // console.log("isAnyChecked", val);
    //   if (val) {
    //     if (!this.isAllChecked) {
    //       this.$refs.selectAllCheckbox.indeterminate = true;
    //     }
    //   } else {
    //     this.$refs.selectAllCheckbox.indeterminate = false;
    //     this.allExpensesSelected = false;
    //   }
    // },
    // isAllChecked(val) {
    //   // console.log("isAllChecked", val);
    //   if (val) {
    //     this.allExpensesSelected = true;
    //     this.$refs.selectAllCheckbox.indeterminate = false;
    //   } else {
    //     if (this.isAnyChecked) {
    //       this.$refs.selectAllCheckbox.indeterminate = true;
    //     }
    //   }
    // },
  },
  created() {
    Evt.listen("disposeModal", () => {
      console.error("called outdated bus");
      this.modalVisible = false;
    });
    this.setupDateTimePickerOpt();
  },
  beforeDestroy() {
    Evt.off("disposeModal");
    this.dateTimeOption.type = "min";
  },
  methods: {
    ...mapActions([
      "patchShift",
      "findAgencyWorker",
      "getExpensesInfo",
      "getFileBlob",
      "getExpenseInitialPrice",
    ]),
    getExpensesId(expense) {
      // getExpensesId(expense: IExpensesData) {
      if (this.canUseNexusMiddleware) {
        return expense.id_order || expense.id;
      }
      return expense.id;
    },
    disposeModalFunc() {
      this.modalVisible = false;
      this.isSaveLoading = false;
    },
    getRecChildren(arr = []) {
      const selKey = "upt_selectable";
      if (Array.isArray(arr)) {
        const recSer = arr.reduce((acc, item) => {
          if (item[selKey]) {
            acc.push(item);
          }
          if (item.children) {
            const childArr = this.getRecChildren(item.children);
            acc.push(...childArr);
          }
          return acc;
        }, []);
        return recSer;
      }
      return [];
    },
    asyncFindWorker: _.debounce(async function (query) {
      if (query === "") return;
      this.isLoadingAgencyWorker = true;
      await this.findAgencyWorker({ query, originallySelectedWorker: "" });
      this.isLoadingAgencyWorker = false;
    }, 400),

    /**
     * `selected` is only the currently triggered filter passed-in as an argument
     */
    onFireSearch(selected, id) {
      this.searchById = selected;
      this.fireSearch(selected, id);
    },
    fireSearch: _.debounce(function (selected, id) {
      console.log("debounced query on its way...");
      const isChanged = this.searchById != this.filterBy.expenseId;
      /**
       * Instead, we give an object that contains all currently set filters!
       */
      switch (id) {
        case "expense-id":
          this.$set(this.filterBy, "expenseId", selected);
          if (isChanged) {
            this.$emit("search-by-filter", this.filterBy);
          }
          // this.filterBy.expenseId = selected
          break;
      }
      // this.$emit("search-by-filter", this.filterBy);
    }, 300),
    onSearchMultiple: _.debounce(function (selected, id) {
      switch (id) {
        case "client":
          this.filterBy.selectedClient = selected;
          break;
        case "agencyWorker":
          this.filterBy.selectedAgencyWorker = selected;
          break;
        case "expense-type-drop":
          this.filterBy.expenseType = selected;
          break;
      }
      this.$emit("search-by-filter-client", this.filterBy);
    }, 300),
    removeFilter(removed, id) {
      console.warn("Removing", removed, id);
    },
    isProcessed(shift) {
      return Boolean(shift.signedOffBy);
    },
    showModal(modal, modalData = {}, modalTitle = "Modal title") {
      // console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
    async onShowExpense(expense) {
      // async onShowExpense(expense: IExpensesData) {
      this.isLoadingFileExpenseId = expense.id;
      await this.onOpenImageFile(expense);
      this.isLoadingFileExpenseId = null;

      // this.showModal(
      //   "ExpenseView",
      //   { files: expense.files },
      //   "View expense receipts"
      // );
    },
    async onReviewExpense(expense) {
      // async onReviewExpense(expense: IExpensesData) {
      const expenseCopy = JSON.parse(JSON.stringify(expense));
      const expenseTypesForCheck = ["EMLG", "EMCR", "EMMC", "EMBC", "ESUB"];
      // awaiting approval tab id = 1
      const statusesForCheck = [1];
      const shouldCallApi =
        expenseTypesForCheck.includes(expenseCopy.expenseType?.type) &&
        statusesForCheck.includes(this.activeTab.id);
      if (shouldCallApi) {
        this.isLoadingReviewExpenseId = expenseCopy.id;
        try {
          const res = await this.getExpenseInitialPrice({ id: expenseCopy.id });
          if ("value" in res.data.data && res.data.data.value !== null) {
            const newValue = res.data.data.value;
            if (newValue !== expenseCopy.value) {
              expenseCopy.old_value = expenseCopy.value;
              expenseCopy.value = newValue;
            }
          }
        } catch (error) {
          console.warn(error);
        } finally {
          this.isLoadingReviewExpenseId = null;
        }
      }

      this.showModal(
        "ExpenseSignoff",
        { expense: expenseCopy, activeTab: this.activeTab },
        "Sign off"
      );
    },
    async onOpenImageFile(expense) {
      // async onOpenImageFile(expense: IExpensesData) {
      const params = {
        link: expense.files[0]?.fileUrl,
      };
      try {
        const res = await this.getFileBlob(params);
        const file = res.data;
        const fileLink = URL.createObjectURL(file);
        window.open(fileLink);
        URL.revokeObjectURL(fileLink);
      } catch (err) {
        console.warn(err.message);
      }
    },
    async onShowDetails(expense) {
      // async onShowDetails(expense: IExpensesData) {
      const params = {
        id: expense.id,
        include: [
          "temp",
          "client",
          "expenseType",
          "details",
          "userRejected",
          "userProcessed",
          "userCancelled",
        ].join(),
      };
      this.isLoadingDetailsExpenseId = expense.id;
      try {
        const res = await this.getExpensesInfo(params);
        const details = res.data.data;
        this.showModal(
          "ExpenseDetails",
          {
            expense: details,
            parentType: expense.parentType,
            activeTab: this.activeTab,
          },
          "Expense details"
        );
      } catch (err) {
        console.warn(err.message);
      } finally {
        this.isLoadingDetailsExpenseId = null;
      }
    },
    confirmAction(msg) {
      // confirmAction(msg: string) {
      return new Promise((resolve) => {
        const alert = {
          title: msg,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    onEmitGetData() {
      this.selectedExpenses = [];
      this.$emit("fetch-expenses");
    },
    parseDate(dateStr, includeTime = true) {
      const timeFormat = includeTime ? " HH:mm" : "";
      return moment(dateStr).format(`DD-MM-YYYY${timeFormat}`);
    },
    parseExpenseValue(val) {
      const sfMonetaryValue = this.initialStatus.monetary_symbol;
      const shouldAddDecimals = val % 1 !== 0;
      const formatedVal = shouldAddDecimals ? val.toFixed(2) : val;
      return `${sfMonetaryValue} ${formatedVal || 0}`;
    },
    setupDateTimePickerOpt() {
      this.dateTimeOption = Object.assign({}, calendarOptions.dateTimeOption);
      this.MultiShift = calendarOptions.MultiShift;
      this.dateTimeOption.type = "day";
      this.dateTimeOption.format = "DD/MM/YYYY";
    },
    dateInputChanged(evt) {
      this.$emit("search-by-filter", this.filterBy);
    },
    resetDateFilter() {
      if (this.filterBy.dateFrom.time || this.filterBy.dateTo.time) {
        this.filterBy.dateFrom = { time: "" };
        this.filterBy.dateTo = { time: "" };
        this.dateInputChanged();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
#expenses-table {
  height: 100%;
  border: 1px solid #e2e7ea;
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

span {
  font-weight: 400;
}

.filters {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  padding: 10px 20px;
  width: 100%;

  .left-side {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-right: auto;
  }

  .right-side {
    display: flex;
    align-items: flex-end;
    gap: 15px;
    margin-left: auto;
  }
}

.shift-type-td {
  color: #aeafb7;

  img {
    max-width: 27px;
    max-height: 27px;
    width: auto;

    &.onCall {
      $width: 40px;
      width: $width;
      max-width: $width;
      max-height: $width;
      margin-bottom: -5px;
      margin-left: -7px;
    }
  }
}

em {
  color: #333;
}

svg {
  margin-left: 0.5em;
  cursor: pointer;
}

.filter_dropdown {
  z-index: 2;
  position: absolute;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 0 5px 0 rgba(black, 0.3);
  left: 0;
  background-color: white;
  padding: 1em;
}

.generic-app-tag {
  background-color: #dbe1e5;
  border-radius: 3px;
  padding: 0.2em 0.5em;
}

.center-text {
  text-align: center;
  height: 150px;
  background: #f0f6f6;

  img {
    width: 50px;
  }
}

.button-wrap {
  display: flex;
  gap: 10px;
}

// Fixes table headers with sort buttons layout.
th,
.flex-header.column-label {
  font-size: 13px;
}

.flex-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .column-label {
    flex: 0 1;
  }

  .sort-buttons {
    flex: 1 0 auto;
    margin-left: 3px;
  }
}

tr {
  &.EREC {
    .generic-app-tag {
      background: #cde8e6;
    }
  }

  &.EMLG {
    .generic-app-tag {
      background: #dbe4f0;
    }
  }

  &.ESUB {
    .generic-app-tag {
      background: #e2f2fa;
    }
  }
}
</style>
<style lang="scss">
#expenses-table {
  table {
    border: 1px solid #dde6eb;
    border-collapse: separate;
  }

  table th {
    position: relative;
    background-color: #f2f7fa;
    vertical-align: middle !important;
  }

  table td {
    color: #405168;
    vertical-align: middle !important;
    border-bottom: 1px solid #dde6eb;

    // &.new-value {
    //   span {
    //     color: rgb(30, 179, 25);
    //   }
    // }

    span.indicator:last-child {
      margin-left: auto;
    }

    .flexer {
      display: flex;
      align-items: center;

      span:first-child {
        margin-right: auto;
      }

      span {
        display: flex;
        align-items: center;
      }
    }
  }

  td.has-button {
    .button {
      min-width: 80px;
      width: 100%;
    }
  }

  .client-select {
    .multiselect__tag {
      white-space: normal;
    }
  }
}
</style>

<style lang="scss">
#expenses-table {
  .filters {
    .multiselect,
    .input {
      width: 220px !important;
      min-height: 40px;
    }
  }
}
</style>
