/* eslint-disable no-unused-vars */
import api from "../../api/reports";
import auth from "../../lib/auth.js";

const state = {
  sectors: [],
  counties: [],
  previewData: [],
  pagination: {
    totalPages: 0,
    currentPage: 0,
    currentPageData: {},
  },
  reports: [],
  recalculationScenario:[ //in the future, it may be obtained from the backend
      {
          "label": "Pay and then Bill",
          "sticker": "P&B",
          "order": 0,
          "code": "PYBL",
      },
      {
          "label": "Bill and then Pay",
          "sticker": "B&P",
          "order": 1,
          "code": "BLPY",
      },
      {
          "label": "Only Bill",
          "sticker": "B",
          "order": 2,
          "code": "ONBL",
      },
      {
          "label": "Only Pay",
          "sticker": "P",
          "order": 3,
          "code": "ONPY",
      }
  ],
  
};

const getters = {
  getCounties: (state) => state.counties,
  // getSectors: (state) => state.sectors,
  // getSectors: (state) => state.sectors.filter(sector => sector.sectorParent),
  getReports: (state) => {
    // Remove in future
    // if (!auth.user.role) return
    // if (auth.user.role === 'super-admin' || auth.user.role === 'office-admin') {
    //   state.reports = [
    //     { name: 'Report 1 – Client Schedules', urlPreview: '/reports/general-client-schedules-report', urlDownload: '/reports/download-general-client-schedules-report' },
    //     // { name: 'Report 2 – Individual Cost Centre Report', urlPreview: '/reports/individual-location-report', urlDownload: '/reports/download-individual-location-report' },
    //     // { name: 'Report 3 – Client Percentage Fill Reports', urlPreview: '/reports/client-percentage-fill-report', urlDownload: '/reports/download-client-percentage-fill-report' },
    //     // { name: 'Report 4 – Total Weekly Hour', urlPreview: '/reports/total-weekly-hours-report', urlDownload: '/reports/download-total-weekly-hours-report' },
    //     // { name: 'Report 5 – Back up Lists', urlPreview: '/reports/backup-lists-report', urlDownload: '/reports/download-backup-lists-report' },
    //     // { name: 'Report 6 – All field export report', urlPreview: '/reports/all-field-export-report', urlDownload: '/reports/download-all-field-export-report' },
    //     // { name: 'Report 7 – Temp Report', urlPreview: '/reports/temp-report', urlDownload: '/reports/download-temp-report' },
    //     { name: 'Report 8 – Processed shifts report', perm: 'view-unbilled-shifts-report', urlPreview: '/reports/unbilled-shifts-report', urlDownload: '/reports/download-unbilled-shifts-report' },
    //     // { name: 'Report 10 – Management Shift Status', urlPreview: '/reports/management-shift-status-report', urlDownload: '/reports/download-management-shift-status-report' },
    //     // { name: 'Report 11 – Unique Workers', urlPreview: '/reports/unique-workers-report', urlDownload: '/reports/download-unique-workers-report' },
    //     // { name: 'Report 12 – Hours Worked', urlPreview: '/reports/hours-worked-report', urlDownload: '/reports/download-hours-worked-report' },
    //     { name: 'Report 13 – Unprocessed Shifts', urlPreview: '/reports/unprocessed-shifts-report', urlDownload: '/reports/download-unprocessed-shifts-report' },
    //     // { name: 'Report 14 – Client assigned report', uniqueId: 'client-assigned', perm: 'view-client-assigned-report', urlPreview: '/reports/client-assigned-report', urlDownload: '/reports/download-client-assigned-report' },
    //     // { name: 'Report 15 – Client activity report', uniqueId: 'client-activity', perm: 'view-client-activity-report', urlPreview: '/reports/client-activity-report', urlDownload: '/reports/download-client-activity-report' },
    //     // { name: 'Report 16 – Client Unassigned Activity Report', perm: 'view-client-unassigned-activity-report', urlPreview: '/reports/client-unassigned-activity-report', urlDownload: '/reports/download-client-unassigned-activity-report' }
    //   ]
    // } else {
    //   state.reports = [
    //     { name: 'Client Schedules', urlPreview: '/reports/general-client-schedules-report', urlDownload: '/reports/download-general-client-schedules-report' },
    //     // {name: 'Client Percentage Fill Reports', urlPreview: '/reports/client-percentage-fill-report', urlDownload: '/reports/download-client-percentage-fill-report'},
    //     { name: 'Processed shifts report', perm: 'view-unbilled-shifts-report', urlPreview: '/reports/unbilled-shifts-report', urlDownload: '/reports/download-unbilled-shifts-report' },
    //     // { name: 'Client assigned report', uniqueId: 'client-assigned', perm: 'view-client-assigned-report', urlPreview: '/reports/client-assigned-report', urlDownload: '/reports/download-client-assigned-report' },
    //     // { name: 'Client activity report', uniqueId: 'client-activity', perm: 'view-client-activity-report', urlPreview: '/reports/client-activity-report', urlDownload: '/reports/download-client-activity-report' },
    //     // { name: 'Client Unassigned Activity Report', perm: 'view-client-unassigned-activity-report', urlPreview: '/reports/client-unassigned-activity-report', urlDownload: '/reports/download-client-unassigned-activity-report' }
    //   ]
    // }
    return state.reports;
  },
  getPreviewData: (state) => state.previewData,
  getPagination: (state) => state.pagination,
  getRecalculationScenario: (state) => state.recalculationScenario,
};

const actions = {
  fetchSectors: ({ commit }, params) => {
    api.fetchSectors(params).then((response) => {
      commit("STORE_SECTORS", response.data);
    });
  },

  fetchRegions: ({ commit }, params) => {
    api.fetchRegions(params).then((response) => {
      commit("STORE_COUNTIES", response.data);
    });
  },
  fetchReportList: async ({ commit }, params) => {
    try {
      const res = await api.fetchReportList(params);
      commit("STORE_REPORTS", res.data);
    } catch (err) {
      console.log("Failed to fetch reports", err.message);
    }
  },

  // @param payload Array of client ids
  fetchClientCategories: ({ commit }, payload) => {
    return api.fetchClientCategories(payload);
  },

  fetchPreviewReports: ({ commit }, params) => {
    return api.fetchReports(params).then((response) => {
      if (response.data.data.pages !== undefined) {
        commit("STORE_PREVIEW_DATA", response.data);
        commit("STORE_PAGINATION", response.data);
        return Promise.resolve();
      }
      return Promise.reject();
    });
  },

  fetchDownloadReport: ({ commit }, params) => {
    return api.downloadReport(params).then((response) => {
      console.log(response);
      if (response.data !== null) {
        return Promise.resolve(response);
      }
      return Promise.reject();
    });
  },
  fetchPageHasChanged: ({ commit }, page) => {
    commit("GO_TO_PAGE", page);
  },
};

const mutations = {
  STORE_SECTORS(state, payload) {
    state.sectors = payload.data;
  },

  STORE_COUNTIES(state, payload) {
    state.counties = payload.data;
  },

  STORE_REPORTS(state, payload) {
    state.reports = payload.data;
  },

  STORE_PREVIEW_DATA(state, payload) {
    state.previewData = payload.data;
  },

  STORE_PAGINATION(state, payload) {
    var total = payload.data.pages.length;
    state.pagination.totalPages = total;
    if (total !== 0) {
      state.pagination.currentPage = 1;
      state.pagination.currentPageData = payload.data.pages[0];
    }
  },

  GO_TO_PAGE(state, payload) {
    state.pagination.currentPage = payload;
    state.pagination.currentPageData = state.previewData.pages[payload - 1];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
