<template lang="pug">
.multiple-wrap
  .table-wrap(ref="list")
    table.table
      thead
        tr
          th
            span Shift ID
          th
            span Cancellation Reason
          th
            span Name
          th
            span Client
          th
            span Subcategories
          th
            span Start Time
          th
            span End Time
          template(v-if="isAnyShiftOnCall")
            th
              span On Call Start Time
            th
              span On Call End Time
          template(v-if="isAnyShiftSleepover")
            th
              span Sleepover Start Time
            th
              span Sleepover End Time
          template(v-if="isAnyShiftAwaken")
            th
              span Awaken Start Time
            th
              span Awaken End Time
          th
            span Status
      tbody(v-if="localShifts && localShifts.length > 0")
        tr(v-for="(shift, index) in localShifts", :key="shift.id")
          td
            span {{ shift?.id }}
          td
            p.control
              multi-select(
                :options="cancellationReasons",
                :close-on-select="true",
                :searchable="true",
                :loading="false",
                track-by="id",
                label="reason",
                v-model="shift.selectedCancellationReason",
                placeholder="CANCELLATION REASON"
              )
                span(slot="noResult") Nothing found.
          td
            span {{ shift?.temp?.name }}
          td
            span {{ shift?.location?.client?.name }}
          td
            span {{  Boolean(shift?.shiftRequest?.subcategories) ? shift.shiftRequest.subcategories.map((subCat) => subCat.name).join(", ") : ''  }}
          td
            span.date-time {{ formatDate(shift.startTime.date) }}
          td
            span.date-time {{ formatDate(shift.endTime.date) }}
          //- ONCALL
          template(v-if="isAnyShiftOnCall")
            td
              span.date-time(
                v-if="shift.type === SHIFT_TYPES.oncall.key && shift.hasOnCall"
              ) {{ parsePartDate(shift, "from", SHIFT_TYPES.oncall.key) }}

            td
              span.date-time(
                v-if="shift.type === SHIFT_TYPES.oncall.key && shift.hasOnCall"
              ) {{ parsePartDate(shift, "to", SHIFT_TYPES.oncall.key) }}
          //- SLEEPOVER
          template(v-if="isAnyShiftSleepover")
            td
              span.date-time(
                v-if="shift.type === SHIFT_TYPES.sleepover.key && shift.hasSleepover"
              ) {{ parsePartDate(shift, "from", SHIFT_TYPES.sleepover.key) }}
            td
              span.date-time(
                v-if="shift.type === SHIFT_TYPES.sleepover.key && shift.hasSleepover"
              ) {{ parsePartDate(shift, "to", SHIFT_TYPES.sleepover.key) }}
          //- AWAKEN
          template(v-if="isAnyShiftAwaken")
            td
              span.date-time(v-if="shift.isAwaken") {{ parsePartDate(shift, "from", SHIFT_TYPES.awaken.key) }}
            td
              span.date-time(v-if="shift.isAwaken") {{ parsePartDate(shift, "to", SHIFT_TYPES.awaken.key) }}
          td
            span(
              v-if="shift.error && !shift.cancelledAt",
              style="color: darkred; display: block; min-width: 150px"
            ) {{ shift.error }}
            span(
              v-if="shift.success && !shift.cancelledAt",
              style="color: green; display: block; min-width: 150px"
            ) {{ shift.success }}
      tbody(v-if="localShifts.length === 0")
        tr
          td
            | No data in table
  .buttons-centered
    button.button.is-generic-app-blue.is-caps-lock(
      v-if="!isAllShiftsCancelledSuccessfully",
      @click.prevent="onSubmitShifts",
      :disabled="disableButton || requiredParamDisable"
    ) 
      img.btn-loader(
        src="../../assets/images/comps/loader.svg",
        v-if="disableButton"
      )
      span SUBMIT
    button.button.is-outlined.is-caps-lock(
      @click.prevent="$parent.$emit('close-modal')"
    ) {{ isAllShiftsCancelledSuccessfully ? "OK" : "CANCEL" }}
</template>

<script>
import MultiSelect from "vue-multiselect";
import { Errors } from "../../lib/helpers/Errors.js";
import { parseErrors } from "../../lib/helpers/function.js";
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";
import { SHIFT_TYPES } from "../../shared/mapData.js";
export default {
  components: {
    MultiSelect,
  },
  props: ["modalProps"],
  data() {
    return {
      localShifts: [],
      oldLocalShifts: [],
      errors: new Errors(),
      disableButton: false,
      isDataChanged: false,
      isAllShiftsCancelledSuccessfully: false,
      SHIFT_TYPES,
      optionsCancellationReasons: [],
      selectedCancellationReason: null,
      successShiftIds: [],
    };
  },
  computed: {
    ...mapState({
      initialStatus: (state) => state.initialStatus,
    }),
    ...mapGetters({
      getShift: "getShift",
      cancellationReasons: "getCancellationReasons",
    }),
    isAnyShiftOnCall() {
      return this.localShifts.some((shift) => shift.hasOnCall);
    },
    isAnyShiftSleepover() {
      return this.localShifts.some((shift) => shift.hasSleepover);
    },
    isAnyShiftAwaken() {
      return this.localShifts.some((shift) => shift.isAwaken);
    },
    requiredParamDisable() {
      let count = 0;
      this.localShifts.forEach((shift) => {
        if (
          !shift.selectedCancellationReason ||
          shift.selectedCancellationReason === null
        ) {
          count++;
        }
      });
      return count > 0;
    },
  },
  watch: {
    isDataChanged: {
      deep: true,
      handler() {
        //Send data to modal component
        this.$parent.$emit("has-changed", this.isDataChanged);
      },
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.fetchCancellationReasons().then(() => {
      this.optionsCancellationReasons = this.cancellationReasons;
      this.setDefaultCancellationReason();
    });
  },
  methods: {
    ...mapActions(["fetchCancellationReasons", "multipleCancelShifts"]),
    // init() creates shiftPart objects if they weren't created already (on timesheet entry)
    init() {
      const editedShifts = this.modalProps.shiftObjs.map((el) => {
        // ONCALL
        if (el.type === SHIFT_TYPES.oncall.key) {
          const oncallPartObj = el.shiftParts.find(
            (partObj) => partObj.type === SHIFT_TYPES.oncall.key
          );
          if (oncallPartObj) {
            el.hasOnCall = true;
          }
        }
        // SLEEPOVER / AWAKEN
        if (el.type === SHIFT_TYPES.sleepover.key) {
          const awakenPartObj = el.shiftParts.find(
            (partObj) => partObj.type === SHIFT_TYPES.awaken.key
          );
          if (awakenPartObj) {
            el.isAwaken = true;
          }
          const sleepoverPartObj = el.shiftParts.find(
            (partObj) => partObj.type === SHIFT_TYPES.sleepover.key
          );
          if (sleepoverPartObj) {
            el.hasSleepover = true;
          }
        }
        return el;
      });
      this.localShifts = JSON.parse(JSON.stringify(editedShifts));
      this.oldLocalShifts = JSON.parse(JSON.stringify(this.localShifts));
    },
    parsePartDate(shift, key, shiftType) {
      const partTimeObj = shift.shiftParts.find(
        (partObj) => partObj.type === shiftType
      );
      let dateTime;
      if (partTimeObj) {
        dateTime = partTimeObj[key].date;
      }
      return dateTime && this.formatDate(dateTime);
    },
    async onSubmitShifts() {
      this.disableButton = true;
      await this.cancelShifts();
      this.disableButton = false;
    },
    async cancelShifts() {
      const params = {
        shifts: [],
      };

      for (const obj of this.localShifts) {
        delete obj.success;
        delete obj.error;
        if (this.successShiftIds.length > 0) {
          this.successShiftIds.forEach((id) => {
            const index = this.localShifts.findIndex((obj) => obj.id === id);
            if (index !== -1) {
              this.localShifts.splice(index, 1);
            }
          });
        }
        const shiftData = {
          id: obj.id,
          reason: obj.selectedCancellationReason?.id ?? null,
        };
        params.shifts.push(shiftData);
      }
      let errors = 0;
      try {
        const res = await this.multipleCancelShifts(params);
        const shiftResponses = res.data.data;
        shiftResponses.forEach((element) => {
          const updatedShift = this.localShifts.find(
            (obj) => obj.id === element.id
          );
          if (element.success) {
            this.successShiftIds.push(element.id);
            updatedShift.success = element.success;
          } else if (element.error) {
            errors++;
            updatedShift.error = element.error;
          }
        });
        this.$emit("multiple-submitted");
        this.isAllShiftsCancelledSuccessfully = true;
      } catch (error) {
        console.log(error);
        const errs = parseErrors(error, "Error cancelling shifts");
        this.$toasted.error(errs).goAway(2500);
      }
    },
    formatDate(stringTime) {
      let m = moment(stringTime, "YYYY-MM-DD HH:mm:ss");
      return m.format("DD/MM/YYYY HH:mm");
    },
    setDefaultCancellationReason() {
      const defaultReason = this.optionsCancellationReasons.find(
        (obj) => obj.id === this.modalProps.cancellation_reason
      ); // Set the selected cancellation reason from props;
      if (defaultReason) {
        console.log("Setting default cancellation reason:", defaultReason); // Debugging line
        this.localShifts.forEach((shift) => {
          // Ensure the reason exists in the options
          this.$set(shift, "selectedCancellationReason", defaultReason);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.multiple-wrap {
  display: flex;
  flex-direction: column;
  gap: 2em 0;
  max-height: 50vh;
  overflow: auto;

  .control {
    display: flex;
    align-items: center;
    gap: 0 0.5em;
    white-space: nowrap;
  }

  .buttons-centered {
    position: sticky;
    bottom: 0;
    left: 0;
    background: #fff;
  }

  .table-wrap {
    display: flex;

    .table {
      width: 100%;
    }
  }
}

.shift {
  display: flex;
  flex-direction: column;
  gap: 0.5em 0;

  .row {
    display: flex;
    align-items: center;
    gap: 0 0.5em;
    width: 100%;

    .control.error {
      color: red;
    }

    .control.success {
      color: green;
    }

    .field {
      width: 50%;
    }
  }
}

.is-suffix {
  white-space: nowrap;
}

thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.date-time {
  padding: 6px;
  background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  border: 1px solid #e2e7ea !important;
  border-radius: 5px !important;
  box-shadow: unset !important;
  display: inline-block;
  white-space: nowrap;
  font-size: 15px;
  pointer-events: none;
  user-select: none;
}
</style>
<style lang="scss">
.multiple-wrap {
  .cov-datepicker {
    width: 100%;
    min-width: 145px;
  }
}
</style>
