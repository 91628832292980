/* eslint-disable no-unused-vars */

import api from "../api";

export default {
  async getStatusInitialLoad({ commit }, data) {
    const response = await api.getStatusInitialLoad(data);
    commit("SET_INITIAL_AGENCY_STATUS", response.data);
    return response;
  },

  // Get all temps
  fetchAllTemps: ({ commit }, params) => {
    return api.fetchAllTemps(params).then((response) => {
      commit("FETCH_ALL_TEMPS", response.data);
      commit("SET_PAGINATION", response.data);
      return response;
    });
  },
  // Recieve list of Clients
  fetchClientsList: ({ commit }, q = {}) => {
    commit("SET_CLIENTS", []);
    return api
      .fetchAllClients(q)
      .then((response) => {
        commit("SET_CLIENTS", response.data);
      })
      .catch((error) => console.log(error));
  },
  // Recieve list of Clients with activity tags info
  fetchClientsListActivityTags: async ({ commit }, q = {}) => {
    // commit("SET_CLIENTS_ACTIVITY_TAGS", []);
    return api
      .fetchAllClientsActiveTagsInfo(q)
      .then((response) => {
        commit("SET_CLIENTS_ACTIVITY_TAGS", response.data.data.clients);
      })
      .catch((error) => console.log(error));
  },
  postClientsListActivityTags: ({ c }, data) =>
    api.postClientsListActivityTags(data),

  fetchActiveClientsListRet: ({ commit }, params) =>
    api.fetchActiveClientsList(params),
  /**
   * Fetch all categories with subcategories
   */
  fetchAllCategoriesList: ({ commit }) => {
    return api
      .fetchAllCategories()
      .then((response) => {
        commit("FETCH_ALL_CATEGORIES", response.data);
      })
      .catch((error) => console.log(error));
  },
  fetchAllRolesList: ({ commit }) => {
    return api
      .fetchAllRoles()
      .then((response) => {
        commit("FETCH_ALL_ROLES", response.data);
      })
      .catch((error) => console.log(error));
  },
  fetchMinRoles: ({ commit }) => {
    return api
      .fetchMinRoles()
      .then((response) => {
        commit("FETCH_MIN_ROLES", response.data);
      })
      .catch((error) => console.log(error));
  },
  fetchAllSpecialitiesList: ({ commit }) => {
    return api
      .fetchAllSpecialities()
      .then((response) => {
        commit("FETCH_SPECIALITIES", response.data);
      })
      .catch((error) => console.log(error));
  },
  /**
   * temp asks his own profile
   */
  fetchUserProfile: ({ commit }, params) => {
    // commit("FETCH_TEMP_PROFILE", []);
    return api
      .fetchUserProfile(params)
      .then((response) => {
        commit("FETCH_USER_PROFILE", response.data);
      })
      .catch((error) => console.log(error));
  },
  fetchTempProfile: ({ commit }, query) => {
    // commit("FETCH_TEMP_PROFILE", []);
    return api
      .fetchTempProfile(query)
      .then((response) => {
        commit("FETCH_TEMP_PROFILE", response.data);
        return response;
      })
      .catch((error) => console.log(error));
  },
  patchTemp: ({ commit }, payload) => {
    return api
      .patchTemp(payload.id, payload.data, payload.query)
      .then((response) => commit("PATCH_TEMP", response));
  },
  /**
   * Reset profile
   */
  resetTempProfile: ({ commit }) => {
    commit("FETCH_TEMP_PROFILE", []);
  },
  resetUserProfile: ({ commit }) => {
    commit("FETCH_USER_PROFILE", []);
  },
  /**
   * Used in input search filter
   * Get temps, satisfying the passed query param
   */
  fetchTemps: ({ commit }, query = "") => {
    return api
      .fetchTemps(query)
      .then((response) => {
        commit("FETCH_TEMPS", response.data);
        return response;
      })
      .catch((error) => console.log(error));
  },

  /**
   * Used in input search filter
   * Get temps, satisfying the passed query param
   *
   * It's different because it supports cancellation
   * Cancellation required when user types really fast in input boxes
   */
  fetchTempsCancellable: ({ commit }, { query, cancelTokenSource }) => {
    console.log(commit);
    console.log(query);
    console.log(cancelTokenSource);
    return api
      .fetchAllTempsCancellable(query, cancelTokenSource)
      .then((response) => {
        commit("FETCH_TEMPS", response.data);
        return response;
      });
  },
  getTempLocations: ({ c }, { query, cancelTokenSource }) => {
    return api.fetchAllTempsCancellable(query, cancelTokenSource);
  },
  getIncrements({ commit }, data) {
    return api.getIncrements(data);
  },
  postIncrements({ commit }, data) {
    return api.postIncrements(data);
  },
  postUpdateForCategory({ commit }, data) {
    return api.postUpdateForCategory(data);
  },

  // patchTempIncrement: ({ commit }, {id, payload, includes}) => {
  //   return api.patchTempIncrement(id, payload, includes).then(response => {
  //     commit('PATCH_TEMP_INCREMENTS', response.data)
  //     // Reeturn the user object
  //     return Promise.resolve(response.data.data)
  //   })
  // },
  postTempIncrement: ({ commit }, data) => {
    return api.postTempIncrement(data);
  },
  deleteTempIncrement: ({ commit }, data) => {
    return api.deleteTempIncrement(data);
  },
  createTimesheetEntry({ commit }, data) {
    return api.createTimesheetEntry(data);
  },
  shiftRequestPost({ c }, data) {
    return api.shiftRequestPost(data);
  },
  editUserAction({ c }, data) {
    return api.editUserAction(data);
  },
  postDocumentAction({ c }, data) {
    return api.postDocumentAction(data);
  },

  // deleteTempIncrement: ({ commit }, {id, payload, includes}) => {
  //   console.log('DELETE TEMP INCREMENT invoked ::', payload)
  //   return api.deleteTempIncrement(id, payload, includes).then(response => {
  //     commit('PATCH_TEMP_INCREMENTS', response.data)
  //     return Promise.resolve()
  //   })
  // },

  /**
   * Clears temps, and pagination object.
   */
  clearTemps: ({ commit }) => {
    commit("CLEAR_TEMPS");
  },
  /**
   * Finds agency worker (for: search as you type box)
   * keeps orignally selected as first option, to be able
   * to remove it easily, otherwise it would be lost when user
   * starts typing
   */
  findAgencyWorker: ({ commit }, payload) => {
    const originallySelectedWorker = payload.originallySelectedWorker;
    const transformedPayload = {
      name: payload.query,
      per_page: 50,
      gender: payload.gender,
      has_on_call: payload.has_on_call,
      has_sleepover: payload.has_sleepover,
    };

    payload.tempId && (transformedPayload.temp_id = payload.tempId);

    return api.fetchWorkers(transformedPayload).then(({ data: { data } }) => {
      let tempData = [...data];
      if (
        originallySelectedWorker &&
        Object.keys(originallySelectedWorker).length
      ) {
        // Makes originallySelectedWorker be in first position
        // Only add if not exists
        const elIndex = tempData.findIndex(
          (worker) => worker.id === originallySelectedWorker.id
        );
        if (elIndex !== -1) {
          tempData.splice(elIndex, 1);
        }
        tempData = [originallySelectedWorker, ...tempData];
      }

      commit("FETCH_AGENCY_WORKERS", tempData);
    });
  },

  getUsers: ({ commit }, payload) => {
    return api.getUsers(payload);
  },
  getFetchUsers: ({ c }, { query, cancelTokenSource }) => {
    return api.getUsersCancellable(query, cancelTokenSource);
  },
  patchUsers: async ({ commit }, payload) => {
    const response = await api.patchUsers(
      payload.id,
      payload.data,
      payload.query
    );
    if (payload.__isSlice) {
      commit("REMOVE_USER_FROM_LIST", payload.id);
    } else {
      commit("PATCH_USER", response);
    }
    return response;
  },
  fetchUsers: async ({ commit }, query = "") => {
    console.log("FetchUsers ACTION :: ", query);
    const response = await api.fetchUsers(query);
    commit("FETCH_USERS", response.data);
    return response;
  },

  fetchUsersCancellable: ({ commit }, params) => {
    return api.fetchUsersCancellable(params).then((response) => {
      commit("FETCH_USERS", response.data);
    });
  },

  createUser: ({ dispatch, commit }, payload) => {
    return api
      .createUser(payload.data)
      .then((response) => {
        // Removed for now, because pagination
        // commit('CREATE_USER', response)
        return dispatch("fetchUsers", { include: payload.query });
      })
      .catch((error) => {
        return Promise.reject(error.response.data);
      });
  },

  deleteUser: ({ commit }, payload) => {
    return api
      .deleteUser(payload.id)
      .then((response) => {
        commit("DELETE_USER", payload);
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error.response.data.errors);
      });
  },

  clearUsers: ({ commit }) => {
    console.log("Cleared users.");
    commit("CLEAR_USERS");
  },

  fetchTempShiftsLog: ({ commit }, q) => {
    return api.fetchTempShiftsLog(q).then((response) => {
      commit("SET_PAGINATION", response.data);
      return Promise.resolve(response);
    });
  },

  // documents optimization
  fetchDocumentLocations: ({ commit }, q) => {
    return api.fetchDocumentLocations(q).then((response) => {
      return Promise.resolve(response.data);
    });
  },

  fetchDocumentLocationsPerClient: ({ commit }, q) => {
    return api.fetchDocumentLocationsPerClient(q).then((response) => {
      return Promise.resolve(response.data);
    });
  },

  fetchDocumentCategoriesPerClient: ({ commit }, q) => {
    return api.fetchDocumentCategoriesPerClient(q).then((response) => {
      return Promise.resolve(response.data);
    });
  },

  // bookshifts optimization
  fetchLocationsPerClient: ({ commit }, q) => {
    return api.fetchLocationsPerClient(q).then((response) => {
      commit("STORE_LOCATIONS_PER_CLIENT", response.data);
      return Promise.resolve(response.data);
    });
  },

  fetchTempInfoFromTss: ({ commit }, q) => {
    // const url = q.isNexusMiddkeware
    // ? `/temps/get-info-by-external-id/${q.id}`
    // : `/temps/tssIdInfo/${q.id}`;
    const url = `/temps/get-info-by-external-id/${q.id}`;
    return api.fetchTempInfoFromTss(url);
  },
  getExternalServiceDisabledFields: ({ commit }) => {
    return api.getExternalServiceDisabledFields();
  },

  // Rates API actions
  postColumnForRates: ({ commit }, data) => {
    return api.postColumnForRates(data);
  },
  patchRates: ({ commit }, data) => {
    return api.patchRates(data);
  },
  deleteRates: ({ commit }, data) => {
    return api.deleteRates(data);
  },
  postRates: ({ commit }, data) => {
    return api.postRates(data);
  },
  reviewRates: ({ commit }, data) => api.reviewRates(data),
  reviewRatesGlobal: ({ commit }, data) => api.reviewRatesGlobal(data),

  // Rates other actions
  getRatesCategories: async ({ commit }, data) => {
    try {
      const res = await api.getRatesCategories(data);
      commit("STORE_RATES_CATEGORIES", res.data.data);
      return res;
    } catch (err) {
      return err;
    }
  },
  postRatesCategories: ({ commit }, data) => api.postRatesCategories(data),
  patchRatesCategories: ({ commit }, data) => api.patchRatesCategories(data),
  deleteRatesCategories: ({ commit }, data) => api.deleteRatesCategories(data),
  getRatesIncrements: ({ commit }, data) => api.getRatesIncrements(data),
  getRatesIncrementsCategory: ({ commit }, data) =>
    api.getRatesIncrementsCategory(data),
  postRatesIncrements: ({ commit }, data) => api.postRatesIncrements(data),
  deleteRatesIncrements: ({ commit }, data) => api.deleteRatesIncrements(data),
  patchRatesIncrements: ({ commit }, data) => api.patchRatesIncrements(data),
  getRatesSectors: async ({ commit }, data) => {
    const res = await api.getRatesSectors(data);
    commit("STORE_RATES_SECTORS", res.data.data);
    return res;
  },
  getRatesCalcItems: ({ commit }, data) => api.getRatesCalcItems(data),
  getRatesClients: ({ commit }, data) => api.getRatesClients(data),
  postRatesFinalize: ({ commit }, data) => api.postRatesFinalize(data),
  ratePickSector: ({ commit }, data) => commit("RATE_PICK_SECTOR", data),
  getColumnNamesLocl: async ({ commit }, data) => {
    const res = await api.getColumnNamesLocl(data);
    commit("SET_COLUMN_NAME_DATA", res.data.data);
    return res;
  },
  getInactiveSecurity: ({ c }, data) => api.getInactiveSecurity(data),
  putInactiveSecurity: ({ c }, data) => api.putInactiveSecurity(data),
  getViableSubcategories: ({ c }, data) => api.getViableSubcategories(data),
  getRatesClientsForClientManagement: ({ c }, data) =>
    api.getRatesClientsForClientManagement(data),
  getCsvDataTimesheet: ({ c }, data) => api.getCsvDataTimesheet(data),
  getBulkUploadProgressTimesheet: ({ c }, data) =>
    api.getBulkUploadProgressTimesheet(data),
  postFileShiftsTimesheet: ({ c }, data) => api.postFileShiftsTimesheet(data),
  getCsvDataOpenShifts: ({ c }, data) => api.getCsvDataOpenShifts(data),
  getBulkUploadProgressOpenShifts: ({ c }, data) =>
    api.getBulkUploadProgressOpenShifts(data),
  postFileShiftsOpenShifts: ({ c }, data) => api.postFileShiftsOpenShifts(data),
  getBreakTimes: ({ c }, data) => api.getBreakTimes(data),
  patchBreakTimes: ({ c }, data) => api.patchBreakTimes(data),
  getRelationshipTypes: ({ c }, data) => api.getRelationshipTypes(data),
  postMultipleShiftsDay: ({ c }, data) => api.postMultipleShiftsDay(data),
  getClientContactsList: async ({ commit }, data) => {
    const response = await api.getClientContactsList(data);
    commit("SET_PAGINATION", response.data);
    return response;
  },
  postClientContacts: ({ c }, data) => api.postClientContacts(data),
  putClientContacts: ({ c }, data) => api.putClientContacts(data),
  deleteClientContacts: ({ c }, data) => api.deleteClientContacts(data),
  getClientContactsTypesList: ({ c }, data) =>
    api.getClientContactsTypesList(data),
  postClientContactsTypes: ({ c }, data) => api.postClientContactsTypes(data),
  postClientContactsTypesVisibility: ({ c }, data) =>
    api.postClientContactsTypesVisibility(data),
  putClientContactsTypes: ({ c }, data) => api.putClientContactsTypes(data),
  getServiceFeeCalcItems: ({ c }, data) => api.getServiceFeeCalcItems(data),
  getServiceFees: ({ c }, data) => api.getServiceFees(data),
  getCheckInactiveFees: ({ c }, data) => api.getCheckInactiveFees(data),
  patchServiceFees: ({ c }, data) => api.patchServiceFees(data),
  deleteServiceFees: ({ c }, data) => api.deleteServiceFees(data),
  postActivateFees: ({ c }, data) => api.postActivateFees(data),
  getMaxEffectiveDate: ({ c }, data) => api.getMaxEffectiveDate(data),
  postMergeRatesServiceFees: ({ c }, data) =>
    api.postMergeRatesServiceFees(data),
  getClientCategories: ({ c }, data) => api.getClientCategories(data),
  getClientLocations: ({ c }, data) => api.getClientLocations(data),
  // patchInvoiceSplit: ({ c }, data) => api.patchInvoiceSplit(data),
  // deleteInvoiceSplit: ({ c }, data) => api.deleteInvoiceSplit(data),
  postCategory: ({ c }, data) => api.postCategory(data),
  patchCategory: ({ c }, data) => api.patchCategory(data),
  getConfig: ({ c }, data) => api.getConfig(data),
  postConfig: ({ c }, data) => api.postConfig(data),

  // Client Candidate Tags
  getTempTags: ({ c }, data) => api.getTempTags(data),
  postTempTags: ({ c }, data) => api.postTempTags(data),
  putTempTags: ({ c }, data) => api.putTempTags(data),
  deleteTempTags: ({ c }, data) => api.deleteTempTags(data),

  // Client Journal Note Types
  getNoteTypes: ({ c }, data) => api.getNoteTypes(data),
  postNoteType: ({ c }, data) => api.postNoteType(data),
  patchNoteType: ({ c }, data) => api.patchNoteType(data),
  systemNotes: ({ c }, data) => api.systemNotes(data),

  // Blob download with headers
  getFileBlob: ({ c }, data) => api.getFileBlob(data),
  postFileBlob: ({ c }, data) => api.postFileBlob(data),

  // Client Journal Notes
  getNotes: ({ c }, data) => api.getNotes(data),
  postNotes: ({ c }, data) => api.postNotes(data),
  postOneNote: ({ c }, data) => api.postOneNote(data),
  deleteNoteFile: ({ c }, data) => api.deleteNoteFile(data),
  getNoteReport: ({ c }, data) => api.getNoteReport(data),

  // Notification Centre
  fetchTemplates: ({ c }, data) => api.fetchTemplates(data),
  fetchTemplateContent: ({ c }, data) => api.fetchTemplateContent(data),
  postTemplate: ({ c }, data) => api.postTemplate(data),
  getNotificationCentreChildLogDetails: ({ c }, data) =>
    api.getNotificationCentreChildLogDetails(data),
  fetchMessages: ({ c }, data) => api.fetchMessages(data),
  postMessage: ({ c }, data) => api.postMessage(data),
  fetchSignatures: ({ c }, data) => api.fetchSignatures(data),
  fetchSignatureData: ({ c }, data) => api.fetchSignatureData(data),
  postSignature: ({ c }, data) => api.postSignature(data),
  putSignature: ({ c }, data) => api.putSignature(data),
  deleteSignature: ({ c }, data) => api.deleteSignature(data),
  getEmailFrom: ({ c }, data) => api.getEmailFrom(data),
  postEmailFrom: ({ c }, data) => api.postEmailFrom(data),

  // User Weekly Increment Counter
  getIncrementWeeksCounterValue: ({ c }, data) =>
    api.getIncrementWeeksCounterValue(data),
  postIncrementWeeksCounterValue: ({ c }, data) =>
    api.postIncrementWeeksCounterValue(data),
  getIncrementWeeksCounter: ({ c }, data) => api.getIncrementWeeksCounter(data),
  postIncrementWeeksCounter: ({ c }, data) =>
    api.postIncrementWeeksCounter(data),

  // Holiday Request
  getHolidayRequests: ({ c }, data) => api.getHolidayRequests(data),
  getPayBillHolidayReq: ({ c }, data) => api.getPayBillHolidayReq(data),
  postPayBillHolidayReq: ({ c }, data) => api.postPayBillHolidayReq(data),
  getClientsHolidayRequest: ({ c }, data) => api.getClientsHolidayRequest(data),
  getCategoriesHolidayRequest: ({ c }, data) =>
    api.getCategoriesHolidayRequest(data),
  postClientsHolidayRequest: ({ c }, data) =>
    api.postClientsHolidayRequest(data),
  postCategoriesHolidayRequest: ({ c }, data) =>
    api.postCategoriesHolidayRequest(data),

  // Invoices - Expenses | Shift
  getShiftBilling: ({ c }, data) => api.getShiftBilling(data),
  getInvoicesShiftBilling: ({ c }, data) => api.getInvoicesShiftBilling(data),
  getExpensesBilling: ({ c }, data) => api.getExpensesBilling(data),
  getInvoicesExpensesBilling: ({ c }, data) =>
    api.getInvoicesExpensesBilling(data),

  // Settings perms
  getRolesPermissions: ({ c }, data) => api.getRolesPermissions(data),
  postRolesPermissions: ({ c }, data) => api.postRolesPermissions(data),

  // Permission per location (Users Management)
  getWholeAdminRegions: ({ c }, data) => api.getWholeAdminRegions(data),
  getWholeAdminCategories: ({ c }, data) => api.getWholeAdminCategories(data),
  // getAdminRegions: ({ c }, data) => api.getAdminRegions(data),
  // getAdminClients: ({ c }, data) => api.getAdminClients(data),
  // getAdminLocations: ({ c }, data) => api.getAdminLocations(data),
  // getAdminCategories: ({ c }, data) => api.getAdminCategories(data),
  // getAdminSubcategories: ({ c }, data) => api.getAdminSubcategories(data),
  getUserSecCliLocPerm: ({ c }, data) => api.getUserSecCliLocPerm(data),
  postUserSecCliLocPerm: ({ c }, data) => api.postUserSecCliLocPerm(data),
  getUserCatPerm: ({ c }, data) => api.getUserCatPerm(data),
  postUserCatPerm: ({ c }, data) => api.postUserCatPerm(data),

  // Rates managment (front abstraction)
  getRatesFrontConfig: ({ c }, data) => api.getRatesFrontConfig(data),
  getRatesData: ({ c }, data) => api.getRatesData(data),

  // Expenses
  getExpenses: ({ c }, data) =>
    api.getExpenses(data.params, data.cancelTokenSource),
  getExpensesInfo: ({ c }, data) => api.getExpensesInfo(data),
  postExpensesSignOff: ({ c }, data) => api.postExpensesSignOff(data),
  postExpensesReject: ({ c }, data) => api.postExpensesReject(data),
  getExpensesFile: ({ c }, data) => api.getExpensesFile(data),
  getExpensesTypes: ({ c }, data) => api.getExpensesTypes(data),
  getExpenseInitialPrice: ({ c }, data) => api.getExpenseInitialPrice(data),

  // Expenses - general receipts
  getGeneralReceipts: ({ c }, data) => api.getGeneralReceipts(data),
  postGeneralReceipts: ({ c }, data) => api.postGeneralReceipts(data),
  putGeneralReceipts: ({ c }, data) => api.putGeneralReceipts(data),
  deleteGeneralReceipts: ({ c }, data) => api.deleteGeneralReceipts(data),

  // Expenses - rates
  getUserPaymentRates: ({ c }, data) => api.getUserPaymentRates(data),
  postUserPaymentRates: ({ c }, data) => api.postUserPaymentRates(data),
  deleteUserPaymentRates: ({ c }, data) => api.deleteUserPaymentRates(data),
  getUserPaymentRatesConfig: ({ c }, data) =>
    api.getUserPaymentRatesConfig(data),
  getUserPaymentRatesMaxEffectiveDate: ({ c }, data) =>
    api.getUserPaymentRatesMaxEffectiveDate(data),
  postUserPaymentFinalize: ({ c }, data) => api.postUserPaymentFinalize(data),

  // Expenses - paybill
  getPayBillExpensesReq: ({ c }, data) => api.getPayBillExpensesReq(data),
  postPayBillExpensesReq: ({ c }, data) => api.postPayBillExpensesReq(data),
  getPayBillExpensesGroupDownload: ({ c }, data) =>
    api.getPayBillExpensesGroupDownload(data),
  getPayBillExpensesCsv: ({ c }, data) => api.getPayBillExpensesCsv(data),
  getPayBillExpensesBackup: ({ c }, data) => api.getPayBillExpensesBackup(data),

  // Settings used in password validation

  passwordValidation: async ({ commit }, data) => {
    const params = { password: data };
    const res = await api.validatePassword(params);
    commit("VALIDATE_PASSWORD", res.data.data);
    return res;
  },

  // Verification Token
  checkToken: ({ c }, data) => api.checkToken(data),
  // Get workers list on notification center "Viewed by workers button"
  getWorkersListOpenedMessages: ({ c }, data) =>
    api.getWorkersListOpenedMessages(data),
  // Getting payroll shift logs on shift details Payment Logs tab
  getPayrollShiftLogs: ({ c }, data) => api.getPayrollShiftLogs(data),
  // Getting external history log shift details
  getExternalHistoryLog: ({ c }, data) => api.getExternalHistoryLog(data),
  // Backpay progress
  progressBackpay: ({ c }, data) => api.progressBackpay(data),
  // List of preview reports on Backpay page
  getListOfPreviewReports: ({ c }, data) => api.getListOfPreviewReports(data),
  //Backpay preview table confirm
  confirmPreviewReports: ({ c }, data) => api.confirmPreviewReports(data),
  //Backpay preview table cancel
  cancelPreviewReports: ({ c }, data) => api.cancelPreviewReports(data),
  // List of reports on Backpay page
  getListOfReports: ({ c }, data) => api.getListOfReports(data),
  // Check recalculation
  checkMassRecalculation: ({ c }, data) => api.checkMassRecalculation(data),
  // Set recalculations filters
  shiftsMassRecalculationFilters: ({ c }) =>
    api.shiftsMassRecalculationFilters(),
  // Start recalculation
  startMassRecalculation: ({ c }, data) => api.startMassRecalculation(data),
  // zip recalculations
  massRecalculationGetBackpayInvoicesOnJobId: ({ c }, data) =>
    api.massRecalculationGetBackpayInvoicesOnJobId(data),
  massRecalculationGetBackpayInvoiceFile: ({ c }, data) =>
    api.massRecalculationGetBackpayInvoiceFile(data),
  // revert
  revertCalculations: ({ c }, data) => api.revertCalculations(data),
  // Rate Management, Public Holidays tab, get years
  getPublicHolidaysYears: ({ c }, data) => api.getPublicHolidaysYears(data),
  // Rate Management, Public Holidays tab, get years
  getPublicHolidaysByYear: ({ c }, data) => api.getPublicHolidaysByYear(data),
  // Create new holiday
  postCreatePublicHoliday: ({ c }, data) => api.postCreatePublicHoliday(data),
  // Update holiday
  updateHoliday: ({ c }, data) => api.updateHoliday(data),
  // Delete holiday
  deleteHoliday: ({ c }, data) => api.deleteHoliday(data),
  // Copy holidays
  copyHolidays: ({ c }, data) => api.copyHolidays(data),

  // All Candidates, edit profile, cessation dates input
  getShiftsCessationDates: ({ c }, data) => api.getShiftsCessationDates(data),
  // All candidates, Rehire, send email
  sendWelcomeEmail: ({ c }, data) => api.sendWelcomeEmail(data),
  // Get country on details button, All Candidates page
  getCountries: ({ c }, data) => api.getCountries(data),
  // All candidates page, temp data on click rehire button
  externalTempinfo: ({ c }, data) => api.externalTempinfo(data),
  // All candidates page, confirm rehire and send email
  confirmRehire: ({ c }, data) => api.confirmRehire(data),

  // Client External ID
  getClientInfoExternalId: ({ c }, data) => api.getClientInfoExternalId(data),
  getExternalServiceClientsDisabledFields: ({ c }) =>
    api.getExternalServiceClientsDisabledFields(),
  // Get Shift Statuses
  getShiftStatuses: async ({ commit }, data) => {
    const res = await api.getShiftStatuses(data);
    commit("SETUP_SHIFT_STATUSES", res.data);
    return res;
  },

  // Global Rate Management
  getGlobalRatesCategories: ({ c }, data) => api.getGlobalRatesCategories(data),
  postGlobalIncrementsCategory: ({ c }, data) =>
    api.postGlobalIncrementsCategory(data),
  getGlobalRates: ({ c }, data) => api.getGlobalRates(data),
  patchGlobalRates: ({ c }, data) => api.patchGlobalRates(data),
  postGlobalRates: ({ c }, data) => api.postGlobalRates(data),
  deleteGlobalRates: ({ c }, data) => api.deleteGlobalRates(data),
  postGlobalColumnForRates: ({ c }, data) => api.postGlobalColumnForRates(data),
  patchGlobalRatesMulti: ({ c }, data) => api.patchGlobalRatesMulti(data),
  getGlobalRatesCalcItems: ({ c }, data) => api.getGlobalRatesCalcItems(data),
  getGlobalRatesClients: ({ c }, data) => api.getGlobalRatesClients(data),
  postGlobalRatesFinalize: ({ c }, data) => api.postGlobalRatesFinalize(data),
  getGlobalRatesMultiDropdown: ({ c }, data) =>
    api.getGlobalRatesMultiDropdown(data),
  getGlobalRatesCategoriesModal: ({ c }, data) =>
    api.getGlobalRatesCategoriesModal(data),
  getGlobalRatesValidate: ({ c }, data) => api.getGlobalRatesValidate(data),
  getGlobalRatesGaps: ({ c }, data) => api.getGlobalRatesGaps(data),
  getGlobalRatesGapsReport: ({ c }, data) => api.getGlobalRatesGapsReport(data),
  getRatesValidate: ({ c }, data) => api.getRatesValidate(data),
  getRatesGaps: ({ c }, data) => api.getRatesGaps(data),
  getRatesGapsReport: ({ c }, data) => api.getRatesGapsReport(data),
  getRatesCitReport: ({ c }, data) => api.getRatesCitReport(data),
  getGlobalRatesCitReport: ({ c }, data) => api.getGlobalRatesCitReport(data),
  deleteMultipleSpecificRates: ({ c }, data) =>
    api.deleteMultipleSpecificRates(data),
  deleteMultipleGlobalRates: ({ c }, data) =>
    api.deleteMultipleGlobalRates(data),

  // Expenses
  checkSignOff: ({ c }, data) => api.checkSignOff(data),
};
