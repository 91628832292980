<template lang="pug">
div(v-if="$can('view-payroll-shift-logs')")
  section.section
    p
    table.table.is-bordered.is-fixed(v-if="tableData")
      thead(v-if="tableData || tableData.length")
        tr
          th Action
          th Date of Action
          th Time of Action
          th.cell-wide Name
          th Shift ID
          th Date of Shift
          th Time of Shift
          th Subcategory
          th Total Gross Pay
          th Total Bill
          //- th Batch Number
      tbody.tableTr
        tr(v-if="loadingPaymentLogs")
          td.center-text(colspan="100")
            img(src="../../assets/images/comps/loader.svg")
        tr(v-if="!tableData || tableData.length == 0")
          td(colspan="100")
            | No data in table
        tr(v-for="item in tableData", v-if="!loadingPaymentLogs")
          td {{ item.type }}
          td {{ item.dateOfAction }}
          td {{ item.timeOfAction }}
          td.cell-wide 
            span {{ item.user.name }}
          td.cell-wide 
            span {{ item.shiftId }}
          td
            span(v-if="item.shiftDate") {{ item.shiftDate }}
          td
            span(v-if="item.shiftTime") {{ item.shiftTime }}
          td
            span(v-if="item.shiftSubcategory") {{ item.shiftSubcategory }}
          td
            span(v-if="item.shiftGrossPay") {{ item.shiftGrossPay }}
          td 
            span(v-if="item.shiftTotalBill") {{ item.shiftTotalBill }}
          //- td
          //-   span(v-if="item.shiftBatchNumber") {{ item.shiftBatchNumber }}

    pagination(
      v-if="pagination.total_pages",
      :total-pages="pagination.total_pages",
      :current-page="pagination.current_page",
      @page-changed="pageHasChanged"
    )
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ref, onMounted } from "vue";
import Pagination from "../../components/Pagination.vue";

export default {
  name: "PaymentLogs",
  components: {
    Pagination,
  },
  data() {
    return {
      tableData: [],
      pagination: {},
      loadingPaymentLogs: true,
    };
  },
  mounted() {
    this.getPaymentLogs();
  },
  methods: {
    ...mapActions({
      getPayrollShiftLogs: "getPayrollShiftLogs",
    }),
    async getPaymentLogs(clickedPage) {
      const params = {
        page: clickedPage,
        shift_id: this.$route.params.shiftId,
      };
      try {
        const res = await this.getPayrollShiftLogs(params);
        this.tableData = res.data.data;
        this.pagination = res.data.meta.pagination;
        this.loadingPaymentLogs = false;
      } catch (err) {
        console.log(err);
      }
    },
    async pageHasChanged(clickedPage) {
      await this.getPaymentLogs(clickedPage);
    },
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped></style>
