<template lang="pug">
section#notification-centre.section
  h1.title.is-3
    i.fa.icon-envelope
    | Notification Centre
  uni-tabs(:tabs="tabs", :active-tab="activeTab", @tab-clicked="onTabClick")
  .content
    //- keep-alive
    router-view
  simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>

<script>
export default {
  name: "NotificationCentrePage",
  computed: {
    tabs() {
      return [
        {
          label: "New Email Communication",
          routeName: "NotificationCentreEmail",
          routeQuery: {
            page: 1,
          },
        },
        {
          label: "New Push Message",
          routeName: "NotificationCentreMessage",
          routeQuery: {
            page: 1,
          },
        },
      ];
    },
  },
  data() {
    return {
      activeTab: {
        label: "New Email Communication",
      },
    };
  },
  methods: {
    onTabClick({ tab }) {
      this.activeTab.label = tab.label;
    },
  },
};
</script>

<style lang="scss" scoped></style>
