<template lang="pug">
.confirm-modal
  .message(:class="{error: modalProps?.error}") {{modalProps?.message}}
  .button-line
    button.button.is-generic-app-red(@click="handleRejectCancel") OK
</template>

<script setup>
const props = defineProps({
  notification: {
    type: String,
    required: false,
    default: '',
  },
  modalProps:{},
});
const emit = defineEmits(["close-confirm-modal"]);


function handleRejectCancel (){
    console.log('reject');
    emit('close-confirm-modal');
}


</script>

<style lang="scss" scoped>
.confirm-modal {
  display: flex;
  flex-direction: column;
  .message {  
    //   display: flex;
    //   justify-content: space-between;
    text-align: center;
    margin: 10px 0 0 0;
    background: transparent;
  }
  .error{
    color:#d9534f ;
  }
  .button-line {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    button {
      margin: 0 10px;
    }
  }
}
</style>
