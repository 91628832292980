<template lang="pug">
#category-form
  .name {{ labelCatNameAction }} subcategory name
  input.input(v-model="newName", autofocus, ref="nameInput", type="text")
  p.control.cat-hidden(v-if="!isActionAdd")
    //- label.ctrl.ctrl--checkbox 
    //-   span Hide category
    //-   input.ctrl--checkbox(v-model="isCatHidden", type="checkbox")
    //-   .ctrl__status
    label.lbl Hide category
    switches(
      v-model="isCatHidden",
      theme="bulma",
      color="blue",
      :emitOnMount="false"
    )
  button.button.is-generic-app-teal(
    :class="{ 'is-loading': isSavingForm }",
    :disabled="!newName || isSavingForm",
    @click="onApplyForm"
  ) Apply
  button.button.cancel(@click="cancelForm") Cancel
  simplert(:useRadius="true", :useIcon="true", ref="simplert", key="simplert")
</template>

<script>
import { mapActions } from "vuex";
import { parseErrors } from "../../../../lib/helpers/function";
export default {
  props: {
    sector: [Object, null],
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newName: "",
      isCatHidden: false,
      isSavingForm: false,
    };
  },
  computed: {
    category() {
      return this.formData.category;
    },
    parentCategory() {
      return this.formData.parent;
    },
    isActionAdd() {
      return this.formData.command === "add";
    },
    labelCatNameAction() {
      return this.isActionAdd ? "Add" : "Change";
    },
  },
  mounted() {
    this.$refs.nameInput.focus();
    if (this.formData.command === "edit") {
      this.newName = this.category.name;
      this.isCatHidden = this.category.hidden ? true : false;
    }
  },
  methods: {
    ...mapActions({
      patchRatesCategories: "patchRatesCategories",
      postRatesCategories: "postRatesCategories",
    }),
    cancelForm() {
      this.$emit("cancel");
    },
    confirmAlert(strAct) {
      // const oldMess = `Are you sure you want to ${strAct}?`
      const message =
        "Please note that the subcategory will be created with the Annual Leave Accumulation feature turned ON. In case this subcategory should not accumulate annual leave, it needs to be turned off manually.";

      return new Promise((resolve) => {
        const alert = {
          title: message,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => {
            resolve();
          },
        };
        this.$refs.simplert.openSimplert(alert);
      });
    },
    async onApplyForm() {
      if (this.canUseAlAccumulation) {
        await this.confirmAlert();
      }

      this.isSavingForm = true;
      const params = {
        sct_id: this.sector.id,
        cat_name: this.newName,
        cat_hidden: this.isCatHidden ? 1 : null,
      };

      try {
        if (this.formData.command === "edit" && this.parentCategory?.id) {
          params["cat_id_parent"] = this.parentCategory.id;
          params.catId = this.category.id;
          await this.patchRatesCategories(params);
          this.$toasted.info("Category changed").goAway(2000);
          this.$emit("change");
        } else {
          params["cat_id_parent"] = this.category.id;
          // params.catId = this.category.id
          await this.postRatesCategories(params);
          this.$toasted.info("New category created").goAway(2000);
          this.$emit("change");
        }
        this.isSavingForm = false;
      } catch (err) {
        console.log(err.message);
        this.isSavingForm = false;
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
      console.log("apply", params);
    },
  },
};
</script>

<style lang="scss" scoped>
#category-form {
  .name {
    margin: 0 0 20px 0;
  }

  .cat-hidden {
    margin: 10px 0;
    display: flex;
    align-items: center;

    .lbl {
      margin-right: 10px;
    }
  }

  .button {
    margin: 10px 0 0 0;

    &.cancel {
      margin-left: 10px;
    }
  }
}
</style>
