<template lang="pug">
.journal-info
  modal(
    v-if="modalVisible",
    @close="modalVisible = false",
    :title="modalTitle",
    :headless="false",
    size="650"
  )
    component(
      :is="inmodal",
      :modalProps="modalData",
      @cancel-modal="modalVisible = false",
      @dispose-modal="modalVisible = false"
    )
  .journal-form-wrap(v-if="canSaveEditAction")
    journal-form(
      :pendingRow="pendingRow",
      :temp-id="tempId",
      :client-id="clientId",
      :subjectTypes="subjectTypes",
      :clients="clients",
      @reset-form="pendingRow = {}",
      @saved-form="onClickFormSave"
    )
  .journal-middle-section
    .journal-filters-wrap
      journal-filters(
        :filter="filter",
        :subjectTypes="subjectTypes",
        :clients="clients",
        :temp-id="tempId",
        :client-id="clientId",
        @refresh-fetch="onRefreshFetch",
        @reset-filters="onReinitFilters"
      )
      //- :temps="temps",
    .journal-export-wrap
      .switch-wrapper
        template(v-if="canViewSystemNotes")
          label.label Include system generated notes in the report
            input(
              :checked="filter.withsystem",
              type="checkbox",
              style="margin-left: 10px",
              @change="onChangeFilterSystem"
            )
        template(v-if="canViewPrivateNotesPerm")
          label.label Include private notes in the report
            input(
              :checked="filter.isprivate",
              type="checkbox",
              style="margin-left: 10px",
              @change="onChangeFilterPrivateReport"
            )
      .btn-wrapper
        journal-export-report(
          :filter="filter",
          :journalList="journalList",
          :temp-id="tempId",
          :client-id="clientId",
          v-if="canExportReport"
        )
        span.info(
          v-tooltip="{ content: `In order to export the journal, the 'From' and 'To' filters are required.`, classes: 'export-status-tooltip' }"
        )
          span.fa.fa-question.popup
  uni-tabs(:tabs="filteredTabs", @tab-clicked="onSubTabClick")
  .journal-table-wrap
    router-view(
      :journalList="journalList",
      :subjectTypes="subjectTypes",
      :clients="clients",
      :temp-id="tempId",
      :client-id="clientId",
      :journalPagination="journalPagination",
      :tableFilter="tableFilter",
      :isLoadingJournals="isLoadingJournals",
      v-if="Object.keys(journalPagination).length",
      @edit-row="onEditRow",
      @file-check="onFileCheck",
      @show-html-body="onShowHtmlBody",
      @clicked-journal-page-manual-logs="onClickJournalPageManualLogs",
      @clicked-journal-page-system-logs="onClickJournalPageSystemLogs",
      @change-filter-private="onChangeFilterPrivateTable"
    )
  //- simplert(:useRadius="true", :useIcon="true", ref="simplert")
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import JournalForm from "../Journals/JournalForm.vue";
import JournalFilters from "../Journals/JournalFilters.vue";
import JournalTable from "../Journals/JournalTable.vue";
import JournalCheckNoteFiles from "../Journals/JournalCheckNoteFiles.vue";
import JournalExportReport from "../Journals/JournalExportReport.vue";
import JournalEntryDetails from "@/components/Journals/JournalEntryDetails.vue";
import { getFormattedTime, parseErrors } from "../../lib/helpers/function";
export default {
  components: {
    JournalForm,
    JournalFilters,
    JournalTable,
    JournalCheckNoteFiles,
    JournalExportReport,
    JournalEntryDetails,
  },
  props: {
    id: {
      type: [Number, String],
    },
    tempId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      perPaginationPage: 15,
      journalPagination: {
        total: 1,
        count: 0,
        per_page: 15,
        current_page: 1,
        total_pages: 1,
      },
      clientId: this.id,
      isLoadingJournalsSystem: false,
      isLoadingJournalsManual: false,
      journalList: [],
      subjectTypes: [],
      modalVisible: false,
      pendingRow: {},
      defaultFilter: {
        clients: [],
        temps: [],
        notetypes: [],
        note: null,
        isprivate: false,
        withsystem: true,
        from: {
          time: "",
        },
        to: {
          time: "",
        },
      },
      filter: {},
      tableFilter: { isprivate: true },
      abortController: new AbortController(),
    };
  },
  computed: {
    ...mapGetters({
      // temps: "getTemps",
      clients: "getAllClients",
      pagination: "getPagination",
    }),
    tabs() {
      if (this.isTempPage) {
        return [
          {
            label: "System Logs",
            routeName: "CandidateJournalsSystem",
            // permission: this.gPermissions.canViewTempDetails,
            routeQuery: {
              page: 1,
            },
          },
          {
            label: "Manual Input Logs",
            routeName: "CandidateJournalLogs",
            // permission: this.gPermissions.canJournalViewTempJournal,
            routeQuery: {
              page: 1,
            },
          },
        ];
      } else {
        return [
          {
            label: "System Logs",
            routeName: "ClientCandidateJournalsSystem",
            // permission: this.gPermissions.canViewTempDetails,
            routeQuery: {
              page: 1,
            },
          },
          {
            label: "Manual Input Logs",
            routeName: "ClientCandidateJournalLogs",
            // permission: this.gPermissions.canJournalViewTempJournal,
            routeQuery: {
              page: 1,
            },
          },
        ];
      }
    },
    filteredTabs() {
      return this.tabs.filter((tab) => {
        const hasPerm =
          "permission" in tab
            ? typeof tab.permission === "string"
              ? this.$can(tab.permission)
              : tab.permission
            : true;
        const hasSysPerm = tab.systemPermissions?.length
          ? tab.systemPermissions.every(
              (tabPerm) => this.initialStatus[tabPerm]
            )
          : true;
        const hasCondition = tab.conditions?.length
          ? tab.conditions.every(Boolean)
          : true;
        return hasPerm && hasSysPerm && hasCondition;
      });
    },
    isLoadingJournals() {
      return this.isSystemJournal
        ? this.isLoadingJournalsSystem
        : this.isLoadingJournalsManual;
    },
    isClientPage() {
      return Boolean(this.clientId);
    },
    isTempPage() {
      return Boolean(this.tempId);
    },
    canSaveEditAction() {
      if (this.clientId) {
        if (Object.keys(this.pendingRow).length) {
          return this.gPermissions.canJournalClientEdit;
        }
        return this.gPermissions.canJournalClientSave;
      } else if (this.tempId) {
        if (Object.keys(this.pendingRow).length) {
          return this.gPermissions.canJournalTempEdit;
        }
        return this.gPermissions.canJournalTempSave;
      }
      return false;
    },
    canExportReport() {
      if (this.isClientPage) {
        return this.gPermissions.canJournalClientExport;
      } else if (this.isTempPage) {
        return this.gPermissions.canJournalTempExport;
      }
      return false;
    },
    canViewPrivateNotesPerm() {
      if (this.isClientPage) {
        return this.gPermissions.canJournalClientViewPrivate;
      } else if (this.isTempPage) {
        return this.gPermissions.canJournalTempViewPrivate;
      }
      return false;
    },
    canViewPrivateNotesLogType() {
      return this.isSystemJournal ? false : true;
    },
    canViewSystemNotes() {
      if (this.isClientPage) {
        return this.gPermissions.canJournalClientViewSystem;
      } else if (this.isTempPage) {
        return this.gPermissions.canJournalTempViewSystem;
      }
      return false;
    },
    isSystemJournal() {
      return (
        this.$route.name === "CandidateJournalsSystem" ||
        this.$route.name === "ClientCandidateJournalsSystem"
      );
    },
  },
  watch: {
    $route: {
      handler(val, oldVal) {
        const currentPageNum = val.query.page && parseInt(val.query.page, 10);
        const oldPageNum = oldVal.query.page && parseInt(oldVal.query.page, 10);
        if (currentPageNum && oldPageNum && currentPageNum !== oldPageNum) {
          console.log(
            ":: Watcher -> Fetching current logs",
            currentPageNum,
            oldPageNum
          );
          this.fetchCurrentLogs();
        }
      },
      deep: true,
    },
  },
  activated() {
    // console.error("NOOO!");
  },
  beforeMount() {
    this.onReinitFilters();
  },
  mounted() {
    if (this.canViewPrivateNotesPerm) {
      // Default display all if can view them
      this.filter.isprivate = true;
    }
    if (this.canViewPrivateNotesPerm && this.canViewPrivateNotesLogType) {
      // Default display all if can view them
      this.tableFilter.isprivate = true;
    }

    this.fetchCurrentLogs();

    this.fetchJournalSubjects();
    if (this.tempId) {
      this.getClients();
    }
  },
  methods: {
    ...mapActions({
      getNotes: "getNotes",
      systemNotes: "systemNotes",
      getNoteTypes: "getNoteTypes",
      fetchClientsList: "fetchClientsList",
      // deleteNoteFile: "deleteNoteFile",
      // fetchTempsCancellable: "fetchTempsCancellable",
    }),
    // async onDeleteRow(jItem) {
    //   // Unused
    //   const label = "delete this note?";
    //   const params = {
    //     id: jItem.id,
    //     for_client_journal: Boolean(this.clientId),
    //   };
    //   try {
    //     await this.confirmApplyChanges(label);
    //     const res = this.deleteNoteFile(params);
    //     console.warn(res.data);
    //     this.fetchManualJournal();
    //   } catch (err) {
    //     console.log("Error", err.message);
    //     let errs = parseErrors(err);
    //     this.$toasted.error(errs).goAway(3500);
    //   }
    // },
    onEditRow(jItem) {
      this.pendingRow = {
        id: jItem.id,
        notetypes: jItem.notetypes,
        client_id: jItem.client_id,
        temp: jItem.temp,
        // temp_id: jItem.temp_id, // Don't use this because we don't fetch all temps
        note: jItem.note,
        isprivate: jItem.isPrivate,
        // files: jItem.files // Files are not included here
      };
      // Scroll to top element to display form
      document.documentElement.scrollTop = 0;
      document.documentElement.scrollLeft = 0;
    },
    onFileCheck(jItem) {
      console.warn("FILE CHECK", jItem);
      const formParams = {
        files: jItem.noteFiles,
        clientId: this.clientId,
        tempId: this.tempId,
      };
      this.showModal(
        "journal-check-note-files",
        formParams,
        "Check note files"
      );
    },
    onChangeFilterPrivateReport(evt) {
      this.filter.isprivate = evt.target.checked;
      // this.fetchManualJournal();
    },
    onChangeFilterSystem(evt) {
      this.filter.withsystem = evt.target.checked;
      // this.fetchManualJournal();
    },
    onChangeFilterPrivateTable(evt) {
      this.tableFilter.isprivate = evt.target.checked;
      this.fetchManualJournal();
    },
    // confirmApplyChanges(name = `apply changes?`) {
    //   return new Promise((resolve) => {
    //     const alert = {
    //       title: `Are you sure you want to ${name}`,
    //       message: "",
    //       type: "warning",
    //       useConfirmBtn: true,
    //       customConfirmBtnText: "Confirm",
    //       customConfirmBtnClass: "button is-danger",
    //       customCloseBtnText: "Cancel",
    //       customCloseBtnClass: "button is-outlined",
    //       onConfirm: () => resolve(),
    //     };
    //     this.$refs.simplert.openSimplert(alert);
    //     setTimeout(() => {
    //       // Timeout because of the transition
    //       const el = this.$refs.simplert.$el;
    //       const btns = el.querySelectorAll("button");
    //       if (btns.length) {
    //         btns[0].focus();
    //       }
    //     }, 600);
    //   });
    // },
    showModal(modal, modalData = {}, modalTitle = "Modal title") {
      console.log("Data we should send to a modal popup...", modalData);
      this.inmodal = modal;
      this.modalData = modalData;
      this.modalTitle = modalTitle; // dynamically set on @click
      this.modalVisible = true;
    },
    getJournalParams(page = null) {
      const params = {
        include: ["temp", "adminCreated", "noteFiles", "noteType"].join(","),
      };
      // Date filters
      const dateForApi = (t) => getFormattedTime(t, "YYYY-MM-DD", "DD/MM/YYYY");
      if (this.filter.from.time) {
        params.date_from = dateForApi(this.filter.from.time);
      }
      if (this.filter.to.time) {
        params.date_to = dateForApi(this.filter.to.time);
      }
      if (this.filter.notetypes.length) {
        params.notetypes = this.filter.notetypes.map((nt) => {
          return { id: nt.id };
        });
      }
      if (this.filter.created_by_ids) {
        params.created_by_ids = this.filter.created_by_ids;
      }
      if (this.filter.note) {
        params.search = this.filter.note;
      }
      // if (this.canViewSystemNotes) {
      //   params.withsystem = Boolean(this.filter.withsystem);
      // }

      if (this.clientId) {
        // Client management
        params.for_client_journal = true;
        params.clients = [{ id: parseInt(this.clientId, 10) }];
        if (this.filter.temps.length) {
          params.temps = this.filter.temps.map((temp) => {
            return { id: temp.id };
          });
        }
      }
      if (this.tempId) {
        // Temp management
        // params.temp_id = this.tempId;
        params.for_client_journal = false;
        params.temps = [{ id: parseInt(this.tempId, 10) }];
        if (this.filter.clients.length) {
          params.clients = this.filter.clients.map((client) => {
            return { id: client.id };
          });
        }
      }

      params.page = page || this.$route.query.page || 1;

      return params;
    },
    async fetchManualJournal(page = null) {
      this.isLoadingJournalsManual = true;
      if (this.abortController) {
        this.abortController.abort();
        this.abortController = new AbortController();
      }
      const data = {
        abortSignal: this.abortController?.signal,
        params: this.getJournalParams(page),
      };
      if (this.canViewPrivateNotesPerm && this.canViewPrivateNotesLogType) {
        data.params.isprivate = Boolean(this.tableFilter.isprivate);
      }

      try {
        const res = await this.getNotes(data);
        this.journalPagination = res.data.meta.pagination;
        this.journalList = (res.data && res.data.data) || [];
      } catch (err) {
        console.error("Error: ", err.message);
        const errs = parseErrors(err, "", true);
        this.$toasted.error(errs).goAway(3500);
      } finally {
        this.isLoadingJournalsManual = false;
      }
    },
    async fetchSystemJournal(page = null) {
      this.isLoadingJournalsSystem = true;
      if (this.abortController) {
        this.abortController.abort();
        this.abortController = new AbortController();
      }
      const data = {
        abortSignal: this.abortController?.signal,
        params: this.getJournalParams(page),
      };
      data.params.per_page = 15;

      try {
        const res = await this.systemNotes(data);
        this.journalPagination = res.data.meta;
        this.journalList = (res.data && res.data.data) || [];
      } catch (err) {
        console.error("Error: ", err.message);
        const errs = parseErrors(err, "", true);
        this.$toasted.error(errs).goAway(3500);
      } finally {
        this.isLoadingJournalsSystem = false;
      }
    },
    async fetchJournalSubjects() {
      const params = {
        isClient: Boolean(this.clientId),
      };

      try {
        const res = await this.getNoteTypes(params);
        this.subjectTypes = res.data.data;
      } catch (err) {
        console.warn(err.message);
      }
    },
    async getClients() {
      const clientIncludes = ["locations", "withArchived"].join();
      const params = {
        includes: clientIncludes,
        per_page: 999,
      };
      try {
        await this.fetchClientsList(params);
      } catch (err) {
        console.warn(err.message);
      }
    },
    async onClickFormSave(fd) {
      // Form saved
      this.fetchCurrentLogs();
    },
    onRefreshFetch() {
      this.fetchCurrentLogs();
    },
    fetchCurrentLogs() {
      if (this.isSystemJournal) {
        this.fetchSystemJournal();
      } else {
        this.fetchManualJournal();
      }
    },
    onReinitFilters() {
      this.filter = JSON.parse(JSON.stringify(this.defaultFilter));
    },
    onShowHtmlBody(data) {
      // Open modal with html
      this.modalSize = 850;
      this.showModal("JournalEntryDetails", data, `Journal entry details`);
    },
    onClickJournalPageManualLogs(clickedPage) {
      this.journalPagination.current_page = clickedPage;
      this.fetchManualJournal(clickedPage);
    },
    onClickJournalPageSystemLogs(clickedPage) {
      this.journalPagination.current_page = clickedPage;
      this.fetchSystemJournal(clickedPage);
    },
    onSubTabClick({ tab }) {
      const isSystemTab =
        tab.routeName === "CandidateJournalsSystem" ||
        tab.routeName === "ClientCandidateJournalsSystem";
      if (isSystemTab) {
        this.fetchSystemJournal();
      } else {
        this.fetchManualJournal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.journal-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
  .uni-tabs-wrap {
    width: 100%;
    padding: 20px 0 10px 0;
  }

  .journal-table-wrap {
    display: flex;
    width: 100%;
  }

  .journal-filters-wrap {
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }

  .journal-export-wrap {
    display: flex;
    // flex-direction: column;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
    gap: 10px;
    margin-left: auto;
    border: 1px solid silver;
    padding: 10px;

    .switch-wrapper {
      display: flex;
      flex-direction: column;

      label {
        margin: 0;
      }
    }

    .btn-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    .info {
      border-radius: 50%;
      border: 1px solid silver;
      margin: 0 0 5px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 27px;
      min-height: 27px;
      width: 27px;
      height: 27px;
      .fa {
        color: #7c91ae;
      }
    }

    .label {
      margin: 0 2px 8px 0;
      white-space: nowrap;
    }
  }

  .journal-table-wrap {
    overflow: auto;
    min-height: 100px;

    .spinner-wrap {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }

  .journal-middle-section {
    display: flex;
    gap: 20px;
    // flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 768px) {
    align-items: flex-start;

    .journal-table-wrap {
      min-height: 200px;
    }
  }
}
.export-status-tooltip {
  // border-radius: 10px;
  border: 1px solid transparent;
  color: white;
  font-weight: 600;
}
</style>
<style lang="scss">
#client-details-page {
  .journal-info {
    padding: 20px 0 0 0;
  }
}
</style>
