<template lang="pug">
div(v-click-outside="closeOutside")
  .after-wrapper
    .message {{ modalProps.afterApiData.checkRecalculateData.after_api }}
    .button
      button.button.is-generic-app-blue(@click="closeModal") OK
</template>
<script>
export default {
  props: ["modalProps"],
  methods: {
    closeModal() {
      this.$emit("dispose-modal");
    },
    closeOutside() {
      if (this.modalProps.outside) {
        this.closeModal();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.after-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0 10px 0;
  .message {
    background-color: white;
    text-align: center;
  }
  .button {
    border: none;
    button {
      width: 60px;
      height: 2.75em;
      border: none;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
