<template lang="pug">
#rates-manage-rates-table
  img.loader(
    src="../../../../assets/images/comps/loader.svg",
    v-if="isLoadingRates && !isMiniLoadingRates"
  )
  simplert(:useRadius="true", :useIcon="true", ref="simplert")
  .table-wrap(
    @scroll="scrollingFunc",
    v-if="!isLoadingRates || isMiniLoadingRates"
  )
    table.rates-table.table.is-striped
      thead
        tr
          th(v-if="canDisplayColumn('effectiveStartDate', type)")
            span {{ getColumnData("effectiveStartDate", type).label }}
          th(
            style="white-space: nowrap",
            v-if="canDisplayColumn('effectiveEndDate', type)"
          )
            span {{ getColumnData("effectiveEndDate", type).label }}
          th(v-if="canDisplayColumn('subcategory', type)")
            multi-select.column-select(
              :options="subcategoriesOptionsFiltered",
              :searchable="true",
              :options-limit="999",
              select-label="",
              selected-label="",
              deselect-label="",
              track-by="id",
              label="name",
              :multiple="false",
              group-label="name",
              group-values="subcategories",
              v-model="selectedColumnSubCategoryOption",
              :placeholder="getColumnData('subcategory', type).placeholder",
              @input="onChangeSubcategory"
            )
            //- span.sort-buttons
              sort-buttons(
                column-name="cat_name",
                v-model="selectedSortColumn"
              //- )
          //- span Category
          th(v-if="canDisplayColumn('increment', type)")
            span {{ getColumnData("increment", type).label }}
            //- span.sort-buttons
              sort-buttons(
                column-name="inc_name",
                v-model="selectedSortColumn"
              )
          th(
            v-if="canDisplayColumn('rateCode', type)",
            style="min-width: 110px"
          )
            span {{ getColumnData("rateCode", type).label }}
            //- span.sort-buttons
              sort-buttons(
                column-name="rat_code",
                v-model="selectedSortColumn"
              //- )
          //- th 
            span Rate From
            span.sort-buttons
              sort-buttons(
                column-name="rat_from",
                v-model="selectedSortColumn"
              //- )
          th(
            v-if="canDisplayColumn('rateKey', type)",
            style="min-width: 110px"
          )
            span {{ getColumnData("rateKey", type).label }}
            //- span.sort-buttons
              sort-buttons(column-name="rat_key", v-model="selectedSortColumn")
          th(
            v-if="canDisplayColumn('ratePayscale', type)",
            style="min-width: 110px"
          )
            span {{ getColumnData("ratePayscale", type).label }}
            //- span.sort-buttons
              sort-buttons(
                column-name="rat_payscale",
                v-model="selectedSortColumn"
              //- )
          th(
            v-if="canDisplayColumn('rateSalary', type)",
            style="min-width: 110px"
          )
            span {{ getColumnData("rateSalary", type).label }}
          th(
            v-if="canDisplayColumn('rateNethours', type)",
            style="min-width: 110px"
          )
            span {{ getColumnData("rateNethours", type).label }}
          th(
            v-if="canDisplayColumn('rateALdays', type)",
            style="min-width: 110px"
          )
            span {{ getColumnData("rateALdays", type).label }}
          th(
            v-if="canDisplayColumn('ratePHdays', type)",
            style="min-width: 110px"
          )
            span {{ getColumnData("ratePHdays", type).label }}
          th(
            v-if="canDisplayColumn('rateBillableDays', type)",
            style="min-width: 110px"
          )
            span {{ getColumnData("rateBillableDays", type).label }}
          th#dynamic-column
            //- v-if="canDisplayColumn('columnForRates', type)"
            multi-select.column-select(
              :options="columnRatesOptions",
              :searchable="true",
              :options-limit="999",
              select-label="",
              selected-label="",
              deselect-label="",
              track-by="id",
              label="name",
              :multiple="false",
              v-model="selectedColumnRateOption",
              placeholder="Column for rates"
            )
          th(style="min-width: 80px")
            .timeContainer(v-if="showTimeInput") Time from
          th(style="min-width: 80px")
            .timeContainer(v-if="showTimeInput") Time to
          th Undo
      tbody
        tr(v-if="isMiniLoadingRates")
          td.center-text(colspan="100")
            img(src="../../../../assets/images/comps/loader.svg")
        transition(name="fade", @after-enter="onAfterEnterTableRow")
          tr.new-row(v-if="isAddingRow")
            td.blank
            td.blank
            td.category-select-wrap(
              v-if="canDisplayColumn('subcategory', type)"
            )
              multi-select.category-select(
                :options="categories",
                select-label="",
                selected-label="",
                deselect-label="",
                track-by="id",
                label="name",
                :multiple="false",
                group-label="name",
                group-values="subcategories",
                :value="getCategoryFromRate(rowData, false)",
                :placeholder="getColumnData('subcategory', type).placeholder",
                :disabled="!getColumnData('subcategory', type)?.enabled_on_create",
                @select="setCategoryRate($event, rowData, false)"
              )
            td.increments-select-wrap(
              v-if="canDisplayColumn('increment', type)"
            )
              multi-select.increments-select(
                :options="incrementOptionsFiltered(rowData)",
                :searchable="true",
                select-label="",
                selected-label="",
                deselect-label="",
                track-by="id",
                label="name",
                :multiple="false",
                group-label="name",
                group-values="increments",
                :value="getIncrementFromRate(rowData, false)",
                :placeholder="getColumnData('increment', type).placeholder",
                :disabled="!getColumnData('increment', type)?.enabled_on_create",
                @select="setIncrementRate($event, rowData, false)"
              )
            td(v-if="canDisplayColumn('rateCode', type)")
              input.input(
                v-model="rowData.rat_code",
                type="text",
                :disabled="!getColumnData('rateCode', type)?.enabled_on_create"
              )
            //- td
            //-   input.input(v-model="rowData.rat_from", type="text")
            td(v-if="canDisplayColumn('rateKey', type)")
              input.input(
                v-model="rowData.rat_key",
                type="text",
                :disabled="!getColumnData('rateKey', type)?.enabled_on_create"
              )
            td(v-if="canDisplayColumn('ratePayscale', type)")
              input.input(
                v-model="rowData.rat_payscale",
                type="text",
                :disabled="!getColumnData('ratePayscale', type)?.enabled_on_create"
              )
            td(v-if="canDisplayColumn('rateSalary', type)")
              input.input(
                v-model="rowData.rat_salary",
                type="text",
                :disabled="!getColumnData('rateSalary', type)?.enabled_on_create"
              )
            td(v-if="canDisplayColumn('rateNethours', type)")
              input.input(
                v-model="rowData.rat_nethours",
                type="text",
                :disabled="!getColumnData('rateNethours', type)?.enabled_on_create"
              )
            td(v-if="canDisplayColumn('rateALdays', type)")
              input.input(
                v-model="rowData.rat_aldays",
                type="text",
                :disabled="!getColumnData('rateALdays', type)?.enabled_on_create"
              )
            td(v-if="canDisplayColumn('ratePHdays', type)")
              input.input(
                v-model="rowData.rat_phdays",
                type="text",
                :disabled="!getColumnData('ratePHdays', type)?.enabled_on_create"
              )
            td(v-if="canDisplayColumn('rateBillableDays', type)")
              input.input(
                v-model="rowData.rat_billabledays",
                type="text",
                :disabled="!getColumnData('rateBillableDays', type)?.enabled_on_create"
              )
            //- // Used for sending dynamic column value for POST
            //- td
              input.input(
                v-model.number="rowData.rvl_value",
                type="text",
                v-if="selectedColumnRateOption",
                @input="checkIfSpecialAvailable"
              )
              span(v-if="!selectedColumnRateOption")
            td.blank(colspan="100")
        tr(
          v-for="(rate, index) in allRatesFilteredSorted",
          :key="rate.id",
          :class="[{ changed: isCellModified(rate) }]",
          @keydown="onKeyDownRateCell",
          @keyup="onKeyUpRateCell($event, rate)",
          @focusout="onFocusOutRateCell($event, rate)"
        )
          td(
            data-td="rat_from",
            v-if="canDisplayColumn('effectiveStartDate', type)"
          )
            span {{ getEffectiveDateFromRate(rate) }}
          td(
            data-td="rat_to",
            style="white-space: nowrap",
            v-if="canDisplayColumn('effectiveEndDate', type)"
          )
            span {{ getEffectiveDateToRate(rate) }}
            span.info(
              style="margin-left: 10px",
              v-tooltip="{ content: generateUpdatedTitle(rate), classes: 'info-updated-tooltip' }"
            )
              span.fa.fa-info.popup
          td.category-select-wrap(
            data-td="category",
            v-if="canDisplayColumn('subcategory', type)"
          )
            multi-select.category-select(
              :options="categories",
              select-label="",
              selected-label="",
              deselect-label="",
              track-by="id",
              label="name",
              :multiple="false",
              group-label="name",
              group-values="subcategories",
              :allow-empty="false",
              :value="getCategoryFromRate(rate)",
              title="Change inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
              :placeholder="getColumnData('subcategory', type).placeholder",
              :disabled="!getColumnData('subcategory', type)?.enabled_on_update",
              @select="setCategoryRate($event, rate)"
            )
            //- v-model="rate.cat_id",
          //- REVIEW - added condition to disable inputs if the selected date is before today
          td.increments-select-wrap(
            data-td="increment",
            v-if="canDisplayColumn('increment', type)"
          )
            multi-select.increments-select(
              :options="incrementOptionsFilteredAvailable(rate)",
              :searchable="true",
              select-label="",
              selected-label="",
              deselect-label="",
              track-by="id",
              label="name",
              :multiple="false",
              group-label="name",
              group-values="increments",
              :allow-empty="false",
              :value="getIncrementFromRate(rate)",
              title="Change inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
              :disabled="!getColumnData('increment', type)?.enabled_on_update || isDateBeforeToday()",
              :placeholder="getColumnData('increment', type).placeholder",
              @select="setIncrementRate($event, rate)"
            )
            //- v-model="rate.inc_id",
          td(data-td="rat_code", v-if="canDisplayColumn('rateCode', type)")
            input.input(
              :value="getValueFromRateModel(rate, 'rat_code')",
              type="text",
              title="Move from inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
              :disabled="!getColumnData('rateCode', type)?.enabled_on_update || isDateBeforeToday()",
              @input="onInputRateCell",
              @change="onChangeRateCell($event, rate, index, 'rat_code')",
              @paste="onPasteRateCell($event, rate, index, 'rat_code')"
            )
            //- v-model.lazy="rate.rat_code",
          //- td {{ getRateDate(rate) }}
          td(data-td="rat_key", v-if="canDisplayColumn('rateKey', type)")
            input.input(
              :value="getValueFromRateModel(rate, 'rat_key')",
              type="text",
              title="Move from inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
              :disabled="!getColumnData('rateKey', type)?.enabled_on_update || isDateBeforeToday()",
              @input="onInputRateCell",
              @change="onChangeRateCell($event, rate, index, 'rat_key')",
              @paste="onPasteRateCell($event, rate, index, 'rat_key')"
            )
          td(
            data-td="rat_payscale",
            v-if="canDisplayColumn('ratePayscale', type)"
          )
            input.input(
              :value="getValueFromRateModel(rate, 'rat_payscale')",
              type="text",
              title="Move from inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
              :disabled="!getColumnData('ratePayscale', type)?.enabled_on_update || isDateBeforeToday()",
              @input="onInputRateCell",
              @change="onChangeRateCell($event, rate, index, 'rat_payscale')",
              @paste="onPasteRateCell($event, rate, index, 'rat_payscale')"
            )
          td(data-td="rat_salary", v-if="canDisplayColumn('rateSalary', type)")
            input.input(
              :value="getValueFromRateModel(rate, 'rat_salary')",
              type="text",
              title="Move from inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
              :disabled="!getColumnData('rateSalary', type)?.enabled_on_update || isDateBeforeToday()",
              @input="onInputRateCell",
              @change="onChangeRateCell($event, rate, index, 'rat_salary')",
              @paste="onPasteRateCell($event, rate, index, 'rat_salary')"
            )
          td(
            data-td="rat_nethours",
            v-if="canDisplayColumn('rateNethours', type)"
          )
            input.input(
              :value="getValueFromRateModel(rate, 'rat_nethours')",
              type="text",
              title="Move from inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
              :disabled="!getColumnData('rateNethours', type)?.enabled_on_update || isDateBeforeToday()",
              @input="onInputRateCell",
              @change="onChangeRateCell($event, rate, index, 'rat_nethours')",
              @paste="onPasteRateCell($event, rate, index, 'rat_nethours')"
            )
          td(data-td="rat_aldays", v-if="canDisplayColumn('rateALdays', type)")
            input.input(
              :value="getValueFromRateModel(rate, 'rat_aldays')",
              type="text",
              title="Move from inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
              :disabled="!getColumnData('rateALdays', type)?.enabled_on_update || isDateBeforeToday()",
              @input="onInputRateCell",
              @change="onChangeRateCell($event, rate, index, 'rat_aldays')",
              @paste="onPasteRateCell($event, rate, index, 'rat_aldays')"
            )
          td(data-td="rat_phdays", v-if="canDisplayColumn('ratePHdays', type)")
            input.input(
              :value="getValueFromRateModel(rate, 'rat_phdays')",
              type="text",
              title="Move from inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
              :disabled="!getColumnData('ratePHdays', type)?.enabled_on_update || isDateBeforeToday()",
              @input="onInputRateCell",
              @change="onChangeRateCell($event, rate, index, 'rat_phdays')",
              @paste="onPasteRateCell($event, rate, index, 'rat_phdays')"
            )
          td(
            data-td="rat_billabledays",
            v-if="canDisplayColumn('rateBillableDays', type)"
          )
            input.input(
              :value="getValueFromRateModel(rate, 'rat_billabledays')",
              type="text",
              title="Move from inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
              :disabled="!getColumnData('rateBillableDays', type)?.enabled_on_update || isDateBeforeToday()",
              @input="onInputRateCell",
              @change="onChangeRateCell($event, rate, index, 'rat_billabledays')",
              @paste="onPasteRateCell($event, rate, index, 'rat_billabledays')"
            )
          td.wid-33(data-td="dynamic_col")
            //- v-if="canDisplayColumn('columnForRates', type)"
            img.loader-small(
              src="../../../../assets/images/comps/loader.svg",
              v-if="isLoadingAllDynValues || isLoadingDynColValuesArr.includes(rate.id)"
            )
            input.input.wid-100(
              type="text",
              :value="getDynamicCalcColumn(rate, 'rvl_value')",
              v-if="canShowDynamicCalcColumn(rate, 'rvl_value')",
              title="Move from inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
              :disabled="!isEnabledCalcItems(rate, 'rvl_value') || isDateBeforeToday()",
              @input="onInputRateCell",
              @change="onChangeRateCell($event, rate, index, 'rvl_value', true)",
              @paste="onPasteRateCell($event, rate, index, 'rvl_value', true)"
            )
            //- @focusout="setDynColumnVal($event, rate, 'rvl_value')",
            //- span {{ getDynamicCalcColumn(rate) }}
          td.wid-33(data-td="time_from")
            .timeContainer(v-if="showTimeInput")
              img.loader-small(
                src="../../../../assets/images/comps/loader.svg",
                v-if="isLoadingAllDynValues || isLoadingDynColValuesArr.includes(rate.id)"
              )
              input.input.wid-100(
                type="text",
                :value="getDynamicCalcColumn(rate, 'rvl_timefrom')",
                v-if="canShowDynamicCalcColumn(rate, 'rvl_timefrom')",
                title="Move from inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
                :disabled="!isEnabledCalcItems(rate, 'rvl_timefrom') || isDateBeforeToday()",
                @input="onInputRateCell",
                @change="onChangeRateCell($event, rate, index, 'rvl_timefrom', true)",
                @paste="onPasteRateCell($event, rate, index, 'rvl_timefrom', true)"
              )
              //- @focusout="setDynColumnVal($event, rate, 'rvl_timefrom')",
          td.wid-33(data-td="time_to")
            .timeContainer(v-if="showTimeInput")
              img.loader-small(
                src="../../../../assets/images/comps/loader.svg",
                v-if="isLoadingAllDynValues || isLoadingDynColValuesArr.includes(rate.id)"
              )
              input.input.wid-100(
                type="text",
                :value="getDynamicCalcColumn(rate, 'rvl_timeto')",
                v-if="canShowDynamicCalcColumn(rate, 'rvl_timeto')",
                title="Move from inputs by using [ALT + UP ARROW] and [ALT + DOWN ARROW]",
                :disabled="!isEnabledCalcItems(rate, 'rvl_timeto') || isDateBeforeToday()",
                @input="onInputRateCell",
                @change="onChangeRateCell($event, rate, index, 'rvl_timeto', true)",
                @paste="onPasteRateCell($event, rate, index, 'rvl_timeto', true)"
              )
              //- @focusout="setDynColumnVal($event, rate, 'rvl_timeto')",
          td(data-td="delete_row", style="min-width: 70px")
            .undo-buttons(v-if="isCellModified(rate)")
              button.button.is-danger.is-low.is-tiny(
                title="This action will undo the unapplied changes for this row.",
                @click="onDeleteRow($event, rate)"
              ) Undo
              input(
                type="checkbox",
                :checked="undoPendingRates.includes(rate.id)",
                :value="rate.id",
                v-if="type.type !== 'SLPO'",
                @change="onChangePendingUndoRates($event, rate)"
              )
        tr(v-if="isEndTableLoading")
          td.center-text(colspan="100")
            img(src="../../../../assets/images/comps/loader.svg")
  .loading-blocker(v-if="isPatchingRowsLoading") 
    img(src="../../../../assets/images/comps/loader.svg")
</template>

<script>
const __defaultRowsCount = 100;
import _ from "underscore";
import MultiSelect from "vue-multiselect";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import {
  parseErrors,
  parseDateObjWithTimeZone,
} from "../../../../lib/helpers/function";
import { SHIFT_TYPES } from "../../../../shared/mapData";
import {
  canDisplayColumn,
  getColumnData,
  getCalcItems,
} from "@/components/Rates/components/Rates/RatesTableFuncs";
import { getFormattedDateTime } from "@/lib/helpers/helpers";
import { Errors } from "@/lib/helpers/Errors";

export default {
  components: {
    MultiSelect,
  },
  props: {
    client: {
      type: Object,
      required: false,
    },
    categoryId: {
      type: Number || undefined,
      required: false,
    },
    rowData: {
      type: Object,
      required: true,
    },
    fetchRateDataCo: {
      type: Number,
      default: 0,
    },
    newCreatedRow: {
      type: Object,
      default: () => ({}),
    },
    showChangedOnly: {
      type: Boolean,
      default: false,
    },
    isAddingRow: Boolean,
    type: Object,
    rateDateSelected: String,
    undoPendingRates: Array,
  },
  data() {
    return {
      allRates: [],
      columnRatesOptions: [],
      columnRateValues: {},
      // rowData: {},
      // categories: [],
      incrementOptions: [],
      selectedColumnRateOption: null,
      selectedColumnSubCategoryOption: null,
      isLoadingRates: false,
      isMiniLoadingRates: false,
      isLoadingAllDynValues: false,
      isLoadingDynColValuesArr: [],
      loadRowsCount: __defaultRowsCount,
      isEndTableLoading: false,
      selectedSortColumn: {
        column: "",
        direction: "",
      },
      fixedChangedDate: "3000-01-01 00:00:00",
      touchedRows: [],
      isPatchingRowsLoading: false,
      timeoutSave: null,
      errors: new Errors(),
      canDisplayColumn,
      getColumnData,
      getCalcItems,
    };
  },
  computed: {
    ...mapState({
      categories: (state) => state.rateCategories,
      rateSectorSelected: (state) => state.rateSectorSelected,
    }),
    allRatesSorted() {
      const defaultSort = (a, b) => {
        // Check categories
        if (a.cat_id < b.cat_id) {
          return -1;
        } else if (a.cat_id > b.cat_id) {
          return 1;
        } else if (a.cat_id === b.cat_id) {
          // Check increments
          if (a.inc_id < b.inc_id) {
            return -1;
          } else if (a.inc_id > b.inc_id) {
            return 1;
          }
        }
        return 0;
      };

      return [...this.allRates].sort((a, b) => {
        const column = this.selectedSortColumn.column;
        const direction = this.selectedSortColumn.direction;
        if (!column) {
          // Default sort [cat_id ASC, inc_id ASC]
          return defaultSort(a, b);
        }

        if (direction === "asc") {
          return a[column] > b[column] ? 1 : -1;
        } else if (direction === "desc") {
          return a[column] > b[column] ? -1 : 1;
        }
        return 0;
      });
    },
    allRatesFilteredSorted() {
      // These are the rates that are displayed to the user
      let rowsToShow = [];
      if (this.showChangedOnly) {
        rowsToShow = this.allRatesChangedOnly;
      } else {
        rowsToShow = this.allRatesSorted;
      }
      return rowsToShow.slice(0, this.loadRowsCount);
    },
    allRatesChangedOnly() {
      return this.allRatesSorted.filter(this.isCellModified);
    },
    showTimeInput() {
      // WIP [Back] Change to get data from statuses [per agency]
      // This should be changed if other value types start supporting
      // rvl_timeto and rvl_timefrom
      if (!this.selectedColumnRateOption) {
        return false;
      }
      return this.selectedColumnRateOption.usetimerange;
    },
    getFlatCategories() {
      const key = "subcategories";
      return this.categories.reduce((acc, curr) => {
        if (Array.isArray(curr[key]) && curr[key].length) {
          acc.push(...curr[key]);
        }
        return acc;
      }, []);
    },
    getFlatIncrements() {
      const key = "increments";
      return this.incrementOptions.reduce((acc, curr) => {
        if (Array.isArray(curr[key]) && curr[key].length) {
          acc.push(...curr[key]);
        }
        return acc;
      }, []);
    },
    getAllRateIds() {
      return this.allRatesSorted.map((rate) => rate.id);
    },
    subcategoriesOptionsFiltered() {
      if (this.categoryId) {
        return this.categories.filter((cat) => cat.id === this.categoryId);
      }
      return this.categories;
    },
    isAnyRatePending() {
      return this.allRatesSorted.some((rate) => this.isCellModified(rate));
    },
  },
  watch: {
    selectedColumnRateOption(val) {
      console.log("selectedColumnRate", this.selectedColumnRateOption);
      if (val) {
        this.fetchColumnRatesData();
      } else {
        this.columnRateValues = {};
      }
      this.updateSpecRowVal(this.rowData.rvl_value);
    },
    isAnyRatePending(val) {
      this.$emit("rate-pending-change", val);
    },
    fetchRateDataCo(val) {
      if (val) {
        this.fetchRatesData(true);
      }
    },
    newCreatedRow: {
      handler() {
        if (this.newCreatedRow) {
          this.allRates.push(this.newCreatedRow);
          this.$emit("added-new-row");
        }
      },
      deep: true,
    },
    selectedColumnSubCategoryOption: {
      handler() {
        this.allRates = [];
        this.loadRowsCount = __defaultRowsCount;
        this.fetchRatesData(true);
      },
      deep: true,
    },
    categoryId(val) {
      if (val) {
        this.selectedColumnSubCategoryOption = null;
      }
      this.fetchRatesData(true);
    },
  },
  mounted() {
    this.fetchRatesData();
    this.fetchCalcItems();
    this.fetchRatesCategories();
  },
  methods: {
    ...mapActions({
      getRatesData: "getRatesData",
      getGlobalRates: "getGlobalRates",
      patchRates: "patchRates",
      patchGlobalRates: "patchGlobalRates",
      deleteRates: "deleteRates",
      deleteGlobalRates: "deleteGlobalRates",
      getRatesCalcItems: "getRatesCalcItems",
      getGlobalRatesCalcItems: "getGlobalRatesCalcItems",
      postColumnForRates: "postColumnForRates",
      postGlobalColumnForRates: "postGlobalColumnForRates",
      getRatesIncrementsCategory: "getRatesIncrementsCategory",
      postGlobalIncrementsCategory: "postGlobalIncrementsCategory",
      getRatesCategories: "getRatesCategories",
      getGlobalRatesCategories: "getGlobalRatesCategories",
    }),
    async fetchRatesData(isMiniLoadingRates = false) {
      this.isMiniLoadingRates = isMiniLoadingRates;
      this.isLoadingRates = true;

      const params = {
        type: this.type.type,
        date: this.rateDateSelected,
      };
      if (this.type.type === SHIFT_TYPES.normal.key) {
        params.cli_id = this.client?.id;
      }

      // For parent category ID
      if (this.categoryId) {
        params.category_id = this.categoryId;
      }
      // For subcategory ID
      if (this.selectedColumnSubCategoryOption?.id) {
        params.subcategory_id = this.selectedColumnSubCategoryOption.id;
      }

      const getApiActionKey =
        this.type.unique_code !== "GLBNRML" ? "getRatesData" : "getGlobalRates";
      try {
        const res = await this[getApiActionKey](params);
        this.allRates = res.data.data;
        // this.allRates = (res.data.data).slice(0, 3)
        if (this.selectedColumnRateOption) {
          await this.fetchColumnRatesData();
        }
        this.isMiniLoadingRates = false;
        this.isLoadingRates = false;
      } catch (err) {
        console.log(err.message);
        this.isMiniLoadingRates = false;
        this.isLoadingRates = false;
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    async fetchRatesCategories() {
      if (!this.rateSectorSelected) {
        console.warn("[WARN] No sector");
        return;
      }

      if (Array.isArray(this.categories) && this.categories.length) {
        if (canDisplayColumn("increment", this.type)) {
          console.log("Cats already here");
          await this.fetchIncrementsCategory();

          return;
        }
      }

      //   this.isLoadingCats = true
      const params = {
        sct_id: this.rateSectorSelected.id,
      };

      const getApiActionKey =
        this.type.unique_code !== "GLBNRML"
          ? "getRatesCategories"
          : "getGlobalRatesCategories";
      try {
        await this[getApiActionKey](params);

        if (canDisplayColumn("increment", this.type)) {
          await this.fetchIncrementsCategory();
        }
        // this.isLoadingCats = false
      } catch (err) {
        console.log(err.message);
        // this.isLoadingCats = false
      }
    },
    async fetchIncrementsCategory() {
      // Parent categories
      const catIds = this.categories.map((cat) => cat.id);

      const params = {
        cat_ids: catIds,
      };

      const getApiActionKey =
        this.type.unique_code !== "GLBNRML"
          ? "getRatesIncrementsCategory"
          : "postGlobalIncrementsCategory";
      try {
        const res = await this[getApiActionKey](params);
        this.incrementOptions = res.data.data;
      } catch (err) {
        console.log(err.message);
      }
    },
    async fetchCalcItems() {
      const params = {
        type: this.type.type,
      };

      const getApiActionKey =
        this.type.unique_code !== "GLBNRML"
          ? "getRatesCalcItems"
          : "getGlobalRatesCalcItems";
      try {
        const res = await this[getApiActionKey](params);
        this.columnRatesOptions = res.data.data;
      } catch (err) {
        console.log(err.message);
      }
    },
    async fetchColumnRatesData(rateIds) {
      if (!this.selectedColumnRateOption) {
        console.log("[WARN] - Invalid column id - fetch");
        return;
      }

      let timeout = null;
      if (rateIds) {
        this.isLoadingDynColValuesArr = rateIds;
        this.isLoadingAllDynValues = false;
      } else {
        timeout = setTimeout(() => {
          this.isLoadingAllDynValues = true;
          this.isLoadingDynColValuesArr = [];
        }, 500);
      }

      const params = {
        cit_id: this.selectedColumnRateOption.id,
        rate_ids: rateIds || this.getAllRateIds,
        type: this.type.type,
      };

      if (params.rate_ids?.length === 0) {
        console.log(">> No rate ids selected");
        clearTimeout(timeout);

        return;
      }

      const getApiActionKey =
        this.type.unique_code !== "GLBNRML"
          ? "postColumnForRates"
          : "postGlobalColumnForRates";
      try {
        const res = await this[getApiActionKey](params);
        // Create a map for rat_id for easier lookup
        if (!this.columnRateValues) {
          // Only init, merge otherwise
          this.columnRateValues = {};
        }

        this.columnRateValues = {};
        for (const dataRow of res.data.data) {
          this.$set(this.columnRateValues, dataRow.rat_id, dataRow);
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        // console.log(">> Clearing timeout");
        clearTimeout(timeout);
        this.isLoadingAllDynValues = false;
        this.isLoadingDynColValuesArr = [];
      }
    },
    canShowDynamicCalcColumn(rate, columnKey) {
      const selectedCode = this.selectedColumnRateOption?.code;
      if (selectedCode) {
        const columnObj = getCalcItems(selectedCode, this.type)?.inputs[
          columnKey
        ];

        return (
          !this.isLoadingAllDynValues &&
          !this.isLoadingDynColValuesArr.includes(rate.id) &&
          columnObj?.visible
        );
      }
      return false;
    },
    isEnabledCalcItems(rate, columnKey, isCreate = false) {
      const selectedCode = this.selectedColumnRateOption?.code;
      if (selectedCode) {
        const columnObj = getCalcItems(selectedCode, this.type)?.inputs[
          columnKey
        ];

        if (isCreate) {
          return columnObj?.enabled_on_create;
        }
        return columnObj?.enabled_on_update;
      }
      return false;
    },
    onChangeSubcategory(evt) {
      this.$emit("change-subcategory", evt);
    },
    onChangeRateCell(evt, rate, index, key, isRateValue = false) {
      // Set the data for rates
      const value = evt.target.value.trim();
      // console.log('onChangeRateCell',rate, key);
      this.createOrUpdateTouchedRowVals(rate, index, key, value, isRateValue);
    },
    onPasteRateCell(evt, rate, index, key, isRateValue = false) {
      evt.preventDefault();
      const pastedValue = (evt.clipboardData || window.clipboardData).getData(
        "text"
      );
      const pastedValueCleaned = pastedValue.trim();
      const checkAndChangeValueRateRow = (rateDataFromRow, value) => {
        if (rateDataFromRow) {
          const isChangedInput = this.valueIsDifferent(
            rateDataFromRow,
            key,
            value
          );
          // console.log('<< changed >>', key, value, isChangedInput);
          if (isChangedInput) {
            this.createOrUpdateTouchedRowVals(
              rateDataFromRow,
              index,
              key,
              value,
              isRateValue
            );
          }
        } else {
          console.log("End of the row count", value);
        }
      };

      const handlePasteChar = pastedValue.includes("\r") ? "\r" : "\n";

      // Used for updating all the column rows from the start index
      if (pastedValue.split(handlePasteChar).length === 2) {
        // Show the confirm dialog
        const isConfirm = confirm(
          "Do you want to update all the rows in the column with the same value starting from this row?"
        );
        if (isConfirm) {
          // Could be slow going with all rates
          for (
            let oneValIndex = index;
            oneValIndex < this.allRatesFilteredSorted.length;
            oneValIndex++
          ) {
            const rateDataFromRow = this.allRatesFilteredSorted[oneValIndex];
            checkAndChangeValueRateRow(rateDataFromRow, pastedValueCleaned);
          }
          // Early exit
          return;
        }
      }

      if (pastedValueCleaned.includes(handlePasteChar)) {
        // WIP [4 Delayed] Add loader for multiple
        // Copied from excel sheet [new lines]
        const splittedVals = pastedValueCleaned
          .split(handlePasteChar)
          .filter(Boolean)
          .map((el) => el.trim());
        for (
          let oneValIndex = 0;
          oneValIndex < splittedVals.length;
          oneValIndex++
        ) {
          const oneVal = splittedVals[oneValIndex];
          // Needs to work with all rows so they can be updated
          const rateDataFromRow =
            this.allRatesFilteredSorted[index + oneValIndex];
          checkAndChangeValueRateRow(rateDataFromRow, oneVal);
        }
      } else if (pastedValueCleaned) {
        // Regular copy
        const rateDataFromRow = this.allRatesFilteredSorted[index];
        checkAndChangeValueRateRow(rateDataFromRow, pastedValueCleaned);
      } else {
        // Ignore if empty
      }
    },
    getEffectiveDateFromRate(rate) {
      const rateFromStr = parseDateObjWithTimeZone(rate.rat_from);
      if (rateFromStr === this.fixedChangedDate) {
        return "-";
      }
      return getFormattedDateTime(rateFromStr, undefined, true);
    },
    getEffectiveDateToRate(rate) {
      const rateFromStr = parseDateObjWithTimeZone(rate.rat_to);
      // if (rateFromStr === this.fixedChangedDate) {
      //   return "-";
      // }
      return getFormattedDateTime(rateFromStr, undefined, true);
    },
    createOrUpdateTouchedRowVals(rate, index, key, value, isRateValue = false) {
      const getTouchedRowIndex = (rateRows, rate) =>
        rateRows.findIndex((tr) => tr.id === rate.id);
      const saveLogic = (rowIndex) => {
        if (isRateValue) {
          // Find the subObj before saving to it
          // console.log(this.touchedRows);
          const initialCitObj = {
            cit_id: this.selectedColumnRateOption.id,
            rat_id: rate.id,
            type: this.type.type,
          };
          const rateValuesExist =
            this.touchedRows[rowIndex].ratevalues &&
            this.touchedRows[rowIndex].ratevalues.length;
          if (!rateValuesExist) {
            this.$set(this.touchedRows[rowIndex], "ratevalues", []);
          }
          const indexOfSubDynCol = this.touchedRows[
            rowIndex
          ].ratevalues.findIndex(
            (rv) => rv.cit_id === this.selectedColumnRateOption.id
          );
          let rateValIndex = indexOfSubDynCol;
          const citIdExists = indexOfSubDynCol > -1;
          if (!rateValuesExist || !citIdExists) {
            const newLength =
              this.touchedRows[rowIndex].ratevalues.push(initialCitObj);
            if (!citIdExists) {
              rateValIndex = newLength - 1;
            }
          }
          this.$set(
            this.touchedRows[rowIndex].ratevalues[rateValIndex],
            key,
            value
          );
        } else {
          this.$set(this.touchedRows[rowIndex], key, value);
        }
      };

      const currentRowIndex = getTouchedRowIndex(this.touchedRows, rate);
      if (currentRowIndex !== -1) {
        saveLogic(currentRowIndex);
      } else {
        // Take only keys you need [This can potentially avoid nullable values in API]
        const omittableKeys = [
          "rat_code",
          "rat_key",
          "rat_payscale",
          "rat_salary",
          "rat_nethours",
          "rat_aldays",
          "rat_phdays",
          "rat_billabledays",
        ].filter((omKey) => omKey !== key);
        const rateToPush = _.omit(
          JSON.parse(JSON.stringify(rate)),
          omittableKeys
        );
        const newLen = this.touchedRows.push(rateToPush);
        saveLogic(newLen - 1);
      }
    },
    valueIsDifferent(originalRateRow, keyToCheck, newValue) {
      const originalValue = originalRateRow[keyToCheck];
      return originalValue !== newValue;
    },
    formatIncrements(dataArr) {
      // Sort increments
      return dataArr.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        } else if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
    },
    incrementOptionsFilteredAvailable(rateRow) {
      const allIncrementsByCat = this.incrementOptionsFiltered(rateRow);

      // Functionality to show only not-used in rows increments
      const usedIncrementsIds = [];
      for (const rate of this.allRates) {
        // Not using allRatesSorted because if breaks searching for increments by cat (using API sorted cats - doesn't change)
        const rateCatId = rate.cat_id;
        const rateIncId = rate.inc_id;
        if (rateCatId > rateRow.cat_id) {
          // !Important - needs testing
          // It is very important that rates are sorted ASC by cat_id
          break;
        }

        if (rateCatId === rateRow.cat_id) {
          if (rateIncId !== rateRow.inc_id) {
            // Exclude same rate row
            usedIncrementsIds.push(rate.inc_id);
          }
        }
      }

      const modifIncByCat = [];
      for (const incByCat of allIncrementsByCat) {
        // Filter used incs
        const unusedInc = incByCat.increments.filter(
          (catInc) => !usedIncrementsIds.includes(catInc.id)
        );

        const newObj = { ...incByCat, increments: unusedInc };
        modifIncByCat.push(newObj);
      }

      return modifIncByCat;
    },
    incrementOptionsFiltered(rate) {
      // Only by category selected
      const catCurrentParent = this.getCurrentCatParent(rate.cat_id);
      if (catCurrentParent) {
        return this.incrementOptions.filter(
          (incCatObj) => incCatObj.id === catCurrentParent.id
        );
      }
      // If no category selected
      return [];
    },
    getCurrentCatParent(subCatId) {
      if (subCatId) {
        return this.categories.find((cat) => {
          return cat.subcategories.some((sub) => sub.id === subCatId);
        });
      }
      return null;
    },
    onKeyDownRateCell(evt) {
      const focusSibling = (keySibling) => {
        this.$nextTick(() => {
          try {
            const activeEl = document.activeElement;
            const attTd = activeEl.closest("td").getAttribute("data-td");
            const tr = activeEl.closest("tr");
            const adjSibling = tr[keySibling];
            const colAdjInput = adjSibling.querySelector(
              `td[data-td="${attTd}"] input`
            );
            colAdjInput.focus();
          } catch (err) {
            console.log("Element not found", err.message);
          }
        });
      };

      if (evt.altKey && evt.code === "ArrowDown") {
        focusSibling("nextSibling");
      } else if (evt.altKey && evt.code === "ArrowUp") {
        focusSibling("previousSibling");
      }
    },
    async onKeyUpRateCell(evt, rate) {
      if (evt.code === "Enter") {
        await this.savePatchRate(evt, rate);
      } else if (evt.code === "Escape") {
        // Clear out all changed rows
        this.touchedRows = [];
      }
    },
    async onFocusOutRateCell(evt, rate) {
      this.savePatchRateDebounce(evt, rate);
    },
    async savePatchRate(evt) {
      // PATCH

      // Block if used from [Enter] key
      if (this.isPatchingRowsLoading) {
        console.log("Blocked focus out func");
        this.isPatchingRowsLoading = false;
        return;
      }

      // Only if input event [dropdown included]
      if (!evt || evt.target.tagName === "INPUT") {
        if (this.touchedRows.length) {
          this.isPatchingRowsLoading = true;
          const res = await this.patchRateApi(this.touchedRows);
          this.isPatchingRowsLoading = false;
          return res;
        } else {
          console.log("No rows to update");
        }
      }
      return false;
    },
    savePatchRateDebounce(evt) {
      this.timeoutSave = setTimeout(() => {
        this.savePatchRate(evt);
      }, 2000);
    },
    onInputRateCell() {
      clearTimeout(this.timeoutSave);
      this.timeoutSave = null;
    },
    async patchRateApi(rates = []) {
      const parseRateValuesForApi = (rateVals) => {
        const timeKeysToFormat = ["rvl_timefrom", "rvl_timeto"];
        const keysToCheckForNull = ["rvl_value", "rvl_timefrom", "rvl_timeto"];

        if (!this.selectedColumnRateOption.id) {
          console.warn("NO RATE DYN COL Sel");
          return {};
        }

        // Only filter the selected column rate option
        const filteredCurrentRateVals = rateVals.filter(
          (rv) => rv.cit_id === this.selectedColumnRateOption.id
        );

        const mappedParams = filteredCurrentRateVals.map((rv) => {
          const params = { ...rv };
          for (const key of timeKeysToFormat) {
            if (params[key]) {
              params[key] = params[key].slice(0, 5);
            }
          }

          // Removes empty values (used for CIT only)
          for (const key of keysToCheckForNull) {
            if (params[key] === null) {
              console.log(`Removing 1 ${key}`);
              delete params[key];
            }
          }

          // Check to see if we should send cit_id
          const citColumns = ["rvl_value", "rvl_timefrom", "rvl_timeto"];
          const selectedCode = this.selectedColumnRateOption?.code;
          let deleteCitCount = 0;
          for (const columnKey of citColumns) {
            const columnObj = getCalcItems(selectedCode, this.type)?.inputs[
              columnKey
            ];
            const isEnabled = columnObj?.enabled_on_update;
            if (!isEnabled) {
              console.log(`Removing 2 ${columnKey}`, columnObj);
              deleteCitCount++;
              delete params[columnKey];
            }
          }

          // Delete whole ratevalues - if all blocked
          if (deleteCitCount === 3) {
            console.log("Remove whole ratevalues", selectedCode);
            return null;
          }
          return params;
        });

        return mappedParams.filter(Boolean);
      };

      const ratesParams = {
        rates: [],
        type: this.type.type,
      };

      if (this.type.type === SHIFT_TYPES.normal.key) {
        ratesParams.cli_id = this.client?.id;
      }

      for (const rate of rates) {
        const params = {
          cat_id: rate.cat_id,
          rat_code: rate.rat_code,
          rat_key: rate.rat_key,
          rat_payscale: rate.rat_payscale,
          rat_salary: rate.rat_salary,
          rat_nethours: rate.rat_nethours,
          rat_aldays: rate.rat_aldays,
          rat_phdays: rate.rat_phdays,
        };

        if (this.type.type === SHIFT_TYPES.normal.key) {
          params.inc_id = rate.inc_id;
        }

        if (this.selectedColumnRateOption) {
          // Dynamic column
          const rvFormatted = parseRateValuesForApi(rate.ratevalues || []);
          console.log("Dyn Rate Column >> ", rvFormatted);
          params.ratevalues = rvFormatted;
        }
        ratesParams.rates.push(params);
      }

      let debouncedFunc = null; // This is for when underscore upgrades

      const getApiActionKey =
        this.type.unique_code !== "GLBNRML" ? "patchRates" : "patchGlobalRates";
      try {
        const res = await this[getApiActionKey](ratesParams);
        this.updateCurrentRateDataRows(rates, res.data.data);
        debouncedFunc = this.debouncedSuccessToast();
        this.isAddingNewRow = false;
        return true;
      } catch (err) {
        debouncedFunc && debouncedFunc.cancel();
        console.log("Error", err.message);

        if (err.response?.data?.errors) {
          this.errors.record(err.response.data.errors);
        }
        const errs = parseErrors(err, "", true);
        this.$toasted.error(errs).goAway(2500);

        // Reset input fields
        this.touchedRows = [];
      }
      return false;
    },
    updateCurrentRateDataRows(localRates, dataArr) {
      const usedIds = localRates.map((rate) => rate.id);
      for (const rate of localRates) {
        const indexFromOriginalArr = this.allRates.findIndex(
          (alr) => alr.id === rate.id
        );
        if (indexFromOriginalArr !== -1) {
          // Needs to find cat_id and inc_id so it can match with new one
          const callbackCheck = (rd) => {
            if (rate.inc_id) {
              return rd.cat_id === rate.cat_id && rd.inc_id === rate.inc_id;
            }
            return rd.cat_id === rate.cat_id;
          };
          const foundRes = dataArr.find(callbackCheck);
          if (foundRes) {
            // Local and DB matched

            // DB Nullable fix [Needs to be fixed on backend]
            // const filteredFoundRes = this.restoreNullableValuesDb(indexFromOriginalArr, foundRes)

            this.allRates.splice(indexFromOriginalArr, 1, foundRes);
          } else {
            console.warn("Invalid match", dataArr, localRates);
          }
        }
      }

      // Filter out used API rows so it doesn't update same change
      // Used rows from temp mem
      this.touchedRows = this.touchedRows.filter(
        (tr) => !usedIds.includes(tr.id)
      );
    },
    restoreNullableValuesDb(indexOriginal, foundRes) {
      const hasNullable = Object.values(foundRes).some(
        (value) => value === null
      );
      if (hasNullable) {
        const originalObj = JSON.parse(
          JSON.stringify(this.allRates[indexOriginal])
        );
        const filteredNullableRes = _.omit(foundRes, (value) => value === null);
        const mergedWithNullableOverwritten = {
          ...originalObj,
          ...filteredNullableRes,
        };
        return mergedWithNullableOverwritten;
      }
      return foundRes;
    },
    debouncedSuccessToast: _.debounce(function () {
      this.$toasted.success("Successfully changed").goAway(1000);
    }, 800),
    async multipleUndoRates() {
      // API support could be in future [for multiple rates]
      const isConfirmed = confirm("Undo all rates?");
      if (isConfirmed) {
        try {
          const availableRatesToUndo = this.allRatesFilteredSorted.filter(
            (rate) => this.isCellModified(rate)
          );
          for (const rate of availableRatesToUndo) {
            const params = { rat_id: rate.id, type: this.type.type };

            const getApiActionKey =
              this.type.unique_code !== "GLBNRML"
                ? "deleteRates"
                : "deleteGlobalRates";
            const res = await this[getApiActionKey](params);
            const previousRateData = res.data && res.data.data;

            // Table row refresh [allRates because cat and inc can change the sort]
            const fIndex = this.allRates.findIndex(
              (currentRate) => currentRate.id === rate.id
            );
            if (fIndex !== -1) {
              if (previousRateData) {
                if (!previousRateData.id) {
                  // Transforms API response to fit within app's guideline
                  previousRateData.id = previousRateData.rat_id;
                }
                this.allRates.splice(fIndex, 1, previousRateData);
              } else {
                this.allRates.splice(fIndex, 1);
              }
            } else {
              console.warn("Couldn't restore previous rate >>>");
            }
          }
        } catch (err) {
          console.warn(err.message);
        }
      }
    },
    onChangePendingUndoRates(evt, rate) {
      this.$emit("change-pending-undo-rates", rate.id);
    },
    async onDeleteRow(evt, rate) {
      if (evt.ctrlKey) {
        // Special use case [for testing only]
        this.multipleUndoRates();
        return;
      }

      // key from : id [PATCH | GET] | rat_id [DELETE]
      const params = { rat_id: rate.id, type: this.type.type };
      try {
        await this.confirmDelete();

        const getApiActionKey =
          this.type.unique_code !== "GLBNRML"
            ? "deleteRates"
            : "deleteGlobalRates";
        const res = await this[getApiActionKey](params);
        const previousRateData = res.data && res.data.data;

        // Table row refresh [allRates because cat and inc can change the sort]
        const fIndex = this.allRates.findIndex(
          (currentRate) => currentRate.id === rate.id
        );
        if (fIndex !== -1) {
          if (previousRateData && Object.keys(previousRateData).length) {
            // Backend sometimes returns [] and sometimes {}, that's why we use Object.keys(...)
            if (!previousRateData.id) {
              // Transforms API response to fit within app's guideline
              previousRateData.id = previousRateData.rat_id;
            }
            this.allRates.splice(fIndex, 1, previousRateData);
          } else {
            this.allRates.splice(fIndex, 1);
          }
        } else {
          console.warn("Couldn't restore previous rate >>");
        }

        this.$toasted.info(res.data.message || res.data.Message).goAway(2500);
      } catch (err) {
        console.log(err.message);
        const errs = parseErrors(err);
        this.$toasted.error(errs).goAway(2500);
      }
    },
    confirmDelete(name = `this change`) {
      return new Promise((resolve) => {
        const alert = {
          title: `Do you wish to undo "${name}"?`,
          message: "",
          type: "warning",
          useConfirmBtn: true,
          customConfirmBtnText: "Confirm",
          customConfirmBtnClass: "button is-danger",
          customCloseBtnText: "Cancel",
          customCloseBtnClass: "button is-outlined",
          onConfirm: () => resolve(),
        };
        this.$refs.simplert.openSimplert(alert);
        setTimeout(() => {
          // Timeout because of the transition
          const el = this.$refs.simplert.$el;
          const btns = el.querySelectorAll("button");
          if (btns.length) {
            btns[0].focus();
          }
        }, 600);
      });
    },
    scrollingFunc(evt) {
      // this.scrollThrottleFunc();
      this.scrollDebounceFunc(evt);
    },
    // scrollThrottleFunc: _.throttle(function () {}, 100),
    scrollDebounceFunc: _.debounce(function (evt) {
      const scrollHeight = evt.target.scrollHeight;
      const clientHeight = evt.target.clientHeight;
      const scrollTop = evt.target.scrollTop;
      const OFFSET = 20;
      if (
        scrollHeight - clientHeight < scrollTop + OFFSET &&
        this.loadRowsCount < this.allRates.length
      ) {
        this.isEndTableLoading = true;
        setTimeout(() => {
          this.loadRowsCount += 100;
          // Timeout to remove loader
          this.isEndTableLoading = false;
        }, 500);
      }
    }, 300),
    getDynamicCalcColumn(rate, key) {
      const selDynColId = this.selectedColumnRateOption.id;

      const findRateValueObj = (objWithRateVals) => {
        const foundRateValueObj = objWithRateVals.ratevalues.find(
          (rv) => rv.cit_id === selDynColId
        );
        if (foundRateValueObj) {
          return foundRateValueObj[key];
        } else {
          console.warn("Invalid cit id", selDynColId);
        }
      };

      const formatOutput = (input) => {
        // Because API sometimes gives out time in 03:00:00 instead of 03:00
        const keysToFormat = ["rvl_timefrom", "rvl_timeto"];
        if (keysToFormat.includes(key)) {
          return input && input.slice(0, 5);
        }
        return input;
      };

      const getDefaultValue = () => {
        const arrVal = this.columnRateValues[rate.id];
        if (arrVal) {
          return arrVal[key];
        }
      };

      const foundObj = this.touchedRows.find((tr) => tr.id === rate.id);
      const foundOriginalRateValue = this.allRatesFilteredSorted.find(
        (alr) => alr.id === rate.id
      );
      if (foundObj && foundObj.ratevalues) {
        // 1) From touched rows
        const val = findRateValueObj(foundObj);
        if (val || val == 0) {
          return formatOutput(val);
        }
      } else if (foundOriginalRateValue && foundOriginalRateValue.ratevalues) {
        // 2) From original rows with ratevalues
        const val = findRateValueObj(foundOriginalRateValue);
        if (val || val == 0) {
          return formatOutput(val);
        }
      }
      // 3) From API res that has only dyn data |> Can be blank if no API res
      const defVal = getDefaultValue();
      if (defVal || defVal == 0) {
        return defVal;
      } else {
        return "";
      }
    },
    setDynColumnVal(evt, rate, key) {
      this.$set(rate, key, evt.target.value);
    },
    isCellModified(rate) {
      return parseDateObjWithTimeZone(rate.rat_from) === this.fixedChangedDate;
    },
    getCategoryFromRate(rate) {
      const foundObj = this.touchedRows.find((tr) => tr.id === rate.id);
      if (foundObj && foundObj.cat_id !== undefined) {
        // 1) From touched rows
        return this.getFlatCategories.find((cat) => cat.id === foundObj.cat_id);
      } else {
        // 2) From original rows with ratevalues
        return this.getFlatCategories.find((cat) => cat.id === rate.cat_id);
      }
    },
    getIncrementFromRate(rate) {
      const foundObj = this.touchedRows.find((tr) => tr.id === rate.id);
      if (foundObj && foundObj.inc_id !== undefined) {
        // 1) From touched rows
        return this.getFlatIncrements.find((inc) => inc.id === foundObj.inc_id);
      } else {
        // 2) From original rows with ratevalues
        return this.getFlatIncrements.find((inc) => inc.id === rate.inc_id);
      }
    },
    getValueFromRateModel(rate, key) {
      // Get from touched rows if any before going to the rate
      const foundObj = this.touchedRows.find((tr) => tr.id === rate.id);
      if (foundObj && foundObj[key] !== undefined) {
        return foundObj[key];
      } else {
        // Original value
        return rate[key];
      }
    },
    async setCategoryRate(evt, rate, shouldChangeRate = true) {
      const value = evt && evt.id;
      if (shouldChangeRate) {
        // If patching rates
        this.createOrUpdateTouchedRowVals(rate, null, "cat_id", value);
        await this.savePatchRate(null, rate);
      } else {
        this.$set(rate, "cat_id", value);
      }
    },
    async setIncrementRate(evt, rate, shouldChangeRate = true) {
      const value = evt && evt.id;
      if (shouldChangeRate) {
        // If patching rates
        this.createOrUpdateTouchedRowVals(rate, null, "inc_id", value);
        await this.savePatchRate(null, rate);
      } else {
        this.$set(rate, "inc_id", value);
      }
    },
    checkIfSpecialAvailable(evt) {
      const val = evt.target.value;
      this.updateSpecRowVal(val);
    },
    updateSpecRowVal(val) {
      // Recheck this in future
      if (val) {
        this.$set(this.rowData, "special", this.selectedColumnRateOption);
      } else {
        this.$delete(this.rowData, "special");
      }
    },
    // getRateDate(rate) {
    //   return parseDateObjWithTimeZone(rate.rat_from);
    // },
    onAfterEnterTableRow(evt) {
      // Focus the first input after display
      const firstInput = evt.querySelector("input");
      if (firstInput) {
        firstInput.focus();
      }
    },
    generateUpdatedTitle(rate) {
      const formatDate = (str) => moment(str).format("DD/MM/YYYY HH:mm:ss");
      const formatKeys = (str) =>
        (str[0].toUpperCase() + str.slice(1)).replace("_", " ");

      const dateUsed = rate.rat_updated?.date;
      const obj = {
        name: rate.userUpdatedName,
        email: rate.userUpdatedEmail,
        updated_at: dateUsed && formatDate(dateUsed),
      };
      const jString = Object.entries(obj)
        .filter((el) => el[1])
        .map((el) => `${formatKeys(el[0])}: ${el[1] || ""}`)
        .join("</br>");
      return jString;
    },

    // REVIEW - helper function to disable inputs if the date is before today
    isDateBeforeToday() {
      if (moment(this.rateDateSelected).isSame(moment(), "day")) return false;
      return moment(this.rateDateSelected).isBefore(moment());
    },
  },
};
</script>

<style lang="scss" scoped>
#rates-manage-rates-table {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;

  .loader {
    $size: 130px;
    width: $size;
    height: $size;
    max-height: $size;
    max-width: $size;
  }

  .loader-small {
    width: 30px;
    height: 30px;
    max-height: 30px;
    max-width: 30px;
  }

  ul {
    overflow: auto;
    height: 100%;
  }

  .table-wrap {
    overflow-y: auto;
    // overflow-x: hidden;
    overflow-x: auto;
    width: 100%;
    min-height: 400px;

    .rates-table {
      $dyn-size: 190px;
      border-radius: 0;
      border-top: unset;

      th {
        border-top: 1px solid #dde6eb;
        padding: 2px 2px 2px 5px;
        position: sticky;
        top: 0px;
        z-index: 49; // Because of dropdown [z-50]
        // z-index: 100; // Because of dropdown [z-50]

        .column-select {
          font-weight: 400;
          font-size: inherit;
        }

        &#dynamic-column {
          .multiselect {
            width: $dyn-size;
            max-width: $dyn-size;
          }
        }
      }

      tr {
        $cat-size: 240px;
        $inc-size: 160px;

        .info-updated-tooltip {
          // border-radius: 10px;
          border: 1px solid transparent;
          color: white;
          font-weight: 600;
        }

        td {
          padding: 2px 2px 2px 5px;
          input {
            height: 40px;
            background: transparent;
            border: 1px solid transparent;
            box-shadow: none;
            padding: 0 5px;
            border: 1px solid rgba(192, 192, 192, 0.4);
            &:focus {
              border: 1px solid rgba(192, 192, 192, 0.4);
            }

            &:disabled,
            &.disabled {
              background-color: #ededed;
            }
          }
          .timeContainer {
            border: none;
          }
          &.wid-33 {
            min-width: 30px;
          }
          // > input:not(.wid-100) {
          //   // width: 120px;
          // }

          &.blank {
            background: silver;
          }
        }

        &.changed {
          background: #ffff2557;
        }

        .category-select-wrap {
          min-width: $cat-size;
          max-width: $cat-size;

          .category-select {
            font-weight: 400;
            font-size: inherit;
          }
        }

        .increments-select-wrap {
          min-width: $inc-size;
          max-width: $inc-size;

          .increments-select {
            font-weight: 400;
            font-size: inherit;
          }
        }
      }
    }
  }

  .loading-blocker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .center-text {
    text-align: center;
    height: 150px;
    background: #f0f6f6;
    width: 50px;
  }

  .fade-enter-active {
    transition: all 0.3s ease;
  }

  .fade-leave-active {
    transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .fade-enter,
  .fade-leave-to {
    transform: translateX(30px);
    opacity: 0;
  }

  .undo-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
<style lang="scss">
#rates-manage-rates {
  @mixin text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .rates-table {
    #dynamic-column {
      .multiselect__placeholder {
        @include text-ellipsis;
      }
    }

    tbody {
      .multiselect__tags,
      .multiselect__input,
      .multiselect__single {
        background: transparent;
        border: 1px solid transparent;
        box-shadow: none;
      }
    }
  }

  .category-select-wrap,
  .increments-select-wrap {
    .multiselect--active,
    .multiselect__content-wrapper {
      z-index: 45 !important;
    }
  }
}
</style>
