<template lang="pug">
.uni-date-picker
  flat-pickr(
    :placeholder="$attrs.placeholder || 'Select Date/Time'",
    :config="datePickerConfig",
    :value="parsedValue",
    :disabled="isDisabled",
    v-bind="$attrs",
    :key="uniqueKey",
    @input="onChangeValue",
    v-on="$listeners"
  )
  //- :date="date",
  //- :mode="mode",
  //- :option="dateTimeOptionsComputed",
</template>

<script>
import calendarOptions from "../common/calendarOptions";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";

export default {
  components: {
    flatPickr,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    // date: {
    //   type: Object,
    //   default: () => ({ time: "" }),
    //   required: true,
    //   validator(val) {
    //     return "time" in val;
    //   },
    // },
    value: {
      required: true,
      default: "",
    },
    format: {
      // Unused
      type: String,
      default: "DD/MM/YYYY HH:mm",
      required: false,
    },
    isFormatToLocal: {
      type: Boolean,
      default: false,
    },
    mode: {
      // Unused
      type: String,
      default: "day",
      required: false,
      validator(value) {
        const validKeys = ["day", "hour"];
        return !value || validKeys.includes(value);
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    propConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      uniqueKey: "",
    };
  },
  computed: {
    datePickerConfig() {
      return {
        altInput: true,
        altFormat: "d/m/Y H:i",
        dateFormat: "Y-m-d H:i:S",
        enableTime: true,
        time_24hr: true,
        ...this.propConfig,
      };
    },
    parsedValue() {
      if (this.value) {
        if (this.datePickerConfig.dateFormat === "Y-m-d") {
          // Custom logic for rate management
          return this.value;
        } else {
          const isIsoDateTime = moment(
            this.value,
            "YYYY-MM-DD HH:mm:ss",
            true
          ).isValid();
          const isIsoDateOnly = moment(
            this.value,
            "YYYY-MM-DD",
            true
          ).isValid();
          if (isIsoDateTime || isIsoDateOnly) {
            return this.value;
          }

          if (this.value.length === 10) {
            return moment(this.value, "DD/MM/YYYY")
              .format("YYYY-MM-DD")
              .valueOf();
          }

          return moment(this.value, "DD/MM/YYYY HH:mm")
            .format("YYYY-MM-DD HH:mm:ss")
            .valueOf();
        }
      }
      return "";
    },
    dateTimeOptionsComputed() {
      return {
        ...calendarOptions.dateTimeOption,
        format: this.format,
      };
    },
  },
  mounted() {
    this.createUniqueKey();
  },
  methods: {
    createUniqueKey() {
      this.uniqueKey = `uni-date-${(Math.random() * 1000).toFixed(0)}`;
    },
    onChangeValue(evt) {
      // EVT - 2020-11-08 00:00:00
      if (evt === this.parsedValue) {
        // Same value, no need to emit
        // console.log("Same value, no emit", evt);
        return;
      } else if (!evt && !this.parsedValue) {
        // Was empty, still is
        // console.log("No value, no emit", evt);
        return;
      }

      let emitVal = "";
      if (this.isFormatToLocal) {
        // if (this.datePickerConfig.dateFormat.split(" ").length === 1) {
        //   console.log(this.datePickerConfig.dateFormat);
        //   this.$emit(
        //     "change",
        //     moment(evt, "YYYY-MM-DD").format("DD/MM/YYYY").valueOf()
        //   );
        // } else {
        const value = moment(evt, "YYYY-MM-DD HH:mm:ss")
          .format("DD/MM/YYYY HH:mm")
          .valueOf();
        emitVal = value;
        // }
      } else {
        emitVal = evt;
      }

      console.log(`>> Emitting [OG]:${evt} [PAR]: ${emitVal}`);
      this.$emit("change", emitVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.uni-date-picker {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
