<script>
import SubmitShiftForm from "./SubmitShiftForm.vue";
import Vue from "vue";
import { mapActions } from "vuex";
import {
  pickNonFalsyKeepZero,
  getFormattedTime,
  findPos,
} from "../../lib/helpers/function.js";
import * as _ from "underscore";
import * as moment from "moment";

export default {
  name: "submit-multiple-shifts-form",
  mixins: [SubmitShiftForm],
  props: {
    isMultipleShifts: {
      default: true,
      type: Boolean,
    },
  },
  computed: {
    shiftsList() {
      return _.map(this.selectedDays, (day, index) => {
        return {
          id: day.id,
          number: index + 1,
          date: moment(day.date).format("DD/MM/YYYY"),
          costCenterName: this.selectedLocation?.name || "",
          // shift_parts_time: this.getTimeIntervalString(
          //   day.hc_startTime,
          //   day.hc_endTime
          // ),
          // shift_parts: day.shift_parts,
          numberOfShifts: day.numberOfShifts,
          time: this.getTimeIntervalString(day.startTime, day.endTime),
          dayObj: day,
        };
      });
    },
    isEnabledCalendar() {
      return this.fromToTime?.startTime.time && this.fromToTime?.endTime.time;
    },
  },
  methods: {
    ...mapActions({
      findTempsForShift: "findTempsForShift",
      shiftRequestPost: "shiftRequestPost",
    }),
    getTimeIntervalString(startTime, endTime) {
      if (!_.isDate(startTime) || !_.isDate(endTime)) {
        return;
      }
      const startTimeFormated = moment(startTime).format("HH:mm");
      const endTimeFormated = moment(endTime).format("HH:mm");
      return `${startTimeFormated} - ${endTimeFormated}`;
    },
    onSelectedDays(selectedDays) {
      this.selectedDays = selectedDays;
    },
    getShiftInterval(dates) {
      function getBeforeAfterDate(localDates) {
        const shift = {
          startTime: moment(localDates[0].startTime).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          endTime: moment(localDates[0].endTime).format("YYYY-MM-DD HH:mm:ss"),
        };
        for (let i = 1; i < localDates.length; i++) {
          const start = moment(localDates[i].startTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          const end = moment(localDates[i].endTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );

          if (moment(start).isBefore(moment(shift.startTime))) {
            shift.startTime = start;
          }

          if (moment(end).isAfter(moment(shift.endTime))) {
            shift.endTime = end;
          }
        }
        return shift;
      }

      if (dates.length === 0) {
        // Sleepover shift
        const sleepoverDates = this.shiftTimeRows.map((timeRow) => {
          const startIso =
            timeRow.start_time?.time &&
            moment(timeRow.start_time.time, "DD/MM/YYYY HH:mm").format(
              "YYYY-MM-DD HH:mm"
            );
          const endIso =
            timeRow.end_time?.time &&
            moment(timeRow.end_time.time, "DD/MM/YYYY HH:mm").format(
              "YYYY-MM-DD HH:mm"
            );
          return { startTime: new Date(startIso), endTime: new Date(endIso) };
        });

        return getBeforeAfterDate(sleepoverDates);
      } else {
        return getBeforeAfterDate(dates);
      }
    },
    // togglePeriod (target) {
    //   let id = (target.id) ? target.id : target.parentElement.id
    //
    //   Object.keys(this.timePresetStart).map(e => {
    //     if (e === id) {
    //       this.fromToTime.startTime.time = this.timePresetStart[id]
    //     }
    //
    //     this.fromToTime.endTime.time = this.timePresetEnd[id]
    //   })
    //
    //   Object.keys(this.buttons).map(e => {
    //     if (e !== id) {
    //       this.buttons[e] = false
    //     }
    //   })
    //
    //   if (this.buttons[id]) {
    //     this.fromToTime.startTime.time = ''
    //     this.fromToTime.endTime.time = ''
    //     this.shiftPeriod = ''
    //   }
    //
    //   this.buttons[id] = !this.buttons[id]
    // },
    getParamsForFetchingTemps() {
      if (
        this.selectedDays.length === 0 &&
        !this.shiftTimeRows?.[0]?.start_time.time
      ) {
        this.$toasted.error("Please select start time").goAway(2500);
        return;
      }
      const shift = this.getShiftInterval(this.selectedDays);
      const obj = {
        client_id: this.selectedClient?.id || null,
        location_id: this.selectedLocation?.id || null,
        subcategories:
          Array.isArray(this.selectedCategories) &&
          this.selectedCategories.length > 0
            ? this.selectedCategories.map((cat) => {
                return { id: cat.id };
              })
            : [],
        availability:
          this.auth.user.role !== "client-admin"
            ? this.selectedAvailability.id
            : 2,
        has_worked_with_client: this.selectedWorkedBefore?.id || null,
        start_time: shift.startTime,
        end_time: shift.endTime,
        shifts: this.getShifts(),
        part_of_the_day: this.partOfDay, // this will be changed
        name: this.prefTempName,
        is_multiple_shifts: this.sendShiftAs ? 1 : 0,
        page: this.actualPage,
        gender: this.selectedGender?.key,
        has_on_call: this.isOnCallShift ? 1 : 0,
        has_sleepover: this.isSleepoverShift ? 1 : 0,
      };
      return obj;
    },
    pageHasChanged(pageNum) {
      this.actualPage = pageNum;
      this.getPrefTemps();
    },
    getPrefTemps() {
      const rawParams = this.getParamsForFetchingTemps();
      const params = pickNonFalsyKeepZero(rawParams);
      console.log("parameters for api, template", params, { ...rawParams });

      // Don't try searching if start time is before now
      if (moment(params.start_time, "YYYY-MM-DD HH:mm:ss").isBefore(moment())) {
        this.$toasted.error("Start time must be after now").goAway(5000);
        return;
      }

      // If searching for clients that marked themselves as available
      if (params.availability === 2) {
        if (params.start_time === null || params.start_time === "") {
          this.$toasted.error("Please select start time").goAway(5000);
          return;
        }
      }

      params.shift_type = params.has_on_call
        ? "ONCL"
        : params.has_sleepover
        ? "SLPO"
        : "NRML";

      this.loaded = false;
      this.isTempsTable = false;
      this.clearTimeErrors();

      this.findTempsForShift(params)
        .then(() => {
          this.loaded = true;
          this.isTempsTable = true;
        })
        .catch((error) => {
          let err = "";
          if (error.response.data.errors) {
            err = error.response.data.errors;
            this.errors.record(err);
          }

          const errMsg = error.response.data.message;
          this.$toasted.error(errMsg).goAway(3000);
        });
    },
    removeShift(shiftId) {
      const shiftIndex = _.findIndex(this.selectedDays, { id: shiftId });
      this.selectedDays.splice(shiftIndex, 1);
    },
    getSubmitShiftParams() {
      const shiftInterval = this.getShiftInterval(this.selectedDays);
      const params = {
        subcategories:
          Array.isArray(this.selectedCategories) &&
          this.selectedCategories.length > 0
            ? this.selectedCategories.map((cat) => {
                return { id: cat.id };
              })
            : [],
        client_id: this.selectedClient && this.selectedClient.id,
        temps: this.selectedTemps,
        template_name: this.templateName,
        special_shift_name: this.specialShiftName,
        notes: this.notes,
        shift_request_reason_id:
          this.selectedShiftRequestReason && this.selectedShiftRequestReason.id,
        shifts: this.getShifts(),
        is_multiple_shifts: this.sendShiftAs ? 1 : 0,
        availability:
          this.auth.user.role !== "client-admin"
            ? this.selectedAvailability.id
            : 2,
        request_id: this.requestId,
        location_id: this.selectedLocation ? this.selectedLocation.id : null,
        start_time: shiftInterval.startTime,
        end_time: shiftInterval.endTime,
        gender: this.selectedGender?.key,
      };

      if (this.canAddEmail && this.form.email) {
        params.con_id = this.form.email.id;
        params.con_email = this.form.email.email;
      }
      if (this.canAddCcEmail && this.form.ccEmail) {
        params.cc_emails = [
          {
            con_id: this.form.ccEmail.id,
            con_email: this.form.ccEmail.email,
          },
        ];
      }
      params.shift_type = this.selectedShiftType.code;

      return params;
    },
    getShifts() {
      if (this.isSleepoverShift) {
        // New logic
        return this.shiftTimeRows.map((timeRow) => ({
          start_time:
            timeRow.start_time.time &&
            moment(timeRow.start_time.time, "DD/MM/YYYY HH:mm").format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          end_time:
            timeRow.end_time.time &&
            moment(timeRow.end_time.time, "DD/MM/YYYY HH:mm").format(
              "YYYY-MM-DD HH:mm:ss"
            ),
          part_of_the_day: this.partOfDay,
          location_id: this.selectedLocation?.id,
          number_of_shifts: timeRow.number_of_shifts,
        }));
      } else {
        const shifts = [];
        _.each(this.selectedDays, (day) => {
          if (!_.isDate(day.startTime) || !_.isDate(day.endTime)) {
            return;
          }
          shifts.push({
            start_time: moment(day.startTime).format("YYYY-MM-DD HH:mm:ss"),
            end_time: moment(day.endTime).format("YYYY-MM-DD HH:mm:ss"),
            part_of_the_day: this.partOfDay,
            location_id: this.selectedLocation?.id,
            number_of_shifts: day.numberOfShifts,
          });
        });
        return shifts;
      }
    },
    resetMultipleShiftsForm() {
      this.resetForm();
      this.selectedDays = [];
    },
    submitShift() {
      if (!this.selectedDays.length && this.shiftTimeRows.length === 0) {
        this.$toasted.error("You must select some days first!").goAway(1500);
        return;
      }
      const params = this.getSubmitShiftParams();
      console.log("Submit shifts params", params);

      this.isSubmitted = true;

      this.shiftRequestPost(pickNonFalsyKeepZero(params))
        .then(() => {
          this.$toasted.info("Shifts created successfully!").goAway(1500);
          this.resetMultipleShiftsForm();
          this.isSubmitted = false;
        })
        .catch((error) => {
          this.isSubmitted = false;
          let err = "";
          if (error.response.data.errors) {
            err = error.response.data.errors;
            this.errors.record(err);
            Vue.nextTick(function () {
              const notification = document.querySelector(".notification");
              window.scroll(0, findPos(notification));
            });
          } else {
            err = error.response.data.message;
            // this.errorMessage = {
            //   text: err,
            //   exists: true
            // }
            this.$toasted.error(err).goAway(3000);
          }
        });
    },
    onNumberOfShiftsChange(shiftId, $event) {
      const day = _.find(this.selectedDays, { id: shiftId });
      if (!day || !$event) {
        return;
      }
      day.numberOfShifts = Number($event.target.value);
    },
    onDayForChangeSelection(day) {
      this.fromToTime.startTime.time = getFormattedTime(day.startTime, "HH:mm");
      this.fromToTime.endTime.time = getFormattedTime(day.endTime, "HH:mm");
    },
  },
};
</script>
