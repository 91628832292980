<!--
:target - anchor for popup ,
          container must have position:relative

:offset - X/Y offsets for fine tuning the position

:data - array of objects structured {[id:'',cat:'',name:'', cost:[]]}

v-if="popup"  - display to be handled outside of the component ,
                becouse multiple ways to close it!

@close - emits close event from inside the component
@editPopup - emits the new data object

add popup-hook class to the parent of the clicked element for a custom anchor
-->
<template lang="pug">
div(:style="style")
  .edit-popup(:class="{ scrollable: isLoading }")
    .p-title
      span EDIT MODE
    template(v-if="isLoading")
      .loading-wrapper(v-if="isLoading")
        img(src="../assets/images/comps/loader.svg")
      .button-wrapper
        button.button.is-generic-app-teal.is-tiny.is-low(
          data-btn-cancel,
          @click="cancel"
        ) CANCEL
    template(v-if="!isLoading")
      label.ctrl.ctrl--checkbox(
        @click.prevent="changeAllSelected",
        v-if="levels[0] === 'locations' && !isDisabled",
        style="margin-left: 5px"
      ) Select all
        input.ctrl--checkbox(type="checkbox", :checked="isAllCheckboxSelected")
        .ctrl__status(
          :class="{ 'status--halfstate': ifTopLevelAnyCheckedLocations.all && ifTopLevelAnyCheckedLocations.none }"
        )
        img(
          src="../assets/images/comps/loader.svg",
          style="height: 20px; margin-left: 10px",
          v-if="isLoadSelectAll"
        )
      ul(style="overflow: auto")
        li(v-for="el in initData", :key="el.id")
          .row(v-if="el[levels].length != 0")
            label.ctrl.ctrl--checkbox(
              @click.prevent="isDisabled ? null : checkRoot(el[levels])",
              :class="{ disabled: isDisabled }"
            ) {{ el.name }}
              input.top-check-input.ctrl--checkbox(
                type="checkbox",
                :checked="rootStatus(el[levels])"
              )
              .ctrl__status(
                :class="{ 'status--halfstate': ifOnlyAnyChecked(el[levels]) }"
              )
            span.circle {{ checkNumber(el[levels]) }}
            span.fa.drop-arrow(
              data-cell="arrow-button",
              :class="{ 'fa-angle-down': el.id != active_menu, 'fa-angle-up': el.id == active_menu }",
              @click.prevent="toggle(el.id)"
            )

          transition(name="fade")
            ul.target(v-if="active_menu == el.id")
              li.li-label(v-if="levels[0] === 'locations'")
                span Ward
                span(
                  v-if="hasAnyAllowanceSetting(el[levels]) && hasAllowanceAgency"
                ) Has Allowance?
              li(v-for="itemRow in el[levels]", :key="itemRow.id")
                .row
                  label.ctrl.ctrl--checkbox(
                    @click.prevent="onClickInputRow(itemRow.id, el[levels], itemRow, itemRow.checked)",
                    :class="{ disabled: isDisabledInput(itemRow) }"
                  ) {{ itemRow.name }}
                    input.ctrl--checkbox(
                      type="checkbox",
                      :checked="itemRow.checked",
                      :disabled="isDisabledInput(itemRow)"
                    )
                    .ctrl__status
                  span.ctrl--switch(
                    @click.prevent="setCheck(itemRow.id, el[levels], itemRow.hasComplianceAllowance, 'hasComplianceAllowance')",
                    v-if="itemRow.hasComplianceAllowance !== undefined && !isDisabled && hasAllowanceAgency"
                  )
                    switches(
                      v-if="itemRow.checked",
                      theme="bulma",
                      color="blue",
                      v-model="itemRow.hasComplianceAllowance"
                    )
      .columns.spacer
        .column.is-half
          button.button.is-generic-app-teal.is-fullwidth(
            :class="{ 'is-loading': isSaving }",
            :disabled="isSaving",
            v-if="!isDisabled",
            @click="onSaveForm"
          ) SAVE
        .column.is-half
          button.button.is-generic-app-teal.is-fullwidth(
            data-btn-cancel,
            :disabled="isSaving",
            @click="cancel"
          ) CANCEL
</template>
<script>
import _ from "underscore";
import Switches from "@/components/shared/VueSwitches/Switches.vue";
import { mapState } from "vuex";

export default {
  components: {
    Switches,
  },
  props: [
    "data",
    "target",
    "offset",
    "levels",
    "isLoading",
    "isSaving",
    "isDisabled",
  ],
  data() {
    return {
      initData: JSON.parse(JSON.stringify(this.data)),
      active_menu: "",
      adjust: {},
      isAllCheckboxSelected: false,
      isLoadSelectAll: false,
    };
  },
  computed: {
    hasAllowanceAgency() {
      return this.initialStatus.can_view_allowances;
    },
    ifTopLevelAnyCheckedLocations() {
      if (this.levels[0] === "locations" && !this.isLoading) {
        const mapped =
          Array.isArray(this.initData) &&
          this.initData.map((el) => el[this.levels[0]]);
        const flatFirst =
          Array.isArray(mapped) &&
          mapped.map((el) => el.filter((inEl) => inEl.checked)).flat();
        const flatSecond =
          Array.isArray(mapped) &&
          mapped
            .map((el) => el.filter((inEl) => inEl.checked === false))
            .flat();
        return {
          all: flatFirst.length > 0,
          none: flatSecond.length > 0,
        };
      }
      return {
        all: false,
        none: false,
      };
    },
    resultData() {
      return this.initData;
    },
    computedTarget() {
      return this.target;
    },
    style() {
      const styleObj = {
        "z-index": 10,
        position: "absolute",
      };

      const aim = this.target.closest(".popup-hook");
      const hook =
        aim !== null ? this.target.closest(".popup-hook") : this.target;

      // console.warn(this.target);
      // Old logic fallback
      let x =
        parseInt(hook.offsetLeft) + parseInt(this.offset.offsetX) + 35 + "px";
      let y =
        parseInt(hook.offsetTop) + parseInt(this.offset.offsetY) - 50 + "px";

      // New logic
      const rect = hook?.getBoundingClientRect();
      if (rect) {
        styleObj.position = "fixed";

        const offsetPlacementX = 60;
        const offsetPlacementY = 30;
        let left = Math.round(rect.left + offsetPlacementX);
        let top = Math.round(rect.top);

        // Need to recalc direction [CAN CHANGE IN FUTURE - arbitrary sizes]
        const arbitraryWidth = 280;
        const arbitraryHeight = 350;
        const viewX = window.innerWidth;
        const viewY = window.innerHeight;
        const overflowX = viewX < left + arbitraryWidth;
        const overflowY = viewY < top + arbitraryHeight;
        if (overflowX) {
          left = rect.left - arbitraryWidth;
        }
        if (overflowY) {
          top = rect.top + offsetPlacementY - arbitraryHeight;
        }

        x = `${left}px`;
        y = `${top}px`;
      }

      styleObj.left = x;
      styleObj.top = y;

      return styleObj;
    },
  },
  watch: {
    target() {
      this.reset();
    },
    data() {
      // 10ms conversion [JSON]
      this.initData = JSON.parse(JSON.stringify(this.data));
      //if there is only 1 menu, open the submenu
      if (this.initData.length === 1) this.active_menu = this.initData[0].id;
    },
    ifTopLevelAnyCheckedLocations: {
      handler(val) {
        if (val.all && val.none) {
          this.isAllCheckboxSelected = false;
        } else if (!val.none && val.all) {
          this.isAllCheckboxSelected = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    changeAllSelected() {
      this.isAllCheckboxSelected = !this.isAllCheckboxSelected;
      this.isLoadSelectAll = true;
      this.$nextTick(() => {
        const allTopInputs = [...this.$el.querySelectorAll(".top-check-input")];
        if (this.isAllCheckboxSelected) {
          for (const input of allTopInputs) {
            if (!input.checked) {
              input.click();
            }
          }
        } else {
          for (const input of allTopInputs) {
            if (input.checked) {
              input.click();
            }
          }
        }
        this.isLoadSelectAll = false;
      });
    },
    hasAnyAllowanceSetting(levelsRows) {
      return levelsRows.some((row) => row.hasComplianceAllowance);
    },
    rootStatus: function (obj) {
      const checked = _.filter(obj, (el) => {
        return el.checked === true;
      });

      return checked.length === obj.length && obj.length !== 0;
    },
    checkNumber: function (obj) {
      const checked = _.filter(obj, (el) => {
        return el.checked === true;
      });

      return checked.length;
    },
    ifOnlyAnyChecked(arr) {
      return (
        arr.some((el) => el.checked) && arr.some((el) => el.checked === false)
      );
    },
    checkRoot: function (obj) {
      if (!this.rootStatus(obj)) {
        Object.keys(obj).map(function (key) {
          this[key].checked = true;
        }, obj);
      } else {
        Object.keys(obj).map(function (key) {
          this[key].checked = false;
        }, obj);
      }
    },
    toggle: function (id) {
      this.active_menu = this.active_menu !== id ? id : "";
    },
    setCheck: function (id, centres, checked, property) {
      const who = _.findWhere(centres, { id: id });
      console.warn(who);
      this.$set(who, property, !checked);
    },
    onSaveForm() {
      // this.isSaving = true;
      const count = _.map(this.resultData, (obj) => {
        return obj[this.levels].filter((t) => {
          return t.checked === true;
        }).length;
      });
      const totalChecked = _.reduce(
        count,
        (d, t) => {
          return d + t;
        },
        0
      );

      const result = {
        entity: this.levels,
        data: this.resultData,
      };

      this.$emit("editPopup", result, totalChecked);
    },
    cancel() {
      this.$emit("close");
    },
    reset() {
      console.log("reseted data");
      this.initData = JSON.parse(JSON.stringify(this.data));
    },
    onClickInputRow(id, levelGroup, itemRow, checked) {
      const isDisabled = this.isDisabledInput(itemRow);
      if (!isDisabled) {
        this.setCheck(id, levelGroup, checked, "checked");
      }
    },
    isDisabledInput(itemRow) {
      if (this.levels[0] === "locations") {
        return Boolean(this.isDisabled || itemRow.archived);
      }
      return Boolean(this.isDisabled);
    },
  },
};
</script>
<style scoped lang="scss">
$color--white: #ffffff;
$color--light-grey: #e3ebed;
$color--dark-blue: #405168;
$color--dark-grey: #7b7b7b;
$color--primary: #2669b0;
$color--secondary: #7c9ec9;

.edit-popup {
  width: 280px;
  max-height: 350px;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 3px;
  background-color: #ffffff;
  z-index: 10;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.13);
  display: flex;
  flex-direction: column;
  gap: 10px;

  .spacer {
    padding: 0 10px 10px;

    .button {
      height: 2em;
    }
  }

  .p-title {
    opacity: 0.4;
    color: #405168;
    text-align: center;
    padding: 10px;

    span {
      font-size: 11px;
      font-weight: bold;
      line-height: 13px;
    }
  }

  .circle {
    border-radius: 50%;
    background: $color--light-grey;
    width: 24px;
    height: 24px;
    line-height: 24px;
    color: $color--dark-blue;
    text-align: center;
    font-weight: 500;
  }

  > ul {
    padding-bottom: 10px;
  }

  label.disabled {
    color: #b6b6b6;
    cursor: default;
  }
}

div.row {
  display: flex;
  flex: 1 100%;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px 5px 10px;

  label {
    flex: 2;
    font-size: 14px;
    color: $color--dark-blue;
    font-weight: 500;
  }
}

.ctrl {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 18px;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.ctrl__status {
  position: absolute;
  top: 5px;
  left: 0;
  height: 12px;
  width: 12px;
  background: $color--white;
  border: 1px solid $color--secondary;
  border-radius: 2px;

  .ctrl input:checked ~ & {
    background: $color--primary;
  }

  .ctrl input:disabled ~ & {
    background: $color--light-grey;
    opacity: 0.6;
    pointer-events: none;
  }

  &:after {
    content: "";
    position: absolute;
    display: none;

    .ctrl input:checked ~ & {
      display: block;
    }

    .ctrl--checkbox & {
      left: 3px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid $color--white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    .ctrl--checkbox input:disabled ~ & {
      border-color: $color--dark-grey;
    }
  }
}

.ctrl--switch {
  display: inline-block;
  padding-left: 10px;
}

.status--halfstate {
  background: linear-gradient(
    to right bottom,
    $color--white 50%,
    $color--primary 50%
  );
}

.fade-enter-to,
.fade-leave {
  max-height: 500px;
}

.fade-enter,
.fade-leave-to {
  max-height: 0;
}

.target {
  transition: all 0.5s;
  overflow: hidden;

  li {
    margin: 0 10px;

    &:first-child {
      border-top: 1px solid $color--light-grey;
      padding-top: 5px;
    }
  }
}

li.li-label {
  display: flex;
  justify-content: space-between;
  color: #bbb;
  border-bottom: 1px solid #eee;
  padding-bottom: 3px;
  margin-bottom: 5px;
}

.loading-wrapper {
  text-align: center;
}

.button-wrapper {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="scss" scoped>
.vue-switcher div {
  height: 5px;
  width: 30px;
  border-radius: 4px;

  &:after {
    margin-left: -14px;
    height: 12px;
    width: 12px;
  }
}
</style>
