import qs from "query-string";
import _ from 'underscore';
import { generateLaravelUrl, packLaravelArray } from '../lib/helpers/function.js';
import { default as axios } from "./config.js";

export default {
  getStatusInitialLoad: (params) => {
    return axios({
      url: "get-status",
      params,
    });
  },
  fetchAllClients: (q) => {
    return axios.get("/clients", {
      params: {
        include: q.includes,
        per_page: q.per_page,
        optimise: q.optimise,
      },
    });
  },
  fetchActiveClientsList: (q) =>
    axios({
      url: "clients",
      params: {
        active: q.active || true,
        include: q.includes,
        per_page: q.per_page,
      },
    }),
  fetchAllClientsActiveTagsInfo: (q) => {
    return axios.get("/admin/security/shift-activity-tags/clients", {
      params: {
        include: q.includes,
        per_page: q.per_page,
      },
    });
  },
  postClientsListActivityTags: (params) =>
    axios({
      url: "/admin/security/shift-activity-tags/clients",
      method: "POST",
      data: params,
    }),
  fetchAllCategories: () => {
    return axios.get("/categories", {
      params: {
        include: "subcategories",
      },
    });
  },
  fetchAllRoles: () => {
    return axios.get("/roles", {
      params: {
        with_temp: 1,
      },
    });
  },
  fetchMinRoles: () =>
    axios({
      url: "roles",
      method: "GET",
    }),
  /**
   * this route is for when logged as temp
   * to get his own profile
   */
  fetchUserProfile: (params = {}) => {
    return axios.get(`/profile`, {
      params: {
        ...params.body,
        include: params.include,
      },
    });
  },
  fetchAllSpecialities: () => {
    return axios.get("/specialities");
  },
  fetchAllTemps: (params = {}) => {
    let url = `/temps`;

    const laravelArr = ['clients', 'categories', 'subcategories', 'sectors'];
    for (const larKey of laravelArr) {
      if (params[larKey]) {
        const packed = packLaravelArray(larKey, params[larKey]);
        if (packed !== '') {
          // If first
          if (url.indexOf('?') === -1) {
            url = url + '?' + packed;
          } else {
            url = url + '&' + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete params[larKey];
      }
    }

    return axios.get(url, { params });
  },
  // Idiomatically same as fetchAllTemps, but cancellable as well.
  fetchAllTempsCancellable: (params = {}, cancelTokenSource) => {
    let url = `/temps`;

    const laravelArr = ['clients', 'categories', 'subcategories', 'sectors'];
    for (const larKey of laravelArr) {
      if (params[larKey]) {
        const packed = packLaravelArray(larKey, params[larKey]);
        if (packed !== '') {
          // If first
          if (url.indexOf('?') === -1) {
            url = url + '?' + packed;
          } else {
            url = url + '&' + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete params[larKey];
      }
    }

    return axios.get(url, {
      params,
      cancelToken: cancelTokenSource.token,
    });
  },

  fetchTemps: (query = {}) => {
    // const arr = ['subcategories']
    // const queryParam = packLaravelArray(arr[0], query[arr[0]])
    return axios.get(
      `/temps-for-shift`,
      {
        params: {
          ...query,
          include: "subcategories,specialities,preferredRegions",
        },
      }
    );
  },

  /**
   * this route is for admin only
   * to be able to get temp's profile
   */
  fetchTempProfile: (query = {}) => {
    console.log(query);
    return axios.get(`/temps/${query.tempId}`, {
      params: {
        ...query.body,
        include: query.include,
      },
    });
  },

  /**
   * this route is for admin only
   * to be able to patch temp's profile
   */
  patchTemp: (id, payload = {}, query = "") => {
    return axios.patch(`/temps/${id}`, payload, {
      params: {
        include: query.include,
      },
    });
  },

  getIncrements: (params) =>
    axios({
      url: "increments",
      params,
    }),
  postIncrements: (params) =>
    axios({
      url: "increments",
      method: "POST",
      data: params,
    }),
  postUpdateForCategory: (params) =>
    axios({
      url: `temps/${params.temp_id}/increments/update-for-category`,
      method: "POST",
      data: params,
    }),

  /*
   *  Adds a new increment
   *  API accepts: category_id, increment_id, start_date: 'YYYY-MM-DD'
   *
   */
  // patchTempIncrement: (id, payload = {}, query = '') => {
  //   // Should at least request increments from the API in order to patch VUEX properly
  //   // This should be the default
  //   var includes = 'increments'
  //   if (query !== '' && query.include !== undefined) {
  //     includes += query.include
  //   }
  //   return axios.patch(`/temps/${id}/increments`, payload, {
  //     params: {
  //       include: includes
  //     }
  //   })
  // },
  postTempIncrement: (params) =>
    axios({
      url: `temps/${params.temp_id}/increments`,
      method: "POST",
      data: params,
    }),

  /*
   *  Deletes an increment
   *  API accepts: category_id, increment_id
   *
   */
  // deleteTempIncrement: (id, payload = {}, query = '') => {
  //   console.log('DELETE TEMP INCREMENT API INVOKED :: ', id, payload)
  //   var includes = 'increments'
  //   if (query !== '' && query.include !== undefined) {
  //     includes += query.include
  //   }
  //   return axios.delete(`/temps/${id}/increments`, {
  //     data: payload,
  //     params: {
  //       include: includes
  //     }
  //   })
  // },
  deleteTempIncrement: (params) =>
    axios({
      url: `temps/${params.temp_id}/increments`,
      method: "DELETE",
      data: params,
    }),
  createTimesheetEntry: (params) =>
    axios({
      url: "shifts/create-timesheet-line",
      method: "POST",
      data: params,
    }),
  shiftRequestPost: (params) =>
    axios({
      url: "shift-requests",
      method: "POST",
      data: params,
    }),
  editUserAction: (params) =>
    axios({
      url: params.url,
      method: "PATCH",
      data: params.params,
    }),
  postDocumentAction: (params) =>
    axios.post(params.url, params.form, params.config),

  /**
   * For async find worker on timesheet entry... TODO
   */

  fetchWorkers: (q) => axios({
    url: 'temps',
    params: q,
  }),
  getUsers: (params) =>
    axios({
      url: `/users/${params.id}`,
      method: "GET",
      params: {
        include: "preferredRegions",
      },
    }),
  patchUsers: (id, payload = {}, query = "") => {
    return axios.patch(`/users/${id}`, payload, {
      params: {
        include: query.include,
      },
    });
  },

  fetchUsers: (q) => {
    return axios.get(`/users`, {
      params: q,
    });
  },

  getUsersCancellable: (params = {}, cancelTokenSource) => {
    const queryParams = qs.stringify(params);
    return axios.get(`/users?${queryParams}`, {
      cancelToken: cancelTokenSource.token,
    });
  },

  fetchUsersCancellable: (params) => {
    const cleanParams = _.omit(params, 'cancelTokenSource');
    return axios({
      url: "/users",
      params: cleanParams,
      cancelToken: params.cancelTokenSource.token,
    });
  },

  createUser: (payload, includes) => {
    return axios.post(`/users`, payload, {
      params: {
        include: includes,
      },
    });
  },

  deleteUser: (id) => {
    return axios.delete(`/users/${id}`);
  },

  // Dashboard [Don't add handlers here]
  fetchCards: () => {
    return axios.get("/dashboard");
  },

  fetchTempShiftsLog: (q) => {
    return axios({
      url: `/temps/${q.id}/shift-logs`,
      params: {
        include: q.include,
        page: q.page,
      },
    });
  },

  fetchDocumentLocations: (q) => {
    return axios({
      url: `/documenttypes/${q.id}/locations`,
    });
  },

  fetchDocumentLocationsPerClient: (q) => {
    const tempPayload = JSON.parse(JSON.stringify(q));
    let fullRequestUrl = `/documenttypes/${q.id}/locations`;

    const laravelArr = ['clients'];
    for (const larKey of laravelArr) {
      if (tempPayload[larKey]) {
        const packed = packLaravelArray(larKey, tempPayload[larKey]);
        if (packed !== '') {
          // If first
          if (fullRequestUrl.indexOf('/?') === -1) {
            fullRequestUrl = fullRequestUrl + '?' + packed;
          } else {
            fullRequestUrl = fullRequestUrl + '&' + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete tempPayload[larKey];
      }
    }
    return axios({
      url: fullRequestUrl,
    });
  },

  fetchDocumentCategoriesPerClient: (q) => {
    const tempPayload = JSON.parse(JSON.stringify(q));
    let fullRequestUrl = `/documenttypes/${q.id}/categories`;

    const laravelArr = ['clients'];
    for (const larKey of laravelArr) {
      if (tempPayload[larKey]) {
        const packed = packLaravelArray(larKey, tempPayload[larKey]);
        if (packed !== '') {
          // If first
          if (fullRequestUrl.indexOf('/?') === -1) {
            fullRequestUrl = fullRequestUrl + '?' + packed;
          } else {
            fullRequestUrl = fullRequestUrl + '&' + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete tempPayload[larKey];
      }
    }
    return axios({
      url: fullRequestUrl,
    });
  },

  fetchLocationsPerClient: (q) => {
    const includes = '?include=locationOnCallInfo,locationSleepoverInfo';
    const suffixParams = includes;
    return axios({
      url: `/clients/${q.id}/locations${suffixParams}`,
    });
  },

  fetchTempInfoFromTss: (url) => {
    // tss id /temps/tssIdInfo/${q.id} , changed 02.07.2023 to external id (CR - Rehire Phase 1)
    return axios({
      url,
    });
  },

  // check the temp fields if they are disabled
  getExternalServiceDisabledFields: () => {
    return axios({
      url: "/temps/get-external-service-disabled-fields",
      method: "GET",
    });
  },

  // Rates API [perm: manage-rates]
  postColumnForRates: (params) =>
    axios({
      url: `/rates/values/${params.cit_id}`,
      method: "POST",
      data: params,
    }),
  patchRates: (params) =>
    axios({
      url: "rates",
      method: "PATCH",
      data: params,
    }),
  deleteRates: (params) =>
    axios({
      url: "rates",
      method: "DELETE",
      params,
    }),
  postRates: (params) =>
    axios({
      url: "rates",
      method: "POST",
      data: params,
    }),
  reviewRates: params => axios.post(`rates/sanity-check-report`, params, { responseType: 'blob' }),
  reviewRatesGlobal: params => axios.post(`global-rates/sanity-check-report`, params, { responseType: 'blob' }),

  // Rates other API
  // Rates categories
  getRatesCategories: (params) =>
    axios({
      url: "rates/categories",
      params,
    }),
  postRatesCategories: (params) =>
    axios({
      url: `rates/categories`,
      method: "POST",
      data: params,
    }),
  patchRatesCategories: (params) =>
    axios({
      url: `rates/categories/${params.catId}`,
      method: "PATCH",
      data: params,
    }),
  deleteRatesCategories: (params) =>
    axios({
      url: `rates/categories/${params.catId}`,
      method: "DELETE",
      params,
    }),

  // Rates increments
  getRatesIncrementsCategory: (params) =>
    axios({
      url: `rates/increments-for-categories`,
      method: "POST",
      data: params,
    }),
  getRatesIncrements: (params) =>
    axios({
      url: `rates/categories/${params.catId}/increments`,
      params,
    }),
  postRatesIncrements: (params) =>
    axios({
      url: `rates/categories/${params.catId}/increments`,
      method: "POST",
      data: params,
    }),
  deleteRatesIncrements: (params) =>
    axios({
      url: `rates/categories/${params.catId}/increments/${params.incId}`,
      method: "DELETE",
      params,
    }),
  patchRatesIncrements: (params) =>
    axios({
      url: `rates/categories/${params.catId}/increments/${params.incId}`,
      method: "PATCH",
      data: params,
    }),

  // Rates
  getRatesSectors: (params) =>
    axios({
      url: "rates/sectors",
      params,
    }),
  getRatesCalcItems: (params) =>
    axios({
      url: "rates/calcitems",
      params,
    }),
  getRatesClients: (params) =>
    axios({
      url: "rates/clients",
      params,
    }),
  postRatesFinalize: (params) =>
    axios({
      url: "rates/finalize",
      method: "POST",
      data: params,
    }),
  getMaxEffectiveDate: params => axios.get('rates/max-effective-date', { params }),
  getRatesClientsForClientManagement: params => axios.get('get-rate-clients', { params }),

  // Security
  getInactiveSecurity: params => axios.get('admin/security/admin-inactive-script-interval', { params }),
  putInactiveSecurity: params => axios.put('admin/security/admin-inactive-script-interval', params),
  getViableSubcategories: params => axios.get(`shifts/${params.id}/get-viable-subcategories`, { params }),

  // Upload file [timesheet]
  getCsvDataTimesheet: params => axios.get(`bulk-upload-errors/timesheet-shifts`, { params }),
  getBulkUploadProgressTimesheet: params => axios.get(`bulk-upload/timesheet-shifts`, { params }),
  postFileShiftsTimesheet: fd => axios.post(`bulk-upload/timesheet-shifts`, fd), // Formdata

  // Upload file [open shifts]
  getCsvDataOpenShifts: params => axios.get(`bulk-upload-errors/open-shifts`, { params }),
  getBulkUploadProgressOpenShifts: params => axios.get(`bulk-upload/open-shifts`, { params }),
  postFileShiftsOpenShifts: fd => axios.post(`bulk-upload/open-shifts`, fd), // Formdata

  // Client break times
  getBreakTimes: params => axios.get(`clients/${params.id}/breaktimes-per-category`, { params }),
  patchBreakTimes: params => axios.put(`clients/${params.client_id}`, params),

  // Next of kin [relationship]
  getRelationshipTypes: params => axios.get('relative-types', { params }),

  // Column Names (localization)
  getColumnNamesLocl: params => axios.get('get-column-names', { params }),

  // Shift schedule
  postMultipleShiftsDay: params => axios.post(`shifts/${params.shift_id}/sign-off-check`, params),

  // Client contacts
  getClientContactsList: params => axios.get('contacts', { params }),
  postClientContacts: params => axios.post('contacts', params),
  putClientContacts: params => axios.put(`contacts/${params.id}`, params),
  deleteClientContacts: params => axios.delete(`contacts/${params.id}`, { params }),
  getClientContactsTypesList: params => axios.get('contacttypes', { params }),
  postClientContactsTypes: params => axios.post('contacttypes', params),
  postClientContactsTypesVisibility: params => axios.post(`contacttypes/${params.id}/toggle-visibility`, params),
  putClientContactsTypes: params => axios.put(`contacttypes/${params.id}`, params),

  // Client Service Fee (Finances)
  getServiceFeeCalcItems: params => axios.get('servicefees/calcitems', { params }),
  getServiceFees: params => axios.get(`servicefees`, { params }),
  patchServiceFees: params => axios.post(`servicefees`, params),
  deleteServiceFees: params => axios.delete(`servicefees/${params.id}`, params),
  postMergeRatesServiceFees: params => axios.post(`servicefees/copy-from-rates`, params),
  postActivateFees: params => axios.post(`servicefees/activate`, params),
  getCheckInactiveFees: params => axios.get('servicefees/check-inactive', { params }),

  // Client Invoice
  getClientCategories: params => axios.get(`clients/${params.client_id}/categories`, { params }),
  getClientLocations: params => axios.get(`clients/${params.client_id}/locations`, { params }),
  // getInvoiceSplit: params => axios.get(`servicefees/invoicesplit`, { params }),
  // postInvoiceSplit: params => axios.post(`servicefees/invoicesplit`, params),
  // patchInvoiceSplit: params => axios.patch(`servicefees/invoicesplit/${params.ins_id}`, params),
  // deleteInvoiceSplit: params => axios.delete(`servicefees/invoicesplit/${params.ins_id}`, { params }),

  // Client Candidate Tags
  getTempTags: () => axios.get('/tags'),
  postTempTags: params => axios.post('/tags', params),
  putTempTags: params => axios.put(`/tags/${params.id}`, params),
  deleteTempTags: params => axios.delete(`/tags/${params.id}`),

  // Client Journal Note Types
  getNoteTypes: params => axios.get('/notetypes', { params }),
  postNoteType: params => axios.post(`/notetypes`, params),
  patchNoteType: params => axios.patch(`/notetypes/${params.id}`, params),
  // systemNotes: params => axios.get('/system-notes', { params }),
  systemNotes: data => {
    let url = '/system-notes';
    const laravelArr = ['temps', 'clients', 'created_by_ids', 'notetypes'];
    for (const larKey of laravelArr) {
      if (data.params[larKey]) {
        const packed = packLaravelArray(larKey, data.params[larKey]);
        if (packed !== '') {
          // If first
          if (url.indexOf('?') === -1) {
            url = url + '?' + packed;
          } else {
            url = url + '&' + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete data.params[larKey];
      }
    }

    return axios.get(url, { params: data.params, "signal": data.abortSignal });
  },
  // Client Journal Notes
  getNotes: data => {
    let url = '/notes';
    const laravelArr = ['temps', 'clients', 'created_by_ids', 'notetypes'];
    for (const larKey of laravelArr) {
      if (data.params[larKey]) {
        const packed = packLaravelArray(larKey, data.params[larKey]);
        if (packed !== '') {
          // If first
          if (url.indexOf('?') === -1) {
            url = url + '?' + packed;
          } else {
            url = url + '&' + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete data.params[larKey];
      }
    }

    return axios.get(url, { params: data.params, "signal": data.abortSignal });
  },
  getFileBlob: params => axios.get(params.link, { params, responseType: 'blob' }),
  postFileBlob: params => axios.post(params.link, params, { responseType: 'blob' }),
  getDynamicApi: params => axios.get(params.link),

  postNotes: params => axios.post(`/notes`, params.fd), // Formdata
  postOneNote: params => axios.post(`/notes/${params.id}`, params.fd), // Formdata
  // getNoteFile: params => axios.get(`/notefiles/${params.id}`, { params, responseType: 'blob' }),
  deleteNoteFile: params => axios.delete(`/notefiles/${params.id}`, { params }),
  // getNoteReport: params => axios.get(`/notes/report`, { params, responseType: 'blob' }),

  getNoteReport: params => {
    let url = '/notes/report';
    const laravelArr = ['temps', 'clients', 'notetypes'];
    for (const larKey of laravelArr) {
      if (params[larKey]) {
        const packed = packLaravelArray(larKey, params[larKey]);
        if (packed !== '') {
          // If first
          if (url.indexOf('?') === -1) {
            url = url + '?' + packed;
          } else {
            url = url + '&' + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete params[larKey];
      }
    }

    return axios.get(url, { params, responseType: 'blob' });
  },

  // Rates Category
  postCategory: params => axios.post('/rates/categories', params),
  patchCategory: params => axios.patch(`/rates/categories/${params.catId}`, params),

  // Config API [Meta]
  getConfig: params => axios.get(`config`, { params }),
  postConfig: params => axios.post(`config`, params),

  // Notification centre
  fetchTemplates: params => axios.get('notification-centre/templates', { params }),
  fetchTemplateContent: params => axios.get(`notification-centre/templates/${params.id}`),
  postTemplate: params => axios.post('notification-centre/templates', params),
  fetchSignatures: params => axios.get('notification-centre/messages/signatures', { params }),
  fetchSignatureData: params => axios.get(`notification-centre/messages/signatures/${params.id}`),
  postSignature: params => axios.post('notification-centre/messages/signatures', params),
  putSignature: params => axios.put(`notification-centre/messages/signatures/${params.id}`, params),
  deleteSignature: params => axios.delete(`notification-centre/messages/signatures/${params.id}`),
  getEmailFrom: params => axios.get('admin/security/notification-centre/email-from', { params }),
  postEmailFrom: params => axios.post('admin/security/notification-centre/email-from', params),

  getNotificationCentreChildLogDetails: params => axios.get('notes/notification-centre-child-log-details', { params }),
  fetchMessages: params => {
    let url = `/notification-centre/messages`;

    const laravelArr = ['clients', 'categories', 'subcategories', 'sectors', 'temps', 'statuses'];
    for (const larKey of laravelArr) {
      if (params[larKey]) {
        const packed = packLaravelArray(larKey, params[larKey]);
        if (packed !== '') {
          // If first
          if (url.indexOf('?') === -1) {
            url = url + '?' + packed;
          } else {
            url = url + '&' + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete params[larKey];
      }
    }

    return axios.get(url, { params });
  },
  postMessage: params => {
    let url = `notification-centre/messages/${params.urlPart}`;
    delete params.urlPart;

    const laravelArr = ['clients', 'categories', 'subcategories', 'sectors', 'temps', 'statuses'];
    for (const larKey of laravelArr) {
      if (params[larKey]) {
        const packed = packLaravelArray(larKey, params[larKey]);
        if (packed !== '') {
          // If first
          if (url.indexOf('?') === -1) {
            url = url + '?' + packed;
          } else {
            url = url + '&' + packed;
          }
        }
        // Has to be called (delete) so it doesn't send duplicate params
        delete params[larKey];
      }
    }

    const formData = new FormData();
    for (const key in params) {
      if (key === 'attachments') {
        // handle separatelly files loaded from template and those that have been added manually
        params.attachments.forEach(file => {
          if (file.id) { // having an ID means its loaded from template
            formData.append('template_attachments[][id]', file.id);
          } else {
            formData.append('attachments[]', file);
          }
        });
        continue;
      }
      if (key === 'body') {
        formData.append('body', JSON.stringify(params.body));
        continue;
      }
      formData.append(key, params[key]);
    }

    return axios.post(url, formData);
  },

  // User Weekly Increment Counter
  getIncrementWeeksCounterValue: params => axios.get(`temps/${params.id}/increment-weeks-counter`, { params }),
  postIncrementWeeksCounterValue: params => axios.post(`temps/${params.id}/increment-weeks-counter`, { ...params.payload }),
  getIncrementWeeksCounter: params => axios.get('admin/security/increment-counter-range', { params }),
  postIncrementWeeksCounter: params => axios.post('admin/security/increment-counter-range', params),

  // Holiday request
  getHolidayRequests: params => axios.get(`temps/${params.id}/holiday-requests`, { params }),
  getPayBillHolidayReq: params => axios.get(`invoices/holiday-request`, { params }),
  postPayBillHolidayReq: params => axios.post(`invoices/holiday-request`, params),
  getClientsHolidayRequest: params => axios.get(`admin/security/holiday-requests/clients`, { params }),
  postClientsHolidayRequest: params => axios.post(`admin/security/holiday-requests/clients`, params.payload),
  getCategoriesHolidayRequest: params => axios.get(`admin/security/holiday-requests/subcategories`, { params }),
  postCategoriesHolidayRequest: params => axios.post(`admin/security/holiday-requests/subcategories`, params.payload),

  // Invoices - Expenses | Shift
  getShiftBilling: params => axios.get('invoices/shifts/get-billing-periods', { params }),
  getInvoicesShiftBilling: params => axios.get('invoices/shifts/get-invoices-for-billing-period', { params }),
  getExpensesBilling: params => axios.get(`invoices/expenses/get-billing-periods`, { params }),
  getInvoicesExpensesBilling: params => axios.get('invoices/expenses/get-invoices-for-billing-period', { params }),

  // Settings perms
  getRolesPermissions: params => axios.get(`admin/security/roles-with-permissions`, { params }),
  postRolesPermissions: params => axios.post(`admin/security/roles-with-permissions`, params),

  // Permission per location (Users Management)
  getWholeAdminRegions: params => axios.get(`admin/sectors`, { params }),
  getWholeAdminCategories: params => axios.get(`admin/categories`, { params }),
  // getAdminRegions: params => axios.get(`admin/sectors`, { params }),
  // getAdminClients: params => axios.get(`admin/sectors/${params.sec_id}/clients`, { params }),
  // getAdminLocations: params => axios.get(`admin/sectors/${params.sec_id}/clients/${params.cli_id}/locations`, { params }),
  // getAdminCategories: params => axios.get(`admin/categories`, { params }),
  // getAdminSubcategories: params => axios.get(`admin/categories/${params.cat_id}/subcategories`, { params }),
  getUserSecCliLocPerm: params => axios.get(`admin/${params.usr_id}/sct-cli-loc-permissions`, { params }),
  postUserSecCliLocPerm: params => axios.post(`admin/${params.usr_id}/sct-cli-loc-permissions`, params),
  getUserCatPerm: params => axios.get(`admin/${params.usr_id}/cat-permissions`, { params }),
  postUserCatPerm: params => axios.post(`admin/${params.usr_id}/cat-permissions`, params),

  // Rates managment (front abstraction)
  getRatesFrontConfig: params => axios.get('rates/frontend-rate-management-config', { params }),
  getRatesData: params => axios.get('rates', { params }),

  // Expenses
  getExpenses: (params, cancelTokenSource) => {
    const paramPack = ['clients', 'temps'];
    const tempUrl = '/expenses';
    const url = generateLaravelUrl(params, paramPack, tempUrl);
    return axios.get(url, {
      params,
      cancelToken: cancelTokenSource.token,
    });
  },
  getExpensesInfo: params => axios.get(`/expenses/${params.id}`, { params }),
  postExpensesSignOff: params => axios.post(`/expenses/${params.id}/sign-off`, params),
  postExpensesReject: params => axios.post(`/expenses/${params.id}/reject`, params),
  getExpensesFile: params => axios.get(`/expense-files/${params.id}`, { params: params }),
  getExpensesTypes: params => axios.get('/expense-types', { params }),
  getExpenseInitialPrice: params => axios.post(`/expenses/${params.id}/sign-off-preview`, params),

  // Expenses - general receipts
  getGeneralReceipts: params => axios.get(`/admin/security/general-receipts`, { params }),
  postGeneralReceipts: params => axios.post(`/admin/security/general-receipts`, params),
  putGeneralReceipts: params => axios.put(`/admin/security/general-receipts/${params.id}`, params),
  deleteGeneralReceipts: params => axios.delete(`/admin/security/general-receipts/${params.id}`, { params }),

  // Expenses - rates
  getUserPaymentRates: params => axios.get(`/userpayment-rates`, { params }),
  postUserPaymentRates: params => axios.post(`/userpayment-rates`, params),
  deleteUserPaymentRates: params => axios.delete(`/userpayment-rates`, { params }),
  getUserPaymentRatesConfig: params => axios.get(`/userpayment-rates/frontend-rate-management-config`, { params }),
  getUserPaymentRatesMaxEffectiveDate: params => axios.get(`/userpayment-rates/max-effective-date`, { params }),
  postUserPaymentFinalize: params => axios.post(`/userpayment-rates/finalize`, params),

  // PayBill - Expenses Billing
  getPayBillExpensesReq: params => axios.get(`invoices/expenses`, { params }),
  postPayBillExpensesReq: params => axios.post(`invoices/expenses`, params),
  getPayBillExpensesGroupDownload: params => axios.get(`invoices/expenses/get-backup-invoices-for-group-download`, { params }),
  getPayBillExpensesCsv: (params) =>
    // Not used
    axios.get(`invoices/expenses/${params.id}/pay-and-bill.csv`, {
      responseType: "arraybuffer",
      params: {
        timestamp: new Date().getTime(),
      },
    }),
  getPayBillExpensesBackup: (params) =>
    axios.get(`invoices/expenses/${params.id}/backup.csv`, {
      responseType: "arraybuffer",
      params: {
        timestamp: new Date().getTime(),
      },
    }),

    // PayBill - Invoices Billing
  getPayBillInvoicesCsv: (params) =>
    // Not used
    axios.get(`invoices/${params.id}/pay-and-bill.csv`, {
      responseType: "arraybuffer",
      params: {
        timestamp: new Date().getTime(),
      },
    }),
  getPayBillInvoicesBackup: (params) =>
    axios.get(`invoices/${params.id}/backup.csv`, {
      responseType: "arraybuffer",
      params: {
        timestamp: new Date().getTime(),
      },
    }),
  // Settings password validation
  validatePassword: (password) => axios({
    url: "validate-password",
    method: "GET",
    params: password,
  }),
  // Verification Token
  checkToken: params => axios.put(`/bank-details/verify-token`, params),
  // Get workers list on notification center "Viewed by workers button"
  getWorkersListOpenedMessages: params => axios.get('temps/seen-message-batch', { params }),
  // Getting payroll shift logs on shift details Payment Logs tab
  getPayrollShiftLogs: params => axios.get(`admin/shifts/${params.shift_id}/payroll-shift-logs`, { params }),
  // Getting external history log on shift details 
  getExternalHistoryLog: params => axios.get(`admin/shifts/${params.shift_id}/calculations-external-info`, { params }),
  //Backpay preview table 
  getListOfPreviewReports: params => axios.get(`shifts-mass-recalculation/preview-jobs`, { params }),
  //Backpay preview table confirm 
  confirmPreviewReports: params => axios.post(`shifts-mass-recalculation/jobs/${params.id}/confirm`, params),
  //Backpay preview table cancel 
  cancelPreviewReports: params => axios.post(`shifts-mass-recalculation/jobs/${params.id}/cancel`, params),
  // Backpay progress
  progressBackpay: params => axios.get(`shifts-mass-recalculation/progress`, { params }),
  // List of reports 
  getListOfReports: params => axios.get(`shifts-mass-recalculation/jobs`, { params }),
  // Check recalculation
  checkMassRecalculation: params => axios.post(`shifts-mass-recalculation/preview-mark-shifts-result`,  params),
  // checkMassRecalculation: (params = {}) => {
  //   let url = `shifts-mass-recalculation`;
  //   const laravelArr = ['clients', 'categories', 'subcategories', 'temps', 'statuses'];
  //   for (const larKey of laravelArr) {
  //     if (params[larKey]) {
  //       const packed = packLaravelArray(larKey, params[larKey]);
  //       if (packed !== '') {
  //         // If first
  //         if (url.indexOf('?') === -1) {
  //           url = url + '?' + packed;
  //         } else {
  //           url = url + '&' + packed;
  //         }
  //       }
  //       // Has to be called (delete) so it doesn't send duplicate params
  //       delete params[larKey];
  //     }
  //   }
  //   return axios.get(url, { params });
  // },
  shiftsMassRecalculationFilters: () => axios.get('shifts-mass-recalculation/filters'),
  // Start recalculation
  startMassRecalculation: params => axios.post(`shifts-mass-recalculation`, params),
  // zip recalculations
  massRecalculationGetBackpayInvoicesOnJobId: params => axios.get(`shifts-mass-recalculation/${params.id}/get-backpay-invoices`),
  massRecalculationGetBackpayInvoiceFile: params => axios.get(`shifts-mass-recalculation/jobs/${params.job_id}/${params.invoice_id}/backup.csv`),
  // revert backpay
  revertCalculations: params => axios.post(`shifts-mass-recalculation/revert`, params),
  // Rate Management, Public Holidays tab, get years
  getPublicHolidaysYears: params => axios.get(`public-holidays/metaInfo`, { params }),
  // Rate Management, Public Holidays tab, get holidays for year
  getPublicHolidaysByYear: params => axios.get(`public-holidays`, { params }),
  // Rate Management, Public Holidays tab, create holiday
  postCreatePublicHoliday: params => axios.post(`public-holidays`, params),
  // Rate Management, Public Holidays tab, update holiday
  updateHoliday: params => axios.put(`public-holidays`, params),
  // Rate Management, Public Holidays tab, delete holiday
  deleteHoliday: params => axios.delete(`public-holidays`, { params }),
  // Rate Management, Public Holidays tab, copy holiday
  copyHolidays: params => axios.post(`copy-public-holidays`, params),

  // All Candidates, edit profile, cessation dates input
  getShiftsCessationDates: params => axios.get(`temps/${params.tempId}/shifts-for-cessation-dates`, { params }),
  // All candidates, Rehire, send email
  sendWelcomeEmail: params => axios.get(`temps/${params.tempId}/send-welcome-email`, { params }),
  // Get country on details button, All Candidates page
  getCountries: params => axios.get(`nationalities`, { params }),
  // All candidates page, temp data on click rehire button
  externalTempinfo: params => axios.get(`temps/${params.tempId}/external-info`),
  // All candidates page, confirm rehire and send email
  confirmRehire: params => axios.post(`temps/${params.tempId}/rehire`, params),

  // Client External ID
  getClientInfoExternalId: params => axios.get(`clients/get-info-by-external-id/${params.id}`),
  // check the client fields if they are disabled
  getExternalServiceClientsDisabledFields: () => {
    return axios({
      url: "/clients/get-external-service-disabled-fields",
      method: "GET",
    });
  },
  refreshClientFromExternalInfo: params => axios.put(`clients/${params.id}/update-info-by-external-id`),

  // Get Shift Statuses
  getShiftStatuses: params => axios.get(`admin/shifts/payroll-statuses`),

  // Global rates management
  getGlobalRatesCategories: params => axios.get('global-rates/categories', { params }),
  postGlobalIncrementsCategory: params => axios.post('global-rates/increments-for-categories', params),
  getGlobalRates: params => axios.get('global-rates', { params }),
  patchGlobalRates: params => axios.patch("global-rates", params),
  postGlobalRates: params => axios.post('global-rates', params),
  deleteGlobalRates: params => axios.delete('global-rates', { params }),
  postGlobalColumnForRates: params => axios.post(`global-rates/values/${params.cit_id}`, params),
  patchGlobalRatesMulti: params => axios.patch('global-rates/multi', params),
  getGlobalRatesCalcItems: params => axios.get('global-rates/calcitems', { params }),
  getGlobalRatesClients: params => axios.get('global-rates/clients', { params }),
  postGlobalRatesFinalize: params => axios.post('global-rates/finalize', params),
  getGlobalRatesMultiDropdown: params => axios.get('global-rates/multi/items', { params }),
  getGlobalRatesCategoriesModal: params => axios.get('global-rates/categories-for-modal', { params }),
  getGlobalRatesValidate: params => axios.get('global-rates/validate', { params }),
  getGlobalRatesGaps: params => axios.get('global-rates/gaps', { params }),
  getGlobalRatesGapsReport: params => axios.post('global-rates/gaps-report', params, {responseType: 'blob'}),
  getRatesValidate: params => axios.get('rates/validate', { params }),
  getRatesGaps: params => axios.get('rates/gaps', { params }),
  getRatesGapsReport: params => axios.post('rates/gaps-report', params, {responseType: 'blob'}),
  getRatesCitReport: params => axios.post('rates/cit-report', params, {responseType: 'blob'}),
  getGlobalRatesCitReport: params => axios.post('global-rates/cit-report', params, {responseType: 'blob'}),
  deleteMultipleSpecificRates: params => axios.post(`rates/delete`, params),
  deleteMultipleGlobalRates: params => axios.post(`global-rates/delete`, params),

  // Expenses
  checkSignOff: params => axios.post(`expenses/${params.id}/sign-off-check`),

  /**
   * 
   * ALL FUTURE APIS WRITE IN TS FILE PLEASE!!
   * apiTs.ts
   */
};
