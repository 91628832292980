<template lang="pug">
tbody
  th
  th.status
  th
  th
  //- th
  template(v-if="hasAllowanceAgency")
    th.loc-allow Location Allowance
    th.secure-allow Secure Allowance
    th.community-allow(v-if="hasCommunityAllowance") Community Allowance
  th.oncall-shifts(v-if="hasOnCallShifts") Standby / On call shifts
  th.sleepover-shifts(v-if="hasSleepoverShifts") Sleepover shifts
  th.sleepover-shifts(v-if="canUseLocationExternalId") Billing Code
  th(colspan="100")
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    hasCommunityAllowance: {
      type: Boolean,
      default: false,
    },
    hasOnCallShifts: Boolean,
    hasSleepoverShifts: Boolean,
  },
  computed: {
    hasAllowanceAgency() {
      return this.initialStatus.can_view_allowances;
    },
  },
};
</script>

<style lang="scss" scoped>
th {
  background: #fefefe;

  &.loc-allow,
  &.secure-allow,
  &.community-allow,
  &.oncall-shifts {
    text-align: center;
  }
}
</style>
