/* eslint-disable @typescript-eslint/no-explicit-any */
import JSZip from "jszip";
import moment from "moment";
/**
 * Creates a zip files via JSZIP
 *
 * @param filesArr {{name:string, blob: any}}
 * @return Blob | null
 */
export async function createZipFromFiles(filesArr = []) {
    const jszip = new JSZip();
    if (filesArr.length === 0) {
        console.warn("No files to zip");
        return null;
    }
    filesArr.forEach((file) => {
        jszip.file(file.name, file.blob);
    });
    if (Object.keys(jszip.files).length === 0 &&
        jszip.files.constructor === Object) {
        console.log("no files ", jszip.files);
        return null;
    }
    try {
        const content = await jszip.generateAsync({ type: "blob" });
        return content;
    }
    catch (err) {
        console.warn(err.message);
    }
    return null;
}
export function getFormattedDateTime(datetime, inputFormat = "YYYY-MM-DD HH:mm:ss", includeAdditional = "") {
    const m = moment(datetime, inputFormat);
    let outputFormat = "DD/MM/YYYY";
    if (includeAdditional === "seconds") {
        outputFormat = "DD/MM/YYYY HH:mm:ss";
    }
    else if (includeAdditional === "time") {
        outputFormat = "DD/MM/YYYY HH:mm";
    }
    return m.isValid() ? m.format(outputFormat) : null;
}
export function getAllFlatRec(arr = []) {
    return arr.reduce((acc, curr) => {
        if (typeof curr.id === "number") {
            acc.push(curr);
        }
        if (curr.children?.length) {
            const items = getAllFlatRec(curr.children);
            acc.push(...items);
        }
        return acc;
    }, []);
}
export function getAllIdsRec(arr = []) {
    return arr.reduce((acc, curr) => {
        if (typeof curr.id === "number") {
            acc.push(curr.id);
        }
        if (curr.children?.length) {
            const ids = getAllIdsRec(curr.children);
            acc.push(...ids);
        }
        return acc;
    }, []);
}
const SHIFT_PARTS_KEY = "shift_parts";
const INCENTIVE = "incentive";
const INCENTIVE_CODE = "INCT";
const POSSIBLE_CVL_KEYS = [
    "code",
    "hours",
    "base_rate",
    "cvl_can_use_al_accumulation",
];
const SHIFT_DATA_POSSIBLE_KEYS = [
    "shift_date_time",
    "shift_break",
    "shift_parts",
    "service_fee",
    "incentive",
    "clc_code2",
    "shf_price_overwrite_note",
];
function shiftPriceOverwriteFormatTimeForBackEnd(time) {
    // time example "8:20"
    const timeArray = time.split(":");
    // hours are always at first place (0)
    const h = parseFloat(timeArray[0]) ?? 0;
    // convert minutes to hours
    const min = parseFloat(timeArray[1]) / 60 ?? 0;
    return h + min;
}
export function handleFormatingShiftPriceOverwriteParamsForBackend(data) {
    const formatedData = {
        rate: [],
        shift_parts: [],
    };
    if (data.shift_data) {
        for (const key in data.shift_data) {
            if (SHIFT_DATA_POSSIBLE_KEYS.indexOf(key) > -1) {
                const shiftData = data.shift_data[key];
                if (key === SHIFT_PARTS_KEY &&
                    shiftData.length > 0 &&
                    formatedData.shift_parts) {
                    for (const shiftPartIndex in shiftData) {
                        const spt = shiftData[shiftPartIndex];
                        if (!spt.spt_to.value && !spt.spt_from.value)
                            continue;
                        formatedData.shift_parts.push({
                            spt_from: spt.spt_from.value,
                            spt_to: spt.spt_to.value,
                            spt_type: spt.spt_from.spt_type,
                        });
                    }
                }
                if (key === INCENTIVE && shiftData) {
                    const shiftDataVal = shiftData[INCENTIVE_CODE];
                    if ((shiftDataVal && shiftDataVal.value) ?? 0 > 0) {
                        formatedData.rate.push({
                            cit_code: shiftDataVal.key,
                            amount: 1,
                            rate_value: shiftDataVal.value,
                            cvl_can_use_al_accumulation: 0,
                        });
                    }
                    continue;
                }
                for (const shiftDataKey in shiftData) {
                    const shiftDataVal = shiftData[shiftDataKey];
                    formatedData[shiftDataVal.key] = shiftDataVal.value;
                }
            }
        }
    }
    if (formatedData.shift_parts && formatedData.shift_parts.length === 0) {
        delete formatedData.shift_parts;
    }
    if (data.calculation_data) {
        for (const key in data.calculation_data) {
            const calculationSection = data.calculation_data[key] ?? null;
            const calculationSectionData = calculationSection.data ?? null;
            const calculationSectionMeta = calculationSection.meta ?? null;
            const calculationSectionCanBeEmpty = calculationSectionMeta.section_can_be_empty ?? false;
            if (!calculationSectionCanBeEmpty &&
                (!calculationSectionData ||
                    calculationSectionData.length === 0)) {
                // return error
                console.error("->->-> calculationSectionCanBeEmpty cannot be empty <-<-<-");
            }
            if (calculationSectionData && calculationSectionData.length > 0) {
                let hasAnyOfPossibleKeys = false;
                for (const cvlDataKey in calculationSectionData) {
                    const cvlData = calculationSectionData[cvlDataKey] ?? null;
                    let citCode = "";
                    let amount = 1;
                    let rateValue = 0;
                    let cvlCanUseAlAccumulation = 0;
                    for (const cvlKey of POSSIBLE_CVL_KEYS) {
                        // const cvlEl = cvlData[cvlKey] ?? null;
                        const cvlEl = cvlData[cvlKey] ?? null;
                        if (cvlEl) {
                            hasAnyOfPossibleKeys = true;
                        }
                        else {
                            continue;
                        }
                        const cvlElMeta = cvlEl ? cvlEl.meta ?? null : null;
                        const cvlElMetaType = cvlElMeta ? cvlElMeta.type ?? "" : "";
                        if (cvlKey === "code") {
                            citCode = cvlEl.value ?? "";
                        }
                        if (cvlKey === "base_rate") {
                            rateValue = parseFloat(cvlEl.value) ?? 0;
                        }
                        if (cvlKey === "cvl_can_use_al_accumulation") {
                            cvlCanUseAlAccumulation = cvlEl.value ?? 0;
                        }
                        if (cvlElMetaType === "time") {
                            amount = shiftPriceOverwriteFormatTimeForBackEnd(cvlEl.value);
                        }
                    }
                    if (hasAnyOfPossibleKeys) {
                        formatedData.rate.push({
                            cit_code: citCode,
                            amount: amount,
                            rate_value: rateValue,
                            cvl_can_use_al_accumulation: cvlCanUseAlAccumulation,
                        });
                    }
                }
            }
        }
    }
    return formatedData;
}
