<template lang="pug">
.wrapper
  .dialog-container-multiple(v-if="modalProps.isMultiple")
    .message
      p {{ modalProps.multipleCancelMessage }}
    .buttons
      button.button(@click="cancelMultipleShifts") OK
  .dialog-container-single(
    v-if="!modalProps.isMultiple && modalProps.singleCancelMessages && modalProps.singleCancelMessages.hasOwnProperty('before_api_1')"
  )
    .message
      p {{ currentMessage }}
    .buttons
      button.button.is-generic-app-blue.is-caps-lock(
        @click="cancelSingleShift",
        v-if="!infoMessageBefore2"
      ) Yes
      button.button.is-outlined.is-caps-lock(
        @click="showInfoMessageBefore2",
        v-if="modalProps.singleCancelMessages.hasOwnProperty('before_api_1') && !infoMessageBefore2"
      ) No
      button.button(@click="callModalClose", v-if="infoMessageBefore2") OK
  .dialog-container-success(v-if="modalProps.successfullMessage")
    .message
      p {{ modalProps.successfullMessage }}
    .buttons
      button.button.is-generic-app-blue.is-caps-lock(@click="callModalClose") OK
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["modalProps"],
  data() {
    return {
      currentMessage: "",
      infoMessageBefore2: false,
    };
  },
  mounted() {
    this.setCurrentMessage();
  },
  methods: {
    setCurrentMessage() {
      if (
        this.modalProps.singleCancelMessages &&
        this.modalProps.singleCancelMessages.before_api_1
      ) {
        this.currentMessage = this.modalProps.singleCancelMessages.before_api_1;
      }
    },
    showInfoMessageBefore2() {
      this.infoMessageBefore2 = true;
      this.currentMessage = this.modalProps.singleCancelMessages.before_api_2;
    },
    cancelMultipleShifts() {
      this.$emit("cancel-multiple-shifts", this.modalProps.shiftIds);
    },
    cancelSingleShift() {
      this.$emit("cancel-single-shift", this.modalProps);
    },
    callModalClose() {
      this.$emit("cancel-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-container-multiple,
.dialog-container-single,
.dialog-container-success {
  text-align: center;
  .message {
    margin: 10px 0 10px 0;
    p {
      background-color: white;
    }
  }
}
.buttons button {
  cursor: pointer;
  margin: 0 5px 10px 5px;
}
</style>
